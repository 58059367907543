import * as React from "react"
import {Button, Table, Image, Tag} from "antd";
import { Comment } from "../../../interfaces/comment.interface";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {format} from "date-fns";


interface IAdminCommentsTableProps {
  comments:Comment[];
  selectedComments:number[];
  publishComment: (id:number)=>void;
  archiveComment: (id:number)=>void;
  removeComment: (id:number)=>void;
  editComment: (comment: Comment)=>void;
  selectionChange: (selectedRows)=>void;
  onChange: (pagination:any)=>void;
  pagination: any;
  hideAction: boolean;
}

interface IAdminCommentsTableState {
  columns: any[];
}

class AdminCommentsTable extends React.Component <IAdminCommentsTableProps, IAdminCommentsTableState> {

  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'Article',
          dataIndex: 'article',
          render: (text, record) => {
            return <a href={`/articles-by-weddingsmalaysia/articles/${record.article.id}`}>{record.article.name}</a>
          }
        },
        {
          title: 'Name',
          dataIndex: 'name'
        },
        {
          title: 'Email',
          dataIndex: 'email'
        },
        {
          title: 'Website',
          dataIndex: 'website'
        },
        {
          title: 'Content',
          dataIndex: 'content'
        },
        {
          title: 'Date',
          dataIndex: 'createdAt',
          render: (text) => {
            return format(new Date(text), 'MMM dd, yyyy')
          }
        }
      ]
    };
  }

  componentDidMount() {
    if (this.props?.hideAction){
      this.setState({columns: [
          {
            title: 'Article',
            dataIndex: 'article',
            render: (text, record) => {
              return <a href={`/articles-by-weddingsmalaysia/articles/${record.article.id}`}>{record.article.name}</a>
            }
          },
          {
            title: 'Name',
            dataIndex: 'name'
          },
          {
            title: 'Email',
            dataIndex: 'email'
          },
          {
            title: 'Website',
            dataIndex: 'website'
          },
          {
            title: 'Content',
            dataIndex: 'content'
          },
          {
            title: 'Date',
            dataIndex: 'createdAt',
            render: (text) => {
              return format(new Date(text), 'MMM dd, yyyy')
            }
          }
        ]});
    } else {
      this.setState({columns: [
          {
            title: 'Article',
            dataIndex: 'article',
            render: (text, record) => {
              return <a href={`/articles-by-weddingsmalaysia/articles/${record.article.id}`}>{record.article.name}</a>
            }
          },
          {
            title: 'Name',
            dataIndex: 'name'
          },
          {
            title: 'Email',
            dataIndex: 'email'
          },
          {
            title: 'Website',
            dataIndex: 'website'
          },
          {
            title: 'Content',
            dataIndex: 'content'
          },
          {
            title: 'Date',
            dataIndex: 'createdAt',
            render: (text) => {
              return format(new Date(text), 'MMM dd, yyyy')
            }
          },
          {
            title: 'Action',
            key: 'operation',
            width: 200,
            render: (text, record) => {
              return <>
                <Button type="link" disabled={record.status == 'archived'} onClick={()=>this.props?.archiveComment(record.id)}>Archive</Button>
              </>
            },
          },
        ]});
    }
  }


  render() {
    const rowSelection = {
      // selectedRowKeys: this.props?.selectedComments,
      preserveSelectedRowKeys: false,
      onChange: (selectedRowKeys, selectedRows) => {
        this.props?.selectionChange(selectedRowKeys);
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };

    return (
      <div className="AdminBannersTable">
        <Table size="small" bordered rowKey="id"
          // rowSelection={{
          //   type: "checkbox",
          //   ...rowSelection,
          //   selectedRowKeys: this.props?.selectedComments
          // }}
          columns={this.state?.columns}
          dataSource={this.props?.comments}
          pagination={this.props?.pagination}
          onChange={this.props?.onChange}
        />
      </div>
    );
  }
}

export default AdminCommentsTable

