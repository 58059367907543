// import './WeddingDetailsStepForm.scss';

import * as React from 'react';
import { Form, FormInstance, Input, Radio, Checkbox, DatePicker, Slider, Button, notification, AutoComplete, InputNumber } from 'antd';
import { ReactSVG } from 'react-svg';
import { startCase } from 'lodash';
import * as moment from 'moment'

import { User } from '../../../interfaces/user.interface';

import UserApi from '../../../api-services/user-api.service';
import CityApi from '../../../api-services/city-api.service';

const accountOutline = require('@mdi/svg/svg/account-outline.svg');
const accountMultipleOutline = require('@mdi/svg/svg/account-multiple-outline.svg');
const homeOutline = require('@mdi/svg/svg/home-outline.svg');
const mapMarkerOutline = require('@mdi/svg/svg/map-marker-outline.svg');
const calendar = require('@mdi/svg/svg/calendar.svg');
const cashMultiple = require('@mdi/svg/svg/cash-multiple.svg');


interface WeddingDetailsStepFormProps {
  user: User;
  cancelButtonText?: string;
  weddingDetailsCancelled?: () => void;
  weddingDetailsSaved?: () => void;
}


interface WeddingDetailsStepFormState {
  currentFormStep: number;
  currentFormStepHasErrors: boolean;

  selectedRole: string;
  savingChanges: boolean;
  currency: string;

  userRoleOptions: Array<{ label: string; value: string; }>;
  cityOptions: any[];
  homeCityOptions: any[];
}


class WeddingDetailsStepForm extends React.Component<WeddingDetailsStepFormProps, WeddingDetailsStepFormState> {

  cityApi = new CityApi;
  userApi = new UserApi;

  weddingDetailsForm = React.createRef<FormInstance>();
  
  getCityOptionsTimeout: any;
  getHomeCityOptionsTimeout: any;
  formValueChangeTimeout: any;
  validateCurrentFormStepTimeout: any;

  
  componentDidMount() {
    this.setState({
      currentFormStep: 1,
      userRoleOptions: [
        { label: 'The Bride', value: 'bride' },
        { label: 'The Groom', value: 'groom' },
        { label: 'JUST BROWSING', value: 'just_browsing' },
      ],
      savingChanges: false,
      cityOptions: [],
      homeCityOptions: [],
      currency: 'MYR'
    });
  }
  
  
  componentDidUpdate(previousProps: WeddingDetailsStepFormProps) {
    if(this.props?.user !== previousProps.user) {
      this.populateFormValues(this.props?.user);
      this.getCurrency();
      this.toggleJustBrowsingOption();
    }
  }


  populateFormValues(user: User): void {
    const weddingDate = (user?.weddingDetail?.date) ? moment(user?.weddingDetail?.date) : null;
    
    this.weddingDetailsForm.current?.setFieldsValue({
      firstName: user?.firstName,
      lastName: user?.lastName,
      weddingRole: user?.weddingDetail?.role,
      partnerFirstName: user?.weddingDetail?.partnerFirstName,
      partnerLastName: user?.weddingDetail?.partnerLastName,
      city: user?.weddingDetail?.city?.id,
      cityName: user?.weddingDetail?.city?.name,
      homeCity: user?.weddingDetail?.homeCity?.id,
      homeCityName: user?.weddingDetail?.homeCity?.name,
      weddingDate: weddingDate,
      weddingBudget: (user?.weddingDetail?.budget) ? user?.weddingDetail?.budget : 10000,
      weddingGuests: user?.weddingDetail?.guests,
    });
  }


  /**
   * Get the currency symbol from user.weddingDetails.
   */
  getCurrency(): void {
    const currency = this.props?.user?.weddingDetail?.budgetCurrency;
    this.setState({ currency: currency });
  }


  nextStepForm(): void {
    if(this.state?.currentFormStep < 4) {
      this.setState({ currentFormStep: this.state?.currentFormStep + 1 });
    }
  }


  prevStepForm(): void {
    if(this.state?.currentFormStep > 1) {
      this.setState({ currentFormStep: this.state?.currentFormStep - 1 });
    }
  }


  /**
   * Hide or display "Just browsing" option under Wedding Details.
   * Hide it if the loaded user profile has selected Groom or Bride role.
   */
   toggleJustBrowsingOption(): void {
    if(['bride', 'groom'].includes(this.props?.user?.weddingDetail?.role)) {
      this.setState({
        userRoleOptions: [
          { label: 'THE BRIDE', value: 'bride' },
          { label: 'THE GROOM', value: 'groom' },
        ],
        selectedRole: this.props?.user?.weddingDetail?.role
      });
    }

    else {
      this.setState({
        userRoleOptions: [
          { label: 'THE BRIDE', value: 'bride' },
          { label: 'THE GROOM', value: 'groom' },
          { label: 'JUST BROWSING', value: 'just_browsing' },
        ],
        selectedRole: 'just_browsing'
      });
    }
  }


  /**
   * CONSIDER moving this method into the parent page component.
   */
  saveWeddingDetails(): void {
    const formValues = this.weddingDetailsForm.current.getFieldsValue();
    
    const payload = {
      firstName: formValues?.firstName,
      lastName: formValues?.lastName,
      weddingRole: formValues?.weddingRole,
      partnerFirstName: formValues?.partnerFirstName,
      partnerLastName: formValues?.partnerLastName,
      city: (formValues.cityUndecided) ? null : formValues.city,
      homeCity: formValues.homeCity,
      weddingDate: (formValues.weddingDateUndecided) ? null : formValues?.weddingDate,
      weddingBudget: (formValues.weddingBudgetUndecided) ? null : formValues?.weddingBudget,
      weddingBudgetCurrency: (this.state?.currency) ? this.state?.currency : 'MYR',
      weddingGuests: (formValues.weddingGuestsUndecided) ? null : formValues?.weddingGuests,
    };

    this.userApi.saveUser(payload)
    .then(response => {
      notification.success({ message: 'Changes saved successfully', className: 'antd-mod center' });
      this.props?.weddingDetailsSaved();
    })
    .catch(error => {
      console.error(error);

      const errorKeys = Object.keys(error?.response?.data?.errors);
      errorKeys.forEach(errorKeyName => {
        const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
        notification.error({ message: errorMessage, className: 'antd-mod center' });
      });
    })
    .finally(() => {
      this.setState({ savingChanges: false });
    });
  }


  getCityOptions(keyword: string) {
    if(this.getCityOptionsTimeout) clearTimeout(this.getCityOptionsTimeout);
    
    this.getCityOptionsTimeout = setTimeout(() => {
      this.cityApi.searchCities(keyword, 10).then(cities => {
        const options = cities.map(city => {
          return { key: `${city.id}-${city.name}`, value: city.name, id: city.id, name: city.name };
        });

        this.setState({ cityOptions: options });

        // Set the first option as the selected item.
        if(options.length) {
          this.weddingDetailsForm?.current?.setFieldsValue({ city: options[0].id });
        }
      });
    }, 250);
  }


  getHomeCityOptions(keyword: string) {
    if(this.getHomeCityOptionsTimeout) clearTimeout(this.getHomeCityOptionsTimeout);
    
    this.getHomeCityOptionsTimeout = setTimeout(() => {
      this.cityApi.searchCities(keyword, 10).then(cities => {
        const options = cities.map(city => {
          return { key: `${city.id}-${city.name}`, value: city.name, id: city.id, name: city.name };
        });
        
        this.setState({ homeCityOptions: options });

        // Set the first option as the selected item.
        if(options.length) {
          this.weddingDetailsForm?.current?.setFieldsValue({ homeCity: options[0].id });
        }
      });
    }, 250);
  }


  /**
   * Manually check if the current form step has errors.
   */
  validateCurrentFormStep(): void {
    if(this.validateCurrentFormStepTimeout) {
      clearTimeout(this.validateCurrentFormStepTimeout);
    }
    
    this.validateCurrentFormStepTimeout = setTimeout(() => {
      const fields = this.weddingDetailsForm?.current?.getFieldsError();
      const formStep1FieldNames = ['firstName', 'lastName', 'weddingRole', 'partnerFirstName', 'partnerLastName'];
      const formStep2FieldNames = ['homeCity', 'city', 'homeCityName', 'cityName'];
      const formStep3FieldNames = ['weddingDate'];
      const formStep4FieldNames = ['weddingBudget', 'weddingGuests'];
      let currentFormStepHasErrors: boolean = false;
  
      fields?.forEach(field => {
        if(this.state?.currentFormStep === 1 && formStep1FieldNames.includes(field.name[0] as string) && field.errors.length) {
          currentFormStepHasErrors = true;
        } else if(this.state?.currentFormStep === 2 && formStep2FieldNames.includes(field.name[0] as string) && field.errors.length) {
          currentFormStepHasErrors = true;
        } else if(this.state?.currentFormStep === 3 && formStep3FieldNames.includes(field.name[0] as string) && field.errors.length) {
          currentFormStepHasErrors = true;
        } else if(this.state?.currentFormStep === 4 && formStep4FieldNames.includes(field.name[0] as string) && field.errors.length) {
          currentFormStepHasErrors = true;
        }
      });

      this.setState({
        currentFormStepHasErrors: currentFormStepHasErrors
      });
    }, 250);
  }
  
  
  renderFormStep1() {
    const displayNone = (this.state?.currentFormStep === 1) ? {} : { display: 'none' };
    
    return (
      <div className="form-step step-1" style={displayNone}>
        <div className="form-section">
          <div className="title-container">
            <ReactSVG src={accountOutline} className="icon" />
            <div className="title typo-title typo-bold">My name is</div>
          </div>
          
          <div className="row-inputs">
            <Form.Item name="firstName" rules={[{ required: true, message: 'First name is required' }]}>
              <Input size="large" placeholder="First Name" />
            </Form.Item>
            <Form.Item name="lastName" rules={[{ required: true, message: 'Last name is required' }]}>
              <Input size="large" placeholder="Last Name" />
            </Form.Item>
          </div>

          <div className="title-container">
            <div className="title typo-title typo-bold">and I'm</div>
          </div>

          <Form.Item name="weddingRole">
            <Radio.Group options={this.state?.userRoleOptions} onChange={(event) => { this.setState({selectedRole: event.target.value}) }} size="large" optionType="button" className="antd-mod typo-bold" />
          </Form.Item>
        </div>

        <div className="form-section">
          <div className="title-container">
            <ReactSVG src={accountMultipleOutline} className="icon" />
            <div className="title typo-title typo-bold">My partner's name is</div>
          </div>

          <div className="row-inputs">
            <Form.Item name="partnerFirstName" rules={[{ required: true, message: 'Partner\'s First name is required' }]}>
              <Input size="large" placeholder="First Name" disabled={this.weddingDetailsForm?.current?.getFieldValue('weddingRole') === 'just_browsing'} />
            </Form.Item>
            <Form.Item name="partnerLastName" rules={[{ required: true, message: 'Partner\'s Last name is required' }]}>
              <Input size="large" placeholder="Last Name" disabled={this.weddingDetailsForm?.current?.getFieldValue('weddingRole') === 'just_browsing'} />
            </Form.Item>
          </div>
        </div>
      </div>
    );
  }


  renderFormStep2() {
    const displayNone = (this.state?.currentFormStep === 2) ? {} : { display: 'none' };

    return (
      <div className="form-step step-2" style={displayNone}>
        <div className="form-section">
          <div className="title-container">
            <ReactSVG src={homeOutline} className="icon" />
            <div className="title typo-title typo-bold">We're living in</div>
          </div>
          
          <Form.Item name="homeCity" rules={[{ required: true }]} style={{ display: 'none' }}>
            <Input size="large" placeholder="City" />
          </Form.Item>

          <Form.Item name="homeCityName" rules={[{ required: true, message: 'Home City is required' }]}>
            <AutoComplete
              options={this.state?.homeCityOptions} 
              onSelect={(value, option) => { this.weddingDetailsForm.current.setFieldsValue({ homeCity: option.id }) }} 
              onSearch={(value) => this.getHomeCityOptions(value) } 
              onBlur={() => { this.weddingDetailsForm?.current?.setFieldsValue({ homeCityName: this.state?.homeCityOptions[0]?.name }) }}
              size="large" placeholder="City" 
            />
          </Form.Item>
        </div>

        <div className="form-section">
          <div className="title-container">
            <ReactSVG src={mapMarkerOutline} className="icon" />
            <div className="title typo-title typo-bold">Where we're getting married</div>
          </div>
          
          <Form.Item name="city" style={{ display: 'none' }}>
            <Input size="large" placeholder="City" />
          </Form.Item>
          
          <Form.Item name="cityName" className="cityName">
            <AutoComplete
              options={this.state?.cityOptions} 
              onSelect={(value, option) => { this.weddingDetailsForm.current.setFieldsValue({ city: option.id }) }} 
              onSearch={(value) => this.getCityOptions(value) } 
              onBlur={() => { this.weddingDetailsForm?.current?.setFieldsValue({ cityName: this.state?.cityOptions[0]?.name }) }}
              disabled={this.weddingDetailsForm?.current?.getFieldValue('cityUndecided')}
              size="large" placeholder="City" 
            />
          </Form.Item>

          <Form.Item name="cityUndecided" className="cityUndecided" valuePropName="checked">
            <Checkbox className="antd-mod">We haven't decided yet</Checkbox>
          </Form.Item>
        </div>
      </div>
    );
  }


  renderFormStep3() {
    const displayNone = (this.state?.currentFormStep === 3) ? {} : { display: 'none' };
    
    return (
      <div className="form-step step-3" style={displayNone}>
        <div className="form-section">
          <div className="title-container">
            <ReactSVG src={calendar} className="icon" />
            <div className="title typo-title typo-bold">When we're getting married</div>
          </div>
          
          <Form.Item name="weddingDate" className="weddingDate">
            <DatePicker size="large" disabled={this.weddingDetailsForm?.current?.getFieldValue('weddingDateUndecided')} placeholder="Select Preferred Date" />
          </Form.Item>

          <Form.Item name="weddingDateUndecided" className="weddingDateUndecided" valuePropName="checked">
            <Checkbox className="antd-mod">We haven't decided yet</Checkbox>
          </Form.Item>
        </div>
      </div>
    );
  }


  renderFormStep4() {
    const displayNone = (this.state?.currentFormStep === 4) ? {} : { display: 'none' };
    
    return (
      <div className="form-step step-4" style={displayNone}>
        <div className="form-section">
          <div className="title-container">
            <ReactSVG src={cashMultiple} className="icon" />
            <div className="title typo-title typo-bold">Our maximum wedding budget is</div>
          </div>
          
          <Form.Item name="weddingBudget">
            <Slider min={10000} max={5000000} tipFormatter={value => `${this.state?.currency} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
          </Form.Item>
          
          <div className="wedding-budget-container">
            <Form.Item name="weddingBudget" rules={[{ type: 'number', min: 10000 }, { type: 'number', max: 5000000 }]} className="weddingBudget">
              <InputNumber size="large" min={10000} max={5000000} disabled={this.weddingDetailsForm?.current?.getFieldValue('weddingBudgetUndecided')} />
            </Form.Item>
            <div className="currency typo-subheading-1">{this.state?.currency}</div>
          </div>

          <Form.Item name="weddingBudgetUndecided" className="weddingBudgetUndecided" valuePropName="checked">
            <Checkbox className="antd-mod">We haven't decided yet</Checkbox>
          </Form.Item>
        </div>

        <div className="form-section">
          <div className="title-container">
            <ReactSVG src={accountMultipleOutline} className="icon" />
            <div className="title typo-title typo-bold">How many guests we're inviting</div>
          </div>
          
          <Form.Item name="weddingGuests">
            <Slider min={0} max={1000} />
          </Form.Item>
          
          <Form.Item name="weddingGuests" rules={[{ type: 'number', min: 0 }, { type: 'number', max: 1000 }]} className="weddingGuests">
            <InputNumber size="large" min={0} max={1000} disabled={this.weddingDetailsForm?.current?.getFieldValue('weddingGuestsUndecided')} />
          </Form.Item>

          <Form.Item name="weddingGuestsUndecided" className="weddingGuestsUndecided" valuePropName="checked">
            <Checkbox className="antd-mod">We haven't decided yet</Checkbox>
          </Form.Item>
        </div>
      </div>
    );
  }


  renderActionButtons() {
    const cancelButtonText = (this.props?.cancelButtonText) ? this.props?.cancelButtonText : 'Cancel';
    
    return (
      <div className="action-buttons">
        <Button 
          className="antd-mod" size="large" type="default" 
          style={{ display: (this.state?.currentFormStep === 1) ? '' : 'none' }} 
          onClick={() => { this.props?.weddingDetailsCancelled(); }}
        >{cancelButtonText}</Button> {/* Should be run when you click the cancel button. */}

        <Button 
          className="antd-mod" size="large" type="default" 
          style={{ display: (this.state?.currentFormStep === 1) ? 'none' : '' }} 
          onClick={() => this.prevStepForm()}
        >Previous</Button>

        <Button 
          className="antd-mod color-primary" size="large" type="primary" 
          disabled={this.state?.currentFormStepHasErrors} 
          style={{ display: (this.state?.currentFormStep === 4) ? 'none' : '' }} 
          onClick={() => this.nextStepForm()}
        >Next</Button>

        <Button 
          className="antd-mod color-primary" size="large" type="primary" 
          disabled={this.state?.currentFormStepHasErrors} 
          loading={this.state?.savingChanges} 
          onClick={() => this.saveWeddingDetails()} 
          style={{ display: (this.state?.currentFormStep === 4) ? '' : 'none' }}
        >Save Changes</Button>
      </div>
    );
  }
  
  
  render() {
    return (
      <div className="WeddingDetailsStepForm">
        <div className="title typo-title-2 typo-bold">Wedding Details</div>
        
        <Form ref={this.weddingDetailsForm} layout="horizontal" onValuesChange={(changedValues, allValues) => { this.validateCurrentFormStep(); this.setState({}); }}>
          {this.renderFormStep1()}
          {this.renderFormStep2()}
          {this.renderFormStep3()}
          {this.renderFormStep4()}
        </Form>
        {this.renderActionButtons()}
      </div>
    );
  }
  
}




export default WeddingDetailsStepForm;