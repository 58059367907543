import axios from 'axios';
import * as moment from 'moment';
import { Banner } from "../interfaces/banner.interface";

class BannerAdminApi {

  getBanners(): Promise<Banner[]>{
    const apiUrl = `/api/v1/admin/banners`;

    return axios.get(apiUrl)
    .then(response => {
      const banners: Banner[] = [];
      response?.data?.data?.forEach(bannerData => {
        banners.push(this.mapBannerResponse(bannerData?.attributes));
      });
      
      return Promise.resolve(banners);
    });
  }

  addBanner(bannerData:FormData): Promise<Banner>{
    const apiUrl = `/api/v1/admin/banners`;
    
    return axios({
      method: "post",
      url: apiUrl,
      data: bannerData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response)=> {
      const banner:Banner = this.mapBannerResponse(response?.data?.data?.attributes);
      return banner;
    })
  }

  updateBanner(bannerId:number, bannerData:FormData): Promise<Banner>{
    const apiUrl = `/api/v1/admin/banners/${bannerId}`;

    return axios({
      method: "put",
      url: apiUrl,
      data: bannerData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response)=> {
      const banner:Banner = this.mapBannerResponse(response?.data?.data?.attributes);
      return banner;
    })
  }

  removeBanner(banners:number[]){
    const apiUrl = `/api/v1/admin/banners/${banners}`;
    return axios.delete(apiUrl).then(response => {
      return Promise.resolve(true);
    });
  }

  publishBanner(bannerId:number): Promise<Banner>{
    const apiUrl = `/api/v1/admin/banners/${bannerId}/publish`;
    return axios.patch(apiUrl).then(response => {
      const banner:Banner = this.mapBannerResponse(response?.data?.data?.attributes);
      return Promise.resolve(banner);
    });
  }

  unpublishBanner(bannerId:number): Promise<Banner>{
    const apiUrl = `/api/v1/admin/banners/${bannerId}/unpublish`;
    return axios.patch(apiUrl).then(response => {
      const banner:Banner = this.mapBannerResponse(response?.data?.data?.attributes);
      return Promise.resolve(banner);
    });
  }

  mapBannerResponse(bannerData){
    return {
      id: bannerData?.id,
      title: bannerData?.title,
      link: bannerData?.link,
      position: bannerData?.position,
      placement: bannerData?.placing,
      image: bannerData?.image_url,
      mayPublish: bannerData?.may_publish,
      mayUnpublish: bannerData?.may_unpublish,
      showStart: bannerData?.show_start,
      showEnd: bannerData?.show_end,
      clickCount: bannerData?.click_count
    };
  }
}

export default BannerAdminApi;
