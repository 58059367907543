// import './ProfileSetupStepsPage.scss';

import * as React from 'react';
import { notification, Button, Modal } from 'antd';

import { User } from '../../../interfaces/user.interface';
import { Country } from '../../../interfaces/country.interface';

import CountryApi from '../../../api-services/country-api.service';
import UserApi from '../../../api-services/user-api.service';

import PhoneVerifyForm from '../../basic-components/phone-verify-form/PhoneVerifyForm';
import WeddingDetailsStepForm from '../../basic-components/wedding-details-step-form/WeddingDetailsStepForm';
import RegistrationGreetingModal from '../../modals/registration-greeting-modal/RegistrationGreetingModal';


notification.config({
  duration: 12,
  top: 60
})


interface ProfileSetupStepsPageProps {
  isAccountSignedIn: boolean;
  googleSignInUrl: string;
  fbSignInUrl: string;
  csrfToken:string;
}


interface ProfileSetupStepsPageState {
  user: User;
  currentFormStep: number;
  loading: boolean;
  countries: Country[];
  defaultCountry: Country;
  verificationCodeSent: boolean;
  greetingsModalVisible: boolean;
}



class ProfileSetupStepsPage extends React.Component<ProfileSetupStepsPageProps, ProfileSetupStepsPageState> {

  countryApi = new CountryApi;
  userApi = new UserApi;
  phoneVerifyFormFormRef;
  
  
  componentDidMount() {
    this.setState({
      currentFormStep: 1,
      loading: false,
      countries: [],
      verificationCodeSent: false,
      greetingsModalVisible: false
    });
    
    this.loadSignedInUserProfile();

    setTimeout(() => {
      this.loadCountries();
    }, 500);
  }


  loadSignedInUserProfile(): void {
    if(!this.props?.isAccountSignedIn) {
      return null;
    }
    
    const userApi = new UserApi;

    userApi.getUserProfile()
    .then(user => {
      this.setState({ user: user });
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: 'Error while loading the user\'s profile.', className: 'antd-mod center' });
    });
  }


  /**
   * Load list of countries.
   * Also set the default country from list. Use MY as default then do not set if MY is not available.
   */
  loadCountries(): void {
    this.countryApi.getCountries()
    .then(countries => {
      const defaultCountry = countries.find(country => country.name === 'MALAYSIA');
      this.setState({
        countries: countries,
        defaultCountry: defaultCountry
      });
    })
    .catch(error => {
      notification.error({ message: 'An error occured while loading the data for countries', className: 'antd-mod center' });
    })
  }


  phoneVerifyFormSendCode(): void {
    this.setState({ loading: true });
    this.phoneVerifyFormFormRef?.phoneNumberForm?.current?.submit();
  }


  phoneVerifyFormVerifyCode(): void {
    this.setState({ loading: true });
    this.phoneVerifyFormFormRef?.verificationCodeForm?.current?.submit();
  }


  showPhoneVerifiedModal(): void {
    Modal.success({
      title: 'Hooray!',
      content: 'Let\'s get started on planning your dream wedding via Bridepay!',
      onOk: () => {
        this.setState({ currentFormStep: 2 });
      }
    });
  }
  
  
  renderPhoneVerifyForm() {
    return (
      <div className="form-step" style={{ display: (this.state?.currentFormStep === 1) ? '':'none' }}>
        <PhoneVerifyForm 
          countries={this.state?.countries} showSubmitButtons={false}
          defaultCountry={this.state?.defaultCountry}
          ref={(instance) => this.phoneVerifyFormFormRef = instance } 
          codeSent={() => { this.setState({ loading: false, verificationCodeSent: true }) }}
          codeNotSent={() => { this.setState({ loading: false }) }}
          codeVerified={() => { this.setState({ loading: false }); this.showPhoneVerifiedModal(); }}
          codeNotVerified={() => { this.setState({ loading: false }) }}
          formValueChanged={() => { this.setState({}) }}
        ></PhoneVerifyForm>

        <div className="action-buttons">
          <Button className="antd-mod color-primary" size="large" type="primary"
            loading={this.state?.loading}
            onClick={() => { this.phoneVerifyFormSendCode() }}
            style={{ display: (this.state?.verificationCodeSent) ? 'none':'' }}
          >Send Code</Button>

          <Button className="antd-mod color-primary" size="large" type="primary"
            onClick={() => { this.phoneVerifyFormVerifyCode() }}
            style={{ display: (this.state?.verificationCodeSent) ? '':'none' }}
          >Verify & Proceed</Button>
        </div>
      </div>
    );
  }


  /**
   * Take note that WeddingDetailsStepForm is a step in itself, so we will just use its own buttons.
   */
  renderWeddingDetailsForm() {
    return (
      <div className="form-step" style={{ display: (this.state?.currentFormStep === 2) ? '':'none' }}>
        <WeddingDetailsStepForm 
          user={this.state?.user} 
          weddingDetailsCancelled={() => { window.location.href = '/'; }} 
          weddingDetailsSaved={() => { this.setState({ greetingsModalVisible: true }) }}
        ></WeddingDetailsStepForm>
      </div>
    );
  }
  
  
  render() {
    return (
      <div className="ProfileSetupStepsPage">
        <div className="main-content">
          {this.renderPhoneVerifyForm()}
          {this.renderWeddingDetailsForm()}
        </div>

        <RegistrationGreetingModal visible={this.state?.greetingsModalVisible} closed={() => { this.setState({ greetingsModalVisible: false }); window.location.href = '/profile'; }}></RegistrationGreetingModal>
      </div>
    );
  }
  
}




export default ProfileSetupStepsPage;