import axios from 'axios';
import { BlogCategory } from "../interfaces/blog-category.interface";

class BlogCategoryApi {

  getCategories(subquery: 'home'|'nav'): Promise<BlogCategory[]>{
    let apiUrl = `/api/v1/blog-categories`;

    if (subquery) apiUrl = `/api/v1/blog-categories/${subquery}`;

    return axios.get(apiUrl)
    .then(response => {
      const categories: BlogCategory[] = [];
      response?.data?.data?.forEach(categoriesData => {
        categories.push(this.mapCategoryResponse(categoriesData?.attributes));
      });
      
      return Promise.resolve(categories);
    });
  }

  getCategory(id: number|string): Promise<BlogCategory>{
    const apiUrl = `/api/v1/blog-categories/${id}`;

    return axios.get(apiUrl)
        .then(response => {
          return Promise.resolve(this.mapCategoryResponse(response?.data?.data?.attributes));
        });
  }

  mapCategoryResponse(categoryData){
    return {
      id: categoryData?.id,
      name: categoryData?.name,
      ancestry: categoryData?.ancestry,
      position: categoryData?.position,
      image: categoryData?.image_url,
      showInHomepage: categoryData?.show_in_homepage,
      showInNavButton: categoryData?.show_in_nav_button,
      slug: categoryData?.slug,
      banners: categoryData?.blog_banners.map(banner => {
        return {
          id: banner.id,
          title: banner.title,
          placing: banner.placing,
          position: banner.position,
          status: banner.status,
          image: banner.image_url,
          link: banner.link
        }
      })
    };
  }
}

export default BlogCategoryApi;