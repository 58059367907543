// import './WeddingDetailsPage.scss';

import * as React from 'react';
import { notification } from 'antd';

import { User } from '../../../interfaces/user.interface';
import UserApi from '../../../api-services/user-api.service';

import WeddingDetailsStepForm from '../../basic-components/wedding-details-step-form/WeddingDetailsStepForm';
import Navbar from '../../basic-components/navbar/Navbar';
import UserProfileNavigation from '../../basic-components/user-profile-navigation/UserProfileNavigation';


interface WeddingDetailsPageProps {
  isAccountSignedIn: boolean;
}


interface WeddingDetailsPageState {
  user: User;
  selectedSection: 'accountDetails'|'weddingDetails';
}


class WeddingDetailsPage extends React.Component<WeddingDetailsPageProps, WeddingDetailsPageState> {

  userApi = new UserApi;
  
  
  componentDidMount() {
    this.setState({
      selectedSection: 'weddingDetails'
    });
    
    this.loadSignedInUserProfile();
  }


  /**
   * Get the signed in user's profile from server if there is a user signed in.
   */
  loadSignedInUserProfile(): void {
    if(!this.props?.isAccountSignedIn) {
      return null;
    }
    
    this.userApi.getUserProfile()
    .then(user => {
      this.setState({ user: user });
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: 'Error while loading the user\'s profile.', className: 'antd-mod center' });
    });
  }
  
  
  render() {
    return (
      <div className="WeddingDetailsPage">
        <Navbar isAccountSignedIn={this.props?.isAccountSignedIn} user={this.state?.user}></Navbar>
        
        <div className="main-content">
          <UserProfileNavigation selectedNavigation="weddingDetails"></UserProfileNavigation>

          <WeddingDetailsStepForm 
            user={this.state?.user} 
            weddingDetailsCancelled={() => { window.location.href = '/profile'; }} 
            weddingDetailsSaved={() => { window.location.href = '/profile'; }}
          ></WeddingDetailsStepForm>
        </div>
      </div>
    );
  }
  
}



export default WeddingDetailsPage;