import * as React from 'react';
import { notification } from 'antd';

import UserApi from '../../../api-services/user-api.service';
import AuthApi from '../../../api-services/auth-api.service';

import Navbar from '../../basic-components/navbar/Navbar';
import LoginModal from '../../modals/login-modal/LoginModal';
import Footer from '../../basic-components/footer/Footer';
import BreadcrumbsNavigation from  '../../basic-components/breadcrumbs-navigation/BreadcrumbsNavigation';
import ForgotPasswordModal from '../../modals/forgot-password-modal/ForgotPasswordModal';

notification.config({
  duration: 12,
  top: 60
})

class PrivacyPage extends React.Component {

  userApi = new UserApi;
  authApi = new AuthApi;

  breadcrumbsNavItems = [
    { id: 1, label: 'Home', link: '/' },
    { id: 2, label: 'Careers', link: '/careers' },
  ];

  componentDidMount(): void {
    this.setState({
      loginModalVisible: false,
      forgotPasswordModalVisible: false
    });

    this.loadSignedInUserProfile();
  }


  loadSignedInUserProfile(): void {
    if(!this.props?.isAccountSignedIn) {
      return null;
    }

    this.userApi.getUserProfile()
      .then(user => {
        this.setState({
          loggedOnUser: user
        });
      })
      .catch(error => {
        console.error(error);
        notification.error({ message: 'Error while loading the user\'s profile.', className: 'antd-mod center' });
      });
  }

  render() {
    return (
      <div className="ContactPage">
        <Navbar
          isAccountSignedIn={this.props?.isAccountSignedIn}
          user={this.state?.loggedOnUser}
          signInRequired={(redirectUrl) => { this.setState({ loginModalVisible: true, loginModalRedirectUrl: redirectUrl }); }}
        />

        <LoginModal
          visible={this.state?.loginModalVisible}
          googleSignInUrl={this.props?.googleSignInUrl}
          fbSignInUrl={this.props?.fbSignInUrl}
          csrfToken={this.props?.csrfToken}
          redirectUrl={this.state?.loginModalRedirectUrl}
          forgotPasswordClicked={() => { this.setState({ loginModalVisible: false, forgotPasswordModalVisible: true }) }}
          closed={() => { this.setState({ loginModalVisible: false }) }}
          registerLink="/registration"
        />

        <ForgotPasswordModal
          visible={this.state?.forgotPasswordModalVisible}
          loginClicked={() => { this.setState({ forgotPasswordModalVisible: false, loginModalVisible: true }) }}
          closed={() => { this.setState({ forgotPasswordModalVisible: false }) }}
        />

        <div className="page-content">
          <BreadcrumbsNavigation items={this.breadcrumbsNavItems} />
        </div>

        <div className="page-content main-content">
          <div className="category-header">
            <div className="content">
              <div className="title typo-headline">Careers</div>
            </div>
          </div>

          <br/>
          <br/>
          <p>
            Should you be interested to learn more about possible career opportunities with us, kindly email your resume to <a href="mailto:kris@bridepay.com">kris@bridepay.com</a>.
          </p>
        </div>

        <Footer userType={this.state?.loggedOnUser?.type}/>
      </div>
    );
  }

}

export default PrivacyPage;