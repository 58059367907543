import * as React from 'react';
import { notification } from 'antd';

import UserApi from '../../../api-services/user-api.service';
import AuthApi from '../../../api-services/auth-api.service';

import Navbar from '../../basic-components/navbar/Navbar';
import LoginModal from '../../modals/login-modal/LoginModal';
import Footer from '../../basic-components/footer/Footer';
import BreadcrumbsNavigation from  '../../basic-components/breadcrumbs-navigation/BreadcrumbsNavigation';
import ForgotPasswordModal from '../../modals/forgot-password-modal/ForgotPasswordModal';

notification.config({
  duration: 12,
  top: 60
})

class PrivacyPage extends React.Component {

  userApi = new UserApi;
  authApi = new AuthApi;

  breadcrumbsNavItems = [
    { id: 1, label: 'Home', link: '/' },
    { id: 2, label: 'Risk Statement', link: '/risk' },
  ];

  componentDidMount(): void {
    this.setState({
      loginModalVisible: false,
      forgotPasswordModalVisible: false
    });

    this.loadSignedInUserProfile();
  }


  loadSignedInUserProfile(): void {
    if(!this.props?.isAccountSignedIn) {
      return null;
    }

    this.userApi.getUserProfile()
      .then(user => {
        this.setState({
          loggedOnUser: user
        });
      })
      .catch(error => {
        console.error(error);
        notification.error({ message: 'Error while loading the user\'s profile.', className: 'antd-mod center' });
      });
  }

  render() {
    return (
      <div className="ContactPage">
        <Navbar
          isAccountSignedIn={this.props?.isAccountSignedIn}
          user={this.state?.loggedOnUser}
          signInRequired={(redirectUrl) => { this.setState({ loginModalVisible: true, loginModalRedirectUrl: redirectUrl }); }}
        />

        <LoginModal
          visible={this.state?.loginModalVisible}
          googleSignInUrl={this.props?.googleSignInUrl}
          fbSignInUrl={this.props?.fbSignInUrl}
          csrfToken={this.props?.csrfToken}
          redirectUrl={this.state?.loginModalRedirectUrl}
          forgotPasswordClicked={() => { this.setState({ loginModalVisible: false, forgotPasswordModalVisible: true }) }}
          closed={() => { this.setState({ loginModalVisible: false }) }}
          registerLink="/registration"
        />

        <ForgotPasswordModal
          visible={this.state?.forgotPasswordModalVisible}
          loginClicked={() => { this.setState({ forgotPasswordModalVisible: false, loginModalVisible: true }) }}
          closed={() => { this.setState({ forgotPasswordModalVisible: false }) }}
        />

        <div className="page-content">
          <BreadcrumbsNavigation items={this.breadcrumbsNavItems} />
        </div>

        <div className="page-content main-content">
          <div className="category-header">
            <div className="content">
              <div className="title typo-headline">Risk Statement</div>
            </div>
          </div>

          <br/>
          <br/>
          <h3><strong>Bridepay's limitation of responsibility and liability</strong></h3>
          <ol>
            <li>All the abbreviation, acronym, words defined in the Terms of Use published herewith shall be adopted and
              applied herein and shall carry the same meanings and definition when the same are referred to herein.
            </li>
            <li>You agree that the e-Platform and all Contents contained therein are provided on an &ldquo;as
              is&rdquo; and &ldquo;as available&rdquo; basis without any warranties, claims or representations made by
              Bridepay of any kind either expressed, implied or statutory with respect to the e-Platform and/or the
              Bridepay Services, including, without limitation, warranties of non-infringement of third party rights,
              title, merchantability, satisfactory quality or fitness for a particular purpose. All data and/or
              information contained in the e-Platform and/or the Bridepay Services are provided for informational
              purposes only.
            </li>
            <li>Without limiting the foregoing, Bridepay does not warrant that the e-Platform or any of the functions
              contained therein will be available, accessible, uninterrupted, timely, secure, accurate, complete or
              error-free, that defects, if any, will be corrected, or that this e-Platform and/or the server that makes
              the same available are free of viruses, clocks, timers, counters, worms, software locks, drop dead
              devices, trojan-horses, routings, trap doors, time bombs or any other harmful codes, instructions,
              programs or components. However, we shall use our best endeavor to ensure the e-Platform are active and in
              order without interruption and in the event of interruption occur due to whatsoever reason, we shall do
              the necessary to repair and restore the e-Platform in the soonest possible time.
            </li>
            <li>Bridepay and all of its respective officers, employees, directors, agents, contractors and assigns shall
              not be liable to you for any losses whatsoever or howsoever caused (regardless of the form of action)
              arising directly or indirectly in connection with:

              <ul>
                <li>any access, use and/or inability to use the e-Platform or the Bridepay Services;</li>
                <li>reliance on any data or information made available through the e-Platform and/or through the
                  Bridepay Services. You should not act on such data or information without first independently
                  verifying its
                  Contents;</li>
                <li>any system, server or connection failure, error, omission, interruption, delay in
                  transmission,
                  computer virus or other malicious, destructive or corrupting code, agent program or macros; and</li>
                <li>any use of or access to any other website or webpage linked to the e-Platform, even if we or
                  our officers or agents or employees may have been advised of, or otherwise might have anticipated, the
                  possibility of the same.</li>
              </ul>
            </li>
            <li>Any risk of misunderstanding, error, damage, expense or losses resulting from the use of the e-Platform
              and/or Bridepay Services provided therein is entirely at your own risk and we shall not be liable
              therefore. Therefore, you should be aware and acknowledge the risks involved when performing any
              transactions or enquiries online.
            </li>
            <li>You acknowledge and understand that we only provide a e-Platform for you to upload Content. We do not
              monitor, review, screen, endorse, edit, curate, check or verify the Contents uploaded to the e-Platform.
              You agree that you access the Content at your own risk and that any decision you make to view, or access
              the Content or any product or service you sell or purchase as a result of viewing, or accessing the
              Content is entirely between you and the Content provider. You are solely responsible and shall be liable
              for all Content you upload or otherwise make available on the e-Platform.
            </li>
            <li>The e-Platform may contain advertisements and/or hyperlinks to other websites or content on the
              e-Platform that are owned or operated by third parties. Such linked websites or content are not under our
              control and we are not liable for any errors, omissions, delays, defamation, libel, slander, falsehood,
              obscenity, pornography, profanity, inaccuracy or any other objectionable material contained in the
              contents, or the consequences of accessing, any linked website. Any hyperlinks to any other websites or
              content are not an endorsement or verification of such websites or content and you agree that your access
              to or use of such linked websites or content is entirely at your own risk.
            </li>
            <li>In relation to Products sold, subscribed and requested, you understand that Seller bears all risk
              attached to the delivery of the purchased Goods or Services and warrants that he/she has or will obtain
              adequate insurance coverage for the delivery of the purchased Goods or Services. In the event where the
              purchased Goods is damaged, lost or failure of delivery during the course of delivery, you acknowledge and
              agree that Bridepay will not be liable for any damage, expense, cost or fees resulted therefrom and the
              Seller and/or the Buyer will reach out to the logistic service provider to resolve such dispute.
            </li>
          </ol>
        </div>

        <Footer userType={this.state?.loggedOnUser?.type}/>
      </div>
    );
  }

}

export default PrivacyPage;