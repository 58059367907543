// import './VendorLoginModal.scss';

import * as React from 'react';
import { Button, Input, Modal, Divider, notification, Form } from 'antd';

import AuthApi from '../../../api-services/auth-api.service';

const registerLink = "/business/register";

interface VendorLoginModalProps {
  visible: boolean;
  csrfToken: string;
  closeLoginModal?: () => void;
  forgotPasswordClicked?: () => void;
}

interface VendorLoginModalState {
  loggingIn: boolean;
  visible: boolean;
}


class VendorLoginModal extends React.Component<VendorLoginModalProps, VendorLoginModalState> {
  
  
  componentDidMount(): void {
    this.setState({
      loggingIn: false
    });
  }


  /**
   * Login using the credentials entered into the form.
   * Reload window if login was successful.
   */
  login(formEvent: any): void {
    const authApi = new AuthApi;

    this.setState({ loggingIn: true });
    
    authApi.vendorLogin(formEvent?.email, formEvent?.password)
    .then(loginResponse => {
      window.location.reload();
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: error?.response?.data?.error, className: 'antd-mod center' });
    })
    .finally(() => {
      this.setState({ loggingIn: false });
    });
  }


  render() {  
    return (
      <Modal className="VendorLoginModal" centered={false} visible={this.props?.visible} onCancel={this.props?.closeLoginModal} width={500} footer={null} keyboard={false} maskClosable={false} destroyOnClose={true}>
        
        <div className="title typo-headline">Login to your Account</div>

        <Form onFinish={(event) => { this.login(event) }} className="login-form">  
          <Form.Item name="email" rules={[{ type: 'email', message: 'The input is not valid E-mail!' }, { required: true, message: 'Email is required' }]}>
            <Input placeholder="Email Address" />
          </Form.Item>

          <Form.Item name="password" rules={[{ required: true, message: 'Password is required' }]}>
            <Input.Password placeholder="Password" />
          </Form.Item>
          
          <Button loading={this.state?.loggingIn} className="continue typo-bold-2" htmlType="submit">Continue</Button>
        </Form>
        
        <a className="forgot-password" onClick={()=>this.props?.forgotPasswordClicked()}>Forgot Password?</a>

        <div className="register-link">
          Dont have an account?
          &nbsp;
          <a href={registerLink}>Register here</a>
        </div>
      </Modal>
    );
  }
  
  
}


export default VendorLoginModal;