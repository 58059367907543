// import './Footer.scss';

import * as React from 'react';
import { Tooltip } from 'antd';
import { FacebookFilled, InstagramFilled, TwitterSquareFilled, YoutubeFilled } from '@ant-design/icons';

import DocumentModal from '../../modals/document-modal/DocumentModal';
import ContactModal from '../../modals/contact-modal/ContactModal'

interface FooterProps {
  userType?: 'user'|'vendor'|string;
}


interface FooterState {
  documentModalVisible: boolean;
  privacyPolicyModalVisible: boolean;
  riskStatementModalVisible: boolean;
  contactModalVisible: boolean;
}


class Footer extends React.Component<FooterProps, FooterState> {

  get termsOfUseDocumentFilePath(): string {
    // return 'https://bridepay-documents.s3.ap-southeast-1.amazonaws.com/users-terms-of-use.docx';
    return '/documents/users-terms-of-use.pdf';
    // if(this.props?.userType === 'vendor') {
    //   return 'https://bridepay-documents.s3.ap-southeast-1.amazonaws.com/users-terms-of-use.docx';
    // } else {
    //   return 'https://bridepay-documents.s3.ap-southeast-1.amazonaws.com/vendors-terms-of-use.docx';
    // }
  }

  redirectToURL(url: string, open: boolean = false): void {
    if(url) {
      if (open) {
        window.open(url);
      } else {
        window.location.href = url;
      }
    }
  }


  renderShareButtons() {
    return (
      <div className="share-buttons-container">
        <a href="https://www.facebook.com/bridepay" target="_blank" className="facebook-icon"><FacebookFilled /></a>
        <a href="#" target="_blank" className="twitter-icon disabled-link"><TwitterSquareFilled /></a>
        <a href="https://www.instagram.com/thebridepay/" target="_blank" className="instagram-icon"><InstagramFilled /></a>
        <a href="#" target="_blank" className="youtube-icon disabled-link"><YoutubeFilled /></a>
      </div>
    );
  }

  renderPaymentButtons() {
    return (
      <div className="payment-buttons-container">
        <Tooltip title="Master Card">
          <img src="/images/mastercard.png" className="paymentImg" />
        </Tooltip>
        <Tooltip title="Visa">
          <img src="/images/visa.png" className="paymentImg" />
        </Tooltip>
        <Tooltip title="FPX Online Banking">
          <img src="/images/fpx.png" className="paymentImg" />
        </Tooltip>
        <Tooltip title="American Express">
          <img src="/images/amex.png" className="paymentImg" />
        </Tooltip>
        <Tooltip title="Union Pay">
          <img src="/images/unionpay.png" className="paymentImg" />
        </Tooltip>
      </div>
    );
  }


  renderFooterLogo() {
    return (
      <div>
        <div className="logo-container">
          <img src="/images/bridepay-logo-black.png" alt="Footer Logo"/>
        </div>
        <div className="logo-container">
          <img src="/images/article-weddingsmalaysia.png" alt="Footer Logo" onClick={() => { this.redirectToURL('/articles-by-weddingsmalaysia') }} />
        </div>
      </div>
    );
  }


  renderFooter() {
    return (
      <div className="Footer">
        {/*<DocumentModal filePath={this.termsOfUseDocumentFilePath} visible={this.state?.documentModalVisible} closed={() => { this.setState({ documentModalVisible: false }) }}></DocumentModal>*/}
        {/*<DocumentModal filePath={'/documents/privacy-policy.pdf'} visible={this.state?.privacyPolicyModalVisible} closed={() => { this.setState({ privacyPolicyModalVisible: false }) }}></DocumentModal>*/}
        {/*<DocumentModal filePath={'/documents/risk-statement.pdf'} visible={this.state?.riskStatementModalVisible} closed={() => { this.setState({ riskStatementModalVisible: false }) }}></DocumentModal>*/}
        {/*<ContactModal visible={this.state?.contactModalVisible} closed={() => { this.setState({ contactModalVisible: false }) }}></ContactModal>*/}

        <div className="row-1">
          <div className="logo-with-other-details">
            {this.renderFooterLogo()}
            <div className="tagline">Bridepay is Malaysia’s largest online marketplace for Brides & Bridegrooms</div>
          </div>

          <div className="links-set-container">
            <div className="links-set-title typo-bold-2">Bridepay</div>
            <hr className="section-underline" />
            <div className="link" onClick={() => { this.redirectToURL('/about', true) }} >About</div>
            <div className="link" onClick={() => { this.redirectToURL('/careers', true) }} >Careers</div>
            <div className="link" onClick={() => { this.redirectToURL('/privacy_policy', true) }}>Privacy Policy</div>
            <div className="link" onClick={() => { this.redirectToURL('/risk', true) }}>Risk Statement</div>
            <div className="link" onClick={() => { this.redirectToURL('/terms', true) }}>Terms of Use</div>
            <div className="link" onClick={() => { this.redirectToURL('/contact_us', true) }} >Contact</div>
          </div>

          <div className="links-set-container">
            <div className="links-set-title typo-bold-2">BridepayForBusiness</div>
            <hr className="section-underline" />
            <div className="link" onClick={() => { this.redirectToURL('/business/register') }} >Become a Vendor</div>
          </div>

          {/*<div className="links-set-container">*/}
          {/*  <div className="links-set-title typo-bold-2">Bridepay Care</div>*/}
          {/*  <hr className="section-underline" />*/}
          {/*  <div className="link">FAQs</div>*/}
          {/*</div>*/}

          <div className={"links-set-container logo-containers"}>
            <div className="logo-container">
              <img src="/images/bridepay-promotional-banner.png" alt="Footer Logo"/>
            </div>
            <div className="logo-container">
              <img src="/images/article-weddingsmalaysia.png" alt="Footer Logo" onClick={() => { this.redirectToURL('/articles-by-weddingsmalaysia') }} />
            </div>
          </div>
        </div>

        <div className="row-2">
          <div className="contentLeft">
            <img src="/images/klpj-logo.png" className="footerLogo" />
            <div className="copyright typo-subheading-1">Curated by <b>KLPJ Wedding Fair</b></div>
          </div>
          <div>
            {this.renderShareButtons()}
            <div className="copyright typo-subheading-1">Copyright © {new Date().getFullYear()} Bridepay. All Rights Reserved</div>
          </div>
          <div>
            {this.renderPaymentButtons()}
          </div>
        </div>

        {/*<div className="row-2">          
          <div className="other-links">
            <div className="copyright typo-subheading-1">Copyright © 2021 Bridepay. All Rights Reserved</div>
            <div className="filler"></div>
            {this.renderShareButtons()}
            { <div className="divider"></div>
            <div className="link typo-subheading-1">Privacy Policy</div>
            <div className="divider"></div>
            <div className="link typo-subheading-1">Terms & Conditions</div>
            <div className="divider"></div>
            <div className="link typo-subheading-1">Sitemap</div>
            }
          </div>
        </div>*/}
      </div>
    );
  }


  renderMobileFooter() {
    return (
      <div className="FooterMobile">
        <div className="links-container">
          <div className="link" onClick={() => { this.redirectToURL('/about', true) }} >About</div>
          <div className="link" onClick={() => { this.redirectToURL('/careers', true) }} >Careers</div>
          <div className="link" onClick={() => { this.redirectToURL('/privacy_policy', true) }}>Privacy Policy</div>
          <div className="link" onClick={() => { this.redirectToURL('/risk', true) }}>Risk Statement</div>
          <div className="link" onClick={() => { this.redirectToURL('/terms', true) }}>Terms of Use</div>
          <div className="link" onClick={() => { this.redirectToURL('/contact_us', true) }} >Contact</div>
          <div className="link" onClick={() => { this.redirectToURL('/business/register') }} >Become a Vendor</div>
        </div>

        {this.renderFooterLogo()}

        <div className="other-details">
          {this.renderShareButtons()}
          <div className="copyright typo-bold">Copyright © {new Date().getFullYear()} Bridepay. All Rights Reserved</div>
        </div>
      </div>
    );
  }


  render() {
    return (
      <div className="footer-container">
        {this.renderFooter()}
        {this.renderMobileFooter()}
      </div>
    );
  }

}


export default Footer;
