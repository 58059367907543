// import './LabelCard.scss';

import * as React from 'react';


interface LabelCardProps {
  slug?: string;
  label: string;
  backgroundColor?: string; /* Add support for this one in the future. */
  theme?: string; /* Add support for this one in the future. The idea is this component will accept themes like "primary", "accent" etc etc etc. */
}


interface LabelCardState {

}



class LabelCard extends React.Component<LabelCardProps, LabelCardState> {

  render() {
    return (
      <div className="LabelCard" onClick={() => { window.location.href = '/vendors/c/' + this.props.slug }}>
        <div>{this.props?.label}</div>
      </div>
    );
  }

}




export default LabelCard;