import * as React from "react"

import { Button, notification, Modal, FormInstance, Form, Input, Switch, DatePicker, Space, Checkbox } from "antd";
import TextArea from 'antd/lib/input/TextArea';
import { PlusOutlined } from '@ant-design/icons'; 

import { Campaign } from "../../../interfaces/campaign.interface";
import CampaignApi from "../../../api-services/campaign-api.service";
import AdminCampaignsTable from "../../basic-components/admin-campaigns-table/AdminCampaignsTable";
import * as moment from "moment";
import CampaignAdminApi from "../../../api-services/campaign-admin-api.service";

const { RangePicker } = DatePicker;


interface IAdminCampaignsPageProps {
  hideAction: boolean;
}

interface IAdminCampaignsPageState {
  campaigns: Campaign[];
  modalVisible: boolean;
  selectedCampaign: Campaign;
  selectedCampaigns: number[];
}


class AdminCampaignsPage extends React.Component <IAdminCampaignsPageProps, IAdminCampaignsPageState> {
  
  campaignApi = new CampaignAdminApi();
  campaignSetupForm = React.createRef<FormInstance>();

  componentDidMount(){
    this.setState({
      campaigns: [],
      selectedCampaigns: [],
      selectedCampaign: null,
      modalVisible: false
    });

    setTimeout(() => {
      this.loadCampaigns();
    }, 500);

    
  }

  loadCampaigns(): void{
    this.campaignApi.getCampaigns()
    .then(campaignsData => {
      this.setState({campaigns: campaignsData});
    })
    .catch(error => {
      notification.error({ message: 'An error occured while loading the data for campaigns', className: 'antd-mod center' });
    });
  }
  

  saveCampaign(newCampaign): void{
    if(this.state?.selectedCampaign){
      const campaignId = this.state?.selectedCampaign?.id;
      const payload = {
        title: newCampaign.title,
        subtitle: newCampaign.subtitle,
        description: newCampaign.description,
        showCountdown: newCampaign.showCountdown,
        allowProductSellingPeriod: newCampaign.allowProductSellingPeriod,
        lockProductDuringCampaignPeriod: newCampaign.lockProductDuringCampaignPeriod,
      }

      if(newCampaign.campaignPeriod != null){
        payload["campaignPeriodStart"] = newCampaign.campaignPeriod[0].format('DD/MM/YYYY')
        payload["campaignPeriodEnd"] = newCampaign.campaignPeriod[1].format('DD/MM/YYYY')
      }

      if(newCampaign.publishedPeriod != null){
        payload["publishedPeriodStart"] = newCampaign.publishedPeriod[0].format('DD/MM/YYYY')
        payload["publishedPeriodEnd"] = newCampaign.publishedPeriod[1].format('DD/MM/YYYY')
      }

      this.campaignApi.updateCampaign(campaignId, payload)
        .then(campaignResponse => {
          const campaignIndex = this.state?.campaigns?.findIndex(c => c.id == campaignId);
          if(campaignIndex > -1){
            var campaigns = [...this.state?.campaigns];
            campaigns[campaignIndex] = campaignResponse;
            this.campaignSetupForm.current!.resetFields();
            this.setState({
              campaigns: campaigns,
              modalVisible: false
            });

            notification.success({ message: 'Changes saved successfully', className: 'antd-mod center' });
          }
        })
        .catch(error => {
          notification.error({ message: 'An error occured while updating campaign', className: 'antd-mod center' });
        });
    }else{
      this.campaignApi.addCampaign(newCampaign)
        .then(campaignResponse => {
          this.setState(prevState => ({
            campaigns: [...prevState.campaigns, campaignResponse],
            modalVisible: false
          }));
          this.campaignSetupForm.current!.resetFields();
        })
        .catch(error => {
          notification.error({ message: 'An error occured while adding campaign', className: 'antd-mod center' });
        });
    }
  }


  updateCampaignList(newCampaign){
    const campaignIndex = this.state?.campaigns?.findIndex(c => c.id == newCampaign.id);
    if(campaignIndex > -1){
      var campaigns = [...this.state?.campaigns];
      campaigns[campaignIndex] = newCampaign;
      this.setState({campaigns: campaigns});

      notification.success({ message: 'Changes saved successfully', className: 'antd-mod center' });
    }
  }
  
  onAddCampaign(): void{
    this.campaignSetupForm.current!
      .validateFields()
      .then(values => this.saveCampaign(values))
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  }
  

  onEditCampaign(campaign:Campaign): void{
    this.setState({modalVisible: true, selectedCampaign: campaign});

    setTimeout(() => {
      this.campaignSetupForm.current!
        .setFieldsValue({
          title: campaign.title,
          subtitle: campaign.subtitle,
          description: campaign.description,
          allowProductSellingPeriod: campaign.allowProductSellingPeriod,
          showCountdown: campaign.showCountdown,
          lockProductDuringCampaignPeriod: campaign.lockProductDuringCampaignPeriod,
          campaignPeriod: campaign.campaignPeriodStart != null ? [campaign.campaignPeriodStart, campaign.campaignPeriodEnd] : null,
          publishedPeriod: campaign.publishedPeriodStart != null ? [campaign.publishedPeriodStart, campaign.publishedPeriodEnd] : null
        });
    });
  }

  onCancelCampaign():void{
    this.campaignSetupForm.current!.resetFields();
    this.setState({modalVisible: false});
  }
  

  removeCampaigns(campaignId?:number): void{
    const campaignsToRemove = campaignId ? [campaignId] : this.state?.selectedCampaigns;
    this.campaignApi.removeCampaign(campaignsToRemove)
    .then(res => {
      this.setState(prevState => ({
        campaigns: prevState.campaigns.filter(campaign => !campaignsToRemove.includes(campaign.id)),
        selectedCampaigns: []
      }));
    })
    .catch(error => {
      notification.error({ message: 'An error occured while removing campaign', className: 'antd-mod center' });
    })
  }
  

  publishCampaign(campaignId?:number): void{
    this.campaignApi.publishCampaign(campaignId)
    .then(campaignResponse => {
      this.updateCampaignList(campaignResponse);
    })
    .catch(error => {
      console.log(error)
      notification.error({ message: 'An error occured while publishing campaign', className: 'antd-mod center' });
    })
  }
  

  unpublishCampaign(campaignId?:number): void{
    this.campaignApi.unpublishCampaign(campaignId)
    .then(campaignResponse => {
      this.updateCampaignList(campaignResponse);
    })
    .catch(error => {
      console.log(error)
      notification.error({ message: 'An error occured while unpublishing campaign', className: 'antd-mod center' });
    })
  }


  renderAddCampaignModal(){
    return (
      <Modal 
        title="Add Campaign" 
        visible={this.state?.modalVisible} 
        maskClosable={false} 
        okText="Save"
        onOk={()=>this.onAddCampaign()} 
        onCancel={()=>this.onCancelCampaign()}
        width={600}
      >
        <Form id="campaign-form" ref={this.campaignSetupForm} layout="vertical" autoComplete="off">
          <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Title is required' }]}>
            <Input size="large"/>
          </Form.Item>
          <Form.Item name="subtitle" label="Subtitle">
            <Input size="large"/>
          </Form.Item>
          <Form.Item name="description" label="Description">
            <TextArea size="large"/>
          </Form.Item>
          <div className="campaign-date">
            <Space>
              <Form.Item name="campaignPeriod" label="Campaign Period">
                <RangePicker size="large" showNow={true}/>
              </Form.Item>
              <Form.Item name="publishedPeriod" label="Create Product Period">
                <RangePicker size="large" showNow={true}/>
              </Form.Item>
            </Space>
          </div>
          <div className="checkboxes">
            <Form.Item valuePropName="checked" name="showCountdown">
              <Checkbox defaultChecked={false}>Show Countdown</Checkbox>
            </Form.Item>
            {/* <Form.Item valuePropName="checked" name="lockProductDuringCampaignPeriod">
              <Checkbox defaultChecked={false}>Lock Product During Campaign Period</Checkbox>
            </Form.Item> */}
            <Form.Item valuePropName="checked" name="allowProductSellingPeriod">
              <Checkbox defaultChecked={false}>Allow Product Selling Period</Checkbox>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    )
  }


  render() {
    return (
      <>
        <div className="AdminCampaignsPage">
          <div className="title">
            <div className="typo-title-2 typo-bold">Campaigns</div>
            {!this.props?.hideAction && (
              <div className="actions">
                <Button type="default" size="large" icon={<PlusOutlined />} onClick={()=>this.setState({modalVisible: true, selectedCampaign: null})}>Add</Button>
                {/* <Button type="default" danger size="large" disabled={this.state?.selectedCampaigns?.length == 0} icon={<DeleteOutlined />} onClick={()=>this.removeCampaigns()}>Remove</Button> */}
              </div>
            )}
          </div>
          <div className="main-content">
            {this.renderAddCampaignModal()}

            <AdminCampaignsTable
                hideAction={this.props?.hideAction}
                campaigns={this.state?.campaigns}
                selectedCampaigns={this.state?.selectedCampaigns}
                selectionChange={(selectedCampaigns)=>{this.setState({selectedCampaigns: selectedCampaigns})}}
                editCampaign={(campaign)=>this.onEditCampaign(campaign)}
                removeCampaign={(campaignId)=>this.removeCampaigns(campaignId)}
                publishCampaign={(campaignId)=>this.publishCampaign(campaignId)}
                unpublishCampaign={(campaignId)=>this.unpublishCampaign(campaignId)}/>
          </div>
        </div>
      </>
    );
  }
}

export default AdminCampaignsPage
