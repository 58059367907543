import {Button, Checkbox, Typography} from "antd";
import * as React from "react";
import {CloseOutlined} from "@ant-design/icons";
import EditTask from "./EditTask";
import ChecklistApi from "../../../api-services/checklist-api.service";

type taskItemProps = {
    id: number;
    title: string;
    description: string;
    updatedAt?: Date;
    isDone: boolean;
    remarks?: string;
    link?: string;
    linkTitle?: string;
    onToggle?: (isDone:boolean) => void;
    onUpdated?: (value:any) => void;
    onDelete?: () => void;
}

const TaskItem = ({id, title, description, isDone, updatedAt, remarks = '', linkTitle, link, onToggle, onUpdated, onDelete }:taskItemProps) => {
    const [checked, setChecked] = React.useState<boolean>(false);
    const [isOpenDialog, setIsOpenDialog] = React.useState<boolean>(false);

    React.useEffect(() => {
        setChecked(isDone)
    }, [isDone])

    const handleUpdated = (value: object) => {
        onUpdated(value);
        setIsOpenDialog(false);
    }

    const onCancelEdit = () => {
        setIsOpenDialog(false);
    }

    const handleChangeCheck = (event) => {
        new ChecklistApi().toggleChecklistItem(id, event.target.checked).then(res => {
            setChecked(checked => res.status);
            onToggle(res.status);
        });
    }

    const handleDeleteItem = () => {
        onDelete();
    }

    return (
        <>
            <div>
                <Checkbox checked={checked} onChange={handleChangeCheck}/>
                <Typography.Text style={{fontSize: '1.2rem'}}
                  onClick={() => setIsOpenDialog(true)}
                  className={`ml-1 ${checked && 'font-line-through'} pointer`}>
                    {title}
                </Typography.Text>
            </div>
            <div className='flex items-center flex-end'>
                {
                  link && <Button shape='round' href={link} target='_blank' className="ant-btn-warning mr-2"
                             style={{minWidth: 170}}>{linkTitle}</Button>
                }
                <Button shape="circle" ghost icon={<CloseOutlined />} onClick={() => handleDeleteItem()} />
            </div>

            {/* Edit Dialog */}
            <EditTask
                isOpen={isOpenDialog}
                title={title}
                description={description}
                remarks={remarks}
                updatedAt={updatedAt}
                closeDialog={onCancelEdit}
                onSuccess={handleUpdated}
            />
        </>
    )
}

export default TaskItem;