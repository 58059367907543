// import './ArticlesListPage.scss';

import * as React from 'react';
import {
  notification,
  Divider,
  Input,
  TreeSelect,
  Image,
  Card,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Article } from '../../../interfaces/article.interface';
import { User } from '../../../interfaces/user.interface';

import UserApi from '../../../api-services/user-api.service';
import ArticleApi from '../../../api-services/article-api.service';

import Footer from '../../basic-components/footer/Footer';
import Navbar from '../../basic-components/navbar/Navbar';
import ArticleCard from '../../basic-components/article-card/ArticleCard';
import LoginModal from '../../modals/login-modal/LoginModal';
import ForgotPasswordModal from '../../modals/forgot-password-modal/ForgotPasswordModal';
import { BlogBanner } from "../../../interfaces/blog-banner.interface";
import BlogBannerApi from "../../../api-services/blog-banner-api.service";
import { BlogCategory } from "../../../interfaces/blog-category.interface";
import BlogCategoryApi from "../../../api-services/blog-category-api.service";
import { format } from "date-fns";


notification.config({
  duration: 12,
  top: 60
})

interface ArticlesListPageProps {
  isAccountSignedIn: boolean;
  googleSignInUrl: string;
  fbSignInUrl: string;
  csrfToken: string;
  selectedCategoryId?: string;
}

interface ArticlesListPageState {
  loggedOnUser: User;
  loginModalVisible: boolean;
  loginModalRedirectUrl: string;
  breadcrumbsNavItems: Array<{ id?: number; label: string; link?: string }>;
  forgotPasswordModalVisible: boolean;

  category: BlogCategory;
  articles: Article[];
  mostPopularArticles: Article[];
  blogHomeBanners: BlogBanner[];
  // blogSideBanners: BlogBanner[];
  blogCategories: BlogCategory[];
  navCategories: BlogCategory[];
  selectedCategory: number | string;
}


class ArticlesListPage extends React.Component<ArticlesListPageProps, ArticlesListPageState> {

  userApi = new UserApi();
  articleApi = new ArticleApi()
  blogBannerApi = new BlogBannerApi();
  blogCategoryApi = new BlogCategoryApi();

  constructor(props) {
    super(props);
    this.state = {
      loggedOnUser: null,
      loginModalVisible: false,
      loginModalRedirectUrl: '',
      breadcrumbsNavItems: [],
      forgotPasswordModalVisible: false,

      category: null,
      articles: [],
      mostPopularArticles: [],
      blogHomeBanners: [],
      // blogSideBanners: [],
      blogCategories: [],
      navCategories: [],
      selectedCategory: props.selectedCategoryId,
    };
  }

  componentDidMount(): void {
    this.loadSelectedCategory(this.props.selectedCategoryId);
    this.loadSignedInUserProfile();
    this.loadBanners();
    this.loadBlogCategories();
    this.loadMostPopularArticles();
    this.loadArticles(this.props.selectedCategoryId);
  }

  loadSelectedCategory(id) {
    this.blogCategoryApi.getCategory(id).then(result => {
      this.setState({ category: result, selectedCategory: result.id });
    });
  }

  loadSignedInUserProfile(): void {
    if (!this.props?.isAccountSignedIn) {
      return null;
    }

    this.userApi.getUserProfile()
      .then(user => {
        this.setState({
          loggedOnUser: user
        });
      })
      .catch(error => {
        console.error(error);
        notification.error({ message: 'Error while loading the user\'s profile.', className: 'antd-mod center' });
      });
  }

  setBreadcrumbsNavItems(): void {
    this.setState({
      breadcrumbsNavItems: [
        { id: 1, label: 'Home', link: '/' },
        // { id: 2, label: this.state?.article?.category?.name, link: `/${this.state?.product?.category?.slug}` },
        // { id: 3, label: this.state?.product?.vendor?.businessName, link: `/${this.state?.product?.vendor?.slug}` },
        // { id: 4, label: this.state?.product?.name, link: '' },
      ]
    });
  }

  loadArticles(categoryId) {
    this.articleApi.getArticles(categoryId, null)
      .then(result => {
        this.setState({
          articles: result
        });
      });
  }

  redirectToArticleDetails(id: number | string, slug: string): void {
    document.location = `/articles-by-weddingsmalaysia/articles/${id}/${slug}`;
  }

  redirectToArticleCategories(categoryId: number | string): void {
    this.blogCategoryApi.getCategory(categoryId).
      then(result => {
        document.location = `/articles-by-weddingsmalaysia/articles/c/${result.slug}`;
      })
  }

  loadMostPopularArticles(): void {
    this.articleApi.getPopularArticles()
      .then(result => {
        this.setState({
          mostPopularArticles: result
        });
      });
  }

  loadBanners(): void {
    this.blogBannerApi.getHomepageBanners()
      .then(banners => {
        this.setState({ blogHomeBanners: banners });
      })
  }

  loadBlogCategories(): void {
    this.blogCategoryApi.getCategories(null)
      .then(result => {
        this.setState({ blogCategories: result });
      });
    this.blogCategoryApi.getCategories('nav')
      .then(result => {
        this.setState({ navCategories: result });
      });
  }

  getParentIdFromAncestry(ancestry) {
    let pid = -1;
    if (ancestry !== null) {
      let ids = ancestry.split('/');
      pid = Number(ids[ids.length - 1]);
    }
    return pid;
  }

  generateCategoryTreeData(categories) {
    return categories?.map(category => {
      return {
        id: category.id.toString(),
        title: category.name,
        pId: this.getParentIdFromAncestry(category.ancestry).toString(),
        value: category.id.toString()
      }
    });
  }

  onChangeCategoryTree = (value) => {
    this.redirectToArticleCategories(value);
    this.setState({ selectedCategory: value })
  }

  handleSearch = (val) => {
    document.location = encodeURI(`/articles-by-weddingsmalaysia/articles?search=${val}`);
  }

  renderHomeBanner(position: number) {
    if (!this.state?.blogHomeBanners) return;
    let banner = this.state?.blogHomeBanners[position];

    if (banner) {
      if (banner.link)
        return <Image src={banner.image}
          preview={false}
          onClick={() => {
            this.blogBannerApi.increaseClickCount(banner.id);
            window.open(banner.link);
          }}
          title={banner.link}
          style={{ cursor: 'pointer' }}
        />;
      return <Image src={banner.image}
        onClick={() => {
          this.blogBannerApi.increaseClickCount(banner.id);
        }}
        preview={false}
      />;
    }
    return;
  }

  renderCategoryBanner(category, place, position) {
    if (!category?.banners) return;
    let banners = category.banners.filter(b => b.placing == place);

    if (banners[position]) {
      if (banners[position].link)
        return <Image src={banners[position].image}
                      preview={false}
                      onClick={
                        () => {
                          this.blogBannerApi.increaseClickCount(banners[position].id);
                          window.open(banners[position].link);
                        }
                      }
                      title={banners[position].link}
                      style={{ cursor: 'pointer' }} />;
      return <Image src={banners[position].image}
                    onClick={
                      () => {
                        this.blogBannerApi.increaseClickCount(banners[position].id);
                      }
                    }
                    preview={false} />;
    }
    return;
  }

  /*renderSideBanner(position:number) {
    if (!this.state?.blogSideBanners) return;
    let banner = this.state?.blogSideBanners[position];

    if (banner) {
        if (banner.link)
            return <Image src={banner.image} preview={false} onClick={() => window.open(banner.link)} title={banner.link} style={{cursor: 'pointer'}} />;
        return <Image src={banner.image} preview={false} />;
    }
    return;
  }*/

  renderModals() {
    return (<>
      <LoginModal
        visible={this.state.loginModalVisible}
        googleSignInUrl={this.props?.googleSignInUrl}
        fbSignInUrl={this.props?.fbSignInUrl}
        csrfToken={this.props?.csrfToken}
        redirectUrl={this.state.loginModalRedirectUrl}
        forgotPasswordClicked={() => { this.setState({ loginModalVisible: false, forgotPasswordModalVisible: true }) }}
        closed={() => { this.setState({ loginModalVisible: false }) }}
        registerLink="/registration"
      />

      <ForgotPasswordModal
        visible={this.state.forgotPasswordModalVisible}
        loginClicked={() => { this.setState({ forgotPasswordModalVisible: false, loginModalVisible: true }) }}
        closed={() => { this.setState({ forgotPasswordModalVisible: false }) }}
      />
    </>);
  }

  renderNavButton() {
    return (
      <div className="page-section">
        <div className="content">
          {this.renderHomeBanner(0)}
          {/*{this.renderCategoryBanner(this.state.category, 'homepage', 0)}*/}
          <ul className="ant-menu ant-menu-root ant-menu-horizontal ant-menu-light">
            {this.state.navCategories && this.state.navCategories.map(category => (
              <li className="ant-menu-overflow-item ant-menu-item ant-menu-item-only-child" key={category.id}>
                <span className="ant-menu-title-content">
                  <a href={`/articles-by-weddingsmalaysia/articles/c/${category.slug}`}>{category.name}</a>
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  renderCategorySelect() {
    return (
      <>
        <div>
          <Divider orientation="left" className="blog-category-title">Categories</Divider>
        </div>
        <TreeSelect
          style={{ width: '100%' }}
          value={this.state.selectedCategory.toString()}
          // suffixIcon={<ArrowRightOutlined />}
          treeDataSimpleMode
          treeData={this.generateCategoryTreeData(this.state.blogCategories)}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          placeholder="Select category"
          treeDefaultExpandAll
          onChange={this.onChangeCategoryTree}
          switcherIcon={<DownOutlined />}
        />
      </>
    );
  }

  render() {
    return (
      <div className="ArticlesListPage">
        <Navbar
          isAccountSignedIn={this.props?.isAccountSignedIn}
          user={this.state?.loggedOnUser}
          activeNavItem={'articles-by-weddingsmalaysia'}
          signInRequired={(redirectUrl) => { this.setState({ loginModalVisible: true, loginModalRedirectUrl: redirectUrl }); }}
        />

        {this.renderModals()}
        {this.renderNavButton()}

        <div className="page-content">
          <div className="product-details-container">
            <div className="column-1">
              <div className="page-section">
                <div className="content">
                  <Divider orientation="left" className="blog-category-title">{this.state.category?.name}</Divider>
                  <div className="articles">
                    {this.state.articles?.map((article, index) => {
                      return (
                        <ArticleCard article={article}
                          url={`/articles-by-weddingsmalaysia/articles/${article.id}/${article.slug}`}
                          key={index}
                          clicked={() => { this.redirectToArticleDetails(article?.id, article?.slug) }}
                        />
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="column-2">
              <Input.Search placeholder="Search articles" allowClear onSearch={this.handleSearch} />
              {this.renderCategorySelect()}
              {this.renderCategoryBanner(this.state.category, 'sidebar', 0)}
              {/* Blog Sidebar1 */}
              <div>
                <Divider orientation="left" className="blog-category-title">Most Popular Articles</Divider>
              </div>
              {this.state.mostPopularArticles?.map((article, index) => {
                return (
                  <Card
                    key={index}
                    onClick={() => { this.redirectToArticleDetails(article?.id, article?.slug) }}
                    hoverable
                    bordered={false}
                    cover={<img alt="article image" src={article.image} loading="lazy" />}
                  >
                    <Card.Meta title={article.name} description={format(article.publishedAt, 'MMM dd, yyyy')} />
                  </Card>
                )
              })}
              {/* Small ArticleCards */}
              {/* Blog Sidebar2 */}
              {this.renderCategoryBanner(this.state.category, 'sidebar', 1)}
              {this.renderCategoryBanner(this.state.category, 'sidebar', 2)}
              {/*<div>
                <Divider orientation="left" className="blog-category-title">Follow us on Instagram</Divider>
              </div>*/}
              {/* Small Images */}
            </div>
          </div>
        </div>

        <Footer userType={this.state.loggedOnUser?.type} />
      </div>
    );
  }
}

export default ArticlesListPage;
