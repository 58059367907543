import { Button, Modal } from "antd";
import * as React from "react"
import { bake_cookie } from 'sfcookies';

const cookie_key = 'age_21_and_non_muslim';

interface IAgeConfirmationModalProps {
  visible: boolean;

  confirmed?: () => void;
  closed?: () => void;
}

interface IAgeConfirmationModalState {
}

class AgeConfirmationModal extends React.Component <IAgeConfirmationModalProps, IAgeConfirmationModalState> {

  emitClosedEvent() {
    if(this.props?.closed) {
      bake_cookie(cookie_key, false);
      this.props.closed();
    }
  }

  emitConfirmedEvent() {
    if(this.props?.confirmed) {
      bake_cookie(cookie_key, true);
      this.props.confirmed();
    }
  }


  renderActionButtons() {
    return (
      <div className="action-buttons-container">
        <Button className="antd-mod" type="default" onClick={() => { this.emitConfirmedEvent() }}>Yes, I am</Button>
        <Button className="antd-mod" type="default" onClick={() => { this.emitClosedEvent() }}>No, I am not</Button>
      </div>
    );
  }
  
  
  render() {
    return (
      <Modal className="AgeConfirmationModal" centered={false} closable={false} visible={this.props?.visible} footer={null}>
        <div className="title typo-title">Are you of legal drinking age?</div>

        <p>I'm aged 21 years old & above and a non-muslim</p>
        
        {this.renderActionButtons()}
      </Modal>
    );
  }
  
}

export default AgeConfirmationModal
