// import './ArticleCard2.scss';

import * as React from 'react';
import { Tag, Tooltip } from 'antd';
import { format } from 'date-fns';
import Dotdotdot from 'react-dotdotdot';

import { Article } from '../../../interfaces/article.interface';

interface ArticleCard2Props {
  article: Article;
}


class ArticleCard2 extends React.Component<ArticleCard2Props> {

  get randomColorClass(): string {
    const colorClasses = ['primary', 'accent', 'special'];
    const min = Math.ceil(0);
    const max = Math.floor(2);
    const randomNumber =  Math.floor(Math.random() * (max - min + 1)) + min;
    return colorClasses[randomNumber];
  }
  
  
  renderCategoryTags() {
    return this.props.article.categories.map((category, categoryIndex) => {
      return <Tag className={`typo-bold ${this.randomColorClass}`} key={categoryIndex}>{category}</Tag>
    });
  }

  
  render() {
    return (
      <div className="ArticleCard2">
        <div className="image-container">
          <img src={this.props.article.imageUrl} alt=""/>
        </div>

        <div className="details">
          <Tooltip title={this.props.article.title}>
            <Dotdotdot className="title typo-subheading-2 typo-bold" clamp={2}>{this.props.article.title}</Dotdotdot>
          </Tooltip>
          
          <div className="date">{format(new Date(this.props.article.date), 'MMM dd, yyyy')}</div>

          <div className="categories">
            {this.renderCategoryTags()}
          </div>
        </div>
      </div>
    );
  }
  
}




export default ArticleCard2;