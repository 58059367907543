// import './AlbumSetupPage.scss';

import * as React from "react"
import {startCase, toLower} from "lodash"

import { Button, Form, FormInstance, Input, notification, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { LeftOutlined } from '@ant-design/icons'; 

import AlbumApi from "../../../api-services/album-api.service";
import { AlbumMedium } from "../../../interfaces/album.interface";
import VideoUploader from "../../ui-components/video-uploader/VideoUploader";
import MultipleImageUploader from "../../ui-components/multiple-image-uploader/MultipleImageUploader";
import VendorNavbar from "../../basic-components/vendor-navbar/VendorNavbar";
import VendorApi from "../../../api-services/vendor-api.service";
import { Vendor } from "../../../interfaces/vendor-interface";
import ReactQuill from 'react-quill';


const modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }]
  ],
}

const maxImageCount = 50;
notification.config({
  duration: 12,
  top: 60
})


interface IAlbumSetupPageProps {
  albumId?:number;
  type?: string;
}

interface IAlbumSetupPageState {
  mode: "add" | "edit";
  savingAlbum: boolean;
  publishingAlbum: boolean;
  uploadedImages: AlbumMedium[];
  uploadedVideos: AlbumMedium[];
  title:string;
  vendor: Vendor;
  description: string;
  status:string;
}

class AlbumSetupPage extends React.Component <IAlbumSetupPageProps, IAlbumSetupPageState> {
  
  albumApi = new AlbumApi;
  albumSetupForm = React.createRef<FormInstance>();


  componentDidMount(){
    this.setState({
      mode: "add",
      uploadedImages: [],
      uploadedVideos: [],
      title: startCase(toLower(this.props?.type.replace("_", " "))),
      status: 'draft'
    });

    this.loadSignedInUserProfile();

    if(this.props?.albumId){
      this.getAlbum();
    }
  }





  /**
   * Get the signed in user's profile from server if there is a user signed in.
   */
   loadSignedInUserProfile(): void {
    const vendorApi = new VendorApi;

    vendorApi.getVendorProfile()
    .then(user => {
      this.setState({ vendor: user });
    })
    .catch(error => {
      notification.error({ message: 'Error while loading the vendor\'s profile.', className: 'antd-mod center' });
    });
  }

  capitalize(str){
    return str.replace(/\b(\w)/g, s => s.toUpperCase());
  }



  getAlbum(){
    const albumId = this.props?.albumId;

    this.albumApi.getAlbum(albumId)
      .then(albumResponse => {
        this.populateAlbumDetailsForm(albumResponse);

        return this.albumApi.getMediums(albumId);
      })
      .then(mediumResponse => {
        this.categorizeMediums(mediumResponse);
      })
      .catch(error => {
        notification.error({ message: `Error while loading the vendor\'s ${this.props?.type}.`, className: 'antd-mod center' });
      })
      .finally(()=>{
        this.setState({mode: "edit"})
      })
  }


  populateAlbumDetailsForm(album){
    this.albumSetupForm.current.setFieldsValue({
      title: album.title,

      // description: album.description,
    });

    this.setState({
      description: album.description,
      status: album.status
    })
  }


  categorizeMediums(mediums){
    //change to generic ?? 
    if(this.props?.type && this.props?.type == "videos"){
      const videos = mediums.filter(medium => medium.type == "video");
      this.setState({uploadedVideos: videos});
    }else{
      const images = mediums.filter(medium => medium.type == "image");
      this.setState({uploadedImages: images});
    }
  }


  redirectToAlbums(){
    var route = "albums";
    if(this.props?.type == "videos") route += "#videos";
    else route = `${this.props?.type}`;
    window.location.href = `/business/dashboard/${route}`;
  }


  saveAlbum(){
    const formEvent = this.albumSetupForm.current!.getFieldsValue();
    const payload = {
      title: formEvent.title,
      description: this.state?.description
    }
    
    this.setState({savingAlbum: true})

    this.albumApi.updateAlbum(this.props?.albumId, payload)
      .then(albumResponse => {
        notification.success({ message: 'Changes saved successfully', className: 'antd-mod center' });
      })
      .catch(error => {
        console.error(error);
  
        const errorKeys = Object.keys(error?.response?.data?.errors);
        errorKeys.forEach(errorKeyName => {
          const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
          notification.error({ message: errorMessage, className: 'antd-mod center' });
        });
      })
      .finally(()=>{
        this.setState({savingAlbum: false})
      })
  }


  saveAndPublishAlbum(){
    if(this.props?.type != "videos" && this.state?.uploadedImages.length<4){
      notification.error({ message: 'Needs to have at least 4 photos to be published', className: 'antd-mod center' });
    }else if(this.props?.type == "videos" && this.state?.uploadedVideos.length<1){
      notification.error({ message: 'Needs to have 1 video to be published', className: 'antd-mod center' });
    }else{
      this.setState({publishingAlbum: true})
      this.saveAlbum();
  
      this.albumApi.publishAlbum(this.props?.albumId)
        .then(albumResponse => {
          this.redirectToAlbums();
        })
        .catch(error => {
          const errorKeys = Object.keys(error?.response?.data?.errors);
          errorKeys.forEach(errorKeyName => {
            const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
            notification.error({ message: errorMessage, className: 'antd-mod center' });
          });
        })
        .finally(()=>{
          this.setState({publishingAlbum: false})
        })
    }


  }

  addToImages(image){
    this.setState(prevState => ({
      uploadedImages: [...prevState.uploadedImages, image]
    }));
  }


  removeFromImages(imageId){
    const albumApi = new AlbumApi;

    albumApi.deleteMediums(imageId)
      .then(response => {
        this.setState(prevState => ({
          uploadedImages: prevState.uploadedImages.filter(image => image.id !== imageId)
        }));
      })
      .catch(err=>{
        notification.error({ message: 'Error while removing photo.', className: 'antd-mod center' });
      });
  }

  addToVideos(video){
    this.setState(prevState => ({
      uploadedVideos: [...prevState.uploadedVideos, video]
    }));
  }


  removeFromVideos(videoId){
    this.setState(prevState => ({
      uploadedVideos: prevState.uploadedVideos.filter(video => video.id !== videoId)
    }));
  }


  renderHeader(){
    const title = (this.state?.mode == "add" ? "Add New " : "Edit ") + this.state?.title;

    return (
      <div className="header sticky-header">
        <div>
          <div className="typo-title typo-bold title">{title}</div>
          <Button type="default" onClick={()=>this.saveAlbum()}>Save Draft</Button>
        </div>
      </div>
    )
  }


  renderBasicDetails(){
    const type = this.state?.title?.toLowerCase().replace("_", " ");
    return (
        <div className="basic-details-section">
          <div className="typo-subheading-2 typo-bold section-header">{type} DETAILS</div>
          <Form ref={this.albumSetupForm} layout="vertical" autoComplete="off">
            <Form.Item name="title" label={`${this.state?.title} Title`} rules={[{ required: true, message: 'Title is required' }]}>
              <Input size="large" placeholder="Keep your title short, accurate & informative"/>
            </Form.Item>

            {
              type && (
                <Form.Item name="description" label={`Describe your ${this.state?.title}`}>
                  <div className="text-editor">
                    <ReactQuill value={this.state?.description || ""} onChange={(val)=>this.setState({description: val})} theme="snow" modules={modules} 
                    placeholder={`Tell customers something interesting or informative about your ${type}`}/>
                  </div>
                </Form.Item>
              )
            }
          </Form>
        </div>
    );
  }


  renderMediumUpload(){
    switch (this.props?.type) {
      case "videos":
        return (
          <section id="videos">
            <div className="typo-subheading-2 typo-bold section-header">VIDEOS</div>
            <VideoUploader albumId={this.props?.albumId} savedVideos={this.state?.uploadedVideos} addVideo={(newVideo)=>this.addToVideos(newVideo)} removeVideo={(videoId)=>this.removeFromVideos(videoId)} />
          </section>
        )
    
      //album
      default:
        return (
          <section id="images">
            <div className="typo-subheading-2 typo-bold section-header">PHOTOS</div>
            <small className="note">Please upload a minimum of 4 photos and up to a maximum of 30 photos per album. JPEG or PNG files only (maximum 8 MB per photo)</small>
            <MultipleImageUploader croppable={false} maxSize={8} uploadImageApi={`/api/v1/albums/my/${this.props?.albumId}/mediums?type=${this.props?.type}`} savedImages={this.state?.uploadedImages} 
              addImage={(newImage)=>this.addToImages(newImage)} removeImage={(imageId)=>this.removeFromImages(imageId)}/>
          </section>
        )
    }
  }


  render() {
    return (
      <div className="AlbumSetupPage">
        {/* <VendorNavbar isAccountSignedIn={true} vendor={this.state?.vendor}></VendorNavbar> */}

        <div className="main-content">
          <Button type="link" className="back-to-link typo-bold" icon={<LeftOutlined />} onClick={()=>this.redirectToAlbums()}>Back to {this.state?.title}</Button>
          
          <div className="album-form">
            
            { this.renderBasicDetails() }

            <div className="upload-section">
              { this.renderMediumUpload() }
            </div>

            <div className="form-actions">
              <Space>
                <Button type="primary" loading={this.state?.savingAlbum} className="antd-mod" size="large" onClick={()=>this.saveAlbum()}>
                  Save
                </Button>
                {
                  this.state?.status != "published" ? (
                    <Button type="primary" loading={this.state?.publishingAlbum} className="antd-mod" size="large" onClick={()=>this.saveAndPublishAlbum()}>
                      Publish
                    </Button>
                  ) :  null
                }
              </Space>
            </div>

          </div>

        </div>
        
      </div>
    );
  }
}

export default AlbumSetupPage
