import * as React from "react"

import { Button, Form, FormInstance, Modal, notification } from "antd";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'; 
import { Vendor } from "../../../interfaces/vendor-interface";
import VendorAdminApi from "../../../api-services/vendor-admin-api.service";
import AdminVendorsTable from "../../basic-components/admin-vendors-table/AdminVendorsTable";
import TextArea from "antd/lib/input/TextArea";


interface IAdminVendorsPageProps {
  hideAction: boolean;
}

interface IAdminVendorsPageState {
  vendors: Vendor[];
  pagination: any;
  selectedVendors: number[];
  resetSelection;
  remarksModalVisible: boolean;
}

class AdminVendorsPage extends React.Component <IAdminVendorsPageProps, IAdminVendorsPageState> {

  vendorApi = new VendorAdminApi();
  remarksForm = React.createRef<FormInstance>();

  componentDidMount(){
    this.setState({
      vendors: [],
      pagination: {
        current: 1,
        pageSize: 50,
        showSizeChanger: false
      },
      selectedVendors: [],
      remarksModalVisible: false
    });

    setTimeout(() => {
      this.loadVendors(1);
    }, 500);

  }

  loadVendors(page): void{
    this.vendorApi.getVendorsForApproval(page, this.state.pagination.pageSize)
    .then(data => {
      const pagination = { ...this.state.pagination };
      pagination.current = page;
      pagination.total = data.total;
      this.setState({
        vendors: data.vendors,
        pagination: pagination
      });
    })
    .catch(error => {
      notification.error({ message: 'An error occured while loading the data for vendors', className: 'antd-mod center' });
    });
  }

  handleTableChange = (pagination) => {
    this.loadVendors(pagination.current)
  }

  approveVendors(vendorId?:number){
    this.vendorApi.approveVendor([vendorId])
    .then(vendorResponse => {
      this.updateVendorDetails(vendorResponse);
    })
    .catch(error => {
      notification.error({ message: 'An error occured while approving vendor', className: 'antd-mod center' });
    });
  }


  sendReminder(vendorId?:number){
    this.vendorApi.sendReminder(vendorId)
    .then(vendorResponse => {
      this.updateVendorDetails(vendorResponse);
      notification.success({ message: 'Sending reminder verification successfully', className: 'antd-mod center' });
    })
    .catch(error => {
      notification.error({ message: 'An error occured while sending reminder verification', className: 'antd-mod center' });
    });
  }

  reviewVendor(vendorId?:number){
    this.vendorApi.reviewVendor(vendorId)
    .then(vendorResponse => {
      this.updateVendorDetails(vendorResponse);
    })
    .catch(error => {
      notification.error({ message: 'An error occured while sending reminder verification', className: 'antd-mod center' });
    });
  }

  suspendVendor(vendorId?:number){
    this.vendorApi.suspendVendor(vendorId)
    .then(vendorResponse => {
      this.updateVendorDetails(vendorResponse);
    })
    .catch(error => {
      notification.error({ message: 'An error occured while sending reminder verification', className: 'antd-mod center' });
    });
  }

  deleteVendor(vendorId?:number){
    this.vendorApi.deleteVendor(vendorId)
    .then(vendorResponse => {
      this.setState(prevState => ({
        vendors: prevState.vendors.filter(vendor => vendor.id !== vendorId)
      }));
    })
    .catch(error => {
      notification.error({ message: 'An error occured while sending reminder verification', className: 'antd-mod center' });
    });
  }

  downloadPhotos(vendorId?:number){
    window.open(`/api/v1/admin/vendors/${vendorId}/photos/download.zip`);
  }

  rejectVendors(){
    const remarks = this.remarksForm.current!.getFieldValue("remarks");
    this.vendorApi.rejectVendor(this.state?.selectedVendors, remarks)
    .then(vendorResponse => {
      this.onCancel();
      this.updateVendorDetails(vendorResponse);
    })
    .catch(error => {
      notification.error({ message: 'An error occured while rejecting vendor', className: 'antd-mod center' });
    });
  }


  updateVendorDetails(newVendorDetails: Vendor){
    const vendors = [...this.state?.vendors],
        vendorToUpdateIdx = this.state?.vendors.findIndex(v => v.id == newVendorDetails.id);

      if(vendorToUpdateIdx > -1){
        vendors[vendorToUpdateIdx] = newVendorDetails;

        this.setState({
          vendors: vendors,
          selectedVendors: []
        });
      }
  }


  onCancel():void{
    this.remarksForm.current!.resetFields();
    this.setState({remarksModalVisible: false});
  }

  renderRemarksModal(){
    return (
        <Modal 
          title="Reasons for Rejection" 
          visible={this.state?.remarksModalVisible} 
          maskClosable={false} 
          okText="Save"
          onOk={()=>this.rejectVendors()} 
          onCancel={()=>this.onCancel()}
          width={600}
        >
          <Form ref={this.remarksForm} layout="vertical" autoComplete="off">
            <Form.Item name="remarks" label="Reasons">
              <TextArea size="large"/>
            </Form.Item>
          </Form>
        </Modal>
    );
  }



  render() {
    return (
      <div className="AdminVendorsPage">
        <div className="title">
          <div className="typo-title-2 typo-bold">Vendors</div>
          {/* <div className="actions">
            <Button type="default" disabled={this.state?.selectedVendors?.length == 0} size="large" icon={<CheckOutlined />} onClick={()=>this.approveVendors()}>Approve</Button>
            <Button type="default" disabled={this.state?.selectedVendors?.length == 0} danger size="large" icon={<CloseOutlined />} onClick={()=>this.rejectVendors()}>Reject</Button>
          </div> */}
        </div>
        <div className="main-content">
          {this.renderRemarksModal()}

          <AdminVendorsTable
              hideAction={this.props?.hideAction}
              vendors={this.state?.vendors}
              selectedVendors={this.state?.selectedVendors}
              selectionChange={(selectedVendors)=>{this.setState({selectedVendors: selectedVendors})}}
              approveClicked={(vendorId)=>this.approveVendors(vendorId)}
              rejectClicked={(vendorId)=>this.setState({selectedVendors: [vendorId], remarksModalVisible: true})}
              sendReminderClicked={(vendorId)=>this.sendReminder(vendorId)}
              deleteClicked={(vendorId)=>this.deleteVendor(vendorId)}
              downloadPhotos={(vendorId)=>this.downloadPhotos(vendorId)}
              suspendClicked={(vendorId)=>this.suspendVendor(vendorId)}
              reviewClicked={(vendorId)=>this.reviewVendor(vendorId)}
              pagination={this.state?.pagination}
              onChange={this.handleTableChange}/>
        </div>
      </div>
    );
  }
}

export default AdminVendorsPage
