// import './TabSideMenu.scss';

import * as React from 'react'


interface TabSideMenuProps {
  navigations: any;
  onSelectMenu: (selected: any) => void;
}

interface TabSideMenuState {
  currentSelection:number;
}

class TabSideMenu extends React.Component <TabSideMenuProps, TabSideMenuState> {

  componentDidMount(){
    this.setState({currentSelection: 0});
    this.getCurrentSelectionByUrl();
  }



  getCurrentSelectionByUrl(selection?:string){
    if(selection == undefined) selection = window.location.hash.substring(1);

    var navs = this.props?.navigations.map((nav: any) => nav.id);
    if(navs.includes(selection)){
      const menu = navs.indexOf(selection);
      this.setState({currentSelection: menu});
      this.props?.onSelectMenu(menu);
    }
  }



  goToPage(id: any, section: any){
    window.location.hash = id == 0 ? "" : section;
    this.getCurrentSelectionByUrl(section);
  }


  render() {
    return (
      <div className="TabSideMenu">
        <div className="navigation">
          {
            this.props?.navigations.map((navigation: any, index: number) => {
              return <div key={index} onClick={()=>this.goToPage(index, navigation.id)} className={`item typo-subheading-1 typo-bold ${this.state?.currentSelection === index ? 'active' : ''}`}>{navigation.label}</div>
            })
          }
        </div>
      </div>
    );
  }
}

export default TabSideMenu;
