// import './AlbumPinCard.scss';

import * as React from 'react';
import { Button } from 'antd';
import { Album } from '../../../interfaces/album.interface';



interface AlbumPinCardProps {
  size?: 'small'|'medium'|'large';
  album: Album;

  clicked?: (album: Album) => void;
}


interface AlbumPinCardState {

}




class AlbumPinCard extends React.Component<AlbumPinCardProps, AlbumPinCardState> {

  emitClicked(album: Album): void {
    if(this.props?.clicked) {
      this.props?.clicked(album);
    }
  }
  
  
  redirectToVendorProfile(slug: string): void {
    window.open(`/vendors/${slug}`);
  }


  redirectToAlbumShowPage(vendorSlug: string, albumSlug: string): void {
    window.open(`/${vendorSlug}/albums/${albumSlug}`);
  }
  
  
  renderOverlay() {
    return (
      <div className="overlay">
        <div className="album-title typo-bold">{this.props?.album?.title}</div>

        <div className="description">{this.props?.album?.description}</div>

        <div className="buttons">
          <Button shape="round" className="typo-bold" onClick={() => { this.redirectToVendorProfile(this.props?.album?.vendor?.slug)}}>by {this.props?.album?.vendor?.businessName}</Button>
          <Button shape="round" className="typo-bold" onClick={() => { this.redirectToAlbumShowPage(this.props?.album?.vendor?.slug, this.props?.album?.slug)}}>{this.props?.album?.mostRecentImages?.length} Photos</Button>
        </div>
      </div>
    );
  }
  
  
  render() {
    return (
      <div className={`AlbumPinCard ${this.props?.size}`} onClick={() => { this.emitClicked(this.props?.album) }}>
        <img src={this.props?.album?.coverImageUrl} alt="Album Pin Card" />
        {this.renderOverlay()}
      </div>
    );
  }
  
}




export default AlbumPinCard;