import * as React from "react"
import { Button, Table, Image } from "antd";
import { Article } from "../../../interfaces/article.interface";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { format } from "date-fns";


interface IAdminArticlesTableProps {
  hideAction: boolean;
  articles: Article[];
  selectedArticles: number[];
  publishArticle: (id: number) => void;
  archiveArticle: (id: number) => void;
  removeArticle: (id: number) => void;
  selectionChange: (selectedRows) => void;
  onChange: (pagination: any) => void;
  pagination: any;
}

interface IAdminArticlesTableState {
  columns: any[];
}

class AdminArticlesTable extends React.Component<IAdminArticlesTableProps, IAdminArticlesTableState> {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'Name',
          dataIndex: 'name',
          render: (text, record) => {
            const articleEditLink = `/admin/articles/${record.id}`;
            return <a href={articleEditLink}>{text}</a>
            // return <a onClick={()=>this.getArticleInfo(record.id)}>{text}</a>
          }
        },
        {
          title: 'Category',
          dataIndex: 'categories',
          render: (text, record) => {
            return (record.categories.map(i => i.name).join(', '));
          }
        },
        {
          title: 'Tag',
          dataIndex: 'tags',
          render: (text, record) => {
            return (record.tags.map(i => i.name).join(', '));
          }
        },
        {
          title: 'View Count',
          dataIndex: 'viewCount'
        },
        {
          title: 'Publish',
          dataIndex: 'mayReject',
          render: (text) => {
            return (
              text ? <CheckOutlined twoToneColor="#52c41a" /> : <CloseOutlined twoToneColor="#ff4d4f" />
            )
          }
        },
        {
          title: 'Published At',
          dataIndex: 'publishedAt',
          render: (text) => {
            return text ? format(new Date(text), 'MMM dd, yyyy') : ''
          }
        }
      ]
    };
  }

  componentDidMount() {
    if (this.props?.hideAction) {
      this.setState({
        columns: [
          {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
              const articleEditLink = `/admin/articles/${record.id}`;
              return <a href={articleEditLink}>{text}</a>
              // return <a onClick={()=>this.getArticleInfo(record.id)}>{text}</a>
            }
          },
          {
            title: 'Category',
            dataIndex: 'categories',
            render: (text, record) => {
              return (record.categories.map(i => i.name).join(', '));
            }
          },
          {
            title: 'Tag',
            dataIndex: 'tags',
            render: (text, record) => {
              return (record.tags.map(i => i.name).join(', '));
            }
          },
          {
            title: 'View Count',
            dataIndex: 'viewCount'
          },
          {
            title: 'Publish',
            dataIndex: 'mayReject',
            render: (text) => {
              return (
                text ? <CheckOutlined twoToneColor="#52c41a" /> : <CloseOutlined twoToneColor="#ff4d4f" />
              )
            }
          },
          {
            title: 'Published At',
            dataIndex: 'publishedAt',
            render: (text) => {
              return text ? format(new Date(text), 'MMM dd, yyyy') : ''
            }
          }
        ]
      });
    } else {
      this.setState({
        columns: [
          {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
              const articleEditLink = `/admin/articles/${record.id}`;
              return <a href={articleEditLink}>{text}</a>
              // return <a onClick={()=>this.getArticleInfo(record.id)}>{text}</a>
            }
          },
          {
            title: 'Category',
            dataIndex: 'categories',
            render: (text, record) => {
              return (record.categories.map(i => i.name).join(', '));
            }
          },
          {
            title: 'Tag',
            dataIndex: 'tags',
            render: (text, record) => {
              return (record.tags.map(i => i.name).join(', '));
            }
          },
          {
            title: 'View Count',
            dataIndex: 'viewCount'
          },
          {
            title: 'Publish',
            dataIndex: 'mayReject',
            render: (text) => {
              return (
                text ? <CheckOutlined twoToneColor="#52c41a" /> : <CloseOutlined twoToneColor="#ff4d4f" />
              )
            }
          },
          {
            title: 'Published At',
            dataIndex: 'publishedAt',
            render: (text) => {
              return text ? format(new Date(text), 'MMM dd, yyyy') : ''
            }
          },
          {
            title: 'Action',
            key: 'operation',
            width: 200,
            render: (text, record) => {
              return <>
                <Button type="link" disabled={record.mayReject} onClick={() => this.props?.publishArticle(record.id)}>Publish</Button>
                <Button type="link" disabled={record.mayPublish} onClick={() => this.props?.archiveArticle(record.id)}>Archive</Button>
                <Button type="link" danger onClick={() => this.props?.removeArticle(record.id)}>Remove</Button>
              </>
            },
          },
        ]
      });
    }
  }

  render() {
    const rowSelection = {
      // selectedRowKeys: this.props?.selectedArticles,
      preserveSelectedRowKeys: false,
      onChange: (selectedRowKeys, selectedRows) => {
        this.props?.selectionChange(selectedRowKeys);
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };

    return (
      <div className="AdminBannersTable">
        <Table size="small" bordered rowKey="id"
          // rowSelection={{
          //   type: "checkbox",
          //   ...rowSelection,
          //   selectedRowKeys: this.props?.selectedArticles
          // }}
          columns={this.state?.columns}
          dataSource={this.props?.articles}
          pagination={this.props?.pagination}
          onChange={this.props?.onChange}
        />
      </div>
    );
  }
}

export default AdminArticlesTable

