/* NOTE: MIGHT REMOVE THIS COMPONENT ONCE THE IFRAME DOCUMENT VIEWER APPROACH GOT APPROVED!!!! */
import * as React from 'react';


interface FileViewerProps {
  filePath: string;
}


class FileViewer extends React.Component<FileViewerProps> {

  componentDidMount() {
    console.log(this.props);
  }
  
  render() {
    return (
      <div className="FileViewer">
        <h1>VIEWER HERE</h1>
        {/* <FileViewerPlugin
          fileType={'docx'}
          filePath={'/documents/privacy-policy.docx'}
        ></FileViewerPlugin> */}
      </div>
    );
  }
}


export default FileViewer;