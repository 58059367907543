import axios from 'axios';
import { City } from '../interfaces/city.interface';
import { Country } from '../interfaces/country.interface';


class CountryApi {

  getCountries(count: number = 1000): Promise<Country[]> {
    const apiUrl = `/api/v1/countries?per_page=${count}`;
    return axios.get(apiUrl)
    .then(response => {
      const countries: Country[] = [];

      response?.data?.data?.forEach(countryData => {
        countries.push({
          id: countryData.attributes.id,
          currency: countryData.attributes.currency,
          display: countryData.attributes.display,
          isActive: countryData.attributes.is_active,
          iso3: countryData.attributes.iso3,
          iso: countryData.attributes.iso,
          name: countryData.attributes.name,
          numCode: countryData.attributes.numcode,
          phoneCode: countryData.attributes.phonecode,
          slug: countryData.attributes.slug,
        });
      });

      return Promise.resolve(countries);
    });
  }

  // to remove
  getCities(countryISO: string): Promise<City[]> {
    const apiUrl = `/api/v1/cities?country=${countryISO}&per_page=1000`;
    return axios.get(apiUrl)
    .then(response => {
      const cities: City[] = [];

      response?.data?.data?.forEach(cityData => {
        cities.push({
          id: cityData.attributes.id,
          name: cityData.attributes.name,
          slug: cityData.attributes.slug,
        });
      });

      return Promise.resolve(cities);
    });
  }

}


export default CountryApi;