import axios from 'axios';

class ChecklistApi {
  getChecklistGroups(): Promise<any[]>{
    const apiUrl = `/api/v1/checklist-groups`;

    return axios.get(apiUrl)
      .then(response => {
        return response?.data;
      });
  }

  getChecklistItems(): Promise<any[]>{
    const apiUrl = `/api/v1/checklist-items`;

    return axios.get(apiUrl)
      .then(response => {
        const items: any[] = [];
        response?.data?.forEach(itemsData => {
          items.push(this.mapCommentResponse(itemsData));
        });

        return items;
      });
  }

  getChecklistItem(id:number): Promise<any>{
    const apiUrl = `/api/v1/checklist-items/${id}`;

    return axios.get(apiUrl)
        .then(response => {
          const checklistItem = this.mapCommentResponse(response?.data?.data?.attributes);

          return Promise.resolve(checklistItem);
        });
  }

  createChecklistItem(itemData: any): Promise<any>{
    const apiUrl = `/api/v1/checklist-items`;
    let temp = {
      title: itemData?.title,
      description: itemData?.description,
      link: itemData?.link,
      date: itemData?.date,
      remarks: itemData?.remarks,
      service_category_id: itemData?.serviceCategoryId,
      check_list_group_id: itemData?.checklistGroupId,
    };
    
    return axios({
      method: "post",
      url: apiUrl,
      data: temp
    })
    .then((response)=> {
      return this.mapCommentResponse(response?.data?.data?.attributes);
    })
  }

  updateChecklistItem(itemId:number, itemData:any): Promise<any>{
    const apiUrl = `/api/v1/checklist-items/${itemId}`;
    let temp = {
      remarks: itemData?.remarks
    };

    return axios({
      method: "put",
      url: apiUrl,
      data: temp
    })
    .then((response)=> {
      return this.mapCommentResponse(response?.data?.data?.attributes);
    })
  }

  deleteChecklistItem(itemId:number){
    const apiUrl = `/api/v1/checklist-items/${itemId}`;
    return axios.delete(apiUrl).then(response => {
      return Promise.resolve(true);
    });
  }

  toggleChecklistItem(itemId:number, status:number) {
    const action = status ? 'make-done' : 'make-todo';
    const apiUrl = `/api/v1/checklist-items/${itemId}/${action}`;
    return axios.patch(apiUrl).then(response => {
      return Promise.resolve(response.data);
    });
  }

  mapCommentResponse(itemData): any {
    return {
      id: itemData?.id,
      title: itemData?.title,
      description: itemData?.description,
      link: itemData?.link,
      date: itemData?.date,
      status: itemData?.status,
      remarks: itemData?.remarks,
      serviceCategoryId: itemData?.service_category_id,
      checklistGroupId: itemData?.check_list_group_id,
      weddingDetailId: itemData?.wedding_detail_id,
      createdAt: itemData?.created_at,
      updatedAt: itemData?.updated_at,
    };
  }
}

export default ChecklistApi;