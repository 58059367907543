import axios from 'axios';
import * as moment from 'moment';
import { User } from "../interfaces/user.interface";

class UserAdminApi {

  getUsers(page, per_page): Promise<any>{
    const apiUrl = `/api/v1/admin/users?per_page=${per_page}&page=${page}`;

    return axios.get(apiUrl)
    .then(response => {
      const users: User[] = [];
      response?.data?.data?.forEach(data => {
        users.push(this.mapUserResponse(data?.attributes));
      });
      
      return Promise.resolve({users: users, total: response?.data?.meta?.total_count});
    });
  }


  mapUserResponse(data){
    return {
      id: data?.id,
      email: data?.email,
      fullName: data?.full_name,
      createdAt: data?.created_at,
      verified: data?.verified,
      primaryPhoneNumber: data?.primary_phone_number,
      weddingDetail: {
        id: data?.wedding_detail?.id,
        role: data?.wedding_detail?.role,
        partnerFullName: data?.wedding_detail?.partner_full_name,
        cityName: data?.wedding_detail?.city,
        homeCityName: data?.wedding_detail?.home_city,
        budget: data?.wedding_detail?.budget,
        guests: data?.wedding_detail?.guests,
      }
    };
  }
}

export default UserAdminApi;