// import './ProductSearchResultPage.scss';

import * as React from 'react';
import { DatePicker, notification, Select } from 'antd';

import { User } from '../../../interfaces/user.interface';
import { Product } from '../../../interfaces/product.interface';
import { ServiceCategory } from '../../../interfaces/service-category.interface';

import UserApi from '../../../api-services/user-api.service';
import AuthApi from '../../../api-services/auth-api.service';
import ProductApi from '../../../api-services/product-api.service';
import ServiceCategoryApi from '../../../api-services/service-category-api.service';

import Countdown from '../../ui-components/countdown/Countdown';
import GenericFilter from '../../ui-components/generic-filter/GenericFilter';

import Navbar from '../../basic-components/navbar/Navbar';
import Footer from '../../basic-components/footer/Footer';
import ProductCard from '../../basic-components/product-card/ProductCard';
import ItemSlider from '../../basic-components/item-slider/ItemSlider';
import LoginModal from '../../modals/login-modal/LoginModal';
import ForgotPasswordModal from '../../modals/forgot-password-modal/ForgotPasswordModal';


notification.config({
  duration: 12,
  top: 60
})


interface ProductSearchResultPageProps {
  isAccountSignedIn: boolean;
  googleSignInUrl: string;
  fbSignInUrl: string;
  csrfToken:string;
}


interface ProductSearchResultPageState {
  loggedOnUser: User;
  loginModalVisible: boolean;
  loginModalRedirectUrl: string;
  shoppingFestivalSaleProducts: Product[];
  allBridepayDeals: Product[];
  categories: ServiceCategory[];
  forgotPasswordModalVisible: boolean;
}



class ProductSearchResultPage extends React.Component<ProductSearchResultPageProps, ProductSearchResultPageState> {

  productApi = new ProductApi();
  serviceCategoryApi = new ServiceCategoryApi();
  
  
  sampleProduct: Product = {
    id: Math.random(),
    name: 'Diamond Package',
    rating: 3.5,
    image: 'https://picsum.photos/250/250',
    discountedPrice: 3500,
    price: 3888,
    discountPercentage: 7,
    easyReschedule: true,
    wishlisted: true,
    category: { id: 1, name: 'Category 123', slug: 'qwe' },
    vendor: {
      id: Math.random(),
      businessName: 'ABC Hotel Kuala Lumpur'
    }
  };
  
  
  componentDidMount(): void {
    this.setState({
      loginModalVisible: false,
      forgotPasswordModalVisible: false
    });

    this.loadSignedInUserProfile();
    this.loadProducts();
    this.loadCategories();
  }


  /**
   * Get the signed in user's profile from server if there is a user signed in.
   */
  loadSignedInUserProfile(): void {
    if(!this.props?.isAccountSignedIn) {
      return null
    }
    
    const userApi = new UserApi;

    userApi.getUserProfile()
    .then(user => {
      this.setState({
        loggedOnUser: user,
      });
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: 'Error while loading the user\'s profile.', className: 'antd-mod center' });
    });
  }


  loadProducts(): void {
    Promise.all([
      this.productApi.getFilteredProducts(3),
      this.productApi.getFilteredProducts(),
    ])
    .then(result => {
      this.setState({
        shoppingFestivalSaleProducts: result[0],
        allBridepayDeals: result[1],
      });
    });
  }


  loadCategories(): void {
    this.serviceCategoryApi.getCategories()
    .then(categories => {
      this.setState({ categories: categories });
    });
  }


  /**
   * Logout current user and reload window afterwards.
   */
  logout(): void {
    const authApi = new AuthApi;

    authApi.logout()
    .then(() => {
      window.location.reload();
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: 'An error occured while logging out.', className: 'antd-mod center' });
    })
  }


  renderSuperFlashSale() {
    const items = [<ProductCard product={this.sampleProduct}></ProductCard>, <ProductCard product={this.sampleProduct}></ProductCard>, <ProductCard product={this.sampleProduct}></ProductCard>, <ProductCard product={this.sampleProduct}></ProductCard>, <ProductCard product={this.sampleProduct}></ProductCard>, <ProductCard product={this.sampleProduct}></ProductCard>, <ProductCard product={this.sampleProduct}></ProductCard>, <ProductCard product={this.sampleProduct}></ProductCard>, <ProductCard product={this.sampleProduct}></ProductCard>, <ProductCard product={this.sampleProduct}></ProductCard>, <ProductCard product={this.sampleProduct}></ProductCard>, <ProductCard product={this.sampleProduct}></ProductCard>, <ProductCard product={this.sampleProduct}></ProductCard>, <ProductCard product={this.sampleProduct}></ProductCard>, <ProductCard product={this.sampleProduct}></ProductCard>];
    const titleExtra = (<Countdown targetDate={new Date('2021-09-08')}></Countdown>);
    
    return (
      <div className="page-section super-flash-sales">
        <div className="content">
          <ItemSlider title="Super Flash Sales" titleExtra={titleExtra} subtitle="Enjoy big discounts, promos, coupons, and many more." items={items}></ItemSlider>
        </div>
      </div>
    );
  }


  renderProductResults() {
    return (
      <div className="page-section product-results">
        <div className="content">
          {this.state?.allBridepayDeals?.map((product, productIndex) => 
            <ProductCard 
              product={product} 
              wishlistButtonDisabled={!this.props?.isAccountSignedIn}
              key={productIndex}
              size='large'
              wishlistStatusChanged={(wishlistStatus) => { this.productApi.updateWishlistStatus(product.id, wishlistStatus) }}
            />
          )}
        </div>
      </div>
    );
  }


  renderCategories() {
    const filterItems = this.state?.categories?.map(category => { return { value: category.id, label: category.name }; });
    
    return (
      <div className="page-section caterer-filter">
        <div className="content">
          <GenericFilter title="Caterers" filterItems={filterItems} filterItemSelected={(selectedFilterItem) => { console.log(selectedFilterItem); }}></GenericFilter>
        </div>
      </div>
    );
  }


  renderMainFilters() {
    return (
      <div className="main-filters">
        <div className="content">
          <div className="filter-set">
            <div className="label typo-bold-2 typo-subheading-1">Filter by:</div>  

            <Select defaultValue="all">
              <Select.Option value="all">All Cities</Select.Option>
              <Select.Option value="1">Option One</Select.Option>
            </Select>
            
            <Select defaultValue="all">
              <Select.Option value="all">Number of Guest</Select.Option>
              <Select.Option value="1">Option One</Select.Option>
            </Select>

            <Select defaultValue="all">
              <Select.Option value="all">Catering For</Select.Option>
              <Select.Option value="1">Option One</Select.Option>
            </Select>

            <Select defaultValue="all">
              <Select.Option value="all">Serving Style</Select.Option>
              <Select.Option value="1">Option One</Select.Option>
            </Select>
          </div>

          <div className="filter-set">
            <div className="label typo-bold-2 typo-subheading-1">Availability:</div>  
            <DatePicker />
          </div>     
        </div>
      </div>
    );
  }
  
  
  render() {
    return (
      <div className="ProductSearchResultPage">
        <Navbar 
          isAccountSignedIn={this.props?.isAccountSignedIn} 
          user={this.state?.loggedOnUser} 
          activeNavItem={'bridepay-deals'}
          signInRequired={(redirectUrl) => { this.setState({ loginModalVisible: true, loginModalRedirectUrl: redirectUrl }); }}
        ></Navbar>

        <LoginModal 
          visible={this.state?.loginModalVisible} 
          googleSignInUrl={this.props?.googleSignInUrl} 
          fbSignInUrl={this.props?.fbSignInUrl} 
          csrfToken={this.props?.csrfToken} 
          redirectUrl={this.state?.loginModalRedirectUrl}  
          forgotPasswordClicked={() => { this.setState({ loginModalVisible: false, forgotPasswordModalVisible: true }) }} 
          closed={() => { this.setState({ loginModalVisible: false }) }} 
          registerLink="/registration"
        ></LoginModal>
        
        <ForgotPasswordModal
          visible={this.state?.forgotPasswordModalVisible} 
          loginClicked={() => { this.setState({ forgotPasswordModalVisible: false, loginModalVisible: true }) }} 
          closed={() => { this.setState({ forgotPasswordModalVisible: false }) }} 
        ></ForgotPasswordModal>
        
        {this.renderMainFilters()}
        {this.renderCategories()}
        {this.renderSuperFlashSale()}
        {this.renderProductResults()}

        <Footer userType={this.state?.loggedOnUser?.type}></Footer>
      </div>
    );
  }
  
}




export default ProductSearchResultPage;