// import './BlogHomePage.scss';

import * as React from 'react';
import {notification, Menu, Divider, Image} from 'antd';

import { User } from '../../../interfaces/user.interface';
import UserApi from '../../../api-services/user-api.service';
import AuthApi from '../../../api-services/auth-api.service';
import Footer from '../../basic-components/footer/Footer';
import Navbar from '../../basic-components/navbar/Navbar';
import LoginModal from '../../modals/login-modal/LoginModal';
import { BlogBanner } from '../../../interfaces/blog-banner.interface';
import ForgotPasswordModal from '../../modals/forgot-password-modal/ForgotPasswordModal';
import ArticleCard from "../../basic-components/article-card/ArticleCard";
import { Article } from '../../../interfaces/article.interface';
import BlogBannerApi from "../../../api-services/blog-banner-api.service";
import BlogCategoryApi from "../../../api-services/blog-category-api.service";
import {BlogCategory} from "../../../interfaces/blog-category.interface";
import ArticleApi from "../../../api-services/article-api.service";


notification.config({
  duration: 12,
  top: 60
})


interface BlogHomePageProps {
  isAccountSignedIn: boolean;
  googleSignInUrl: string;
  fbSignInUrl: string;
  csrfToken:string;
}


interface BlogHomePageState {
  loggedOnUser: User;
  loginModalVisible: boolean;
  forgotPasswordModalVisible: boolean;
  loginModalRedirectUrl: string;
  blogBanners: BlogBanner[];
  recentArticles: Article[];
  homeCategories: BlogCategory[];
  navCategories: BlogCategory[];
  selectedCategory: number;
}


class BlogHomePage extends React.Component<BlogHomePageProps, BlogHomePageState> {

  userApi = new UserApi();
  authApi = new AuthApi();
  blogCategoryApi = new BlogCategoryApi();
  blogBannerApi = new BlogBannerApi();
  articleApi = new ArticleApi();

  componentDidMount(): void {
    this.setState({
      loginModalVisible: false,
      forgotPasswordModalVisible: false
    });

    this.loadSignedInUserProfile();
    this.loadBanners();
    this.loadRecentArticles();
    this.loadBlogCategories();
  }

  loadRecentArticles() {
    this.articleApi.getRecentArticles()
        .then(articles => {
          this.setState({recentArticles: articles});
        })
        .catch(error => {
          console.error(error);
          notification.error({ message: 'Error while loading the articles.', className: 'antd-mod center' });
        })
  }

  loadSignedInUserProfile(): void {
    if(!this.props?.isAccountSignedIn) {
      return null;
    }

    this.userApi.getUserProfile()
        .then(user => {
          this.setState({
            loggedOnUser: user
          });
        })
        .catch(error => {
          console.error(error);
          notification.error({ message: 'Error while loading the user\'s profile.', className: 'antd-mod center' });
        });
  }

  loadBanners(): void {
    this.blogBannerApi.getHomepageBanners()
    .then(banners => {
      this.setState({blogBanners: banners});
    })
  }

  loadBlogCategories(): void {
    Promise.all([
      this.blogCategoryApi.getCategories('home'),
      this.blogCategoryApi.getCategories('nav')
    ])
        .then(results => {
          this.setState({
            homeCategories: results[0],
            navCategories: results[1]
          }, () => {
            this.state?.homeCategories?.map((category) => {
              this.articleApi.getArticles(category.id, null)
                  .then((articles) => {
                    let categoryObj = {};
                    categoryObj[category.id] = articles?.slice(0, 6);
                    this.setState(categoryObj);
                  })
            })
          });
        });
  }

  /**
   * Logout current user and reload window afterwards.
   */
  logout(): void {
    this.authApi.logout()
        .then(() => {
          window.location.reload();
        })
        .catch(error => {
          console.error(error);
          notification.error({ message: 'An error occured while logging out.', className: 'antd-mod center' });
        })
  }

  renderBanner(position:number) {
    if (!this.state?.blogBanners) return;
    let banner = this.state?.blogBanners[position];

    if (banner) {
        if (banner.link)
            return <Image loading='lazy'
                          src={banner.image}
                          preview={false}
                          onClick={() => {
                            this.blogBannerApi.increaseClickCount(banner.id);
                            window.open(banner.link);
                          }}
                          title={banner.link}
                          style={{cursor: 'pointer'}}
            />;
        return <Image loading='lazy'
                      src={banner.image}
                      onClick={() => {
                        this.blogBannerApi.increaseClickCount(banner.id);
                      }}
                      preview={false}
        />;
    }
    return;
  }

  renderCategoryHomeBanner(category) {
    if (!category.banners) return;
    let banner = category.banners.find(b => b.placing == 'homepage');

    if (banner) {
      if (banner.link)
        return <Image loading='lazy'
                      src={banner.image}
                      preview={false}
                      onClick={() => {
                        this.blogBannerApi.increaseClickCount(banner.id);
                        window.open(banner.link);
                      }}
                      title={banner.link}
                      style={{cursor: 'pointer'}}
        />;
      return <Image loading='lazy'
                    src={banner.image}
                    onClick={() => {
                      this.blogBannerApi.increaseClickCount(banner.id);
                    }}
                    preview={false}
      />;
    }
    return;
  }

  renderRecentArticles() {
    if (this.state?.recentArticles)
      return (
          <div className="page-section" id={`article-recent`}>
              <div className="content">
                  <div className="articles">
                      {this.state?.recentArticles.map((article, index) => {
                          return (
                              <ArticleCard article={article}
                                           key={index}
                                           url={`/articles-by-weddingsmalaysia/articles/${article.id}/${article.slug}`}
                                           clicked={() => { this.redirectToArticleDetails(article?.id, article?.slug) }}
                              />
                          )
                      })}
                  </div>
              </div>
          </div>
      )
  }

  renderCategories() {
    return this.state?.homeCategories?.map((category, index) => (
        <div className="page-section" key={category.name} id={`category-${category.id}`}>
          <div className="content">
            {this.renderCategoryHomeBanner(category)}
            <Divider orientation="left" className="blog-category-title">{category.name}</Divider>
            <div className="articles">
              {this.state[category.id]?.map((article, index) => {
                return (
                    <ArticleCard article={article}
                                 key={index}
                                 url={`/articles-by-weddingsmalaysia/articles/${article.id}/${article.slug}`}
                                 clicked={() => { this.redirectToArticleDetails(article?.id, article?.slug) }}
                    />
                )
              })}
            </div>
          </div>
        </div>
    ))
  }

  redirectToArticleDetails(id: number|string, slug: string): void {
    window.open(`/articles-by-weddingsmalaysia/articles/${id}/${slug}`);
  }

  handleCategoryClick = (target) => {
    this.setState({selectedCategory: target.key})
  }

  render() {
    return (
        <div className="BlogHomePage">
          <Navbar
              isAccountSignedIn={this.props?.isAccountSignedIn}
              user={this.state?.loggedOnUser}
              activeNavItem={'articles-by-weddingsmalaysia'}
              signInRequired={(redirectUrl) => { this.setState({ loginModalVisible: true, loginModalRedirectUrl: redirectUrl }); }}
          />

          <LoginModal
              visible={this.state?.loginModalVisible}
              googleSignInUrl={this.props?.googleSignInUrl}
              fbSignInUrl={this.props?.fbSignInUrl}
              csrfToken={this.props?.csrfToken}
              redirectUrl={this.state?.loginModalRedirectUrl}
              forgotPasswordClicked={() => { this.setState({ loginModalVisible: false, forgotPasswordModalVisible: true }) }}
              closed={() => { this.setState({ loginModalVisible: false }) }}
              registerLink="/registration"
          />

          <ForgotPasswordModal
              visible={this.state?.forgotPasswordModalVisible}
              loginClicked={() => { this.setState({ forgotPasswordModalVisible: false, loginModalVisible: true }) }}
              closed={() => { this.setState({ forgotPasswordModalVisible: false }) }}
          />

          <div className={"page-section"}>
            <div className={"content"}>
              {this.renderBanner(0)}
              <ul className="ant-menu ant-menu-root ant-menu-horizontal ant-menu-light">
                {this.state?.navCategories && this.state?.navCategories.map(category => (
                  <li className="ant-menu-overflow-item ant-menu-item ant-menu-item-only-child" key={category.id}>
                    <span className="ant-menu-title-content">
                      <a href={`/articles-by-weddingsmalaysia/articles/c/${category.slug}`}>{category.name}</a>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {this.renderRecentArticles()}
          {this.renderCategories()}
          <Footer userType={this.state?.loggedOnUser?.type}/>
        </div>
    );
  }

}

export default BlogHomePage;
