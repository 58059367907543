import axios from 'axios';
import { City } from '../interfaces/city.interface';


class CityApi {

  searchCities(keyword: string, perPage: number = 100): Promise<City[]> {
    const apiUrl = `/api/v1/cities?per_page=${perPage}&keyword=${keyword}`;

    return axios.get(apiUrl)
    .then(response => {
      const cities: City[] = [];

      response?.data?.data?.forEach(cityData => {
        cities.push({
          id: cityData?.attributes?.id,
          name: cityData?.attributes?.name
        });
      });
      
      return Promise.resolve(cities);
    });
  }

  /* params: countryISO - iso code for country */
  /* params: state - state id or code */
  /* params: keyword - city keyword */
  searchCitiesByCountryAndState(state, keyword: string="", perPage: number = 100): Promise<City[]> {
    const apiUrl = `/api/v1/cities?state=${state}&keyword=${keyword}&per_page=${perPage}`;

    return axios.get(apiUrl)
    .then(response => {
      const cities: City[] = [];

      response?.data?.data?.forEach(cityData => {
        cities.push({
          id: cityData?.attributes?.id,
          name: cityData?.attributes?.name
        });
      });
      
      return Promise.resolve(cities);
    });
  }
  
}



export default CityApi;