// import './ImagePinCard.scss';

import * as React from 'react';
import { Button } from 'antd';
import { Album } from '../../../interfaces/album.interface';



interface ImagePinCardProps {
  imageUrl: string;
  title?: string;
  description?: string;
  size?: 'small'|'medium'|'large';

  clicked?: () => void;
}


interface ImagePinCardState {

}




class ImagePinCard extends React.Component<ImagePinCardProps, ImagePinCardState> {

  emitClicked(): void {
    if(this.props?.clicked) {
      this.props?.clicked();
    }
  }
  
  
  redirectToVendorProfile(slug: string): void {
    window.open(`/vendors/${slug}`);
  }


  redirectToAlbumShowPage(vendorSlug: string, albumSlug: string): void {
    window.open(`/${vendorSlug}/albums/${albumSlug}`);
  }
  
  
  renderOverlay() {
    if(!this.props?.title && !this.props?.description) {
      return null;
    } else {
      return (
        <div className="overlay">
          <div className="album-title typo-bold">{this.props?.title}</div>
          <div className="description">{this.props?.description}</div>
        </div>
      );
    }
  }
  
  
  render() {
    return (
      <div className={`ImagePinCard ${this.props?.size}`} onClick={() => { this.emitClicked() }}>
        <img src={this.props?.imageUrl} alt="Album Pin Card" />
        {this.renderOverlay()}
      </div>
    );
  }
  
}




export default ImagePinCard;