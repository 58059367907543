// import './AlbumCardAdmin.scss';

import * as React from 'react';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import Dotdotdot from 'react-dotdotdot';

import { Album } from '../../../interfaces/album.interface';



interface AlbumCardAdminProps {
  album: Album;
  editClicked?: (album: Album) => void;
  totalVideosClicked?: (album: Album) => void;
  totalPhotosClicked?: (album: Album) => void;
}


interface AlbumCardAdminState {

}





class AlbumCardAdmin extends React.Component<AlbumCardAdminProps, AlbumCardAdminState> {


  emitEditClicked(): void {
    if(this.props?.editClicked) {
      this.props?.editClicked(this.props?.album);
    }
  }


  emittotalVideosClicked(): void {
    if(this.props?.totalVideosClicked) {
      this.props?.totalVideosClicked(this.props?.album);
    }
  }


  emittotalPhotosClicked(): void {
    if(this.props?.totalPhotosClicked) {
      this.props?.totalPhotosClicked(this.props?.album);
    }
  }
  
  
  renderAlbumPreview() {
    return (
      <div className="album-preview">
        <img src={this.props?.album?.coverImageUrl} />
        <Button className="edit" onClick={() => { this.emitEditClicked() }} icon={<EditOutlined />}>Edit</Button>
      </div>
    );
  }


  renderStatus() {
    return <div className="status published typo-bold-2 typo-body-small">{this.props?.album?.status}</div>;
  }


  renderAlbumDetails() {
    return (
      <div className="album-details">
        <Dotdotdot className="title typo-title typo-bold" clamp={1}>{this.props?.album?.title}</Dotdotdot>
        
        <div className="other-details">
          <div className="typo-bold-2 typo-body-small item">{this.props?.album?.totalPhotos} PHOTOS</div>
          <div className="typo-bold-2 typo-body-small">|</div>
          <div className="typo-bold-2 typo-body-small item">{this.props?.album?.totalVideos} VIDEOS</div>
          <div className="typo-bold-2 typo-body-small">|</div>
          {this.renderStatus()}
        </div>
      </div>
    );
  }


  render() {
    return (
      <div className="AlbumCardAdmin">
        {this.renderAlbumPreview()}
        {this.renderAlbumDetails()}
      </div>
    );
  }

}




export default AlbumCardAdmin;