import axios from 'axios';
import { Calendar } from "../interfaces/calendar.interface";

class CalendarApi {

  getCalendars(): Promise<Calendar[]>{
    const apiUrl = `/api/v1/calendars/my`;

    return axios.get(apiUrl)
    .then(response => {
      const calendars: Calendar[] = [];

      response?.data?.data?.forEach(calendarData => {
        calendars.push({
          id: calendarData?.attributes?.id,
          name: calendarData?.attributes?.name,
          startDate: calendarData?.attributes?.begin_date,
          endDate: calendarData?.attributes?.end_date,
          hasBooking: calendarData?.attributes?.has_booking,
        });
      });
      
      return Promise.resolve(calendars);
    });
  }

  getCalendarsForAdmin(vendorId: number|string): Promise<Calendar[]>{
    const apiUrl = `/api/v1/admin/vendors/${vendorId}/calendars`;

    return axios.get(apiUrl)
      .then(response => {
        const calendars: Calendar[] = [];

        response?.data?.data?.forEach(calendarData => {
          calendars.push({
            id: calendarData?.attributes?.id,
            name: calendarData?.attributes?.name,
            startDate: calendarData?.attributes?.begin_date,
            endDate: calendarData?.attributes?.end_date,
            mayDelete: calendarData?.attributes?.may_delete,
            blockedDates: calendarData?.attributes?.blocked_dates,
          });
        });

        return Promise.resolve(calendars);
      });
  }

  createCalendar(calendar: Calendar): Promise<any> {
    const apiUrl = `/api/v1/calendars/my`;

    return axios.post(apiUrl, {
      calendar:{
        begin_date: calendar.startDate,
        end_date: calendar.endDate,
        name: calendar.name,
      } 
    });
  }
  

}

export default CalendarApi;