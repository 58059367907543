import axios from 'axios';
import { Banner } from "../interfaces/banner.interface";

class BannerApi {

  getBanners(): Promise<Banner[]> {
    const apiUrl = `/api/v1/banners`;

    return axios.get(apiUrl)
      .then(response => {
        const banners: Banner[] = [];
        response?.data?.data?.forEach(bannerData => {
          banners.push(this.mapBannerResponse(bannerData?.attributes));
        });

        return Promise.resolve(banners);
      });
  }

  mapBannerResponse(bannerData) {
    return {
      id: bannerData?.id,
      title: bannerData?.title,
      link: bannerData?.link,
      placement: bannerData?.placing,
      image: bannerData?.image_url,
      position: bannerData?.position,
    };
  }

  increaseClickCount(banner:number){
    const apiUrl = `/api/v1/banners/${banner}/increase-click-count`;
    return axios.patch(apiUrl).then(response => {
      return Promise.resolve(true);
    });
  }
}

export default BannerApi;
