// import './UserAccountDetails.scss';

import * as React from 'react';
import { Button, Form, FormInstance, Input, notification, Radio, Select, Skeleton, Spin } from 'antd';
import { startCase } from 'lodash';

import { Country } from '../../../interfaces/country.interface';
import { User } from '../../../interfaces/user.interface';

import UserApi from '../../../api-services/user-api.service';
import AvatarUpload from '../../ui-components/avatar-upload/AvatarUpload';

interface UserAccountDetailsProps {
  user: User;
  countries: Country[];
  defaultCountry: Country;
}

interface UserAccountDetailsState {
  selectedCountryPhoneCode: string;
  selectedSection: 'accountDetails'|'weddingDetails';
  savingChanges: boolean;
  loading: boolean;
  accountDetailsFormHasErrors: boolean;
}


class UserAccountDetails extends React.Component<UserAccountDetailsProps, UserAccountDetailsState> {

  accountDetailsForm = React.createRef<FormInstance>();
  
  
  componentDidMount(): void {
    this.setState({
      selectedCountryPhoneCode: '60',
      selectedSection: 'accountDetails',
      savingChanges: false,
      loading: true
    });
  }
  
  
  componentDidUpdate(previousProps: UserAccountDetailsProps) {
    if(this.props?.user !== previousProps.user) {
      this.populateAccountDetailsForm();
    }

    if(this.props.defaultCountry !== previousProps.defaultCountry) {
      this.setState({ selectedCountryPhoneCode: this.props?.defaultCountry?.phoneCode });
    }
  }


  saveChanges(formEvent: any): void {
    this.setState({ savingChanges: true });

    const userApi = new UserApi;
    let payload = {};

    if(this.state?.selectedSection === 'accountDetails') {
      payload = {
        email: formEvent.email,
        firstName: formEvent.firstName,
        lastName: formEvent.lastName,
        phoneNumber: this.state?.selectedCountryPhoneCode + formEvent.phoneNumber,
      };
    }

    userApi.saveUser(payload)
    .then(response => {
      notification.success({ message: 'Changes saved successfully', className: 'antd-mod center' });
    })
    .catch(error => {
      console.error(error);

      const errorKeys = Object.keys(error?.response?.data?.errors);
      errorKeys.forEach(errorKeyName => {
        const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
        notification.error({ message: errorMessage, className: 'antd-mod center' });
      });
    })
    .finally(() => {
      this.setState({ savingChanges: false });
    });
  }


  populateAccountDetailsForm(): void {
    this.accountDetailsForm.current!.setFieldsValue({
      firstName: this.props?.user?.firstName,
      lastName: this.props?.user?.lastName,
      email: this.props?.user?.email,
      image: this.props?.user?.image,
      phoneNumber: this.props?.user?.phoneNumber?.rawNational,
    });
    this.setState({loading:false});
  }


  selectAccountDetails(): void {
    this.setState({ selectedSection: 'accountDetails' });
    this.populateAccountDetailsForm();
  }


  /**
   * Manually check if the form is has errors.
   */
  validateAccountDetailsForm(): void {
    setTimeout(() => {
      const errors = this.accountDetailsForm?.current?.getFieldsError();
      let hasErrors = false;
  
      errors.forEach(error => {
        if(error?.errors?.length) {
          hasErrors = true;
        }
      });
      
      this.setState({ accountDetailsFormHasErrors: hasErrors });
    }, 250);
  }


  renderUserPhotoContainer() {
    return (
      <div className="user-photo-container">
        { this.state?.loading ? <Skeleton.Image />: <AvatarUpload imageUrl={this.props?.user?.image}/> }
      </div>
    );
  }


  renderPhoneCodeDropdown() {
    return <Select defaultValue="MY 60" onChange={(selectedValue) => { this.setState({selectedCountryPhoneCode: selectedValue}) }} className="select-before" disabled={true}>
      {this.props?.countries?.map(country => {
        return <Select.Option key={country.phoneCode} value={country.phoneCode}>{country.iso} {country.phoneCode}</Select.Option>
      })}
    </Select>
  }
  
  
  render() {
    return (
      <div className="UserAccountDetails">
        <Form ref={this.accountDetailsForm} layout={'vertical'} onFinish={(event) => { this.saveChanges(event) }} onValuesChange={() => { this.validateAccountDetailsForm(); }}>
          <div className="account-details">
            <div className="title typo-title-2 typo-bold">Account Details</div>
  
            {this.renderUserPhotoContainer()}

            {
              this.state?.loading ? <Skeleton active /> : (
                <>
                  <div className="name-fields">
                    <Form.Item name="firstName" label="First Name"  rules={[{ required: true, message: 'First name is required' }]}>
                      <Input value={this.props?.user?.firstName} size="large" placeholder="Enter your first name" />
                    </Form.Item>
        
                    <Form.Item name="lastName" label="Last Name" rules={[{ required: true, message: 'Last name is required' }]}>
                      <Input value={this.props?.user?.lastName} size="large" placeholder="Enter your last name" />
                    </Form.Item>
                  </div>
        
                  <Form.Item name="email" label="Email Address">
                    <Input value={this.props?.user?.email} size="large" placeholder="Enter your email address" disabled={true} />
                  </Form.Item>
        
                  <Form.Item name="phoneNumber" label="Phone Number">
                    <Input size="large" addonBefore={this.renderPhoneCodeDropdown()} placeholder="Enter your phone number" disabled={true} />
                  </Form.Item>
      
                  <div className="form-buttons">
                    <Button type="primary" loading={this.state?.savingChanges} htmlType="submit" disabled={this.state?.accountDetailsFormHasErrors} className="antd-mod color-primary typo-bold-2" size="large">Save</Button>
                  </div>
                </>
              )
            }
          </div>
        </Form>
      </div>
    );
  }
  

}


export default UserAccountDetails;