import * as React from "react"

import { Button, notification } from "antd";
import { PlusOutlined } from '@ant-design/icons';

import { Article } from "../../../interfaces/article.interface";
import AdminArticlesTable from "../../basic-components/admin-articles-table/AdminArticlesTable";
import ArticleAdminApi from "../../../api-services/article-admin-api.service";

interface IAdminArticlesPageProps {
  hideAction: boolean;
}

interface IAdminArticlesPageState {
  articles: Article[];
  modalVisible: boolean;
  selectedArticle: Article;
  selectedArticles: number[];
  uploading: boolean;
  pagination: any;
}

class AdminArticlesPage extends React.Component <IAdminArticlesPageProps, IAdminArticlesPageState> {

  articleApi = new ArticleAdminApi();

  componentDidMount(){
    let url = new URL(window.location.href);
    let searchParams = url.searchParams;
    let page = searchParams?.get('page')??1;
    page = Number(page);
    if (isNaN(page)) {
      page = 1;
    }
    this.setState({
      articles: [],
      selectedArticles: [],
      selectedArticle: null,
      modalVisible: false,
      uploading: false,
      pagination: {
        position: ['topRight', 'bottomRight'],
        current: page,
        pageSize: 20,
        showSizeChanger: false
      }
    });

    setTimeout(() => {
      this.loadArticles(page);
    }, 500);

    window.addEventListener('popstate', function(event) {
      location.reload();
    });
  }

  loadArticles(page): void{
    this.articleApi.getArticles(page, this.state?.pagination.pageSize)
    .then(articlesData => {
      const pagination = { ...this.state.pagination };
      pagination.current = page;
      pagination.total = articlesData.total;
      this.setState({articles: articlesData.articles, pagination});
    })
    .catch(error => {
      notification.error({ message: 'An error occurred while loading the data for articles', className: 'antd-mod center' });
    });
  }

  handleTableChange = (pagination) => {
    let url = new URL(window.location.href);
    url.searchParams.set('page', pagination.current);
    // window.location.href = url.href;
    window.history.pushState({}, '', url);
    this.loadArticles(pagination.current);
  }

  removeArticle(articleId?:number): void{
    this.articleApi.removeArticle(articleId)
    .then(res => {
      this.setState(prevState => ({
        articles: prevState.articles.filter(article => (article.id !== articleId)),
        selectedArticles: []
      }));
    })
    .catch(error => {
      notification.error({ message: 'An error occurred while removing article', className: 'antd-mod center' });
    })
  }

  publishArticle(articleId?:number): void{
    this.articleApi.publishArticle(articleId)
        .then(res => {
          this.loadArticles(this.state?.pagination.current)
        })
        .catch(error => {
          notification.error({ message: 'An error occurred while publishing article', className: 'antd-mod center' });
        })
  }

  archiveArticle(articleId?:number): void{
    this.articleApi.archiveArticle(articleId)
        .then(res => {
          this.loadArticles(this.state?.pagination.current)
        })
        .catch(error => {
          notification.error({ message: 'An error occurred while archiving article', className: 'antd-mod center' });
        })
  }

  redirectToCreate(): void {
    document.location = '/admin/articles/new';
  }

  render() {
    return (
      <>
        <div className="AdminBannersPage">
          <div className="title">
            <div className="typo-title-2 typo-bold">Articles</div>
            <div className="actions">
              <Button type="default" size="large" icon={<PlusOutlined />} onClick={()=>this.redirectToCreate()}>Add</Button>
              {/* <Button type="default" danger size="large" disabled={this.state?.selectedArticles?.length == 0} icon={<DeleteOutlined />} onClick={()=>this.removeArticles()}>Remove</Button> */}
            </div>
          </div>
          <div className="main-content">
            <AdminArticlesTable
                hideAction={this.props?.hideAction}
                articles={this.state?.articles}
                pagination={this.state?.pagination}
                onChange={this.handleTableChange}
                selectedArticles={this.state?.selectedArticles}
                selectionChange={
                  (selectedArticles)=>{
                    this.setState({
                      selectedArticles: selectedArticles
                    })
                  }
                }
                removeArticle={(articleId)=>this.removeArticle(articleId)}
                publishArticle={(articleId)=>this.publishArticle(articleId)}
                archiveArticle={(articleId)=>this.archiveArticle(articleId)}
            />
          </div>
        </div>
      </>
    );
  }
}

export default AdminArticlesPage
