import * as React from 'react';
import { notification } from 'antd';

import UserApi from '../../../api-services/user-api.service';
import AuthApi from '../../../api-services/auth-api.service';

import Navbar from '../../basic-components/navbar/Navbar';
import LoginModal from '../../modals/login-modal/LoginModal';
import Footer from '../../basic-components/footer/Footer';
import BreadcrumbsNavigation from  '../../basic-components/breadcrumbs-navigation/BreadcrumbsNavigation';
import ForgotPasswordModal from '../../modals/forgot-password-modal/ForgotPasswordModal';

notification.config({
  duration: 12,
  top: 60
})

class PrivacyPage extends React.Component {

  userApi = new UserApi;
  authApi = new AuthApi;

  breadcrumbsNavItems = [
    { id: 1, label: 'Home', link: '/' },
    { id: 2, label: 'About Us', link: '/about' },
  ];

  componentDidMount(): void {
    this.setState({
      loginModalVisible: false,
      forgotPasswordModalVisible: false
    });

    this.loadSignedInUserProfile();
  }


  loadSignedInUserProfile(): void {
    if(!this.props?.isAccountSignedIn) {
      return null;
    }

    this.userApi.getUserProfile()
      .then(user => {
        this.setState({
          loggedOnUser: user
        });
      })
      .catch(error => {
        console.error(error);
        notification.error({ message: 'Error while loading the user\'s profile.', className: 'antd-mod center' });
      });
  }

  render() {
    return (
      <div className="ContactPage">
        <Navbar
          isAccountSignedIn={this.props?.isAccountSignedIn}
          user={this.state?.loggedOnUser}
          signInRequired={(redirectUrl) => { this.setState({ loginModalVisible: true, loginModalRedirectUrl: redirectUrl }); }}
        />

        <LoginModal
          visible={this.state?.loginModalVisible}
          googleSignInUrl={this.props?.googleSignInUrl}
          fbSignInUrl={this.props?.fbSignInUrl}
          csrfToken={this.props?.csrfToken}
          redirectUrl={this.state?.loginModalRedirectUrl}
          forgotPasswordClicked={() => { this.setState({ loginModalVisible: false, forgotPasswordModalVisible: true }) }}
          closed={() => { this.setState({ loginModalVisible: false }) }}
          registerLink="/registration"
        />

        <ForgotPasswordModal
          visible={this.state?.forgotPasswordModalVisible}
          loginClicked={() => { this.setState({ forgotPasswordModalVisible: false, loginModalVisible: true }) }}
          closed={() => { this.setState({ forgotPasswordModalVisible: false }) }}
        />

        <div className="page-content">
          <BreadcrumbsNavigation items={this.breadcrumbsNavItems} />
        </div>

        <div className="page-content main-content">
          <div className="category-header">
            <div className="content">
              <div className="title typo-headline">About Us</div>
            </div>
          </div>

          <br/>
          <br/>
          <p>Welcome to Bridepay, Malaysia&rsquo;s largest online marketplace for Brides and Bridegrooms. At Bridepay,
            we understand that your wedding day is one of the most important moments of your life, and we are here to
            make your dream wedding a reality.</p>
          <h3><strong>Who We Are</strong></h3>
          <p>Bridepay is Malaysia&rsquo;s premier online marketplace dedicated to Brides and Bridegrooms. We bring
            together a diverse array of top-tier wedding vendors, including stunning venues, talented photographers,
            skilled wedding planners, exquisite bridal gown designers, professional bridal makeup artists, unique
            wedding gift suppliers and more. Our platform is designed to inspire and assist couples in every aspect of
            their wedding preparation, providing a seamless and enjoyable planning experience.</p>
          <p>We take pride in offering &lsquo;Wedding Deals, Everyday!&rsquo; from our trusted partner venues and
            vendors. These exclusive deals are crafted to help you make the most of your wedding budget without
            compromising on quality. By connecting with our partners through Bridepay, you can access special offers and
            discounts that will make your wedding planning process more affordable and stress-free.</p>
          <h3><strong>Our Mission</strong></h3>
          <p>Our mission is to simplify your wedding planning journey by providing a one-stop solution for all your
            wedding needs. We strive to offer a curated selection of top-notch vendors who are committed to making your
            wedding day as beautiful and memorable as you've always envisioned.</p>
          <h3><strong>Why Choose Bridepay?</strong></h3>
          <ul>
            <li><strong>Trusted Vendors :</strong>We partner with experienced and reputable vendors to guarantee the
              highest quality of service for your wedding.
            </li>
            <li><strong>Seamless Experience :</strong>Our user-friendly platform makes it easy to browse, compare, and
              book the best vendors for your big day.
            </li>
            <li><strong>Comprehensive Services :</strong>From venue selection to bridal makeup, wedding gifts and more,
              Bridepay covers all aspects of your wedding preparation.
            </li>
            <li><strong>Personalised Support :</strong>Our dedicated vendors are here to assist you at every step,
              ensuring your wedding planning experience is stress-free and enjoyable.
            </li>
          </ul>
          <h3><strong>Join Us on Your Journey</strong></h3>
          <p>At Bridepay, we are more than just a wedding planner &ndash; we are your partner in creating unforgettable
            memories. Let us help you turn your wedding dreams into reality. Start your wedding preparation with
            Bridepay today and connect with the best wedding vendors to make your special day truly extraordinary.</p>
          <p>&nbsp;</p>
        </div>

        <Footer userType={this.state?.loggedOnUser?.type}/>
      </div>
    );
  }

}

export default PrivacyPage;