import './BannerCard.scss';

import * as React from 'react';


interface BannerCardProps {
  imageUrl: string;
}

class BannerCard extends React.Component<BannerCardProps> {

  render() {
    return (
      <div className="BannerCard">
        <img src={this.props?.imageUrl} alt="Banner Card"/>
      </div>
    );
  }
  
}



export default BannerCard;