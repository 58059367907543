import axios from 'axios';
import { Album, AlbumMedium } from '../interfaces/album.interface';


interface SaveAlbumPayload {
  title?: string;
  description?: string;
}

class AlbumApi {

  getAlbum(albumId: number): Promise<Album> {
    const apiUrl = `/api/v1/albums/my/${albumId}?`;

    return axios.get(apiUrl)
    .then(response => {
      const album:Album = {
        id: response?.data?.data?.attributes?.id,
        status: response?.data?.data?.attributes?.status,
        title: response?.data?.data?.attributes?.title,
        description: response?.data?.data?.attributes?.description,
        mayPublish: response?.data?.data?.attributes?.may_publish,
      }
      
      return Promise.resolve(album);
    });
  }

  createAlbum(type:string = 'album'): Promise<Album> {
    const apiUrl = `/api/v1/albums/my?type=${type}`;

    return axios.post(apiUrl)
    .then(response => {
      const album:Album = {
        id: response?.data?.data?.attributes?.id,
        status: response?.data?.data?.attributes?.status,
        title: response?.data?.data?.attributes?.title,
        description: response?.data?.data?.attributes?.description,
      }
      
      return Promise.resolve(album);
    });
  }

  updateAlbum(albumId: number, payload: SaveAlbumPayload): Promise<Album> {
    const apiUrl = `/api/v1/albums/my/${albumId}`;

    return axios.put(apiUrl, {
      album: {
        title: payload.title,
        description: payload.description
      }
    })
    .then(response => {
      const album:Album = {
        id: response?.data?.data?.attributes?.id,
        status: response?.data?.data?.attributes?.status,
        title: response?.data?.data?.attributes?.title,
        description: response?.data?.data?.attributes?.description,
        mayPublish: response?.data?.data?.attributes?.may_publish,
      }
      
      return Promise.resolve(album);
    });
  }


  /**
   * Get the list of Albums from a single vendor.
   */
  getVendorAlbums(vendorSlug: string, kind?: string): Promise<Album[]> {
    let apiUrl = `/api/v1/albums?vendor=${vendorSlug}`;

    if(kind) {
      apiUrl += `&kind=${kind}`;
    }

    return axios.get(apiUrl).then((response: any) => {
      const albums: Album[] = [];
      
      response?.data?.data?.forEach(albumData => {
        albums.push({
          id: albumData.attributes?.id,
          description: albumData.attributes?.description,
          coverImageUrl: albumData.attributes?.cover_image_url,
          kind: albumData.attributes?.kind,
          slug: albumData.attributes?.slug,
          status: albumData.attributes?.status,
          title: albumData.attributes?.title,
          totalPhotos: albumData.attributes?.total_photos,
          totalVideos: albumData.attributes?.total_videos,
          mostRecentImages: albumData.attributes?.most_recent_images?.map(image => {
            return {
              id: image.id,
              liked: image.liked,
              url: image.url
            };
          }),
          videoUrl: albumData.attributes?.video_url,
          vendor: {
            id: albumData.attributes?.vendor?.id,
            name: albumData.attributes?.vendor?.name,
            slug: albumData.attributes?.vendor?.slug,
          },
          updatedAt: albumData.attributes?.updated_at
        });
      });
      
      return Promise.resolve(albums);
    });
  }



  getMediums(albumId: number): Promise<AlbumMedium[]>{
    const apiUrl = `/api/v1/albums/my/${albumId}/mediums`;

    return axios.get(apiUrl)
      .then(response =>{
        const images:AlbumMedium[] = [];
        response?.data?.data?.forEach((imageResponse: any) => {
          images.push({
            id: imageResponse?.attributes?.id,
            imageUrl: imageResponse?.attributes?.image_url,
            videoUrl: imageResponse?.attributes?.video_url,
            type: imageResponse?.attributes?.type,
          })
        });
        return Promise.resolve(images);
      })
  }


  deleteMediums(ids: number[]): Promise<any>{
    const apiUrl = '/api/v1/mediums/'+ids;
    
    return axios.delete(apiUrl)
      .then(response =>{
        return Promise.resolve(true);
      })
  }


  addVideo(albumId: number, videoUrl: string): Promise<AlbumMedium>{
    const apiUrl = `/api/v1/albums/my/${albumId}/mediums?type='video'`;

    return axios.post(apiUrl, {
      video_url: videoUrl
    })
      .then(response =>{
        const video:AlbumMedium = {
          id: response?.data?.data?.attributes?.id,
          videoUrl: response?.data?.data?.attributes?.video_url
        }

        return Promise.resolve(video);
      })
  }


  getAlbums(type:string = 'album'): Promise<Album[]> {
    const apiUrl = `/api/v1/albums/my?type=${type}`;

    return axios.get(apiUrl)
    .then((response: any) => {
      const albums: Album[] = [];

      response?.data?.data?.forEach((albumData: any) => {
        albums.push({
          id: albumData.attributes?.id,
          title: albumData.attributes?.title,
          description: albumData.attributes?.description,
          status: albumData.attributes?.status,
          coverImageUrl: albumData.attributes?.cover_image_url,
          kind: albumData.attributes?.kind,
          slug: albumData.attributes?.slug,
          mostRecentImages: albumData.attributes?.most_recent_images?.map(image => {
            return {
              id: image.id,
              liked: image.liked,
              url: image.url
            };
          }),
          totalPhotos: albumData.attributes?.total_photos,
          totalVideos: albumData.attributes?.total_videos,
          vendor: {
            id: albumData.attributes?.vendor?.id,
            name: albumData.attributes?.vendor?.name,
            slug: albumData.attributes?.vendor?.slug,
          },
          updatedAt: albumData.attributes?.updated_at
        });
      })
      
      return albums;
    });
  }

  getRecommendedAlbums(kind: string): Promise<Album[]> {
    const apiUrl = `/api/v1/albums/recommended?kind=${kind}`;

    return axios.get(apiUrl)
    .then((response: any) => {
      const albums: Album[] = [];

      response?.data?.data?.forEach((albumData: any) => {
        albums.push({
          id: albumData.attributes?.id,
          title: albumData.attributes?.title,
          description: albumData.attributes?.description,
          status: albumData.attributes?.status,
          coverImageUrl: albumData.attributes?.cover_image_url,
          kind: albumData.attributes?.kind,
          slug: albumData.attributes?.slug,
          mostRecentImages: albumData.attributes?.most_recent_images?.map(image => {
            return {
              id: image.id,
              liked: image.liked,
              url: image.url
            };
          }),
          totalPhotos: albumData.attributes?.total_photos,
          totalVideos: albumData.attributes?.total_videos,
          vendor: {
            id: albumData.attributes?.vendor?.id,
            name: albumData.attributes?.vendor?.name,
            slug: albumData.attributes?.vendor?.slug,
          },
          updatedAt: albumData.attributes?.updated_at
        });
      })
      
      return albums;
    });
  }



  publishAlbum(albumId){
    const apiUrl = `/api/v1/albums/my/${albumId}/publish`;
    return axios.patch(apiUrl).then(response => {
      console.log("res", response)
      return Promise.resolve(true);
    });
  }


  deleteAlbum(albumId){
    const apiUrl = `/api/v1/albums/my/${albumId}`;
    return axios.delete(apiUrl).then(response => {
      return Promise.resolve(true);
    });
  }
  

}

export default AlbumApi;