// import './ContactPage.scss';

import * as React from 'react';
import { Descriptions, notification } from 'antd';

import { User } from '../../../interfaces/user.interface';

import UserApi from '../../../api-services/user-api.service';
import AuthApi from '../../../api-services/auth-api.service';

import Navbar from '../../basic-components/navbar/Navbar';
import LoginModal from '../../modals/login-modal/LoginModal';
import Footer from '../../basic-components/footer/Footer';
import BreadcrumbsNavigation from  '../../basic-components/breadcrumbs-navigation/BreadcrumbsNavigation';
import ForgotPasswordModal from '../../modals/forgot-password-modal/ForgotPasswordModal';

notification.config({
  duration: 12,
  top: 60
})

class ContactPage extends React.Component<ContactPageProps, ContactPageState> {
  
  userApi = new UserApi;
  authApi = new AuthApi;
  
  breadcrumbsNavItems = [
    { id: 1, label: 'Home', link: '/' },
    { id: 2, label: 'Contact Us', link: '/contact_us' },
  ];
  
  componentDidMount(): void {
    this.setState({
      loginModalVisible: false,
      forgotPasswordModalVisible: false
    });
    
    this.loadSignedInUserProfile();
  }


  loadSignedInUserProfile(): void {
    if(!this.props?.isAccountSignedIn) {
      return null;
    }

    this.userApi.getUserProfile()
    .then(user => {
      this.setState({
        loggedOnUser: user
      });
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: 'Error while loading the user\'s profile.', className: 'antd-mod center' });
    });
  }


  showLoginModal(): void {
    this.setState({ loginModalVisible: true });
  }


  closeLoginModal(): void {
    this.setState({ loginModalVisible: false });
  }


  /**
   * Logout current user and reload window afterwards.
   */
  logout(): void {
    this.authApi.logout()
    .then(() => {
      window.location.reload();
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: 'An error occured while logging out.', className: 'antd-mod center' });
    })
  }
  
  redirectToURL(url: string): void {
    if(url) {
      window.location.href = url;
    }
  }
  
  render() {
    return (
      <div className="ContactPage">
        <Navbar 
          isAccountSignedIn={this.props?.isAccountSignedIn} 
          user={this.state?.loggedOnUser} 
          signInRequired={(redirectUrl) => { this.setState({ loginModalVisible: true, loginModalRedirectUrl: redirectUrl }); }}
        />

        <LoginModal 
          visible={this.state?.loginModalVisible} 
          googleSignInUrl={this.props?.googleSignInUrl} 
          fbSignInUrl={this.props?.fbSignInUrl} 
          csrfToken={this.props?.csrfToken} 
          redirectUrl={this.state?.loginModalRedirectUrl}  
          forgotPasswordClicked={() => { this.setState({ loginModalVisible: false, forgotPasswordModalVisible: true }) }} 
          closed={() => { this.setState({ loginModalVisible: false }) }} 
          registerLink="/registration"
        />
        
        <ForgotPasswordModal
          visible={this.state?.forgotPasswordModalVisible} 
          loginClicked={() => { this.setState({ forgotPasswordModalVisible: false, loginModalVisible: true }) }} 
          closed={() => { this.setState({ forgotPasswordModalVisible: false }) }} 
        />

        <div className="page-content">
          <BreadcrumbsNavigation items={this.breadcrumbsNavItems} />
        </div>

        <div className="page-content main-content">
          <div className="category-header">
            <div className="content">
              <div className="title typo-headline">Contact Us</div>
            </div>
          </div>

          <br/>
          <br/>
          <p>
            <b>Bridepay Sdn Bhd</b><br/>
            <i>202101012115 (1412414-M)</i>
          </p>

          <Descriptions bordered>
            <Descriptions.Item label="Tel No">
              +60 12 373 0823
            </Descriptions.Item>
            <Descriptions.Item label="E-mail">
              kris@bridepay.com
            </Descriptions.Item>
          </Descriptions>

        </div>

        <Footer userType={this.state?.loggedOnUser?.type} />
      </div>
    );
  }
  
}

export default ContactPage;