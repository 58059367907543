import * as React from 'react';
import {Row, Col, Image, Typography, Progress} from 'antd';
import { RightOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

type OverviewProps = {
    dayLeft: number,
    totalTask: number,
    completedTask: number,
    bookedMerchants: number
}

const Overview = ({ completedTask, totalTask, bookedMerchants, dayLeft}: OverviewProps) => {
    let date = `${dayLeft} Days to go !`;
    if (dayLeft < 0) date = 'Expired Date'
    return (
        <div className="overview">
            <Row gutter={32}>
                <Col md={14} xs={24} className="mt-4">
                    <Title className="py-3">{date}</Title>
                    <Title level={5}>You Have Completed {completedTask} out of {totalTask} Tasks</Title>
                    <Progress percent={completedTask/totalTask*100} status="success" showInfo={false} />
                </Col>

                {/*<Col md={10} xs={24} className="mt-4">
                    <Title className="mb-0 fs-xxl">{bookedMerchants}</Title>
                    <Title level={4} className="mt-2">Merchants Booked</Title>
                    <a href="/">
                        <Text strong>
                            Explore Vendor
                            <span className="fs-xxs">
                                <RightOutlined />
                            </span>
                        </Text>
                    </a>
                </Col>*/}
            </Row>
        </div>
    )
}

export default Overview;