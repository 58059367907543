// import './PhoneVerifyForm.scss';

import * as React from 'react';
import { Form, FormInstance, Modal, Select, Input, Button, notification } from 'antd';

import { Country } from '../../../interfaces/country.interface';

import UserApi from '../../../api-services/user-api.service';



interface PhoneVerifyFormProps {
  countries: Country[];
  defaultCountry: Country;
  showSubmitButtons?: boolean;

  codeSent?: () => void;
  codeNotSent?: (errorMessage?: string) => void;
  codeVerified?: () => void;
  codeNotVerified?: (errorMessage?: string) => void
  formValueChanged?: () => void;
}


interface PhoneVerifyFormState {
  sendingCodeToPhoneNumber: boolean;
  verifyingCode: boolean;
  selectedCountryPhoneCode: string;
  currentView: 'phoneNumberInput'|'verificationCodeInput'
}



class PhoneVerifyForm extends React.Component<PhoneVerifyFormProps, PhoneVerifyFormState> {

  userApi = new UserApi;

  phoneNumberForm = React.createRef<FormInstance>();
  verificationCodeForm = React.createRef<FormInstance>();


  componentDidMount() {
    this.setState({
      currentView: 'phoneNumberInput'
    });
  }


  componentDidUpdate(previousProps) {
    if(this.props.defaultCountry !== previousProps.defaultCountry) {
      this.setState({ selectedCountryPhoneCode: this.props?.defaultCountry?.phoneCode });
    }
  }
  

  sendCodeToPhoneNumber(formEvent: any): void {
    const phoneNumber = `${this.state?.selectedCountryPhoneCode}${formEvent.phoneNumber}`;

    this.setState({ sendingCodeToPhoneNumber: true });

    this.userApi.sendCodeToPhoneNumber(phoneNumber)
    .then(response => {
      this.showPhoneVerificationCode(response.code);
      this.setState({ currentView: 'verificationCodeInput' });
      this.props?.codeSent();
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: error?.response?.data?.error, className: 'antd-mod center' });
      this.props?.codeNotSent(error?.response?.data?.error);
    })
    .finally(() => {
      this.setState({ sendingCodeToPhoneNumber: false });
    });
  }


  verifyCode(formEvent: any): void {
    const userApi = new UserApi;
    const phoneNumber = `${this.state?.selectedCountryPhoneCode}${this.phoneNumberForm.current?.getFieldValue('phoneNumber')}`;

    this.setState({ verifyingCode: true });

    userApi.verifyPhoneCode(phoneNumber, formEvent.code)
    .then(response => {
      // notification.success({ message: 'Your phone number is now verified', className: 'antd-mod center' });
      this.props?.codeVerified();
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: error?.response?.data?.error, className: 'antd-mod center' });
      this.props?.codeNotVerified(error?.response?.data?.error);
    })
    .finally(() => {
      this.setState({ verifyingCode: false });
    });
  }


  /**
   * Show an info modal displaying the verifation code returned by the server.
   */
  showPhoneVerificationCode(message: string): void {
    Modal.info({
      title: message
    });
  }
  
  
  renderPhoneCodeDropdown() {
    const phoneCodeDropdown = (
      <Select defaultValue="MY 60" onChange={(selectedValue) => { this.setState({selectedCountryPhoneCode: selectedValue}) }} className="select-before">
        {this.props?.countries?.map(country => {
          return <Select.Option key={country.phoneCode} value={country.phoneCode}>{country.iso} {country.phoneCode}</Select.Option>
        })}
      </Select>
    );

    return phoneCodeDropdown;
  }


  renderPhoneNumberForm() {
    if(this.state?.currentView === 'phoneNumberInput') {
      return (
        <Form ref={this.phoneNumberForm} onFinish={(event) => { this.sendCodeToPhoneNumber(event) }} onValuesChange={() => { this.props?.formValueChanged() }} layout="vertical" className="login-form">  
          <div className="instruction typo-subheading-2">We will send you <b>verification code</b> on this phone number.</div>
          <Form.Item name="phoneNumber" label="Enter Phone Number">
            <Input size="large" addonBefore={this.renderPhoneCodeDropdown()} placeholder="Enter your phone number" />
          </Form.Item>
          
          <Button
            className="send-code ant-mod typo-bold-2" type="primary" size="large" htmlType="submit"
            loading={this.state?.sendingCodeToPhoneNumber} 
            style={{ display: (this.props?.showSubmitButtons) ? '':'none' }}
          >Send Code</Button>
        </Form>
      );
    }

    else {
      return (<Form ref={this.phoneNumberForm} style={{ display: 'none' }}></Form>);
    }
  }


  renderVerificationCodeForm() {
    if(this.state?.currentView === 'verificationCodeInput') {
      const phoneNumberEntered = this.phoneNumberForm.current?.getFieldValue('phoneNumber');
      const wholePhoneNumber = `${this.state?.selectedCountryPhoneCode}${this.phoneNumberForm.current?.getFieldValue('phoneNumber')}`;
      
      return (
        <Form ref={this.verificationCodeForm} onFinish={(event) => { this.verifyCode(event) }} layout="vertical" className="login-form">  
          <div className="instruction typo-subheading-2">Enter the <b>verification code</b> sent to <b>{wholePhoneNumber}</b>.</div>

          <Form.Item name="code">
            <Input size="large" placeholder="Enter verification code here" />
          </Form.Item>
          
          <div className="resend-code typo-subheading-2">Haven't received the <b>code?</b> <a className="typo-bold" onClick={() => { this.sendCodeToPhoneNumber({ phoneNumber: phoneNumberEntered }) }}>RESEND CODE</a></div>
          
          <Button 
            className="send-code ant-mod typo-bold-2" type="primary" size="large" htmlType="submit"
            loading={this.state?.verifyingCode} 
            style={{ display: (this.props?.showSubmitButtons) ? '':'none' }}
          >Verify & Proceed</Button>
        </Form>
      );
    }

    else {
      return (<Form ref={this.verificationCodeForm} style={{ display: 'none' }}></Form>);
    }
  }
  
  
  render() {
    return (
      <div className="PhoneVerifyForm">
        <div className="title typo-title-2 typo-bold-2">Phone Verification</div>
        {this.renderPhoneNumberForm()}
        {this.renderVerificationCodeForm()}
      </div>
    );
  }
  
}



export default PhoneVerifyForm;