import * as React from "react"

import { Button, notification, Modal, FormInstance, Form, Input, DatePicker, Select, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { BlogBanner } from "../../../interfaces/blog-banner.interface";
import BlogBannerAdminApi from "../../../api-services/blog-banner-admin-api.service";
import AdminBlogBannersTable from "../../basic-components/admin-blog-banners-table/AdminBlogBannersTable";
import {BlogCategory} from "../../../interfaces/blog-category.interface";
import BlogCategoryApi from "../../../api-services/blog-category-api.service";
import BlogCategoryAdminApi from "../../../api-services/blog-category-admin-api.service";
import * as moment from "moment";

const {RangePicker} = DatePicker;

interface IAdminBlogBannersPageProps {
  hideAction: boolean;
}

interface IAdminBlogBannersPageState {
  banners: BlogBanner[];
  modalVisible: boolean;
  selectedBanner: BlogBanner;
  selectedBanners: number[];
  uploading: boolean;
  savedImage;
  imageUrl: string;
  pagination: any;
  categories: BlogCategory[];
}

class AdminBlogBannersPage extends React.Component <IAdminBlogBannersPageProps, IAdminBlogBannersPageState> {
  static defaultProps = {hideAction: false};
  bannerApi = new BlogBannerAdminApi();
  categoryApi = new BlogCategoryAdminApi();
  bannerSetupForm = React.createRef<FormInstance>();

  componentDidMount(){
    this.setState({
      banners: [],
      selectedBanners: [],
      selectedBanner: null,
      modalVisible: false,
      uploading: false,
      savedImage: [],
      pagination: {
        current: 1,
        pageSize: 25,
        showSizeChanger: false
      }
    });

    setTimeout(() => {
      this.loadBanners(1);
      this.loadCategories();
    }, 500);
  }

  loadBanners(page): void{
    this.bannerApi.getBanners(page, this.state?.pagination.pageSize)
    .then(bannersData => {
      const pagination = { ...this.state.pagination };
      pagination.current = page;
      pagination.total = bannersData.total;
      this.setState({banners: bannersData.banners, pagination});
    })
    .catch(error => {
      notification.error({ message: 'An error occurred while loading the data for banners', className: 'antd-mod center' });
    });
  }

  loadCategories():void {
    this.categoryApi.getCategories(1, 10000)
      .then(categoriesData => {
        this.setState({categories: categoriesData.categories});
      })
      .catch(error => {
        notification.error({ message: 'An error occurred while loading the data for blog categories', className: 'antd-mod center' });
      });
  }

  handleTableChange = (pagination) => {
    this.loadBanners(pagination.current)
  }

  saveBanner(newBanner): void{
    const formData = new FormData();
    formData.append("title", newBanner.title);
    if (newBanner.category) formData.append("blog_category_id", newBanner.category);
    if (newBanner.link) formData.append("link", newBanner.link);
    if (newBanner.placing) formData.append("placing", newBanner.placing);
    if (newBanner.position) formData.append("position", newBanner.position);

    this.state?.savedImage.forEach(file => {
      formData.append('image', file);
    });

    if(newBanner.showPeriod){
      formData.append("show_start", newBanner.showPeriod[0].utc().format('DD/MM/YYYY HH:mm:ss'));
      formData.append("show_end", newBanner.showPeriod[1].utc().format('DD/MM/YYYY HH:mm:ss'));
    }

    if(this.state?.selectedBanner){
      const bannerId = this.state?.selectedBanner?.id;
      this.bannerApi.updateBanner(bannerId, formData)
        .then(bannerResponse => {
          const bannerIndex = this.state?.banners?.findIndex(c => c.id == bannerId);
          if(bannerIndex > -1){
            let banners = [...this.state?.banners];
            banners[bannerIndex] = bannerResponse;
            this.bannerSetupForm.current!.resetFields();
            this.setState({
              banners: banners,
              modalVisible: false,
              imageUrl: ""
            });

            notification.success({ message: 'Changes saved successfully', className: 'antd-mod center' });
          }
        })
        .catch(error => {
          notification.error({ message: 'An error occurred while updating banner', className: 'antd-mod center' });
        });
    }else{
      this.bannerApi.addBanner(formData)
        .then(bannerResponse => {
          this.setState(prevState => ({
            banners: [...prevState.banners, bannerResponse],
            modalVisible: false,
            imageUrl: ""
          }));
          this.bannerSetupForm.current!.resetFields();
        })
        .catch(error => {
          console.log("error", error)
          notification.error({ message: 'An error occurred while adding banner', className: 'antd-mod center' });
        });
    }
  }

  updateBannerList(newBanner){
    const bannerIndex = this.state?.banners?.findIndex(c => c.id == newBanner.id);
    if(bannerIndex > -1){
      var banners = [...this.state?.banners];
      banners[bannerIndex] = newBanner;
      this.setState({banners: banners});

      notification.success({ message: 'Changes saved successfully', className: 'antd-mod center' });
    }
  }

  onAddBanner(): void{
    this.bannerSetupForm.current!
      .validateFields()
      .then(values => this.saveBanner(values))
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  }

  onEditBanner(banner:BlogBanner): void{
    this.setState({modalVisible: true, selectedBanner: banner, imageUrl: banner.image});

    setTimeout(() => {
      this.bannerSetupForm.current!
        .setFieldsValue({
          title: banner.title,
          link: banner.link,
          placing: banner.placing,
          position: banner.position,
          category: banner.category.id,
          showPeriod: banner.showStart ? [moment(banner.showStart), moment(banner.showEnd)] : null,
        });
    });
  }

  onCancelBanner():void{
    this.bannerSetupForm.current!.resetFields();
    this.setState({modalVisible: false, savedImage: [], imageUrl: ""});
  }

  removeBanners(bannerId?:number): void{
    const bannersToRemove = bannerId ? [bannerId] : this.state?.selectedBanners;
    this.bannerApi.removeBanner(bannersToRemove)
    .then(res => {
      this.setState(prevState => ({
        banners: prevState.banners.filter(banner => !bannersToRemove.includes(banner.id)),
        selectedBanners: []
      }));
    })
    .catch(error => {
      notification.error({ message: 'An error occurred while removing banner', className: 'antd-mod center' });
    })
  }

  publishBanner(bannerId?:number): void{
    this.bannerApi.publishBanner(bannerId)
    .then(bannerResponse => {
      this.updateBannerList(bannerResponse);
    })
    .catch(error => {
      console.log(error)
      notification.error({ message: 'An error occurred while publishing banner', className: 'antd-mod center' });
    })
  }

  unpublishBanner(bannerId?:number): void{
    this.bannerApi.unpublishBanner(bannerId)
    .then(bannerResponse => {
      this.updateBannerList(bannerResponse);
    })
    .catch(error => {
      console.log(error)
      notification.error({ message: 'An error occurred while un-publishing banner', className: 'antd-mod center' });
    })
  }

  getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
   return e && e.fileList;
  };

  beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({ message: 'You can only upload JPG/PNG file!', className: 'antd-mod center' });
    }else{
      this.setState({savedImage: [file]});
    }
    return isJpgOrPng;
  };

  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  handleChange = info => {
    this.getBase64(info.file.originFileObj, imageUrl =>
      this.setState({
        imageUrl,
        uploading: false,
      }),
    );
  };

  renderAddBannerModal(){
    const uploadButton = (
      <div id="upload-button">
        {this.state?.uploading ? <LoadingOutlined /> : <PlusOutlined />} Upload
      </div>
    );

    return (
      <Modal 
        title="Add Blog Banner"
        visible={this.state?.modalVisible} 
        maskClosable={false} 
        okText="Save"
        onOk={()=>this.onAddBanner()} 
        onCancel={()=>this.onCancelBanner()}
        width={600}
      >
        <Form id="banner-form" ref={this.bannerSetupForm} layout="vertical" autoComplete="off">
          <Form.Item name='image' getValueFromEvent={this.getFile} label="Image">
            <Upload maxCount={1} showUploadList={false} fileList={this.state?.savedImage} accept="image/*" 
            beforeUpload={this.beforeUpload} onChange={this.handleChange} className="uploader">
              {this.state?.imageUrl ? <img src={this.state?.imageUrl} className="banner-image" alt="banner-image"/> : uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Title is required' }]}>
            <Input size="large"/>
          </Form.Item>
          <Form.Item name="link" label="Link">
            <Input size="large"/>
          </Form.Item>
          <Form.Item name="placing" label="Placement" rules={[{ required: true, message: 'Placement is required' }]}>
            <Select size="large">
              <Select.Option value='homepage'>Homepage - Image size 1500 * 280</Select.Option>
              <Select.Option value='sidebar'>Sidebar - Image size 600 * 600</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="position" label="Position">
            <Input type="number" size="large"/>
          </Form.Item>
          <Form.Item name="showPeriod" label="Showing Period">
            <RangePicker showTime size="large" showNow={true}/>
          </Form.Item>
          <Form.Item name="category" label="Category">
            <Select size="large">
              <Select.Option value={null} key={-1}>No category</Select.Option>
              {
                this.state?.categories?.map(category => (
                  <Select.Option value={category.id} key={category.id}>{category.name}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  render() {
    return (
      <>
        <div className="AdminBannersPage">
          <div className="title">
            <div className="typo-title-2 typo-bold">Banners</div>
            {!this.props?.hideAction && (
              <div className="actions">
                <Button type="default" size="large" icon={<PlusOutlined />} onClick={()=>this.setState({modalVisible: true, selectedBanner: null})}>Add</Button>
                {/* <Button type="default" danger size="large" disabled={this.state?.selectedBanners?.length == 0} icon={<DeleteOutlined />} onClick={()=>this.removeBanners()}>Remove</Button> */}
              </div>
            )}
          </div>
          <div className="main-content">
            {this.renderAddBannerModal()}
            <AdminBlogBannersTable
                hideAction={this.props?.hideAction}
                banners={this.state?.banners}
                pagination={this.state?.pagination}
                onChange={this.handleTableChange}
                selectedBanners={this.state?.selectedBanners}
                selectionChange={(selectedBanners)=>{this.setState({selectedBanners: selectedBanners})}}
                editBanner={(banner)=>this.onEditBanner(banner)}
                removeBanner={(bannerId)=>this.removeBanners(bannerId)}
                publishBanner={(bannerId)=>this.publishBanner(bannerId)}
                unpublishBanner={(bannerId)=>this.unpublishBanner(bannerId)}
            />
          </div>
        </div>
      </>
    );
  }
}

export default AdminBlogBannersPage
