// import './PhoneVerifyModal.scss';

import * as React from 'react';
import { Modal } from 'antd';

import { Country } from '../../../interfaces/country.interface';

import PhoneVerifyForm from '../../basic-components/phone-verify-form/PhoneVerifyForm';


interface PhoneVerifyModalProps {
  countries: Country[];
  defaultCountry: Country;
  visible?: boolean;
  closePhoneVerifyModal?: () => void;
}


interface PhoneVerifyModalState {

}



class PhoneVerifyModal extends React.Component<PhoneVerifyModalProps, PhoneVerifyModalState> {

  
  componentDidMount() {
    // No codes as of now.
  }


  render() {
    return (
      <Modal className="phoneVerifyModal" centered={false} onCancel={this.props?.closePhoneVerifyModal} visible={this.props?.visible} width={450} footer={null} keyboard={false} maskClosable={false} destroyOnClose={true}>
        <PhoneVerifyForm defaultCountry={this.props?.defaultCountry} showSubmitButtons={true} countries={this.props?.countries}></PhoneVerifyForm>
      </Modal>
    );
  }

}



export default PhoneVerifyModal;