import * as React from "react"

import { Button, Form, FormInstance, Modal, notification } from "antd";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import AdminProductsTable from "../../basic-components/admin-products-table/AdminProductsTable";
import { Product } from "../../../interfaces/product.interface";
import ProductAdminApi from "../../../api-services/product-admin-api.service";
import TextArea from "antd/lib/input/TextArea";


interface IAdminProductsPageProps {
  hideAction: boolean;
}

interface IAdminProductsPageState {
  products: Product[];
  pagination: any;
  selectedProducts: number[];
  resetSelection;
  remarksModalVisible: boolean;
}

class AdminProductsPage extends React.Component<IAdminProductsPageProps, IAdminProductsPageState> {

  productApi = new ProductAdminApi();
  remarksForm = React.createRef<FormInstance>();

  componentDidMount() {
    this.setState({
      products: [],
      pagination: {
        current: 1,
        pageSize: 50,
        showSizeChanger: false
      },
      selectedProducts: [],
      remarksModalVisible: false
    });

    setTimeout(() => {
      this.loadProducts(1);
    }, 500);

  }


  loadProducts(page): void {
    this.productApi.getProductsForApproval(page, this.state.pagination.pageSize)
      .then(data => {
        const pagination = { ...this.state.pagination };
        pagination.current = page;
        pagination.total = data.total;
        this.setState({
          products: data.products,
          pagination: pagination
        });
      })
      .catch(error => {
        notification.error({ message: 'An error occured while loading the data for products', className: 'antd-mod center' });
      });
  }

  handleTableChange = (pagination) => {
    this.loadProducts(pagination.current)
  }

  approveProducts(productId?: number) {
    const productsToApprove = productId ? [productId] : this.state?.selectedProducts;
    this.productApi.approveProduct(productsToApprove)
      .then(res => {
        this.updateProductDetails(res)
      })
      .catch(error => {
        notification.error({ message: 'An error occured while approving products', className: 'antd-mod center' });
      });
  }


  rejectProducts() {
    const remarks = this.remarksForm.current!.getFieldValue("remarks");
    this.productApi.rejectProduct(this.state?.selectedProducts, remarks)
      .then(res => {
        this.onCancel();
        this.updateProductDetails(res)
      })
      .catch(error => {
        notification.error({ message: 'An error occured while rejecting products', className: 'antd-mod center' });
      });
  }


  updateProductDetails(newProductDetails: Product) {
    const products = [...this.state?.products],
      vendorToUpdateIdx = this.state?.products.findIndex(v => v.id == newProductDetails.id);

    if (vendorToUpdateIdx > -1) {
      products[vendorToUpdateIdx] = newProductDetails;

      this.setState({
        products: products,
        selectedProducts: []
      });
    }
  }


  onCancel(): void {
    this.remarksForm.current!.resetFields();
    this.setState({ remarksModalVisible: false });
  }

  renderRemarksModal() {
    return (
      <Modal
        title="Reasons for Rejection"
        visible={this.state?.remarksModalVisible}
        maskClosable={false}
        okText="Save"
        onOk={() => this.rejectProducts()}
        onCancel={() => this.onCancel()}
        width={600}
      >
        <Form ref={this.remarksForm} layout="vertical" autoComplete="off">
          <Form.Item name="remarks" label="Reasons">
            <TextArea size="large" />
          </Form.Item>
        </Form>
      </Modal>
    );
  }


  render() {
    return (
      <div className="AdminProductsPage">
        <div className="title">
          <div className="typo-title-2 typo-bold">Products</div>
          {/* <div className="actions">
            <Button type="default" disabled={this.state?.selectedProducts?.length == 0} size="large" icon={<CheckOutlined />} onClick={()=>this.approveProducts()}>Approve</Button>
            <Button type="default" disabled={this.state?.selectedProducts?.length == 0} danger size="large" icon={<CloseOutlined />} onClick={()=>this.rejectProducts()}>Reject</Button>
          </div> */}
        </div>
        <div className="main-content">
          {this.renderRemarksModal()}

          <AdminProductsTable
            hideAction={this.props?.hideAction}
            products={this.state?.products}
            selectedProducts={this.state?.selectedProducts}
            selectionChange={(selectedProducts) => { this.setState({ selectedProducts: selectedProducts }) }}
            approveProduct={(productId) => this.approveProducts(productId)}
            rejectProduct={(productId) => this.setState({ selectedProducts: [productId], remarksModalVisible: true })}
            pagination={this.state?.pagination}
            onChange={this.handleTableChange}
          />
        </div>
      </div>
    );
  }
}

export default AdminProductsPage
