// import './AlbumShowPage.scss';

import * as React from 'react';
import { notification, Tabs, Button } from 'antd';
import Gallery from 'react-photo-gallery';
import reactImageSize from 'react-image-size';
import { LeftOutlined } from '@ant-design/icons';

import { User } from '../../../interfaces/user.interface';
import { Vendor } from '../../../interfaces/vendor-interface';
import { Album } from '../../../interfaces/album.interface';

import UserApi from '../../../api-services/user-api.service';
import AlbumApi from '../../../api-services/album-api.service';

import Navbar from '../../basic-components/navbar/Navbar';
import BreadcrumbsNavigation from '../../basic-components/breadcrumbs-navigation/BreadcrumbsNavigation';
import VendorSummary from '../../basic-components/vendor-summary/VendorSummary';
import Footer from '../../basic-components/footer/Footer';
import VendorProductsTab from '../../tab-contents/vendor-products-tab/VendorProductsTab';
import VendorAboutTab from '../../tab-contents/vendor-about-tab/VendorAboutTab';
import VendorFaqTab from '../../tab-contents/vendor-faq-tab/VendorFaqTab';
import VendorAlbumsTab from '../../tab-contents/vendor-albums-tab/VendorAlbumsTab';
import LoginModal from '../../modals/login-modal/LoginModal';
import SingleImageModal from '../../modals/single-image-modal/SingleImageModal';
import ForgotPasswordModal from '../../modals/forgot-password-modal/ForgotPasswordModal';
import LikeableImage from "../../basic-components/likeable-image/LikeableImage";
import AlbumImageApi from "../../../api-services/album-image-api.service";
import AppointmentModal from "../../modals/appointment-modal/AppointmentModal";

interface AlbumShowPageProps {
  isAccountSignedIn: boolean;
  googleSignInUrl: string;
  fbSignInUrl: string;
  csrfToken:string;
  backendData: any; /* Object generated by backend which contains vendor and album data */
}

interface AlbumShowPageState {
  user: User;
  vendor: Vendor;
  album: Album;
  loginModalVisible: boolean;
  loginModalRedirectUrl: string;
  breadcrumbsNavItems: Array<{ id?: any; label: string; link?: string; }>;
  galleryModalVisible: boolean;
  currentGalleryIndex: number;
  galleryImages: Array<any>;
  albumsSet: Array<{name?: string, result?: Album[]}>;
  activeTab: string;
  videoModalVisible: boolean;
  currentVideoUrl: string;
  forgotPasswordModalVisible: boolean;
  appointmentModalVisible: boolean;
}

class AlbumShowPage extends React.Component<AlbumShowPageProps, AlbumShowPageState> {

  userApi = new UserApi();
  albumApi = new AlbumApi();
  albumImageApi = new AlbumImageApi;

  componentWillMount() {
    // console.log('BACKEND DATA', this.props?.backendData);
    this.mapIntoVendorObject(this.props?.backendData?.vendor);
    this.mapIntoAlbumObject(this.props?.backendData?.album);

    this.setState({
      galleryImages: []
    });

    this.setState({
      activeTab: this.props?.backendData?.album?.kind
    });
  }

  componentDidMount(): void {
    this.setBreadcrumbsNavItems();
    this.loadSignedInUserProfile();
    this.setGalleryImages();

    this.loadAlbums(this.state?.vendor?.albumTypes);
  }

  /**
   *
   * @param vendorData Vendor object generated by the backend.
   */
  mapIntoVendorObject(vendorData: any): void {
    const vendor: Vendor = {
      id: vendorData?.id,
      address: vendorData?.address,
      fullAddress: vendorData?.full_address,
      avatarUrl: vendorData?.avatar_url,
      description: vendorData?.about,
      businessName: vendorData?.business_name,
      businessRegisteredName: vendorData?.business_registered_name,
      businessRegisteredNumber: vendorData?.business_registration_no,
      email: vendorData?.email,
      facebook: vendorData?.facebook,
      whatsapp: vendorData?.whatsapp,
      rating: vendorData?.rating,
      reviews: vendorData?.reviews,
      slug: vendorData?.slug,
      tiktok: vendorData?.tiktok,
      twitter: vendorData?.twitter,
      instagram: vendorData?.instagram,
      website: vendorData?.website,
      youtube: vendorData?.youtube,
      zipCode: vendorData?.zip_code,
      city: {
        id: vendorData?.city?.id,
        name: vendorData?.city?.name
      },
      country: {
        id: vendorData?.country?.id,
        name: vendorData?.country?.name,
        display: vendorData?.country?.display,
        currency: vendorData?.country?.currency
      },
      primaryPhoneNumber: {
        id: vendorData?.primary_phone?.id,
        isBusiness: vendorData?.primary_phone?.is_business,
        isPrimary: vendorData?.primary_phone?.is_primary,
        number: vendorData?.primary_phone?.number,
        verified: vendorData?.primary_phone?.verified,
        countryCode: vendorData?.primary_phone?.formatted?.country_code,
        national: vendorData?.primary_phone?.formatted?.national,
        rawNational: vendorData?.primary_phone?.formatted?.raw_national
      },
      albumTypes: vendorData?.album_types?.map(albumType => albumType),
      serviceCategories: [],
      highlightedFaqs: [],
      faqItems: []
    };

    // Populate vendor.serviceCategories
    vendorData?.services?.forEach(service => {
      vendor.serviceCategories.push({
        id: service?.category?.id,
        name: service?.category?.name,
        slug: service?.category?.slug,
      });
    });

    // Populate highlighted faqs
    vendorData?.highlighted_faqs?.forEach(faq => {
      vendor.highlightedFaqs.push({
        position: faq.position,
        label: faq.label,
        value: faq.value
      });
    });

    // Populate vendor.faqItems
    vendorData?.faqs?.forEach(faq => {
      vendor.faqItems.push({
        question: faq.question,
        answer: faq.answer
      });
    });

    // console.log('VENDOR', vendor);
    this.setState({ vendor: vendor });
  }

  /**
   *
   * @param albumData Album data generated b the backend.
   */
  mapIntoAlbumObject(albumData: any): void {
    const album: Album = {
      id: albumData.id,
      description: albumData.description,
      kind: albumData.kind,
      slug: albumData.slug,
      status: albumData.status,
      title: albumData.title,
      coverImageUrl: albumData.cover_image_url,
      mostRecentImages: albumData.most_recent_images?.map(image => {
        return {
          id: image.id,
          liked: image.liked,
          url: image.url
        };
      })
    };

    this.setState({ album: album });
  }

  get currentGalleryImageUrl(): string {
    if(this.state?.galleryImages && this.state?.galleryImages[this.state?.currentGalleryIndex]) {
      return this.state?.galleryImages[this.state?.currentGalleryIndex]?.src;
    } else {
      return null;
    }
  }

  loadAlbums(albumTypes): void {
    let queryAlbumsRequests = []

    albumTypes.forEach(album_type => {
      queryAlbumsRequests.push(this.albumApi.getVendorAlbums(this.state?.vendor?.slug, album_type))
    });

    Promise.all(queryAlbumsRequests)
    .then(result => {
      // console.log('RESULTS', result);
      let albumsSet = [];
      result.forEach((r, i) => {
        albumsSet.push({
          name: albumTypes[i],
          result: r
        });
      });

      this.setState({
        albumsSet: albumsSet
      });
    });
  }

  setBreadcrumbsNavItems(): void {
    this.setState({
      breadcrumbsNavItems: [
        { id: 1, label: 'Home', link: '/' },
        { id: 2, label: 'Vendors', link: '/vendors' },
        { id: this.state?.vendor?.id, label: this.state?.vendor?.businessName, link: `/vendors/${this.state?.vendor?.slug}` },
        { id: this.state?.album?.id, label: this.state?.album?.title, link: '' },
      ]
    });
  }

  loadSignedInUserProfile(): void {
    if(!this.props?.isAccountSignedIn) {
      return null;
    }
    this.userApi.getUserProfile()
    .then(user => {
      this.setState({
        user: user
      });
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: 'Error while loading the user\'s profile.', className: 'antd-mod center' });
    });
  }

  tabOnChange(activeKey: string): void {
    // console.log('activeKey', activeKey)
    this.setState({
      activeTab: activeKey
    });
    // TODO: change browser link
    // history.pushState(null, '', `/{vendor_name)}/${activeKey}`);
  }

  /**
   * Set gallery images.
   * This also determines if the image has portrait or landscape layout.
   * Will use 4x3 ratio if the image is landscape then 3x4 if protrait, else will use 1x1.
   */
  setGalleryImages(): void {
    const galleryImages: Array<{ src: string; width: number; height: number; }> = [];
    const imageSizeRequests = this.props?.backendData?.images?.length ? this.props?.backendData?.images?.map(image => ({...reactImageSize(image.attributes.image_url), ...image.attributes})) : [];

    Promise.all(imageSizeRequests)
    .then((imgElements: any[]) => {
      imgElements?.forEach((imgElement, imgElementIndex) => {
        if(imgElement?.height > imgElement?.width) {
          galleryImages.push({ ...imgElement, width: 3, height: 4, src: imgElement.image_url });
        } else if(imgElement?.width > imgElement?.height) {
          galleryImages.push({ ...imgElement, width: 4, height: 3, src: imgElement.image_url });
        } else {
          galleryImages.push({ ...imgElement, width: 1, height: 1, src: imgElement.image_url });
        }
      });
      this.setState({ galleryImages: galleryImages });
    });
  }

  videoClick(album): void {
    this.setState({
      videoModalVisible: true,
      currentVideoUrl: album.videoUrl
    });
    // console.log(album)
  }

  redirectToAlbumShowPage(vendorSlug: string, albumSlug: string): void {
    window.open(`/${vendorSlug}/albums/${albumSlug}`,'_self');
  }

  backToAlbum(albumType): void{
    this.setState({
      activeTab: albumType,
      album: null
    });
  }

  capitalize(string){
    return string.replaceAll('_', ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  }

  /**
   * Like/Unlike image.
   * Remove the image from the view after unliking it.
   */
  updateAlbumImageLikeStatus(imageId: number, likeStatus): void {
    this.albumImageApi.updateAlbumImageLikeStatus(imageId, likeStatus);
        // .then(() => {
        //   if(likeStatus === false) {
        //     const likedImages = this.state?.likedImages?.filter(image => image.id !== imageId);
        //     this.setState({ likedImages: likedImages });
        //   }
        // });
  }

  renderAlbumTabByType(albumType: string) {
    if(this.state?.albumsSet?.length){
      let index = this.state?.vendor?.albumTypes.indexOf(albumType);
      if(albumType == this.state?.album?.kind) {
        return (
          <Tabs.TabPane tab={(<div className="typo-subheading-1">{this.capitalize(albumType)}</div>)} key={albumType}>
            <div className="VendorAlbumsTab">
              <div className="content-waterfalls">
                <Button type="link" className="back-to-link typo-bold" icon={<LeftOutlined />} onClick={()=>{this.backToAlbum(albumType)}}>Back to Album List</Button>
                <div className="title typo-headline">{this.state?.album?.title}</div>
                <div className="description" dangerouslySetInnerHTML={{__html: this.state.album?.description}}></div>
                <SingleImageModal visible={this.state?.galleryModalVisible} imageUrl={this.currentGalleryImageUrl} type="image" closed={() => { this.setState({ galleryModalVisible: false }) }}></SingleImageModal>
                { this.state?.galleryImages?.length > 0 ? (
                    <Gallery
                        photos={this.state?.galleryImages}
                        renderImage={(image) => <LikeableImage
                            width={image.photo.width}
                            height={image.photo.height}
                            imageUrl={image.photo.image_url}
                            likeStatus={image.photo.like}
                            likeButtonDisabled={!this.props?.isAccountSignedIn}
                            key={image.photo.id}
                            likeStatusChanged={(likeStatus: boolean) => { this.updateAlbumImageLikeStatus(image.photo.id, likeStatus) }}
                            onClick={() => { this.setState({ galleryModalVisible: true, currentGalleryIndex: image.index }) }}
                        />}
                        direction={"column"}
                        // onClick={(event, metaData) => { debugger; this.setState({ galleryModalVisible: true, currentGalleryIndex: metaData.index }) }}
                    />
                ) : null }
              </div>
            </div>
          </Tabs.TabPane>
        );
      } else{
        return (
          <Tabs.TabPane tab={(<div className="typo-subheading-1">{this.capitalize(albumType)}</div>)} key={albumType}>
            <SingleImageModal visible={this.state?.videoModalVisible} imageUrl={this.state?.currentVideoUrl} type="video" closed={() => { this.setState({ videoModalVisible: false }) }}></SingleImageModal>
            <VendorAlbumsTab tabTitle={this.capitalize(albumType)} albums={this.state?.albumsSet[index].result} albumClicked={(album) => { albumType == 'videos' ? this.videoClick(album) : this.redirectToAlbumShowPage(this.state?.vendor?.slug, album.slug) }}></VendorAlbumsTab>
          </Tabs.TabPane>
        );
      }
    }
  }

  handleAppointmentButton(): void {
    this.setState({appointmentModalVisible: true});
  }

  handleSubmitAppointment(data): void {
    this.userApi.createAppointment(this.state?.vendor.id, data.email, data.date, data.detail, data.name, data.mobile)
      .then(result => {
        if (result) {
          notification.success({ message: 'Appointment saved successfully', className: 'antd-mod center' });
          this.setState({appointmentModalVisible: false});
        } else {
          notification.error({ message: 'Error while creating the appointment.', className: 'antd-mod center' });
        }
      });
  }

  render() {
    return (
      <div className="AlbumShowPage">
        <Navbar
          isAccountSignedIn={this.props?.isAccountSignedIn}
          user={this.state?.user}
          signInRequired={(redirectUrl) => { this.setState({ loginModalVisible: true, loginModalRedirectUrl: redirectUrl }); }}
        />

        <LoginModal
          visible={this.state?.loginModalVisible}
          googleSignInUrl={this.props?.googleSignInUrl}
          fbSignInUrl={this.props?.fbSignInUrl}
          csrfToken={this.props?.csrfToken}
          redirectUrl={this.state?.loginModalRedirectUrl}
          forgotPasswordClicked={() => { this.setState({ loginModalVisible: false, forgotPasswordModalVisible: true }) }}
          closed={() => { this.setState({ loginModalVisible: false }) }}
          registerLink="/registration"
        />

        <ForgotPasswordModal
          visible={this.state?.forgotPasswordModalVisible}
          loginClicked={() => { this.setState({ forgotPasswordModalVisible: false, loginModalVisible: true }) }}
          closed={() => { this.setState({ forgotPasswordModalVisible: false }) }}
        />

        <AppointmentModal
          visible={this.state?.appointmentModalVisible}
          onCreate={(data) => {this.handleSubmitAppointment(data)}}
          onCancel={() => {this.setState({ appointmentModalVisible: false })}}
        />

        <div className="page-content">
          <BreadcrumbsNavigation items={this.state?.breadcrumbsNavItems} />
          <VendorSummary
            signedIn={this.props?.isAccountSignedIn}
            vendor={this.state?.vendor}
            onClickAppointment={() => { this.handleAppointmentButton() }}
          />
        </div>

        <Tabs className="antd-mod vendor-main-tabs" activeKey={this.state?.activeTab} onChange={(activekey) => { this.tabOnChange(activekey) }} >
          <Tabs.TabPane tab={(<div className="typo-subheading-1">Wedding Deals</div>)} key="wedding_deals">
            <VendorProductsTab vendor={this.state?.vendor} />
          </Tabs.TabPane>

          {
            /* Render Album tabs that are specified in vendor.albumTypes */
            this.state?.vendor?.albumTypes?.map(albumType => this.renderAlbumTabByType(albumType))
          }

          <Tabs.TabPane tab={(<div className="typo-subheading-1">FAQS</div>)} key="faq">
            <VendorFaqTab faqItems={this.state?.vendor?.faqItems} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={(<div className="typo-subheading-1">About</div>)} key="about">
            <VendorAboutTab about={this.state?.vendor?.description} />
          </Tabs.TabPane>
        </Tabs>

        <Footer userType={this.state?.user?.type} />
      </div>
    );
  }
}

export default AlbumShowPage;
