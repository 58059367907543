import axios from 'axios';

class AuthApi {

  /**
   * Will try to login using the credentials provided.
   * Will return OK or failed status.
   */
  login(email: string, password: string): Promise<any> {
    return axios.post('/api/v1/accounts/login', {
      account: {
        email: email,
        password: password
      }
    });
  }

  vendorLogin(email: string, password: string): Promise<any> {
    return axios.post('/api/v1/business/accounts/login', {
      account: {
        email: email,
        password: password
      }
    });
  }


  logout(): Promise<any> {
    return axios.delete('/api/v1/accounts/logout');
  }


  forgotPassword(email:string): Promise<any> {
    const apiUrl = `/api/v1/accounts/password`;

    return axios.post(apiUrl, {
      account:{
        email: email
      }
    })
    .then(response => {
      return Promise.resolve(true);
    });

  }


}


export default AuthApi;