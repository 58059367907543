import * as React from "react"
import { Button, Table, Image } from "antd";

import {BlogBanner} from "../../../interfaces/blog-banner.interface";
import * as moment from "moment";


interface IAdminBlogBannersTableProps {
  hideAction: boolean;
  banners:BlogBanner[];
  selectedBanners:number[];
  editBanner: (banner: BlogBanner)=>void;
  publishBanner: (id: number)=>void;
  unpublishBanner: (id: number)=>void;
  removeBanner: (id:number)=>void;
  selectionChange: (selectedRows)=>void;
  onChange: (pagination:any)=>void;
  pagination: any;
}

interface IAdminBlogBannersTableState {
  columns: any[];
}

class AdminBlogBannersTable extends React.Component <IAdminBlogBannersTableProps, IAdminBlogBannersTableState> {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'Image',
          dataIndex: 'image',
          width: 50,
          render: (text) => {
            return <Image width={50} src={text}/>
          }
        },
        {
          title: 'Title',
          dataIndex: 'title'
        },
        {
          title: 'Link',
          dataIndex: 'link'
        },
        {
          title: 'Placement',
          dataIndex: 'placing'
        },
        {
          title: 'Position',
          dataIndex: 'position'
        },
        {
          title: 'Start Time',
          dataIndex: 'showStart',
          render: (showStart, record) => {
            return (
              <span>{record.showStart != null && `${moment(showStart).format('DD/MM/YYYY HH:mm:ss')}`}</span>
            )
          }
        },
        {
          title: 'End Time',
          dataIndex: 'showEnd',
          render: (showEnd, record) => {
            return (
              <span>{record.showEnd != null && `${moment(showEnd).format('DD/MM/YYYY HH:mm:ss')}`}</span>
            )
          }
        },
        {
          title: 'Click Count',
          dataIndex: 'clickCount'
        },
        {
          title: 'Category',
          render: (text, record) => {
            return record.category.name;
          }
        }
      ]
    };
  }

  componentDidMount() {
    if (this.props?.hideAction) {
      this.setState({columns: [
          {
            title: 'Image',
            dataIndex: 'image',
            width: 50,
            render: (text) => {
              return <Image width={50} src={text}/>
            }
          },
          {
            title: 'Title',
            dataIndex: 'title'
          },
          {
            title: 'Link',
            dataIndex: 'link'
          },
          {
            title: 'Placement',
            dataIndex: 'placing'
          },
          {
            title: 'Position',
            dataIndex: 'position'
          },
          {
            title: 'Start Time',
            dataIndex: 'showStart',
            render: (showStart, record) => {
              return (
                <span>{record.showStart != null && `${moment(showStart).format('DD/MM/YYYY HH:mm:ss')}`}</span>
              )
            }
          },
          {
            title: 'End Time',
            dataIndex: 'showEnd',
            render: (showEnd, record) => {
              return (
                <span>{record.showEnd != null && `${moment(showEnd).format('DD/MM/YYYY HH:mm:ss')}`}</span>
              )
            }
          },
          {
            title: 'Category',
            render: (text, record) => {
              return record.category.name;
            }
          },
          {
            title: 'Click Count',
            dataIndex: 'clickCount'
          }
        ]});
    } else {
      this.setState({columns: [
          {
            title: 'Image',
            dataIndex: 'image',
            width: 50,
            render: (text) => {
              return <Image width={50} src={text}/>
            }
          },
          {
            title: 'Title',
            dataIndex: 'title'
          },
          {
            title: 'Link',
            dataIndex: 'link'
          },
          {
            title: 'Placement',
            dataIndex: 'placing'
          },
          {
            title: 'Position',
            dataIndex: 'position'
          },
          {
            title: 'Start Time',
            dataIndex: 'showStart',
            render: (showStart, record) => {
              return (
                <span>{record.showStart != null && `${moment(showStart).format('DD/MM/YYYY HH:mm:ss')}`}</span>
              )
            }
          },
          {
            title: 'End Time',
            dataIndex: 'showEnd',
            render: (showEnd, record) => {
              return (
                <span>{record.showEnd != null && `${moment(showEnd).format('DD/MM/YYYY HH:mm:ss')}`}</span>
              )
            }
          },
          {
            title: 'Category',
            render: (text, record) => {
              return record.category.name;
            }
          },
          {
            title: 'Click Count',
            dataIndex: 'clickCount'
          },
          {
            title: 'Action',
            key: 'operation',
            width: 200,
            render: (text, record) => {
              return <>
                <Button type="link" onClick={()=>this.props?.editBanner(record)}>Edit</Button>
                {record.mayPublish && <Button type="link" onClick={()=>this.props?.publishBanner(record.id)}>Publish</Button>}
                {record.mayUnpublish && <Button type="link" onClick={()=>this.props?.unpublishBanner(record.id)}>Unpublish</Button>}
                {/* <Button type="link" danger onClick={()=>this.props?.removeBanner(record.id)}>Remove</Button> */}
              </>
            },
          },
        ]});
    }
  }


  render() {
    const rowSelection = {
      // selectedRowKeys: this.props?.selectedBanners,
      preserveSelectedRowKeys: false,
      onChange: (selectedRowKeys, selectedRows) => {
        this.props?.selectionChange(selectedRowKeys);
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };

    return (
      <div className="AdminBannersTable">
        <Table size="small" bordered rowKey="id"
          columns={this.state.columns}
          dataSource={this.props?.banners}
          pagination={this.props?.pagination}
          onChange={this.props?.onChange}
        />
      </div>
    );
  }
}

export default AdminBlogBannersTable

