import * as React from "react"
import {Button, DatePicker, Form, FormInstance, Input, Modal, notification, Select, Upload} from "antd";
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {Banner} from "../../../interfaces/banner.interface";
import AdminBannersTable from "../../basic-components/admin-banners-table/AdminBannersTable";
import BannerAdminApi from "../../../api-services/banner-admin-api.service";
import * as moment from "moment";

const {RangePicker} = DatePicker;


interface IAdminBannersPageProps {
  hideAction: boolean;
}

interface IAdminBannersPageState {
  banners: Banner[];
  modalVisible: boolean;
  selectedBanner: Banner;
  selectedBanners: number[];
  uploading: boolean;
  savedImage;
  imageUrl: string;
}

class AdminBannersPage extends React.Component <IAdminBannersPageProps, IAdminBannersPageState> {
  static defaultProps = {hideAction: false};
  bannerApi = new BannerAdminApi();
  bannerSetupForm = React.createRef<FormInstance>();
  placements: string[] = ['top', 'middle', 'bottom'];

  componentDidMount() {
    this.setState({
      banners: [],
      selectedBanners: [],
      selectedBanner: null,
      modalVisible: false,
      uploading: false,
      savedImage: []
    });

    setTimeout(() => {
      this.loadBanners();
    }, 500);
  }

  loadBanners(): void {
    this.bannerApi.getBanners()
      .then(bannersData => {
        this.setState({banners: bannersData});
      })
      .catch(error => {
        notification.error({
          message: 'An error occurred while loading the data for banners',
          className: 'antd-mod center'
        });
      });
  }

  saveBanner(newBanner): void {
    const formData = new FormData();
    formData.append("title", newBanner.title);
    formData.append("link", newBanner.link);
    formData.append("placement", newBanner.placement);
    formData.append("position", newBanner.position);

    this.state?.savedImage.forEach(file => {
      formData.append('image', file);
    });

    if(newBanner.showPeriod){
      formData.append("show_start", newBanner.showPeriod[0].utc().format('DD/MM/YYYY HH:mm:ss'));
      formData.append("show_end", newBanner.showPeriod[1].utc().format('DD/MM/YYYY HH:mm:ss'));
    }

    if (this.state?.selectedBanner) {
      const bannerId = this.state?.selectedBanner?.id;
      this.bannerApi.updateBanner(bannerId, formData)
        .then(bannerResponse => {
          const bannerIndex = this.state?.banners?.findIndex(c => c.id == bannerId);
          if (bannerIndex > -1) {
            let banners = [...this.state?.banners];
            banners[bannerIndex] = bannerResponse;
            this.bannerSetupForm.current!.resetFields();
            this.setState({
              banners: banners,
              modalVisible: false
            });

            notification.success({message: 'Changes saved successfully', className: 'antd-mod center'});
          }
        })
        .catch(error => {
          notification.error({message: 'An error occurred while updating banner', className: 'antd-mod center'});
        });
    } else {
      this.bannerApi.addBanner(formData)
        .then(bannerResponse => {
          this.setState(prevState => ({
            banners: [...prevState.banners, bannerResponse],
            modalVisible: false
          }));
          this.bannerSetupForm.current!.resetFields();
        })
        .catch(error => {
          console.log("error", error)
          notification.error({message: 'An error occurred while adding banner', className: 'antd-mod center'});
        });
    }
  }

  updateBannerList(newBanner) {
    const bannerIndex = this.state?.banners?.findIndex(c => c.id == newBanner.id);
    if (bannerIndex > -1) {
      let banners = [...this.state?.banners];
      banners[bannerIndex] = newBanner;
      this.setState({banners: banners});

      notification.success({message: 'Changes saved successfully', className: 'antd-mod center'});
    }
  }

  onAddBanner(): void {
    this.bannerSetupForm.current!
      .validateFields()
      .then(values => this.saveBanner(values))
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  }

  onEditBanner(banner: Banner): void {
    this.setState({modalVisible: true, selectedBanner: banner, imageUrl: banner.image});

    setTimeout(() => {
      this.bannerSetupForm.current!
        .setFieldsValue({
          title: banner.title,
          link: banner.link,
          placement: banner.placement,
          position: banner.position,
          showPeriod: banner.showStart ? [moment(banner.showStart), moment(banner.showEnd)] : null,
        });
    });
  }

  onCancelBanner(): void {
    this.bannerSetupForm.current!.resetFields();
    this.setState({modalVisible: false, savedImage: [], imageUrl: ""});
  }

  removeBanners(bannerId?: number): void {
    const bannersToRemove = bannerId ? [bannerId] : this.state?.selectedBanners;
    this.bannerApi.removeBanner(bannersToRemove)
      .then(res => {
        this.setState(prevState => ({
          banners: prevState.banners.filter(banner => !bannersToRemove.includes(banner.id)),
          selectedBanners: []
        }));
      })
      .catch(error => {
        notification.error({message: 'An error occurred while removing banner', className: 'antd-mod center'});
      });
  }

  publishBanner(bannerId?: number): void {
    this.bannerApi.publishBanner(bannerId)
      .then(bannerResponse => {
        this.updateBannerList(bannerResponse);
      })
      .catch(error => {
        console.log(error)
        notification.error({message: 'An error occurred while publishing banner', className: 'antd-mod center'});
      });
  }

  unpublishBanner(bannerId?: number): void {
    this.bannerApi.unpublishBanner(bannerId)
      .then(bannerResponse => {
        this.updateBannerList(bannerResponse);
      })
      .catch(error => {
        console.log(error)
        notification.error({message: 'An error occurred while unpublishing banner', className: 'antd-mod center'});
      });
  }

  getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({message: 'You can only upload JPG/PNG file!', className: 'antd-mod center'});
    } else {
      this.setState({savedImage: [file]});
    }
    return isJpgOrPng;
  };

  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  handleChange = info => {
    this.getBase64(info.file.originFileObj, imageUrl =>
      this.setState({
        imageUrl,
        uploading: false,
      }),
    );
  };

  renderAddBannerModal() {
    const uploadButton = (
      <div id="upload-button">
        {this.state?.uploading ? <LoadingOutlined/> : <PlusOutlined/>} Upload
      </div>
    );

    return (
      <Modal
        title="Add Banner"
        visible={this.state?.modalVisible}
        maskClosable={false}
        okText="Save"
        onOk={() => this.onAddBanner()}
        onCancel={() => this.onCancelBanner()}
        width={600}
      >
        <Form id="banner-form" ref={this.bannerSetupForm} layout="vertical" autoComplete="off">
          <Form.Item name='image' getValueFromEvent={this.getFile} label="Image">
            <Upload maxCount={1}
                    showUploadList={false}
                    fileList={this.state?.savedImage}
                    accept="image/*"
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleChange}
                    className="uploader"
            >
              {this.state?.imageUrl ?
                <img src={this.state?.imageUrl} className="banner-image" alt="banner-image"/> : uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item name="title" label="Title" rules={[{required: true, message: 'Title is required'}]}>
            <Input size="large"/>
          </Form.Item>
          <Form.Item name="link" label="Link">
            <Input size="large"/>
          </Form.Item>
          <Form.Item name="placement" label="Placing">
            <Select size="large">
              <Select.Option value='top'>Top - Image size 430 * 215 (2:1)</Select.Option>
              <Select.Option value='middle'>Middle - Image size 1920 * 760 (48:19)</Select.Option>
              <Select.Option value='bottom'>Bottom - Image size 430 * 215 (2:1)</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="position" label="Position">
            <Input type="number" size="large"/>
          </Form.Item>
          <Form.Item name="showPeriod" label="Showing Period">
            <RangePicker showTime size="large" showNow={true}/>
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  render() {
    return (
      <>
        <div className="AdminBannersPage">
          <div className="title">
            <div className="typo-title-2 typo-bold">Banners</div>
            {!this.props?.hideAction && (
              <div className="actions">
                <Button type="default" size="large" icon={<PlusOutlined/>}
                        onClick={() => this.setState({modalVisible: true, selectedBanner: null})}>Add</Button>
                {/* <Button type="default" danger size="large" disabled={this.state?.selectedBanners?.length == 0} icon={<DeleteOutlined />} onClick={()=>this.removeBanners()}>Remove</Button> */}
              </div>
            )}
          </div>
          <div className="main-content">
            {this.renderAddBannerModal()}
            <AdminBannersTable
              hideAction={this.props?.hideAction}
              banners={this.state?.banners}
              selectedBanners={this.state?.selectedBanners}
              selectionChange={(selectedBanners) => {
                this.setState({selectedBanners: selectedBanners})
              }}
              editBanner={(banner) => this.onEditBanner(banner)}
              removeBanner={(bannerId) => this.removeBanners(bannerId)}
              publishBanner={(bannerId) => this.publishBanner(bannerId)}
              unpublishBanner={(bannerId) => this.unpublishBanner(bannerId)}
            />
          </div>
        </div>
      </>
    );
  }
}

export default AdminBannersPage
