import axios from "axios";
import { Product, ProductImage } from "../interfaces/product.interface";


class ProductAdminApi {

  getProductsForApproval(page, per_page): Promise<any> {
    const apiUrl = `/api/v1/admin/products?per_page=${per_page}&page=${page}`;

    return axios.get(apiUrl)
      .then((response: any) => {
        const products: Product[] = [];

        response?.data?.data?.forEach((productData: any) => {
          products.push(this.mapProductShortInfo(productData?.attributes));
        });

        return Promise.resolve({ products: products, total: response?.data?.meta?.total_count });
      });
  }

  getProduct(productId: any): Promise<Product> {
    const apiUrl = `/api/v1/admin/products/${productId}?`;

    return axios.get(apiUrl)
      .then(response => {
        const product: Product = this.mapProduct(response?.data?.data);
        return Promise.resolve(product);
      });
  }

  getImages(productId: any): Promise<ProductImage[]> {
    const apiUrl = `/api/v1/admin/products/${productId}/images`;

    return axios.get(apiUrl)
      .then(response => {
        const images: ProductImage[] = [];
        response?.data?.data?.forEach((imageResponse: any) => {
          images.push({
            id: imageResponse?.attributes?.id,
            imageUrl: imageResponse?.attributes?.image_url,
          })
        });
        return Promise.resolve(images);
      })
  }



  approveProduct(productIds: number[]): Promise<Product> {
    const apiUrl = `/api/v1/admin/products/${productIds}/approve`;
    return axios.patch(apiUrl).then(response => {
      const product = this.mapProductShortInfo(response?.data?.data?.attributes);
      return Promise.resolve(product);
    });
  }


  rejectProduct(productIds: number[], remarks?: string): Promise<Product> {
    const apiUrl = `/api/v1/admin/products/${productIds}/reject`;
    return axios.patch(apiUrl, {
      remarks: remarks
    }).then(response => {
      const product = this.mapProductShortInfo(response?.data?.data?.attributes);
      return Promise.resolve(product);
    });
  }


  mapProduct(serverResponse) {
    return {
      id: serverResponse?.attributes?.id,
      name: serverResponse?.attributes?.name,
      unit: serverResponse?.attributes?.unit,
      type: serverResponse?.attributes?.kind,
      status: serverResponse?.attributes?.status,
      sku: serverResponse?.attributes?.sku,
      details: serverResponse?.attributes?.details,
      description: serverResponse?.attributes?.description,
      terms: serverResponse?.attributes?.terms_and_conditions,
      paymentTerms: serverResponse?.attributes?.payment_terms,
      price: serverResponse?.attributes?.price,
      discountPercentage: serverResponse?.attributes?.discounted_price,
      trackInventory: serverResponse?.attributes?.track_inventory,
      quantity: serverResponse?.attributes?.total_qty_on_hand,
      isPhysical: serverResponse?.attributes?.is_physical,
      partialPaymentType: serverResponse?.attributes?.partial_payment_type,
      partialPayment: serverResponse?.attributes?.required_partial_payment,
      requirePartialPayment: serverResponse?.attributes?.partial_payment_required,
      cutOffDays: serverResponse?.attributes?.cut_off_days,
      easyReschedule: serverResponse?.attributes?.flexi_reschedule,
      campaign: serverResponse?.attributes?.campaign?.id,
      subCategories: serverResponse?.attributes?.subcategories?.map(c => c.id),
      availabilityStart: serverResponse?.attributes?.availability_start,
      availabilityEnd: serverResponse?.attributes?.availability_end,
      viewCount: serverResponse?.attributes?.view_count,
      outstationFees: serverResponse?.attributes?.outstation_fees?.map(o => {
        const location = o.parent_locations || [];
        location.push(o.location_id);
        return { location: location, fee: o.fee }
      }),
      option: serverResponse?.attributes?.option,
      optionValues: serverResponse?.attributes?.option_values,
      calendarId: serverResponse?.attributes?.calendar_id,
      variants: serverResponse?.attributes?.variants.map(variant => {
        return {
          id: variant?.id,
          option: variant?.option_value,
          details: variant?.details,
          price: variant?.price,
          discountedPrice: variant?.discounted_price,
          savingAmount: variant?.discount_amount,
          stock: variant?.total_qty_on_hand,
          sku: variant?.sku,
          trackInventory: variant?.track_inventory,
          calendarId: variant?.calendar_id
        };
      }),
      nonMuslim: serverResponse?.attributes?.non_muslim_only,
      above21: serverResponse?.attributes?.above_twenty_one_only,
      requiredServiceDate: serverResponse?.attributes?.required_service_date,
      requiredServiceLocation: serverResponse?.attributes?.required_service_location,
      category: {
        id: serverResponse?.attributes?.category?.id,
        name: serverResponse?.attributes?.category?.name,
      },
      vendor: {
        id: serverResponse?.attributes?.vendor?.id,
        name: serverResponse?.attributes?.vendor?.name,
        serviceCategories: serverResponse?.attributes?.services?.map(service => {
          return {
            id: service?.category?.id,
            name: service?.category?.name,
            slug: service?.category?.slug,
          };
        })
      },
    };
  }


  mapProductShortInfo(serverResponse) {
    return {
      id: serverResponse?.id,
      image: serverResponse?.cover_image_url,
      mayApprove: serverResponse?.may_approve,
      mayReject: serverResponse?.may_reject,
      name: serverResponse?.name,
      status: serverResponse?.status?.replace("_", " "),
      viewCount: serverResponse?.view_count,
      vendor: {
        id: serverResponse?.vendor?.id,
        businessName: serverResponse?.vendor?.name,
        slug: serverResponse?.vendor?.slug,
      },
    }
  }
}

export default ProductAdminApi;
