// import './LoginModal.scss';

import * as React from 'react';
import { Button, Input, Modal, Divider, notification, Form } from 'antd';
import { ReactSVG } from 'react-svg';

import AuthApi from '../../../api-services/auth-api.service';

const facebook = require('@mdi/svg/svg/facebook.svg');


interface LoginModalProps {
  visible: boolean;
  googleSignInUrl: string;
  fbSignInUrl: string;
  csrfToken: string;
  registerLink: string;
  redirectUrl?: string;

  closed?: () => void;
  forgotPasswordClicked?: () => void;
}

interface LoginModalState {
  loggingIn: boolean;
}


class LoginModal extends React.Component<LoginModalProps, LoginModalState> {
  
  
  componentDidMount(): void {
    this.setState({
      loggingIn: false
    });
  }

  
  /**
   * Login using the credentials entered into the form.
   * Reload window if login was successful.
   */
  login(formEvent: any): void {
    const authApi = new AuthApi;

    this.setState({ loggingIn: true });
    
    authApi.login(formEvent?.email, formEvent?.password)
    .then(loginResponse => {
      if(this.props?.redirectUrl) {
        window.location.href = this.props?.redirectUrl;
      } else {
        window.location.reload();
      }
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: error?.response?.data?.error, className: 'antd-mod center' });
    })
    .finally(() => {
      this.setState({ loggingIn: false });
    });
  }


  render() {  
    return (
      <Modal className="LoginModal" centered={false} visible={this.props?.visible} onCancel={this.props?.closed} width={500} footer={null} keyboard={false} maskClosable={false} destroyOnClose={true}>
        
        <div className="title typo-headline">Login to your Account</div>

        <Form onFinish={(event) => { this.login(event) }} className="login-form">  
          <Form.Item name="email" rules={[{ type: 'email', message: 'The input is not valid E-mail!' }, { required: true, message: 'Email is required' }]}>
            <Input placeholder="Email Address" />
          </Form.Item>

          <Form.Item name="password" rules={[{ required: true, message: 'Password is required' }]}>
            <Input.Password placeholder="Password" />
          </Form.Item>
          
          <Button loading={this.state?.loggingIn} className="continue typo-bold-2" htmlType="submit">Continue</Button>
        </Form>
        
        <a className="forgot-password" onClick={()=>this.props?.forgotPasswordClicked()}>Forgot Password?</a>
        
        <Divider plain>or login with</Divider>
        
        <form action={this.props?.fbSignInUrl} method="post" id="fbLoginForm">
          <input type="hidden" name="authenticity_token" value={this.props?.csrfToken}></input>
          <Button htmlType="submit" className="facebook typo-bold-2 typo-body" > 
            <ReactSVG src={facebook} />  
            Facebook
          </Button>
        </form>

        <form action={this.props?.googleSignInUrl} method="post" id="googleLoginForm">
          <input type="hidden" name="authenticity_token" value={this.props?.csrfToken}></input>
          <Button htmlType="submit" className="google typo-bold-2 typo-body" > 
            <img src="/images/google-logo.png" alt="Google Icon" />
            Google
          </Button>
        </form>

        <div className="register-link">
          Dont have an account?
          &nbsp;
          <a href={this.props?.registerLink}>Register here</a>
        </div>
      </Modal>
    );
  }
  
  
}


export default LoginModal;