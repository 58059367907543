// import './AdminNavbar.scss';

import * as React from "react"

import { Input } from "antd";
import { SearchOutlined } from '@ant-design/icons';


interface IAdminNavbarProps {
}

interface IAdminNavbarState {
}

class AdminNavbar extends React.Component <IAdminNavbarProps, IAdminNavbarState> {
  render() {
    return (
      <div className="AdminNavbar">
        <div className="content">
            <div className="logo-container">
              <a href="/">
                <picture>
                  <source media="(max-width: 768px)" srcSet="/images/bridepay-logo-only.png"/>
                  <img src="/images/bridepay-logo-full.png" alt="Bridepay Logo"/>
                </picture>
              </a>
            </div>

            <div className="search-bar">
              <Input placeholder="Bridepay Search" allowClear size="large" suffix={false} addonAfter={<SearchOutlined />} />
            </div>

            <div className="other-links typo-subheading-1 typo-bold-2">
              Account
            </div>
          </div>
      </div>
    );
  }
}

export default AdminNavbar
