// import './PageCoverSlider.scss';

import * as React from 'react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Navigation, Pagination, Autoplay]);


interface PageCoverSliderProps {
  pageCoverImages: JSX.Element[]; /* List of <img /> tags to be displayed in the slider. */
}


interface PageCoverSliderState {

}




class PageCoverSlider extends React.Component<PageCoverSliderProps, PageCoverSliderState> {

  render() {
    const navigationConfig = {
      nextEl: `div.PageCoverSlider div.navigation-container img.nav-button.next`,
      prevEl: `div.PageCoverSlider div.navigation-container img.nav-button.prev`
    };
    
    return (
      <div className="PageCoverSlider">
        <Swiper className="item-slider" loop slidesPerView={1} navigation={navigationConfig} autoplay={{delay: 6000}}>
          {this.props?.pageCoverImages?.map((pageCoverImage, pageCoverImageIndex) => <SwiperSlide key={pageCoverImageIndex}>{pageCoverImage}</SwiperSlide>)}
        </Swiper>

        <div className="navigation-container">
          <div className="content">
            <img className="nav-button prev" src="/images/nav-prev-3.png" alt="Previous" />
            <img className="nav-button next" src="/images/nav-next-3.png" alt="Next" />
          </div>
        </div>
      </div>
    );
  }
  
}




export default PageCoverSlider;