// import './VendorDashboardOtherAlbumTab.scss';

import * as React from 'react';
import { Button, Modal, notification } from 'antd';

import { Album } from '../../../interfaces/album.interface';
import AlbumApi from '../../../api-services/album-api.service';
import TabSideMenu from '../../ui-components/tab-side-menu/TabSideMenu';
import AlbumCard from '../../basic-components/album-card/AlbumCard';



interface VendorDashboardOtherAlbumTabProps {
  name: string;
}


interface VendorDashboardOtherAlbumTabState {
  currentSelection: number;
  loadingAlbums: boolean;
  albums: Album[];
}




class VendorDashboardOtherAlbumTab extends React.Component<VendorDashboardOtherAlbumTabProps, VendorDashboardOtherAlbumTabState> {

  albumApi = new AlbumApi;  

  
  navigations = [
    {id: this.props?.name, type: this.props?.name, label: this.capitalize(this.props?.name)},
    // {id: "videos", type: "video", label: "VIDEOS"},
  ];
  
  selected = this.navigations[0];

  componentDidMount() {
    this.setState({
      currentSelection: 0
    });
    
    setTimeout(() => {
      this.getAlbums();
    }, 500);

  }
  
  capitalize(string){
    return string.replaceAll('_', ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  }

  addNewAlbum(): void {
    this.albumApi.createAlbum(this.selected?.type)
    .then(albumResponse => {
      window.location.href = `/business/dashboard/${this.selected.id}/${albumResponse.id}/edit`;
    })
  }


  getAlbums(): void {
    this.setState({ loadingAlbums: true });
    
    this.albumApi.getAlbums(this.selected.type)
    .then(albums => {
      this.setState({ albums: albums });
    })
    .catch(error => {
      notification.error({ message: `There\'s an error while loading the ${this.selected.id}.`, className: 'antd-mod center' })
    })
    .finally(() => {
      this.setState({ loadingAlbums: true });
    });
  }


  editAlbum(album: Album): void {
    window.location.href = `/business/dashboard/${this.selected.id}/${album.id}/edit`;
  }


  removeAlbum(albumId: number): void {
    this.albumApi.deleteAlbum(albumId)
      .then(albumResponse => {
        this.setState(prevState => ({
          albums: prevState.albums.filter(album => album.id !== albumId)
        }));
      })
  }


  handleSelectedMenu = (selected: any) => {
    this.setState({currentSelection: selected});
    this.selected = this.navigations[selected];
    
    setTimeout(() => {
      this.getAlbums();
    }, 500);
  }


  renderHeader() {
    return (
      <div className="header">
        <div className="title typo-title-2 typo-bold">
          {this.capitalize(this.props?.name)}
        </div>
        <Button className="antd-mod add-new" onClick={() => { this.addNewAlbum() }} type="primary" size="large">Add New</Button>
      </div>
    );
  }



  renderAlbumsList() {
    return (
      <div className="albums-list">
        {this.state?.albums?.map(album => 
          <AlbumCard name={this.props?.name} album={album} key={album.id} editClicked={(album) => { this.editAlbum(album) }} editable={true}
            removable={true} removeClicked={(albumId) => { this.removeAlbum(albumId) } }></AlbumCard>)
        }
      </div>
    );
  }
  
  
  render() {
    return (
      <div className="VendorDashboardOtherAlbumTab">
        {/* <TabSideMenu navigations={this.navigations} onSelectMenu={this.handleSelectedMenu}/> */}

        <div className="tab-content">
          {this.renderHeader()}
          {this.renderAlbumsList()}
        </div>
      </div>
    );
  }
  
}





export default VendorDashboardOtherAlbumTab;