// import './GalleryModal.scss';

import * as React from 'react';
import { Modal } from 'antd';
import { Carousel } from 'react-responsive-carousel';



interface GalleryModalProps {
  visible?: boolean;
  imageUrls: string[];
  currentGalleryIndex?: number;
  
  closed?: () => void;
}


interface GalleryModalState {

}



class GalleryModal extends React.Component<GalleryModalProps, GalleryModalState> {

  emitClosed(): void {
    if(this.props?.closed) {
      this.props.closed();
    }
  }
  
  
  render() {
    return (
      <Modal className="GalleryModal" width={'80vw'} centered={true} visible={this.props?.visible} onCancel={() => { this.emitClosed() }} footer={null} destroyOnClose={true} bodyStyle={{ maxHeight: '100vh' }} maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.9)"}}>
        <Carousel emulateTouch autoFocus selectedItem={this.props?.currentGalleryIndex} useKeyboardArrows={true}>{
          this.props?.imageUrls?.map((imageUrl, imageUrlIndex) => {
            return <div key={imageUrlIndex}>
              <img src={imageUrl} alt="Gallery Image" />
            </div>
          })
        }</Carousel>
      </Modal>
    );
  }
  
}




export default GalleryModal;