// import './AlbumCard.scss';

import * as React from 'react';
import { Tooltip, Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Dotdotdot from 'react-dotdotdot';

import { Album } from '../../../interfaces/album.interface';
import * as moment from "moment";


interface AlbumCardProps {
  album?: Album;
  name?: string;
  vendorName?: string;
  editable?: boolean;
  removable?: boolean;
  detailsAlignment?: 'left'|'center'|'right';
  clicked?: () => void;
  editClicked?: (album: Album) => void;
  removeClicked?: (albumId: number) => void;
  totalVideosClicked?: (album: Album) => void;
  totalPhotosClicked?: (album: Album) => void;
}


interface AlbumCardState {

}





class AlbumCard extends React.Component<AlbumCardProps, AlbumCardState> {

  emitClicked(): void {
    if(this.props?.clicked) {
      this.props?.clicked();
    }
  }
  
  
  emitEditClicked(): void {
    if(this.props?.editClicked) {
      this.props?.editClicked(this.props?.album);
    }
  }


  emittotalVideosClicked(): void {
    if(this.props?.totalVideosClicked) {
      this.props?.totalVideosClicked(this.props?.album);
    }
  }


  emittotalPhotosClicked(): void {
    if(this.props?.totalPhotosClicked) {
      this.props?.totalPhotosClicked(this.props?.album);
    }
  }


  emitRemoveClicked(){
    if(this.props?.removeClicked) {
      this.props?.removeClicked(this.props?.album?.id);
    }
  }
  
  
  renderOtherPhotos() {
    const otherPhotoUrls = this.props?.album?.mostRecentImages?.map(image => image.url) ? this.props?.album?.mostRecentImages?.slice(1,4)?.map(image => image.url) : [this.props?.album?.coverImageUrl];
    
    return (
      <div className="other-photos-container">
        {
          otherPhotoUrls.map((photo, index) => {
            if (otherPhotoUrls.length > 1 && index === 2 && this.props?.album?.totalPhotos > 4) {
              return (
                <div className="other-photo last-item" key={index}>
                  <img src={photo} loading='lazy' />
                  <div className="overlay">
                    <div className="typo-title">+{this.props?.album?.totalPhotos - 4}</div>
                  </div>
                </div>
              )
            } else {
              return (
                <div className="other-photo" key={index}>
                  <img src={photo} loading='lazy' />
                </div>
              )
            }
          })
        }
      </div>
    );
  }
  
  
  renderAlbumPreview() {
    // Generate styles for edit button that will hide/show based on this.props.editable
    const editButtonStyle = (this.props?.editable) ? {} : { display: 'none' };
    const removeButtonStyle = (this.props?.removable) ? {} : { display: 'none' };
    
    return (
      <div className="album-preview" onClick={() => { this.emitClicked() }}>
        <div className={`main-photo ${this.props?.album?.mostRecentImages?.length > 3 ? '' : 'full-image'} ${this.props?.album?.kind == "videos" ? 'video-type' : '' }`}>
          <img src={this.props?.album?.coverImageUrl} loading='lazy' />
        </div>

        {this.props?.album?.mostRecentImages?.length > 3 ? this.renderOtherPhotos() : null}
        <div className="action-buttons">
          <Button className="edit" onClick={() => { this.emitEditClicked() }} icon={<EditOutlined />} style={editButtonStyle}></Button>
          <Popconfirm title={`Are you sure you want to delete this ${this.props?.name}?`} onConfirm={() => this.emitRemoveClicked()}>
            <Button type="primary" danger icon={<DeleteOutlined />} style={removeButtonStyle}></Button>
          </Popconfirm>
        </div>
      </div>
    );
  }


  renderVendorName() {
    if(this.props.album) {
      return (
        <div className="row-1">
          <Tooltip title={this.props?.album?.vendor?.name}>
            <Dotdotdot className="vendor" clamp={1}>by {this.props?.album?.vendor?.name}</Dotdotdot>
          </Tooltip>
        </div> 
      )
    }

    else {
      return null;
    }
  }

  
  renderStatus() {
    if(this.props?.editable) {
      return <div className={`status typo-body-small ${this.props?.album?.status}`}>
        {this.props?.album?.status}
      </div>
    }

    else {
      return null;
    }
  }


  renderPhotosCount() {
    if(this.props?.album?.totalPhotos || this.props?.album?.totalPhotos === 0) {
      const count = this.props?.album?.totalPhotos;
      return "| " + this.props?.album?.totalPhotos + (count>1? " Photos" : " Photo");
    } else {
      return null;
    }
  }


  renderVideosCount() {
    if(this.props?.album?.totalPhotos || this.props?.album?.totalVideos === 0) {
      const count = this.props?.album?.totalPhotos;
      return this.props?.album?.totalVideos + (count>1? " Videos" : " Video");
    } else {
      return null;
    }
  }


  renderAlbumDetails() {
    let alignmentStyle;

    if(this.props?.detailsAlignment === 'left') {
      alignmentStyle = { alignItems: 'flex-start' };
    } else if(this.props?.detailsAlignment === 'right') {
      alignmentStyle = { alignItems: 'flex-end' };
    } else {
      alignmentStyle = { alignItems: 'flex-start' };
    }
    
    return (
      <div className="album-details" style={alignmentStyle}>
        <div className="title-container">
          <Dotdotdot className="title" clamp={2}>{this.props?.album?.title}</Dotdotdot>
        </div>
        <div className="row-1">
          <div className="typo-body-small">{this.props?.album?.updatedAt != null ? moment(this.props.album.updatedAt).format('[Updated] DD MMMM YYYY') : '' } { this.props?.album?.kind == "videos" ? this.renderVideosCount() : this.renderPhotosCount()}</div> 
        </div>
        <div className="other-details">
          {this.renderVendorName()}
          {/* <br /> */}
          {this.renderStatus()}
        </div>
      </div>
    );
  }


  render() {
    return (
      <div className="AlbumCard">
        {this.renderAlbumPreview()}
        {this.renderAlbumDetails()}
      </div>
    );
  }

}




export default AlbumCard;