import { Modal, Form, Input, Button, notification } from "antd";
import * as React from "react"
import AuthApi from "../../../api-services/auth-api.service";


interface IForgotPasswordModalProps {
  visible: boolean;
  redirectUrl?: string;

  closed?: () => void;
  loginClicked?: () => void;
}

interface IForgotPasswordModalState {
  submitting: boolean;
  showNotification: boolean;
  email:string;
}

class ForgotPasswordModal extends React.Component <IForgotPasswordModalProps, IForgotPasswordModalState> {

  componentDidMount(): void {
    this.setState({
      submitting: false,
      showNotification: false
    });
  }


  emitCloseEvent(): void{
    if(this.props?.closed){
      this.props?.closed();
    }

    this.setState({showNotification: false});
  }

  emitBackEvent(): void{
    if(this.props?.loginClicked){
      this.props?.loginClicked();
    }

    this.setState({showNotification: false});
  }

  
  /**
   * Login using the credentials entered into the form.
   * Reload window if login was successful.
   */
  submit(formEvent: any): void {
    const authApi = new AuthApi;

    this.setState({ submitting: true });
    
    authApi.forgotPassword(formEvent?.email)
    .then(loginResponse => {
      this.setState({showNotification: true, email: formEvent?.email});
    })
    .catch(error => {
      console.error(error);
      this.setState({showNotification: true, email: formEvent?.email});
      // notification.error({ message: error?.response?.data?.error, className: 'antd-mod center' });
    })
    .finally(() => {
      this.setState({ submitting: false });
    });
  }



  renderForm(){
    return (
      <>
        <div className="title typo-subheading-2">Enter the email address you used to register below</div>
        <Form onFinish={(event) => { this.submit(event) }} className="password-form">  
          <Form.Item name="email" rules={[{ type: 'email', message: 'The input is not valid E-mail!' }, { required: true, message: 'Email is required' }]}>
            <Input placeholder="Email Address" />
          </Form.Item>
          
          <Button loading={this.state?.submitting} className="continue typo-bold-2" htmlType="submit">Continue</Button>
        </Form>
      </>
    )
  }


  renderNotification(){
    return (
      <>
        <div className="typo-bold typo-subheading-2">Instruction sent!</div>
        
        <p className="typo-subheading-1"> Please check your email. Instructions for changing your password have been sent to  <strong>{this.state?.email}</strong>. </p>
        <br />
        <p className="typo-subheading-1"> Please check your email</p>

        <Button className="login typo-bold-2" onClick={()=>this.emitBackEvent()}>Login</Button>
      </>
    )
  }


  render() {  
    return (
      <Modal className="ForgotPasswordModal" centered={false} visible={this.props?.visible} onCancel={()=>this.emitCloseEvent()} width={500} footer={null} keyboard={false} maskClosable={false} destroyOnClose={true}>
        
        <div className="title typo-headline">Change Password</div>
        { this.state?.showNotification ? this.renderNotification() : this.renderForm() }

      </Modal>
    );
  }
}

export default ForgotPasswordModal
