// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "antd/dist/antd.css"
import "antd-css-utilities/utility.min.css"
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'react-quill/dist/quill.snow.css';
import "../components/ui-components/countdown/Countdown.scss"
import "../components/ui-components/generic-filter/GenericFilter.scss"
import "../components/ui-components/avatar-upload/AvatarUpload.scss"
import "../components/ui-components/tag/Tag.scss"
import "../components/basic-components/footer/Footer.scss"
import "../components/basic-components/navbar/Navbar.scss"
import "../components/basic-components/registration-form/RegistrationForm.scss"
import "../components/basic-components/user-account-details/UserAccountDetails.scss"
import "../components/basic-components/wedding-details-step-form/WeddingDetailsStepForm.scss"
import "../components/basic-components/phone-verify-form/PhoneVerifyForm.scss"
import "../components/basic-components/item-slider/ItemSlider.scss"
import "../components/basic-components/product-card/ProductCard.scss"
import "../components/basic-components/vendor-card/VendorCard.scss"
import "../components/basic-components/breadcrumbs-navigation/BreadcrumbsNavigation.scss"
import "../components/basic-components/vendor-summary/VendorSummary.scss"
import "../components/basic-components/vendor-card/VendorCard.scss"
import "../components/basic-components/faq-item/FaqItem.scss"
import "../components/basic-components/album-card/AlbumCard.scss"
import "../components/basic-components/album-card-admin/AlbumCardAdmin.scss"
import "../components/basic-components/album-cover/AlbumCover.scss"
import "../components/basic-components/product-controls-card/ProductControlsCard.scss"
import "../components/basic-components/banner-card/BannerCard.scss"
import "../components/basic-components/icon-label-card/IconLabelCard.scss"
import "../components/basic-components/article-card/ArticleCard.scss"
import "../components/basic-components/article-card-2/ArticleCard2.scss"
import "../components/basic-components/event-card/EventCard.scss"
import "../components/basic-components/label-card/LabelCard.scss"
import "../components/basic-components/page-alert/PageAlert.scss"
import "../components/basic-components/page-cover-slider/PageCoverSlider.scss"
import "../components/basic-components/offers-slider/OffersSlider.scss"
import "../components/basic-components/album-gallery/AlbumGallery.scss"
import "../components/basic-components/album-pin-card/AlbumPinCard.scss"
import "../components/basic-components/image-pin-card/ImagePinCard.scss"
import "../components/basic-components/change-password-form/ChangePasswordForm.scss"
import "../components/basic-components/user-profile-navigation/UserProfileNavigation.scss"
import "../components/basic-components/likeable-image/LikeableImage.scss"
import "../components/modals/login-modal/LoginModal.scss"
import "../components/modals/phone-verify-modal/PhoneVerifyModal.scss"
import "../components/modals/registration-greeting-modal/RegistrationGreetingModal.scss"
import "../components/modals/gallery-modal/GalleryModal.scss"
import "../components/modals/single-image-modal/SingleImageModal.scss"
import "../components/modals/document-modal/DocumentModal.scss"
import "../components/modals/age-confirmation-modal/AgeConfirmationModal.scss"
import "../components/modals/forgot-password-modal/ForgotPasswordModal.scss"
import "../components/tab-contents/vendor-products-tab/VendorProductsTab.scss"
import "../components/tab-contents/vendor-about-tab/VendorAboutTab.scss"
import "../components/tab-contents/vendor-faq-tab/VendorFaqTab.scss"
import "../components/tab-contents/vendor-albums-tab/VendorAlbumsTab.scss"
import "../components/pages/home-page/HomePage.scss"
import "../components/pages/article-details-page/ArticleDetailsPage.scss"
import "../components/pages/articles-list-page/ArticlesListPage.scss"
import "../components/pages/blog-home-page/BlogHomePage.scss"
import "../components/pages/registration-page/RegistrationPage.scss"
import "../components/pages/result-page/ResultPage.scss"
import "../components/pages/contact-page/ContactPage.scss"
import "../components/pages/user-account-page/UserAccountPage.scss"
import "../components/pages/wedding-details-page/WeddingDetailsPage.scss"
import "../components/pages/profile-setup-steps-page/ProfileSetupStepsPage.scss"
import "../components/pages/products-search-result-page/ProductSearchResultPage.scss"
import "../components/pages/vendor-profile-page/VendorProfilePage.scss"
import "../components/pages/vendors-list-page/VendorsListPage.scss"
import "../components/pages/products-list-page/ProductsListPage.scss"
import "../components/pages/product-details-page/ProductDetailsPage.scss"
import "../components/pages/album-show-page/AlbumShowPage.scss"
import "../components/pages/change-password-page/ChangePasswordPage.scss"
import "../components/pages/wishlist-page/WishlistPage.scss"
import "../components/pages/checklist-page/ChecklistPage.scss"
import "../components/pages/inspirations-page/InspirationsPage.scss"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
