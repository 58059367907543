// import './IconLabelCard.scss';

import * as React from 'react';
import { ReactSVG } from 'react-svg';





interface IconLabelCardProps {
  icon: string;
  iconType: string; /* Might be 'svg' or 'img' */
  label: string;
  iconHeight?: number; /* Custom icon height. Optional only */
  iconWidth?: number; /* Custom icon width. Optional only */
}


class IconLabelCard extends React.Component<IconLabelCardProps> {

  /**
   * Render icon based on iconType.
   */
  renderIcon() {
    let iconElem;
    let imageContainerStyles: any = {};
    
    if(this.props?.iconType === 'svg') {
      iconElem = <ReactSVG className="icon" src={this.props?.icon} />;
    } else if(this.props?.iconType === 'img') {
      iconElem = <img src={this.props?.icon} alt="Icon Label Image" />;
    }

    // Custom icon height.
    if(this.props?.iconHeight) {
      imageContainerStyles['height'] = `${this.props?.iconHeight}px`;
    }

    // Custom icon width.
    if(this.props?.iconWidth) {
      imageContainerStyles['width'] = `${this.props?.iconWidth}px`;
    }

    return (
      <div className="image-container" style={imageContainerStyles}>
        {iconElem}
      </div>
    );
  }
  
  
  render() {
    return (
      <div className="IconLabelCard">
        {this.renderIcon()}

        <div className="label-container">
          <div className="typo-bold">{this.props.label}</div>
        </div>
      </div>
    );
  }
  
}




export default IconLabelCard;