import axios from 'axios';
import { PhoneNumber } from '../interfaces/phone-number.interface';
import { User } from '../interfaces/user.interface';


interface registerUserPayload {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  specialOffers: boolean;
}


interface saveUserPayload {
  email?: string;
  firstName?: string;
  lastName?: string;
  partnerFirstName?: string;
  partnerLastName?: string;
  phoneNumber?: string;
  weddingRole?: string;
  weddingDate?: Date;
  weddingGuests?: number;
  weddingBudget?: number;
  weddingBudgetCurrency?: string;
  city?: number;
  homeCity?: number;
}


interface SendCodeToPhoneNumberResponse {
  code: string;
  phoneNumber: PhoneNumber;
}


interface ChangePasswordPayload {
  password: string;
  confirmPassword: string;
  resetPasswordToken: string;
}



class UserApi {

  /**
   * Get the profile of the currently logged in user.
   * NOTE: Create an interface for User.
   */
  getUserProfile(): Promise<User> {
    let apiUrl = `/api/v1/profile`;

    return axios.get(apiUrl)
    .then(response => {
      const user: User = {
        id: response?.data?.data?.attributes?.id,
        email: response?.data?.data?.attributes?.email,
        fullName: response?.data?.data?.attributes?.full_name,
        firstName: response?.data?.data?.attributes?.first_name,
        lastName: response?.data?.data?.attributes?.last_name,
        image: response?.data?.data?.attributes?.avatar_url,
        verified: response?.data?.data?.attributes?.verified,
        type: response?.data?.data?.type,
        isAdmin: response?.data?.data?.attributes?.is_admin,

        phoneNumber: {
          id: response?.data?.data?.attributes?.primary_phone?.id,
          isBusiness: response?.data?.data?.attributes?.primary_phone?.is_business,
          isPrimary: response?.data?.data?.attributes?.primary_phone?.is_primary,
          number: response?.data?.data?.attributes?.primary_phone?.number,
          verified: response?.data?.data?.attributes?.primary_phone?.verified,
          countryCode: response?.data?.data?.attributes?.primary_phone?.formatted?.country_code,
          national: response?.data?.data?.attributes?.primary_phone?.formatted?.national,
          rawNational: response?.data?.data?.attributes?.primary_phone?.formatted?.raw_national
        },

        weddingDetail: {
          id: response?.data?.data?.attributes?.wedding_detail?.id,
          budget: response?.data?.data?.attributes?.wedding_detail?.budget,
          budgetCurrency: response?.data?.data?.attributes?.wedding_detail?.budget_currency,
          city: {
            id: response?.data?.data?.attributes?.wedding_detail?.city?.id,
            name: response?.data?.data?.attributes?.wedding_detail?.city?.name
          },
          homeCity: {
            id: response?.data?.data?.attributes?.wedding_detail?.home_city?.id,
            name: response?.data?.data?.attributes?.wedding_detail?.home_city?.name
          },
          date: response?.data?.data?.attributes?.wedding_detail?.date,
          firstName: response?.data?.data?.attributes?.wedding_detail?.first_name,
          lastName: response?.data?.data?.attributes?.wedding_detail?.last_name,
          partnerFirstName: response?.data?.data?.attributes?.wedding_detail?.partner_first_name,
          partnerLastName: response?.data?.data?.attributes?.wedding_detail?.partner_last_name,
          role: response?.data?.data?.attributes?.wedding_detail?.role,
          guests: response?.data?.data?.attributes?.wedding_detail?.guests
        }
      };

      return Promise.resolve(user);
    });
  }


  registerUser(payload: registerUserPayload): Promise<any> {
    return axios.post('/api/v1/accounts/register', {
      account: {
        email: payload.email,
        password: payload.password,
        password_confirmation: payload.confirmPassword,
        first_name: payload.firstName,
        last_name: payload.lastName,
        receive_updates: payload.specialOffers
      }
    });
  }


  saveUser(payload: saveUserPayload): Promise<any> {
    const apiUrl = `/api/v1/profile`;

    return axios.patch(apiUrl, {
      profile: {
        first_name: payload.firstName,
        last_name: payload.lastName,
        email: payload.email,
        phone_number: payload.phoneNumber,
        wedding_role: payload.weddingRole,
        partner_first_name: payload.partnerFirstName,
        partner_last_name: payload.partnerLastName,
        wedding_city_id: payload.city,
        wedding_home_city_id: payload.homeCity,
        wedding_date: payload.weddingDate,
        wedding_budget: payload.weddingBudget,
        wedding_budget_currency: payload.weddingBudgetCurrency,
        wedding_guests: payload.weddingGuests,
      }
    });
  }


  uploadUserAvatar(avatar): Promise<any> {
    const apiUrl = `/api/v1/profile/upload_avatar`;

    return axios.post(apiUrl, {avatar});
  }


  sendCodeToPhoneNumber(phoneNumber: string): Promise<SendCodeToPhoneNumberResponse> {
    const apiUrl = `/api/v1/accounts/otp/send`;

    return axios.post(apiUrl, {
      phone_number: phoneNumber
    })
    .then(response => {
      const formattedResponse: SendCodeToPhoneNumberResponse = {
        code: response?.data?.code,
        phoneNumber: {
          number: response?.data?.phone?.number,
          countryCode: response?.data?.phone?.country_code,
          rawNational: response?.data?.phone?.raw_national,
          national: response?.data?.phone?.national
        }
      };

      return Promise.resolve(formattedResponse);
    });
  }


  verifyPhoneCode(phoneNumber: string, code: string): Promise<PhoneNumber> {
    const apiUrl = `/api/v1/accounts/otp/verify`;

    return axios.post(apiUrl, {
      phone_number: phoneNumber,
      otp: code
    })
    .then(response => {
      const phoneNumber: PhoneNumber = {
        id: response?.data?.phone?.id,
        countryCode: response?.data?.phone?.country_code,
        national: response?.data?.phone?.national,
        number: response?.data?.phone?.number,
        rawNational: response?.data?.phone?.raw_national
      };

      return Promise.resolve(phoneNumber);
    });
  }


  changePassword(payload: ChangePasswordPayload): Promise<any> {
    const apiUrl = `/api/v1/accounts/password`;

    return axios.patch(apiUrl, { 
      account: {
        password: payload.password,
        password_confirmation: payload.confirmPassword,
        reset_password_token: payload.resetPasswordToken
      }
    })
    .then(response => {

      return Promise.resolve(true);
    });
  }

  createAppointment(vendorId: number|string, email: string, date: string, detail: string, name: string, mobile: number|string) {
    const apiUrl = '/api/v1/appointments';
    const payload = {
      vendor_id: vendorId,
      email: email,
      date: date,
      detail: detail,
      name: name,
      mobile: mobile
    }

    return axios.post(apiUrl, payload)
      .then(response => {
        return Promise.resolve(true);
      })
      .catch(error => {
        console.error(error);
        return Promise.resolve(false);
      });
  }
}


export default UserApi;