// import './ProductsListPage.scss';
import * as React from 'react';
import { read_cookie } from 'sfcookies';
import {notification, Modal, Button, Select, Spin, Col, Row} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Vendor } from '../../../interfaces/vendor-interface';
import { Product } from '../../../interfaces/product.interface';
import { User } from '../../../interfaces/user.interface';
import { ServiceCategory } from '../../../interfaces/service-category.interface';

import VendorApi from '../../../api-services/vendor-api.service';
import UserApi from '../../../api-services/user-api.service';
import ProductApi from '../../../api-services/product-api.service';
import ServiceCategoryApi from '../../../api-services/service-category-api.service';
import CampaignApi from "../../../api-services/campaign-api.service";

import Navbar from '../../basic-components/navbar/Navbar';
import Footer from '../../basic-components/footer/Footer';
import BreadcrumbsNavigation from  '../../basic-components/breadcrumbs-navigation/BreadcrumbsNavigation';
import ProductCard from '../../basic-components/product-card/ProductCard';
import LoginModal from '../../modals/login-modal/LoginModal';
import AgeConfirmationModal from '../../modals/age-confirmation-modal/AgeConfirmationModal';
import ForgotPasswordModal from '../../modals/forgot-password-modal/ForgotPasswordModal';
import CountryApi from "../../../api-services/country-api.service";
import VendorCard from "../../basic-components/vendor-card/VendorCard";
import {Campaign} from "../../../interfaces/campaign.interface";

notification.config({
  duration: 12,
  top: 60
})

interface ProductsListPageProps {
  isAccountSignedIn: boolean;
  googleSignInUrl: string;
  fbSignInUrl: string;
  csrfToken:string;
  selectedCategoryData: any;
  selectedCampaign: any;
}

interface ProductsListPageState {
  user: User;
  loginModalVisible: boolean;
  loginModalRedirectUrl: string;
  products: Product[];
  categories: ServiceCategory[];
  subCategories: ServiceCategory[];
  selectedCategory: "default";
  selectedSubCategory: any[];
  categoryModalVisible: boolean;
  ageConfirmationModalVisible: boolean;
  forgotPasswordModalVisible: boolean;
  countries: any[];
  selectedCountry: number;
  selectedCity: number;
  cities: any[];
  loadingProducts: boolean;
  currentPage: number;
  hasMore: boolean;
  campaigns: Campaign[];
}

const perPage = 20;

class ProductsListPage extends React.Component<ProductsListPageProps, ProductsListPageState> {

  campaignApi = new CampaignApi;
  vendorApi = new VendorApi;
  userApi = new UserApi;
  productApi = new ProductApi;
  serviceCategoryApi = new ServiceCategoryApi;
  countryApi = new CountryApi;

  breadcrumbsNavItems = [
    { id: 1, label: 'Home', link: '/' },
    { id: 2, label: 'Bridepay Deals', link: '/bridepay-deals' },
  ];

  redirectToProductDetails(slug: string): void {
    window.open(`/products/${slug}`);
  }

  componentDidMount() {
    const ageConfirmed = read_cookie("age_21_and_non_muslim");
    this.setState({
      ageConfirmationModalVisible: (this.props?.selectedCategoryData && this.props?.selectedCategoryData?.slug == "wines-alcohol") && (ageConfirmed.length==0 || JSON.parse(ageConfirmed)==false),
      countries: [],
      cities: [],
      selectedCountry: -1,
      selectedCity: -1,
      loadingProducts: false,
      currentPage: 1,
      hasMore: true
    });
    this.loadCampaigns();
    this.loadServiceCategory();
    this.loadCountries();
    this.loadProducts(null, null,1);

    setTimeout(() => {
      this.loadSignedInUserProfile();
    }, 500);
  }

  onSelectSubCategory(slug): void {
    const foundItem = this.state?.selectedSubCategory?.find(x => x.includes(slug))
    let newItems = this.state?.selectedSubCategory === undefined ? [] : this.state?.selectedSubCategory

    if (foundItem) {
      this.setState({selectedSubCategory: newItems.filter(x => x !== slug)});
    } else {
      newItems.push(slug)
      this.setState({selectedSubCategory: newItems});
    }
  };

  loadSignedInUserProfile(): void {
    if(!this.props?.isAccountSignedIn) {
      return null;
    }

    this.userApi.getUserProfile()
    .then(user => {
      this.setState({ user: user });
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: 'Error while loading the user\'s profile.', className: 'antd-mod center' });
    });
  }

  loadCampaigns(): void {
    this.campaignApi.getCampaigns()
      .then(result => {
        this.setState({
          campaigns: result
        });
      });
  }

  loadServiceCategory(): void {
    Promise.all([
      this.serviceCategoryApi.getCategories()
    ])
    .then(result => {
      this.setState({
        categories: result[0]
      }, () => {
        if (this.props?.selectedCategoryData?.slug !== 'all-bridepay-deals') {
          this.loadServiceSubCategory(this.state?.categories?.find(x => x.slug.includes(this.props?.selectedCategoryData.slug))?.id)
        }
      });
    });
  }

  loadServiceSubCategory(categoryId): void {
    Promise.all([
      this.serviceCategoryApi.getSubCategories(categoryId)
    ])
    .then(result => {
      this.setState({
        subCategories: result[0]
      });
    });
  }

  loadCountries(): void {
    this.countryApi.getCountries()
      .then(result => {
        this.setState({countries: result});
      });
  }

  loadCities():void {
    if (this.state.selectedCountry == -1) {
      this.setState({cities: []});
      return;
    }
    this.countryApi.getCities(this.state.countries[this.state.selectedCountry].iso)
      .then(result => this.setState({cities: result}));
  }

  onSelectCountry = (value: number):void => {
    let country = value > -1 ? this.state.countries[value].id : null;

    this.loadProducts(country, null, 1);
    this.setState({selectedCountry: value, selectedCity: -1}, () => this.loadCities());
  }

  onSelectCity = (value: number):void => {
    let country = null;
    let city = null;

    if (this.state.selectedCountry > -1) country = this.state.countries[this.state.selectedCountry].id;
    if (value > -1) city = this.state.cities[value].id;

    this.loadProducts(country, city, 1);
    this.setState({selectedCity: value});
  }

  loadProducts(country, city, page): void {
    let categoryId = this.props?.selectedCategoryData?.slug;

    if (this.props?.selectedCategoryData?.slug == 'all-bridepay-deals') {
      categoryId = null;
    }
    Promise.all([
      this.productApi.getFilteredProducts(this.props?.selectedCampaign, null, null, country, city, categoryId, page, perPage)
    ])
      .then(result => {
        let productList: any[];

        if (page === 1) productList = [...result[0]];
        else productList = [...this.state.products, ...result[0]]
        this.setState({
          loadingProducts: true,
          products: productList,
          currentPage: page,
          hasMore: perPage === result[0].length
        });
      })
      .catch((error) => {
        console.error(error);
        notification.error({message: 'Error while loading the list of products', className: 'antd-mod center'});
      })
      .finally(() => {
        this.setState({loadingProducts: false});
      });
  }

  loadMoreProducts = () => {
    let country = null;
    let city = null;

    if (this.state.selectedCountry > -1) country = this.state.countries[this.state.selectedCountry].id;
    if (this.state.selectedCity > -1) city = this.state.cities[this.state.selectedCity].id;
    this.loadProducts(country, city, this.state.currentPage + 1)
  }

  renderAllBridepayFlashDeals() {
    let productArray = this.state?.products;
    return (
        <div className="page-section all-bridepay-flash-deals">
          <div className="content">
            <div id="product-list" className="product-list">
              <InfiniteScroll
                  dataLength={this.state?.products?.length || 0} //This is important field to render the next data
                  next={this.loadMoreProducts}
                  hasMore={this.state?.hasMore || false}
                  loader={<div className="loading"><Spin/></div>}
              >
                {productArray?.map((product, productIndex) =>
                  <ProductCard
                    product={product}
                    wishlistButtonDisabled={!this.props?.isAccountSignedIn}
                    key={productIndex}
                    size='large'
                    clicked={() => { this.redirectToProductDetails(product?.slug) }}
                    wishlistStatusChanged={(wishlistStatus) => { this.productApi.updateWishlistStatus(product.id, wishlistStatus) }}
                  />
                )}
                {/*<Row gutter={[15, 15]}>
                  {productArray?.map((product, productIndex) =>
                      <Col xs={24} sm={12} md={8} lg={6} key={productIndex}>
                        <ProductCard
                            product={product}
                            wishlistButtonDisabled={!this.props?.isAccountSignedIn}
                            key={productIndex}
                            clicked={() => { this.redirectToProductDetails(product?.slug) }}
                            wishlistStatusChanged={(wishlistStatus) => { this.productApi.updateWishlistStatus(product.id, wishlistStatus) }}
                        />
                      </Col>
                  )}
                </Row>*/}
              </InfiniteScroll>
            </div>
          </div>
        </div>
    );
  }

  renderCategoryHeader() {
    let title = this.props?.selectedCategoryData?.slug == 'all-bridepay-deals' ? "All Bridepay Deals" : this.state?.categories?.find(x => x.slug.includes(this.props?.selectedCategoryData?.slug))?.name;
    if (this.props?.selectedCampaign) {
      let campaign = this.state?.campaigns?.find(o => o.id == this.props?.selectedCampaign);

      if (campaign) title += `(${campaign.title})`;
    }
    return (
        <div className="category-header">
          <div className="content">
            <div>
              <div className="header">
                <div className="row-1">
                  <div className="title typo-headline">{title}</div>
                </div>

                <div className="row-2">
                  <div>
                    <Select className="typo-body mr-2"
                            value={this.state?.selectedCountry}
                            style={{width: 200}}
                            placeholder="Country"
                            defaultActiveFirstOption={false}
                            filterOption={false}
                            size="large"
                            onChange={this.onSelectCountry}
                            notFoundContent={null}
                    >
                      <Select.Option value={-1}>All Countries</Select.Option>
                      {this.state?.countries.map((country, index) =>
                        <Select.Option value={index} key={index}>{country.name}-({country.iso})</Select.Option>
                      )}
                    </Select>
                    <Select className="typo-body mt-2"
                            value={this.state?.selectedCity}
                            defaultValue={-1}
                            style={{width: 200}}
                            showSearch
                            placeholder="City"
                            defaultActiveFirstOption={false}
                            size="large"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input)}
                            filterSort={(optionA, optionB) =>
                              (optionA!.children as unknown as string)
                                .toLowerCase()
                                .localeCompare((optionB!.children as unknown as string).toLowerCase())
                            }
                            onSelect={this.onSelectCity}
                            notFoundContent={null}
                    >
                      <Select.Option value={-1}>All Cities</Select.Option>
                      {this.state?.cities.map((city, index) =>
                        <Select.Option value={index} key={index}>{city.name}</Select.Option>
                      )}
                    </Select>
                  </div>
                  <Button
                    style={{backgroundColor: '#fff', color: '#000', borderColor: '#000', height: 39}}
                    onClick={
                      () => this.setState({categoryModalVisible: true})
                    }
                    type="primary"
                    icon={<DownOutlined/>}
                  >
                    All Categories
                  </Button>
                </div>
              </div>
              {this.props?.selectedCategoryData?.slug !== 'all-bridepay-deals' && (
                <div style={{textAlign: 'left', marginTop: 20}}>
                  {this.state?.subCategories?.map(category => (
                    <Button
                      key={category.id}
                      className={this.state?.selectedSubCategory?.includes(category?.slug) ? "active-subcategory" : "inactive-subcategory"}
                      style={{borderRadius: '20px', margin: '5px', lineHeight: 1.2}}
                      onClick={() => this.onSelectSubCategory(category?.slug)}
                    >
                      {category?.name}
                    </Button>)
                  )}
                </div>
              )}
            </div>
            <Modal
              title=""
              centered
              visible={this.state?.categoryModalVisible}
              onOk={() => this.setState({categoryModalVisible: false})}
              onCancel={() => this.setState({categoryModalVisible: false})}
              footer={null}
              width={1000}
            >
              <div style={{textAlign: 'center'}}>
                <div style={{fontFamily: 'Playfair Display', fontSize: 22, marginBottom: '20px'}}>Choose a category
                </div>
                <Button onClick={() => {
                  window.location.href = this.props?.selectedCampaign ? `/campaigns/${this.props?.selectedCampaign}/products/all-bridepay-deals` : '/products/c/all-bridepay-deals'
                }}
                        style={{borderRadius: '20px', margin: '5px', lineHeight: 1.2}}
                >All Bridepay Deals</Button>
                {this.state?.categories?.map(category => (
                  <Button
                    onClick={() => {
                      window.location.href = this.props?.selectedCampaign ? `/campaigns/${this.props?.selectedCampaign}/products/${category.slug}` : `/products/c/${category.slug}`
                    }}
                    style={{borderRadius: '20px', margin: '5px', lineHeight: 1.2}}
                    key={category.name}
                  >
                    {category.name}
                  </Button>
                ))}
              </div>
            </Modal>
          </div>
        </div>
    );
  }

  render() {
    return (
        <div className="ProductsListPage">
          <Navbar
              isAccountSignedIn={this.props?.isAccountSignedIn}
              user={this.state?.user}
              activeNavItem={'bridepay-deals'}
              signInRequired={(redirectUrl) => { this.setState({ loginModalVisible: true, loginModalRedirectUrl: redirectUrl }); }}
          />

          <LoginModal
              visible={this.state?.loginModalVisible}
              googleSignInUrl={this.props?.googleSignInUrl}
              fbSignInUrl={this.props?.fbSignInUrl}
              csrfToken={this.props?.csrfToken}
              redirectUrl={this.state?.loginModalRedirectUrl}
              forgotPasswordClicked={() => { this.setState({ loginModalVisible: false, forgotPasswordModalVisible: true }) }}
              closed={() => { this.setState({ loginModalVisible: false }) }}
              registerLink="/registration"
          />

          <ForgotPasswordModal
              visible={this.state?.forgotPasswordModalVisible}
              loginClicked={() => { this.setState({ forgotPasswordModalVisible: false, loginModalVisible: true }) }}
              closed={() => { this.setState({ forgotPasswordModalVisible: false }) }}
          />

          <AgeConfirmationModal visible={this.state?.ageConfirmationModalVisible} confirmed={()=>this.setState({ageConfirmationModalVisible: false})} closed={() => window.location.href = '/'}/>

          <div className="page-content">
            <BreadcrumbsNavigation items={this.breadcrumbsNavItems} />
          </div>

          <div className="page-content main-content">
            {this.renderCategoryHeader()}
            {this.renderAllBridepayFlashDeals()}
          </div>
          <Footer userType={this.state?.user?.type} />
        </div>
    );
  }
}

export default ProductsListPage;
