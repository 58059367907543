import * as React from 'react';
import {startCase} from 'lodash';
import { Button, Form, FormInstance, Input, notification, Select, Skeleton } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { Vendor } from '../../../interfaces/vendor-interface';
import VendorApi from '../../../api-services/vendor-api.service';
import AvatarUpload from '../../ui-components/avatar-upload/AvatarUpload';

import LocationHierarchy from '../../ui-components/location-hierarchy/LocationHierarchy';
import ReactQuill from 'react-quill';
import { Country } from '../../../interfaces/country.interface';


const modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }]
  ],
}


interface BusinessDetailsFormProps {
  vendor: Vendor;
  countries?: Country[];
  onVendorUpdate: () => void;
}

interface BusinessDetailsFormState {
  saving: boolean;
  loading: boolean;
  businessDescription:string;
  selectedCountryPhoneCode: string;
  selectedCountry:number;
  selectedState:string|number;
  selectedCity:number;
}

class BusinessDetailsForm extends React.Component <BusinessDetailsFormProps, BusinessDetailsFormState> {
  
  form = React.createRef<FormInstance>();

  componentDidMount(){
    this.setState({
      saving: false,
      loading: true,
      businessDescription: "",
      selectedCountryPhoneCode: '60',
      selectedCountry: 1
    });
    
    this.populateBusinessForm();
  }


  componentDidUpdate(previousProps: BusinessDetailsFormProps) {
    if(this.props?.vendor !== previousProps.vendor) {
      this.populateBusinessForm();
    }
  }


  componentDidCatch(){
    this.setState({loading: false});
  }


  populateBusinessForm(){
    this.form.current!.setFieldsValue({
      businessName: this.props?.vendor?.businessName,
      businessRegisteredName: this.props?.vendor?.businessRegisteredName,
      businessNo: this.props?.vendor?.businessRegisteredNumber,
      address: this.props?.vendor?.address,
      description: this.props?.vendor?.description,
      country: this.props?.vendor?.country?.id,
      city: this.props?.vendor?.city?.id,
      state: this.props?.vendor?.state?.code,
      zipCode: this.props?.vendor?.zipCode,
      phoneKind: this.props?.vendor?.primaryPhoneKind || "phone",
      phoneNumber: this.props?.vendor?.primaryPhoneNumber.number
    });

    this.setState({
      loading: false, 
      businessDescription: this.props?.vendor?.description || "",
      selectedState: this.props?.vendor?.state?.code,
      selectedCity: this.props?.vendor?.city?.id
    });
  }

  
  saveChanges(formEvent: any): void {
    this.setState({ saving: true });

    const vendorApi = new VendorApi;
    const payload = {
      address: formEvent.address,
      zipCode: formEvent.zipCode,
      description: formEvent.description,
      businessRegisteredName: formEvent.businessRegisteredName,
      businessRegisteredNumber: formEvent.businessNo,
      businessName: formEvent.businessName,
      phoneKind: formEvent.phoneKind,
      phoneNumber: formEvent.phoneNumber,
      country: formEvent.country,
      state: formEvent.state,
      city: formEvent.city,
    };

    vendorApi.saveBusinessDetails(payload)
    .then(response => {
      this.setState({businessDescription: formEvent.description})
      notification.success({ message: 'Changes saved successfully', className: 'antd-mod center' });
    })
    .catch(error => {
      console.error(error);

      const errorKeys = Object.keys(error?.response?.data?.errors);
      errorKeys.forEach(errorKeyName => {
        const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
        notification.error({ message: errorMessage, className: 'antd-mod center' });
      });
    })
    .finally(() => {
      this.props.onVendorUpdate();
      this.setState({ saving: false });
    });
  }



  setBusinessDescription(description){
    this.form.current!.setFieldsValue({
      description: description
    })
  }


  renderPhoneCodeDropdown() {
    const phoneCodeDropdown = (
      <Select defaultValue="MY 60" onChange={(selectedValue) => { this.setState({selectedCountryPhoneCode: selectedValue}) }} className="select-before">
        {this.props?.countries?.map(country => {
          return <Select.Option key={country.phoneCode} value={country.phoneCode}>{country.iso} {country.phoneCode}</Select.Option>
        })}
      </Select>
    );

    return phoneCodeDropdown;
  }



  render() {
    return (
      <div className="BusinessDetailsForm">

          <div className="business-profile-picture">
            { this.state?.loading ? <Skeleton.Image />: <AvatarUpload imageUrl={this.props?.vendor?.image}/> }
          </div>
          
          <Form ref={this.form} onFinish={(formEvent)=>this.saveChanges(formEvent)} layout="vertical" autoComplete="off">
            {
              this.state?.loading ? <Skeleton active /> : (
                <>
                  <Form.Item name="businessRegisteredName" label="Business Registered Name" rules={[{ required: true, message: 'Business Registered Name is required' }]}>
                    <Input size="large" disabled placeholder="Enter your registered business name"/>
                  </Form.Item>

                  <Form.Item name="businessNo" label="Business Registration No" rules={[{ required: true, message: 'Business Registration No. is required' }]}>
                    <Input size="large" disabled placeholder="Enter your business registration no."/>
                  </Form.Item>

                  <Form.Item name="businessName" label="Brand Name" rules={[{ required: true, message: 'Brand Name is required' }]}>
                    <Input size="large" disabled placeholder="This will be your URL"/>
                  </Form.Item>
                  
                  <Form.Item name="description" label="About the Business" rules={[{ required: true, message: 'About is required' }]}>
                    <div className="text-editor">
                      <ReactQuill value={this.state?.businessDescription} onChange={(val)=>this.setBusinessDescription(val)} theme="snow" modules={modules}/>
                    </div>
                  </Form.Item>

                  <Form.Item name="address" label="Business Address" rules={[{ required: true, message: 'Business Address is required' }]}>
                    <TextArea size="large"/>
                  </Form.Item>

                  <Form.Item>
                    <Form.Item name="zipCode" label="Postal Code / Zip Code" rules={[{ required: true, message: 'Zip Code is required' }]} style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}>
                      <Input size="large" />
                    </Form.Item>
                    
                    {/* <Form.Item label="Business Phone Number" style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0'  }}>
                      <div className="phone-inputs">
                        <Form.Item name="phoneKind">
                          <Select size="large">
                            <Select.Option value="phone" >Phone</Select.Option>
                            <Select.Option value="mobile">Mobile</Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="phoneNumber" rules={[{ required: true, message: 'Phone number is required' }]}>
                          <Input size="large" addonBefore={this.renderPhoneCodeDropdown()} placeholder="Enter business phone number"/>
                        </Form.Item>
                      </div>
                    </Form.Item> */}

                    <Form.Item name="phoneNumber" label="Business Phone Number" style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }} 
                      rules={[{ required: true, message: 'Phone number is required' }]}>
                      <Input size="large" placeholder="Enter business phone number"/>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item>
                    <LocationHierarchy form={this.form} savedCountry={this.state?.selectedCountry} savedState={this.state?.selectedState} savedCity={this.state?.selectedCity}/>
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" className="antd-mod" htmlType="submit" loading={this.state?.saving}>Save</Button>
                  </Form.Item>
                </>
              )
            }
          </Form>
      </div>
    );
  }
}

export default BusinessDetailsForm;
