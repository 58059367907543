import axios from "axios";
import { message } from 'antd';
import { CalendarSlot } from "../interfaces/calendar-slot.interface";

class CalendarSlotApi {

  getCalendarSlots(calendarId: number): Promise<CalendarSlot[]>{
    const apiUrl = `/api/v1/calendars/my/${calendarId}/calendar_slots`;

    return axios.get(apiUrl)
    .then((response: any) => {
      const slots: CalendarSlot[] = response?.data?.data?.map(slotData => {
        return {
          id: slotData?.attributes?.id,
          allDay: slotData?.attributes?.all_day,
          start: new Date(slotData?.attributes?.begin_time),
          end: new Date(slotData?.attributes?.end_time),
          day: slotData?.attributes?.day_of_week,
          hasBooking: slotData?.attributes?.has_booking,
          numberBookings: slotData?.attributes?.number_bookings,
        };
      });
      
      return Promise.resolve(slots);
    })
    .catch(() => {
      message.error('There\'s an error while creating the calendar slot.');
      return Promise.reject();
    });
  }


  createCalendarSlot(calendarId: number, calendarSlot: CalendarSlot, days: number[]): Promise<any> {
    const apiUrl = `/api/v1/calendars/my/${calendarId}/calendar_slots`;
    var calendarSlotData = {
      days: days,
      all_day: calendarSlot.allDay,
      number_bookings: calendarSlot.numberBookings
    };

    if(!calendarSlot.allDay){
      calendarSlotData["begin_time"] = calendarSlot.start;
      calendarSlotData["end_time"] = calendarSlot.end;
    }

    return axios.post(apiUrl, {
      calendar_slot: calendarSlotData
    })
    .catch(() => {
      message.error('There\'s an error while creating the calendar slot.');
      return Promise.reject();
    });;
  }


  saveCalendarSlot(calendarId: number, calendarSlot: CalendarSlot): Promise<any> {
    const apiUrl = `/api/v1/calendars/my/${calendarId}/calendar_slots/${calendarSlot.id}`;

    var calendarSlotData = {
      all_day: calendarSlot.allDay,
      number_bookings: calendarSlot.numberBookings,
      begin_time:calendarSlot.start,
      end_time:calendarSlot.end,
    };

    return axios.put(apiUrl, {
      calendar_slot: calendarSlotData
    })
    .catch(() => {
      message.error('There\'s an error while saving the changes.');
      return Promise.reject();
    });;
  }


  deleteCalendarSlot(calendarId: number, calendarSlotId: number): Promise<any> {
    const apiUrl = `/api/v1/calendars/my/${calendarId}/calendar_slots/${calendarSlotId}`;

    return axios.delete(apiUrl)
    .catch(() => {
      message.error('There\'s an error deleting the calendar slot.');
      return Promise.reject();
    });
  }
}

export default CalendarSlotApi;