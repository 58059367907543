// import './UserAccountPage.scss';

import * as React from 'react';
import { notification } from 'antd';

import { Country } from '../../../interfaces/country.interface';
import { User } from '../../../interfaces/user.interface';

import UserApi from '../../../api-services/user-api.service';
import AuthApi from '../../../api-services/auth-api.service';
import CountryApi from '../../../api-services/country-api.service';

import Navbar from '../../basic-components/navbar/Navbar';
import Footer from '../../basic-components/footer/Footer';
import UserAccountDetails from '../../basic-components/user-account-details/UserAccountDetails';
import UserProfileNavigation from '../../basic-components/user-profile-navigation/UserProfileNavigation';


notification.config({
  duration: 12,
  top: 60
})


interface UserAccountPageProps {
  isAccountSignedIn: boolean;
  googleSignInUrl: string;
  fbSignInUrl: string;
  csrfToken:string;
}


interface UserAccountPageState {
  user: User;
  countries: Country[];
  defaultCountry: Country;
  selectedSection: 'accountDetails'|'weddingDetails'|'wishlist'|'inspirations';
}



class UserAccountPage extends React.Component<UserAccountPageProps, UserAccountPageState> {

  userApi = new UserApi;
  countryApi = new CountryApi;
  authApi = new AuthApi;
  
  
  componentDidMount(): void {
    this.setState({
      countries: [],
      selectedSection: 'accountDetails'
    });
    
    this.loadSignedInUserProfile();

    // For some reason, we need to put this inside setTimeout to prevent backend error. 
    // That backend error occurs when we try to send multiple consecutive requests.
    setTimeout(() => {
      this.loadCountries();
    }, 500);
  }


  /**
   * Get the signed in user's profile from server if there is a user signed in.
   */
  loadSignedInUserProfile(): void {
    if(!this.props?.isAccountSignedIn) {
      return null;
    }

    this.userApi.getUserProfile()
    .then(user => {
      this.setState({ user: user });
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: 'Error while loading the user\'s profile.', className: 'antd-mod center' });
    });
  }


  /**
   * Load list of countries.
   * Also set the default country from list. Use MY as default then do not set if MY is not available.
   */
  loadCountries(): void {
    this.countryApi.getCountries()
    .then(countries => {
      const defaultCountry = countries.find(country => country.name === 'MALAYSIA');
      this.setState({
        countries: countries,
        defaultCountry: defaultCountry
      });
    })
    .catch(error => {
      notification.error({ message: 'An error occured while loading the data for countries', className: 'antd-mod center' });
    })
  }


  /**
   * Logout current user and reload window afterwards.
   */
  logout(): void {
    this.authApi.logout()
    .then(() => {
      window.location.reload();
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: 'An error occured while logging out.', className: 'antd-mod center' });
    })
  }
  
  
  render() {
    return (
      <div className="UserAccountPage">
        <Navbar isAccountSignedIn={this.props?.isAccountSignedIn} user={this.state?.user}></Navbar>

        <div className="main-content">
          <UserProfileNavigation selectedNavigation="accountDetails"></UserProfileNavigation>
          <UserAccountDetails user={this.state?.user} countries={this.state?.countries} defaultCountry={this.state?.defaultCountry}></UserAccountDetails>
        </div>

        <Footer userType={this.state?.user?.type}></Footer>
      </div>
    );
  }
  

}


export default UserAccountPage;