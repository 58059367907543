// import './ResultPage.scss';

import * as React from 'react';
import { Result, Button } from 'antd';

class ResultPage extends React.Component<ResultPageProps, ResultPageState> {
  
  componentDidMount(): void {
  
  }
  
  redirectToURL(url: string): void {
    if(url) {
      window.location.href = url;
    }
  }
  
  render() {
    return (
      <div className="ResultPage">
        <Result          
          status={this.props?.status}
          title={this.props?.title}
          subTitle={this.props?.subtitle}
         >
        </Result>

        <div className="ButtonDiv">
          <Button type="primary" key="home" className="HomeButton"  onClick={() => { this.redirectToURL('/') }}>
            Bridepay Homepage
          </Button>
        </div>
       </div>

    );
  }
  
}

export default ResultPage;