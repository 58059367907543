// import './ItemSlider.scss';

import * as React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Navigation]);


interface ItemSliderProps {
  title: string;
  subtitle?: string;
  showMoreLink?: string;
  showMoreText?: string;
  titleExtra?: JSX.Element; /* Custom element to be inserted right next to the title. */
  items: JSX.Element[]; /* List of items that will rendered as slide items. */
  responsive?: boolean
}


interface ItemSliderState {

}


class ItemSlider extends React.Component<ItemSliderProps, ItemSliderState> {
  titleKey: string;
  responsive: boolean;
  constructor(props) {
    super(props);
    this.responsive = this.props?.responsive? this.props.responsive: false;
    this.titleKey = this.props?.title ? this.props?.title.toLowerCase().replace(/[^a-z0-9]+/g,'-').replace(/(^-|-$)/g,'') : (Math.random() + 1).toString(36).substring(7);
  }

  renderHeader() {
    const subtitle = (this.props?.subtitle) ? this.props?.subtitle : '';
    const showMoreText = (this.props?.showMoreText) ? this.props?.showMoreText : 'SHOW MORE';

    return (
      <div className="header">
        <div className={"row-1 " + this.titleKey}>
          <div className="title typo-headline">{this.props?.title}</div>

          {this.props?.titleExtra}

          <div className="filler"></div>
          <div className="showmore-container">
            <div className="typo-subheading-1 typo-bold show-more"
                 onClick={() => (this.props?.showMoreLink ? window.open(this.props?.showMoreLink) : null)}>{showMoreText}</div>

            <div className="navigation-container">
              <img src="/images/nav-prev.png" className="nav-button custom-swiper-button-prev" alt="Prev"/>
              <img src="/images/nav-next.png" className="nav-button custom-swiper-button-next" alt="Next"/>
            </div>
          </div>
        </div>
        <div className="row-2">
          <div className="subtitle">{subtitle}</div>
        </div>
      </div>
    );
  }


  renderSlider() {
    const navigationConfig = {
      nextEl: "div.ItemSlider div." + this.titleKey + " div.navigation-container img.custom-swiper-button-next",
      prevEl: "div.ItemSlider div." + this.titleKey + " div.navigation-container img.custom-swiper-button-prev"
    };
    
    return (
      <>
        {
          this.responsive ? (
              <Swiper
                  className="item-slider"
                  slidesPerView="auto"
                  spaceBetween={15}
                  centeredSlides={true}
                  centeredSlidesBounds={true}
                  pagination={{
                    el: ".swiper-pagination",
                    dynamicBullets: false,
                    clickable: true
                  }}
                  autoplay
                  freeMode observer navigation={navigationConfig}>
                {this.props?.items?.map((item, itemIndex) => <SwiperSlide key={itemIndex}>{item}</SwiperSlide>)}
              </Swiper>
          ) : (
              <Swiper
                  className="item-slider"
                  slidesPerView="auto"
                  spaceBetween={15}
                  centeredSlides={true}
                  centeredSlidesBounds={true}
                  pagination={{
                    el: ".swiper-pagination",
                    dynamicBullets: true,
                    clickable: true
                  }}
                  // Responsive breakpoints
                  // breakpoints={{
                  //   576: {
                  //     slidesPerView: 2.4,
                  //     spaceBetween: 15
                  //   },
                  //   768: {
                  //     slidesPerView: 3.2,
                  //     spaceBetween: 15
                  //   },
                  //   992: {
                  //     slidesPerView: 4.2,
                  //     spaceBetween: 15
                  //   }
                  // }}
                  autoplay
                  freeMode observer navigation={navigationConfig}>
                {this.props?.items?.map((item, itemIndex) => <SwiperSlide key={itemIndex}>{item}</SwiperSlide>)}
              </Swiper>
          )
        }
      </>
    );
  }
  
  
  render() {
    return (
      <div className="ItemSlider">
        {this.renderHeader()}
        {this.renderSlider()}
      </div>
    );
  }
  
}




export default ItemSlider;