import axios from 'axios';
import { BlogBanner } from "../interfaces/blog-banner.interface";

class BlogBannerAdminApi {

  getBanners(page, per_page): Promise<{ banners: BlogBanner[], total: number }>{
    const apiUrl = `/api/v1/admin/blog-banners?per_page=${per_page}&page=${page}`;

    return axios.get(apiUrl)
    .then(response => {
      const banners: BlogBanner[] = [];
      response?.data?.data?.forEach(bannerData => {
        banners.push(this.mapBannerResponse(bannerData?.attributes));
      });
      
      return Promise.resolve({banners, total: response?.data?.meta?.total_count});
    });
  }


  addBanner(bannerData:FormData): Promise<BlogBanner>{
    const apiUrl = `/api/v1/admin/blog-banners`;
    
    return axios({
      method: "post",
      url: apiUrl,
      data: bannerData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response)=> {
      const banner:BlogBanner = this.mapBannerResponse(response?.data?.data?.attributes);
      return banner;
    })
  }

  updateBanner(bannerId:number, bannerData:FormData): Promise<BlogBanner>{
    const apiUrl = `/api/v1/admin/blog-banners/${bannerId}`;

    return axios({
      method: "put",
      url: apiUrl,
      data: bannerData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response)=> {
      const banner:BlogBanner = this.mapBannerResponse(response?.data?.data?.attributes);
      return banner;
    })
  }


  removeBanner(banners:number[]){
    const apiUrl = `/api/v1/admin/blog-banners/${banners}`;
    return axios.delete(apiUrl).then(response => {
      return Promise.resolve(true);
    });
  }


  publishBanner(bannerId:number): Promise<BlogBanner>{
    const apiUrl = `/api/v1/admin/blog-banners/${bannerId}/publish`;
    return axios.patch(apiUrl).then(response => {
      const banner:BlogBanner = this.mapBannerResponse(response?.data?.data?.attributes);
      return Promise.resolve(banner);
    });
  }

  unpublishBanner(bannerId:number): Promise<BlogBanner>{
    const apiUrl = `/api/v1/admin/blog-banners/${bannerId}/unpublish`;
    return axios.patch(apiUrl).then(response => {
      const banner:BlogBanner = this.mapBannerResponse(response?.data?.data?.attributes);
      return Promise.resolve(banner);
    });
  }


  mapBannerResponse(bannerData){
    return {
      id: bannerData?.id,
      title: bannerData?.title,
      link: bannerData?.link,
      position: bannerData?.position,
      placing: bannerData?.placing,
      image: bannerData?.image_url,
      mayPublish: bannerData?.may_publish,
      mayUnpublish: bannerData?.may_unpublish,
      showStart: bannerData?.show_start,
      showEnd: bannerData?.show_end,
      clickCount: bannerData?.click_count,
      category: {
        id: bannerData?.blog_category.id,
        name: bannerData?.blog_category.name,
        slug: bannerData?.blog_category.slug,
        showInHomepage: bannerData?.blog_category.show_in_homepage,
        showInNavButton: bannerData?.blog_category.show_in_nav_button,
      }
    };
  }
}

export default BlogBannerAdminApi;