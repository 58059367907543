import axios from 'axios';
import { BlogBanner } from "../interfaces/blog-banner.interface";

class BlogBannerApi {

  getBanners(): Promise<BlogBanner[]>{
    const apiUrl = `/api/v1/blog-banners`;

    return axios.get(apiUrl)
    .then(response => {
      const banners: BlogBanner[] = [];
      response?.data?.data?.forEach(bannerData => {
        banners.push(this.mapBannerResponse(bannerData?.attributes));
      });
      
      return Promise.resolve(banners);
    });
  }

  getHomepageBanners(): Promise<BlogBanner[]>{
    const apiUrl = `/api/v1/blog-banners/homepage`;

    return axios.get(apiUrl)
        .then(response => {
          const banners: BlogBanner[] = [];
          response?.data?.data?.forEach(bannerData => {
            banners.push(this.mapBannerResponse(bannerData?.attributes));
          });

          return Promise.resolve(banners);
        });
  }

  getSidebarBanners(): Promise<BlogBanner[]>{
    const apiUrl = `/api/v1/blog-banners/sidebar`;

    return axios.get(apiUrl)
        .then(response => {
          const banners: BlogBanner[] = [];
          response?.data?.data?.forEach(bannerData => {
            banners.push(this.mapBannerResponse(bannerData?.attributes));
          });

          return Promise.resolve(banners);
        });
  }

  mapBannerPayload(banner){
    return {
      image: banner.image,
      title: banner.title,
      link: banner.link,
      placing: banner.placement,
      position: banner.position
    };
  }


  mapBannerResponse(bannerData){
    return {
      id: bannerData?.id,
      title: bannerData?.title,
      link: bannerData?.link,
      position: bannerData?.position,
      placement: bannerData?.placing,
      image: bannerData?.image_url,
      mayPublish: bannerData?.may_publish,
      mayUnpublish: bannerData?.may_unpublish,
      category: {
        id: bannerData?.blog_category.id,
        name: bannerData?.blog_category.name,
        slug: bannerData?.blog_category.slug,
        showInHomepage: bannerData?.blog_category.show_in_homepage,
        showInNavButton: bannerData?.blog_category.show_in_nav_button,
      }
    };
  }

  increaseClickCount(banner:number){
    const apiUrl = `/api/v1/blog-banners/${banner}/increase-click-count`;
    return axios.patch(apiUrl).then(response => {
      return Promise.resolve(true);
    });
  }
}

export default BlogBannerApi;