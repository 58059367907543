import * as React from 'react';
import * as moment from 'moment';
import { Modal, Form, Input, Select, DatePicker } from 'antd';
import { FormInstance } from 'antd/lib/form';
const { Option } = Select;
const { TextArea } = Input;

interface AppointmentModalProps {
    visible: boolean;
    onCreate: (payload: any) => void;
    onCancel: () => void;
}


interface AppointmentModalState {
}

class AppointmentModal extends React.Component<AppointmentModalProps, AppointmentModalState> {
    formRef: React.RefObject<FormInstance>;
    public constructor(props) {
        super(props);
        this.formRef = React.createRef<FormInstance>();
    }

    componentDidUpdate(prevProps: AppointmentModalProps) {
        if (this.props.visible && !prevProps.visible) {
            // Set default value for the email field when the modal becomes visible
            if (this.formRef.current) {
                this.formRef.current.setFieldsValue({
                    detail: "Hello! I came across your vendor profile at Bridepay.com.\n" +
                      "\n" +
                      "I would like to fix an appointment with you to learn more about your wedding services. Do let me know if the suggested date and time are possible.\n" +
                      "\n" +
                      "Regards."
                });
            }
        }
    }

    // Function to handle form submission
    handleSubmit = () => {
        const form = this.formRef.current!;
        form.validateFields()
            .then(values => {
                // console.log('Received values:', values);
                form.resetFields();
                this.props.onCreate(values);
            })
            .catch(errorInfo => {
                console.log('Validation failed:', errorInfo);
            });
    };

    disabledDate = (current) => {
        // Can not select days before today
        return current && current < moment().startOf('day');
    };

    render() {
        const { visible, onCancel } = this.props;

        return (
          <Modal
            visible={visible}
            title='We Will Send Your Appointment To This Vendor.'
            okText='Send'
            cancelText='Cancel'
            onCancel={onCancel}
            onOk={this.handleSubmit}
          >
            <Form ref={this.formRef} layout='vertical'>
              <Form.Item
                  name='name'
                  label='Your Name'
                  rules={[{ required: true, message: 'Please enter your name!' }]}
              >
                  <Input placeholder='Enter your name.' />
              </Form.Item>
              <Form.Item
                name='email'
                label='Your Email'
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Please enter a valid email address!'
                  }
                ]}
              >
                <Input placeholder='Enter your email.' />
              </Form.Item>
              <Form.Item
                name='mobile'
                label='Your Mobile number'
                rules={[
                  { required: true, message: 'Please enter your mobile number!' },
                  {
                    validator: (_, value) =>
                      new RegExp(/^\+?\d{1,3}?[-\s.]?(\(\d{1,3}\)[-\s.]?)?[\d\s.-]{3,}$/).test(value)
                        ? Promise.resolve()
                        : Promise.reject(new Error('Please enter a valid mobile number!')),
                  }
                ]}
              >
                <Input placeholder='Enter your mobile number.' />
              </Form.Item>
              <Form.Item
                name='date'
                label='Date & time'
                rules={[
                  { required: true, message: 'Please select a date and time!' }
                ]}
              >
                <DatePicker
                  showTime={{
                    format: 'HH:mm',
                    defaultValue: moment('00:00:00', 'HH:mm:ss'), // Set default time to 00:00:00
                  }}
                  format='DD/MM/YYYY HH:mm'
                  placeholder='Select Date and Time'
                  disabledDate={this.disabledDate}
                />
              </Form.Item>
              <Form.Item
                name='detail'
                label='Detail'
                rules={[{ required: true, message: 'Please enter some text!' }]}
              >
                <TextArea autoSize={{ minRows: 7 }} />
              </Form.Item>
            </Form>
          </Modal>
        );
    }
}

export default AppointmentModal;
