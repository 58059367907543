import * as React from "react"

import { Button, notification, Modal, FormInstance, Form, Input, Select } from "antd";
import { PlusOutlined } from '@ant-design/icons';

import { BlogTag } from "../../../interfaces/blog-tag.interface";
import AdminBlogTagsTable from "../../basic-components/admin-blog-tags-table/AdminBlogTagsTable";
import BlogTagAdminApi from "../../../api-services/blog-tag-admin-api.service";


interface IAdminBlogTagsPageProps {
  hideAction: boolean;
}

interface IAdminBlogTagsPageState {
  tags: BlogTag[];
  modalVisible: boolean;
  selectedBlogTag: BlogTag;
  selectedBlogTags: number[];
  pagination: any;
}


class AdminBlogTagsPage extends React.Component <IAdminBlogTagsPageProps, IAdminBlogTagsPageState> {
  
  tagApi = new BlogTagAdminApi();
  tagSetupForm = React.createRef<FormInstance>();
  status: string[] = ['unpublished', 'published'];

  componentDidMount(){
    this.setState({
      tags: [],
      selectedBlogTags: [],
      selectedBlogTag: null,
      modalVisible: false,
      pagination: {
        current: 1,
        pageSize: 25,
        showSizeChanger: false
      }
    });

    setTimeout(() => {
      this.loadBlogTags(1);
    }, 500);
  }

  loadBlogTags(page): void{
    this.tagApi.getTags(page, this.state?.pagination.pageSize)
    .then(tagsData => {
      const pagination = { ...this.state.pagination };
      pagination.current = page;
      pagination.total = tagsData.total;
      this.setState({tags: tagsData.tags, pagination});
    })
    .catch(() => {
      notification.error({ message: 'An error occurred while loading the data for tags', className: 'antd-mod center' });
    });
  }

  handleTableChange = (pagination) => {
    this.loadBlogTags(pagination.current)
  }

  saveBlogTag(data: any): void{
    let payload: BlogTag = {
      name: data.name,
      status: data.status
    };

    if(this.state?.selectedBlogTag){
      const tagId = this.state?.selectedBlogTag?.id;
      this.tagApi.updateTag(tagId, payload)
        .then(tagResponse => {
          const tagIndex = this.state?.tags?.findIndex(c => c.id == tagId);

          if(tagIndex > -1){
            let tags = [...this.state?.tags];

            tags[tagIndex] = tagResponse;
            this.tagSetupForm.current!.resetFields();
            this.setState({
              tags: tags,
              modalVisible: false
            });
            notification.success({ message: 'Changes saved successfully', className: 'antd-mod center' });
          }
        })
        .catch(() => {
          notification.error({ message: 'An error occurred while updating tag', className: 'antd-mod center' });
        });
    }else{
      this.tagApi.addTag(payload)
        .then(tagResponse => {
          this.setState(prevState => ({
            tags: [...prevState.tags, tagResponse],
            modalVisible: false
          }));
          this.tagSetupForm.current!.resetFields();
        })
        .catch(error => {
          console.log("error", error)
          notification.error({ message: 'An error occurred while adding tag', className: 'antd-mod center' });
        });
    }
  }


  updateBlogTagList(newBlogTag){
    const tagIndex = this.state?.tags?.findIndex(c => c.id == newBlogTag.id);

    if(tagIndex > -1){
      let tags = [...this.state?.tags];

      tags[tagIndex] = newBlogTag;
      this.setState({tags: tags});
      notification.success({ message: 'Changes saved successfully', className: 'antd-mod center' });
    }
  }
  
  onAddBlogTag(): void{
    this.tagSetupForm.current!
      .validateFields()
      .then(values => this.saveBlogTag(values))
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  }
  

  onEditBlogTag(tag:BlogTag): void{
    this.setState({modalVisible: true, selectedBlogTag: tag});

    setTimeout(() => {
      this.tagSetupForm.current!
        .setFieldsValue({
          name: tag.name,
          status: tag.status
        });
    });
  }

  onCancelBlogTag():void{
    this.tagSetupForm.current!.resetFields();
    this.setState({modalVisible: false});
  }
  

  removeBlogTags(tagId:number): void{
    this.tagApi.removeTag(tagId)
    .then(() => {
      this.setState(prevState => ({
        tags: prevState.tags.filter(tag => !(tagId === tag.id)),
        selectedBlogTags: []
      }));
    })
    .catch(() => {
      notification.error({ message: 'An error occurred while removing tag', className: 'antd-mod center' });
    })
  }
  

  /*publishBlogTag(tagId?:number): void{
    this.tagApi.publishBlogTag(tagId)
    .then(tagResponse => {
      this.updateBlogTagList(tagResponse);
    })
    .catch(error => {
      console.log(error)
      notification.error({ message: 'An error occurred while publishing tag', className: 'antd-mod center' });
    })
  }
  

  unpublishBlogTag(tagId?:number): void{
    this.tagApi.unpublishBlogTag(tagId)
    .then(tagResponse => {
      this.updateBlogTagList(tagResponse);
    })
    .catch(error => {
      console.log(error)
      notification.error({ message: 'An error occurred while un-publishing tag', className: 'antd-mod center' });
    })
  }*/


  renderAddBlogTagModal(){
    return (
      <Modal 
        title="Blog Tag"
        visible={this.state?.modalVisible} 
        maskClosable={false} 
        okText="Save"
        onOk={()=>this.onAddBlogTag()} 
        onCancel={()=>this.onCancelBlogTag()}
        width={600}
      >
        <Form id="banner-form" ref={this.tagSetupForm} layout="vertical" autoComplete="off">
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Name is required' }]}>
            <Input size="large"/>
          </Form.Item>
          <Form.Item name="status" label="Status">
            <Select size="large">
              <Select.Option value={0}>Unpublished</Select.Option>
              <Select.Option value={1}>Published</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    )
  }


  render() {
    return (
      <>
        <div className="AdminBannersPage">
          <div className="title">
            <div className="typo-title-2 typo-bold">BlogTags</div>
            {!this.props?.hideAction && (
              <div className="actions">
                <Button type="default" size="large" icon={<PlusOutlined />} onClick={()=>this.setState({modalVisible: true, selectedBlogTag: null})}>Add</Button>
                {/* <Button type="default" danger size="large" disabled={this.state?.selectedBlogTags?.length == 0} icon={<DeleteOutlined />} onClick={()=>this.removeBlogTags()}>Remove</Button> */}
              </div>
            )}
          </div>
          <div className="main-content">
            {this.renderAddBlogTagModal()}

            <AdminBlogTagsTable
              blogTags={this.state?.tags}
              hideAction={this.props?.hideAction}
              pagination={this.state?.pagination}
              onChange={this.handleTableChange}
              selectedBlogTags={this.state?.selectedBlogTags}
              selectionChange={
                (selectedBlogTags)=>{
                  this.setState({selectedBlogTags: selectedBlogTags})
                }
              }
              editBlogTag={(tag)=>this.onEditBlogTag(tag)}
              removeBlogTag={(tagId)=>this.removeBlogTags(tagId)}
            />
          </div>
        </div>
      </>
    );
  }
}

export default AdminBlogTagsPage
