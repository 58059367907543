import { Tooltip } from 'antd';
import * as React from 'react';



interface LikeableImageProps {
  imageUrl: string;
  likeStatus?: boolean;
  likeButtonDisabled?: boolean;
  likeStatusChanged?: (likeStatus: boolean) => void;
  width?: number | string;
  height?: number | string;
  onClick?: () => void;
}


interface LikeableImageState {
  liked: boolean;
}


class LikeableImage extends React.Component<LikeableImageProps, LikeableImageState> {

  componentDidMount(): void {
    this.setState({
      liked: this.props?.likeStatus
    });
  }

  
  emitLikeStatusChanged(likeStatus: boolean): void {
    if(this.props?.likeStatusChanged) {
      this.props.likeStatusChanged(likeStatus);
    }
  }


  /**
   * Show a confirmation message first when unliking an image.
   * The reason is to prevent accidental unlike.
   * Do NOT show a confimation when liking an image.
   */
   confirmLikeStatusChange(likeStatus: boolean): void {
    if(likeStatus === false) {
      const confirm = window.confirm(`Unlike this image?`);
      if(confirm) {
        this.props?.likeStatusChanged(likeStatus);
        this.setState({ liked: false });
      }
    }

    else {
      this.props?.likeStatusChanged(likeStatus);
      this.setState({ liked: true });
    }
  }
  
  
  renderLikeButton() {
    if(this.props?.likeButtonDisabled === true) {
      return null;
    }

    else {
      return (
        <div className="like-button-container">
          <Tooltip title="Like this image">
            <img src="/images/wishlist-inactive.png" onClick={() => { this.confirmLikeStatusChange(true) }} className={`icon ${this.state?.liked ? 'hidden' : ''}`} alt="Add to wishlist" />
          </Tooltip>
          <Tooltip title="Unlike this image">
            <img src="/images/wishlist-active.png" onClick={() => { this.confirmLikeStatusChange(false) }} className={`icon ${this.state?.liked ? '' : 'hidden'}`} alt="Remove wishlist" />
          </Tooltip>
        </div>
      );
    }
  }
  
  
  render() {
    return (
      <div className="LikeableImage" style={{width: this.props?.width, height: this.props?.height, display: 'inline-block'}} onClick={this.props.onClick??(() => {})}>
        <img src={this.props?.imageUrl} width={this.props?.width} height={this.props?.height} />
        {this.renderLikeButton()}
      </div>
    );
  }

}




export default LikeableImage;