// import './VendorCalendar.scss';

import * as React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { CalendarEvent } from '../../../interfaces/calendar-event.interface';



interface VendorCalendarProps {
  calendarEvents: CalendarEvent[];
  addCalendarEventClicked?: () => void;
  calendarEventClicked?: (calendarEvent: CalendarEvent) => void
}


interface VendorCalendarState {

}



class VendorCalendar extends React.Component<VendorCalendarProps, VendorCalendarState> {

  fullCalendarRef = React.createRef<FullCalendar>();
  
  // NOTE: Remove custom buttons as of now as it is not yet clear if we will use calendar events. It seems that they are referring "calendar slots" the same with "calendar events"
  // customButtons = {
  //   myCustomButton: {
  //     text: 'Add Calendar Event',
  //     click: () => {
  //       if(this.props?.addCalendarEventClicked) {
  //         this.props?.addCalendarEventClicked();
  //       }
  //     }
  //   }
  // };

  headerToolbarConfig = {
    right: 'today prev,next'
  };


  emitAddCalendar(): void {
    if(this.props?.addCalendarEventClicked) {
      this.props.addCalendarEventClicked();
    }
  }


  emitCalendarEventClicked(event: any): void {
    if(this.props?.calendarEventClicked) {
      const calendarEvent = {
        title: event.event.title,
        start: event.event.start,
        end: event.event.end,
      };
      
      this.props.calendarEventClicked(calendarEvent);
    }
  }


  render() {
    const events = this.props?.calendarEvents?.map(calendarEvent => {
      return {
        title: calendarEvent.title,
        start: calendarEvent.start,
        end: calendarEvent.end,
      };
    });
    
    return (
      <div className="VendorCalendar">
        <FullCalendar 
          plugins={[ dayGridPlugin ]} 
          events={events} 
          headerToolbar={this.headerToolbarConfig} 
          buttonText={{ today: 'Today' }} 
          ref={this.fullCalendarRef}
          eventClick={(event) => { this.emitCalendarEventClicked(event) }}
        />
      </div>
    );
  }
  
}




export default VendorCalendar;