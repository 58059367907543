import * as React from 'react';
import {Col, Input, Row, Select} from "antd";

const statusOptions = [
    {
        value: 0,
        label: 'All'
    },
    {
        value: 1,
        label: 'Todo'
    },
    {
        value: 2,
        label: 'Done'
    }
];

const Filter = ({groups, onFilter}) => {

    const [bookingDay, setBookingDay] = React.useState<number>(0);
    const changeBookingDay = (value: number) => {
        onFilter(value, status, searchKey);
        setBookingDay(value);
    };

    const [status, setStatus] = React.useState<number | null>(0);
    const changeStatus = (value: number) => {
        onFilter(bookingDay, value, searchKey);
        setStatus(value);
    };

    const [searchKey, setSearchKey] = React.useState<string>('');
    const changeSearchKey = (e: React.ChangeEvent<HTMLInputElement>) => {
        onFilter(bookingDay, status, e.target.value);
        setSearchKey(e.target.value);
    }

    return (
        <div className="filter">
            <Row gutter={[16, 16]}>
                <Col md={12} xs={24}>
                    <Input.Search
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Search"
                        value={searchKey}
                        onChange={changeSearchKey} />
                </Col>
                <Col md={4} xs={0} />
                <Col md={8} xs={24}>
                    <Row gutter={16}>
                        <Col flex={5}>
                            <Select
                                value={bookingDay}
                                onChange={changeBookingDay}
                                options={[{label: 'View By All Months', value: 0}, ...groups?.map((group: any) => ({value: group.id, label: group.name}))??[]]}
                                style={{width: '100%'}}
                            />
                        </Col>
                        <Col flex={2}>
                            <Select
                                placeholder="Status"
                                value={status}
                                onChange={changeStatus}
                                options={statusOptions}
                                style={{width: '100%'}}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default Filter;