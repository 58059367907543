import * as React from "react"
import { Button, notification } from "antd";
import { LeftOutlined } from '@ant-design/icons';
import ArticleAdminApi from "../../../api-services/article-admin-api.service";
// import ArticleApi from "../../../api-services/article-api.service";
import ArticleSetupForm from "../../basic-components/article-setup-form/ArticleSetupForm";
import {Article} from "../../../interfaces/article.interface";
import {BlogCategory} from "../../../interfaces/blog-category.interface";
import BlogCategoryAdminApi from "../../../api-services/blog-category-admin-api.service";
import {BlogTag} from "../../../interfaces/blog-tag.interface";
import BlogTagAdminApi from "../../../api-services/blog-tag-admin-api.service";


notification.config({
  duration: 12,
  top: 60
})


interface IAdminArticleShowPageProps {
  articleId?:number;
  mode: "add" | "edit";
  hideAction: boolean;
}

interface IAdminArticleShowPageState {
  article: Article;
  image: string;
  categories: BlogCategory[];
  tags: BlogTag[];
}

class AdminArticleShowPage extends React.Component <IAdminArticleShowPageProps, IAdminArticleShowPageState> {

  articleAdminApi = new ArticleAdminApi;


  componentDidMount(){
    this.setState({
      categories: [],
      tags: [],
      image: null,
      article: null
    });
    this.loadCategories();
    this.loadTags();
    if(this.props?.articleId){
      this.getArticle();
    }
  }

  loadCategories(): void {
    const categoryApi = new BlogCategoryAdminApi();
    categoryApi.getCategories(1, 99999)
    .then(categoriesData => {
      this.setState({ categories: categoriesData.categories });
    })
    .catch(error => {
      notification.error({ message: 'An error occurred while loading the data for categories', className: 'antd-mod center' });
    })
  }

  loadTags(): void {
    const tagApi = new BlogTagAdminApi();
    tagApi.getTags(1, 99999)
        .then(tagsData => {
          this.setState({ tags: tagsData.tags });
        })
        .catch(error => {
          notification.error({ message: 'An error occurred while loading the data for tags', className: 'antd-mod center' });
        })
  }

  getArticle(){
    const articleId = this.props?.articleId;

    this.articleAdminApi.getArticle(articleId)
      .then(articleResponse => {
        this.setState({article: articleResponse});
      })
      .catch(error => {
        notification.error({ message: `Error while loading article.`, className: 'antd-mod center' });
      })
  }


  redirectToArticles(){
    // window.location.href = '/admin/articles';
    window.history.back();
  }
  

  render() {
    return (
      <div className="AdminProductShowPage">
        <div className="main-content">
          <Button type="link" className="back-to-link typo-bold" icon={<LeftOutlined />} onClick={()=>this.redirectToArticles()}>Back to Articles</Button>
          
          <ArticleSetupForm
              mode={this.props?.mode}
              article={this.state?.article}
              wholeCategories={this.state?.categories}
              wholeTags={this.state?.tags}
              hideAction={this.props?.hideAction}/>
        </div>
      </div>
    );
  }
}


export default AdminArticleShowPage
