// import './SingleImageModal.scss';

import * as React from 'react';
import { Modal } from 'antd';
import ReactPlayer from 'react-player'



interface SingleImageModalProps {
  visible?: boolean;
  imageUrl: string;
  type?: string;
  description?: string;
  closed?: () => void;
}


interface SingleImageModalState {

}



class SingleImageModal extends React.Component<SingleImageModalProps, SingleImageModalState> {

  emitClosed(): void {
    if(this.props?.closed) {
      this.props.closed();
    }
  }
  
  renderContent(){
    switch (this.props?.type){
      case "video":
        return (
          <ReactPlayer url={this.props?.imageUrl} />
         )

        // image
       default:
        return (
          <img src={this.props?.imageUrl} alt="Image in a Modal" />
        )
    }

  }
  
  render() {
    return (
      <Modal className="SingleImageModal" width={'80vw'} centered={true} visible={this.props?.visible} onCancel={() => { this.emitClosed() }} footer={null} destroyOnClose={true} bodyStyle={{ maxHeight: '100vh' }} maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.9)"}}>
        <div className="description" dangerouslySetInnerHTML={{__html: this.props?.description}} />
        {this.renderContent()}
      </Modal>
    );
  }
  
}




export default SingleImageModal;