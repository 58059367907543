// import './VendorFaqTab.scss';

import * as React from 'react';

import { VendorFaqItem } from '../../../interfaces/vendor-faq-item.interface';
import FaqItem from '../../basic-components/faq-item/FaqItem';



interface VendorFaqTabProps {
  faqItems: VendorFaqItem[];
}


interface VendorFaqTabState {

}




class VendorFaqTab extends React.Component<VendorFaqTabProps, VendorFaqTabState> {

  renderFaqItems() {
    if(this.props?.faqItems?.length) {
      return (
        <div className="content faq-list">
          {this.props?.faqItems?.map((faqItem, faqItemKey) => <FaqItem key={faqItemKey} item={faqItem}></FaqItem>)}
        </div>
      );
    }

    else {
      return <div className="typo-subheading-1">No FAQ items found.</div>
    }
  }
  
  
  render() {
    return (
      <div className="VendorFaqTab">
        <div className="content">
          <div className="header">
            <div className="title">Frequently Asked Questions</div>
          </div>

          {this.renderFaqItems()}
        </div>
      </div>
    );
  }
  
  
}






export default VendorFaqTab;