// import './VendorDashboardPage.scss';

import * as React from 'react';
import { Button, Modal, notification, Tabs } from 'antd';
import {startCase, toLower} from "lodash"

import { Vendor } from '../../../interfaces/vendor-interface';
import VendorApi from '../../../api-services/vendor-api.service';

import VendorNavbar from '../../basic-components/vendor-navbar/VendorNavbar';
import VendorDashboardAlbumTab from '../../tab-contents/vendor-dashboard-album-tab/VendorDashboardAlbumTab';
import VendorProfileTab from '../../tab-contents/vendor-profile-tab/VendorProfileTab';
import VendorDashboardProductTab from '../../tab-contents/vendor-dashboard-product-tab/VendorDashboardProductTab';
// import VendorDashboardMenuTab from '../../tab-contents/vendor-dashboard-menu-tab/VendorDashboardMenuTab';
// import VendorDashboardRawSpacesTab from '../../tab-contents/vendor-dashboard-raw-spaces-tab/VendorDashboardRawSpacesTab';
import VendorDashboardOtherAlbumTab from '../../tab-contents/vendor-dashboard-other-album-tab/VendorDashboardOtherAlbumTab';
import VendorBookingCalendarTab from '../../tab-contents/vendor-booking-calendar-tab/VendorBookingCalendarTab';
import { ServiceCategory } from '../../../interfaces/service-category.interface';
import ProductSetupPage from '../product-setup-page/ProductSetupPage';
import AlbumSetupPage from '../album-setup-page/AlbumSetupPage';

const { TabPane } = Tabs;
notification.config({
  duration: 12,
  top: 60
})

interface VendorDashboardPageProps {
  tab:string;
  isAccountSignedIn: boolean;
  csrfToken: string;

  id?:number;
  vendorServiceCategory?:ServiceCategory;
  type?: string;
}

interface VendorDashboardPageState {
  vendor: Vendor;
  selectedTab: string;
  activeTab: string;
  addMode: boolean;
}

class VendorDashboardPage extends React.Component <VendorDashboardPageProps, VendorDashboardPageState> {
  
  vendorApi = new VendorApi;

  tabs = [
    { route: "edit", name: "Profile" },
    { route: "products", name: "Wedding Deals" },
    { route: "calendars", name: "Booking Calendar" },
    // { route: "albums", name: "Albums" },
    // { route: "menus", name: "Menus" },
    // { route: "interiors", name: "Interiors" },
    // { route: "raw_spaces", name: "Raw Spaces" },
  ]

  componentDidMount(): void {
    this.setState({
      activeTab: this.getSelectedTab(),
      addMode: this.props?.id != undefined
    });

    setTimeout(() => {
      this.loadSignedInUserProfile();
    }, 500);
  }



  capitalize(string){
    return string.replaceAll('_', ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  }

  getSelectedTab():string{
    var pathName = this.getPathName();
    const tabRoutes = this.tabs.map(t => t.route);
    const index = tabRoutes.indexOf(pathName);

    return (index > -1) ? pathName : "edit"
  }

  getPathName():string{
    return window.location.pathname.split("/")[3];
  }



  /**
   * Get the signed in user's profile from server if there is a user signed in.
   */
  loadSignedInUserProfile(): void {
    this.vendorApi.getVendorProfile()
    .then(user => {
      this.setState({ vendor: user });
      if(user.albumTypes.indexOf(this.getPathName()) > -1 ){
        this.setState({
          activeTab: this.getPathName()
        })
      }
    })
    .catch(error => {
      notification.error({ message: 'Error while loading the vendor\'s profile.', className: 'antd-mod center' });
    });
  }

  updateVendor = () =>{
    this.vendorApi.getVendorProfile()
    .then(user => {
      this.setState({ vendor: user });
    })
  }


  tabOnChange(activeKey: string): void {
    // console.log('activeKey', activeKey)
    this.setState({
      activeTab: activeKey,
      addMode: false
    });
    
    window.history.pushState(null, '', `/business/dashboard/${activeKey}`);
  }


  renderTab(tab){
    // const selectedTab = this.tabs.findIndex(t => t.route === this.props?.tab).toString();
    switch (tab) {
      case "products":
        return !this.state?.addMode ? <VendorDashboardProductTab/> : <ProductSetupPage productId={this.props?.id} vendorServiceCategory={this.props?.vendorServiceCategory}/>;
      case "calendars":
        return <VendorBookingCalendarTab/>;
      // case "albums":
      //   return <VendorDashboardAlbumTab/>;
      // case "menus":
      //   return <VendorDashboardOtherAlbumTab name="menus" />;
      // case "interiors":
      //   return <VendorDashboardOtherAlbumTab name="interiors" />;
      // case "raw_spaces":
      //   return <VendorDashboardOtherAlbumTab name="raw_spaces" />;
    
      default:
        return <VendorProfileTab vendor={this.state?.vendor} onVendorUpdate={this.updateVendor} />
    }
  }

  renderAlbumTabByType(albumType: string) {
    const title = this.capitalize(startCase(toLower(albumType.replace("_", " "))));

    if(!this.state?.addMode){
      if(albumType == "albums"){
        return (
          <TabPane tab={<span className="typo-subheading-1">{this.capitalize(albumType)}</span>} key={albumType}>
            <VendorDashboardAlbumTab/>
          </TabPane>
        );
      }else if(albumType == "videos"){
        return ('');
      }
      else{
        return (
          <TabPane tab={<span className="typo-subheading-1">{title}</span>} key={albumType}>
            <VendorDashboardOtherAlbumTab name={albumType} /> 
          </TabPane>
        );
      }
    }else{
      return (
        <TabPane tab={<span className="typo-subheading-1">{title}</span>} key={albumType}>
          <AlbumSetupPage albumId={this.props?.id} type={albumType}/>
        </TabPane>
      );
    }

  }


  publishVendorProfile(){
    if(this.state?.vendor?.mayPublish){
      this.vendorApi.publishProfile()
        .then(response => {
          notification.success({ message: 'Your profile has been published successfully.', className: 'antd-mod center' });
          var vendor = this.state.vendor
          vendor.status = "published"
          this.setState({
            vendor: vendor
          });
        })
        .catch(error => {
          notification.error({ message: 'Error while publishing the vendor\'s profile.', className: 'antd-mod center' });
        });
    }else{
      var content = "";
      if(this.state?.vendor?.status == "pending"){
        // content = "Your business has been submitted for approval. Please complete your FAQs, create at least 1 Album & 1 Wedding Deal to Publish!"
        content = "Your business has been submitted for approval. Please complete your FAQs, create at least 1 Album to Publish!"
      } else if(this.state?.vendor?.status == "approved"){
        // content = "Complete your FAQs, create at least 1 Album & 1 Wedding Deal to Publish!"
        content = "Complete your FAQs, create at least 1 Album to Publish!"
      } else if(this.state?.vendor?.status == "rejected"){
        content = "Your business regristration has been declined!"
      } else if(this.state?.vendor?.status == "suspended"){
        content = "Your business account has been suspended!"
      } else{
        // content = "Complete your FAQs, create at least 1 Album & 1 Wedding Deal to Publish!"
        content = "Complete your FAQs, create at least 1 Album to Publish!"
      }
      Modal.info({
        title: 'Publish Profile',
        content: content
      });
    }
  }
  
  
  render() {

    var operations = {
      right: <Button onClick={()=>this.publishVendorProfile()} disabled={this.state?.vendor?.status == "published" || this.state?.vendor?.status == "suspended" }>{this.state?.vendor?.status == "published" || this.state?.vendor?.status == "suspended" ? "Published" : "Publish Profile"}</Button>,
    };

    // const selectedTab = this.tabs.findIndex(t => t.route === this.props?.tab).toString();
    return (
      <div className="VendorDashboardPage">
        <VendorNavbar isAccountSignedIn={true} vendor={this.state?.vendor}></VendorNavbar>
        
        <div className="main-content">
          <Tabs activeKey={this.state?.activeTab} tabBarExtraContent={operations}
            onChange={(activekey) => { this.tabOnChange(activekey) }}>
            {
              this.tabs.map((tab, idx) => {
                // Temporary disabled the booking calendars until the feature is ready
                if (tab.route == "calendars"){
                   return (
                     <TabPane tab={<span className="typo-subheading-1">{tab.name}</span>} key={tab.route} disabled>
                       {this.renderTab(tab.route)}
                     </TabPane>
                   );
                } else{

                  return (
                    <TabPane tab={<span className="typo-subheading-1">{tab.name}</span>} key={tab.route}>
                      {this.renderTab(tab.route)}
                    </TabPane>
                  );
                }
              })
            }
            {
              /* Render Album tabs that are specified in vendor.albumTypes */
              this.state?.vendor?.albumTypes?.map(albumType => this.renderAlbumTabByType(albumType))
            }
          </Tabs>
        </div>
      </div>
    );
  }
}

export default VendorDashboardPage;
