// import './VideoUploader.scss';

import * as React from "react"
import { FormInstance, Form, Spin, Button, Input } from "antd";
import AlbumApi from "../../../api-services/album-api.service";
import { AlbumMedium } from "../../../interfaces/album.interface";
import { DeleteOutlined } from '@ant-design/icons'; 
import ReactPlayer from 'react-player'

const {Search} = Input;


interface IVideoUploaderProps {
  albumId: number;
  savedVideos: AlbumMedium[];
  addVideo: (videoRes) => void;
  removeVideo: (videoId) => void;
}

interface IVideoUploaderState {
  addingVideo:boolean;
  validateVideoStatus;
  errorMsg: string;
}

class VideoUploader extends React.Component <IVideoUploaderProps, IVideoUploaderState> {
  
  albumApi = new AlbumApi;
  albumUploadForm = React.createRef<FormInstance>();
  

  componentDidMount(){
    this.setState({
      addingVideo: false,
      validateVideoStatus: '',
      errorMsg: ''
    })
  }


  componentDidUpdate(previousProps){
    // if(previousProps.savedVideos !== this.props?.savedVideos){
    //   this.setState({videos: this.props?.savedVideos});
    // }
  }


  onAddVideo(videoUrl){
    if(videoUrl.trim() !== ""){
      this.setState({addingVideo: true});
  
      this.albumApi.addVideo(this.props?.albumId, videoUrl)
        .then(videoResponse => {
          console.log(videoResponse);
          this.props?.addVideo(videoResponse);
          this.albumUploadForm.current.resetFields();
        })
        .catch((error) =>{
          console.log(error)
          this.setState({
            validateVideoStatus: "error",
            errorMsg: error?.response?.data?.error || "Invalid video url"
          })
        })
        .finally(()=>{
          this.setState({addingVideo: false});
        })
    }
  }


  deleteVideo(videoId){
    this.albumApi.deleteMediums(videoId)
      .then(response => {
        this.props?.removeVideo(videoId);
      })
      .catch(err=>{

      });
  }


  resetValidationStatus(){
    this.setState({
      validateVideoStatus: '',
      errorMsg: ''
    })
  }


  render() {
    return (
      <div className="VideoUploader">
        <div>
          <Form ref={this.albumUploadForm}>
            <Form.Item name="videoUrl" validateStatus={this.state?.validateVideoStatus} help={this.state?.errorMsg}>
              <Search placeholder="Enter code or video url" loading={this.state?.addingVideo} disabled={ this.props?.savedVideos && this.props?.savedVideos.length == 1}
                enterButton="ADD VIDEO" size="large" onSearch={(val)=>this.onAddVideo(val)} onChange={()=>this.resetValidationStatus()}/>
            </Form.Item>
          </Form>
        </div>
        
        {
          this.props?.savedVideos && this.props?.savedVideos.length > 0 && (
            <div className="videos-preview">
              <Spin size="large" spinning={this.state?.addingVideo}>
                {
                  this.props?.savedVideos.map(video => {
                    return (
                      <div key={video.id} className="video">
                        <Button type="primary" danger className="delete-video" icon={<DeleteOutlined />} onClick={()=>this.deleteVideo(video.id)}/>
                        <ReactPlayer url={video.videoUrl} />
                      </div>
                    )
                  })
                }
              </Spin>
            </div>
          )
        }
      </div>
    );
  }
}

export default VideoUploader
