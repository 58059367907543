// import './CalendarModal.scss';

import * as React from 'react';
import { Modal, Form, Input, Button, DatePicker } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import * as moment from 'moment';

import { Calendar } from '../../../interfaces/calendar.interface';



interface CalendarModalProps {
  calendar?: Calendar;
  mode?: 'add'|'edit'|'view';
  visible: boolean;
  closed: () => void;
  calendarSaved?: (calendar: Calendar) => void;
}


interface CalendarModalState {
  calendarFormData: any;
}



class CalendarModal extends React.Component<CalendarModalProps, CalendarModalState> {


  saveCalendar(): void {
    if(this.props?.calendarSaved) {
      this.state.calendarFormData.startDate = this.state.calendarFormData.dateRange[0]?.toDate();
      this.state.calendarFormData.endDate = this.state.calendarFormData.dateRange[1]?.toDate();
      this.props.calendarSaved(this.state?.calendarFormData);
    }
  }
  
  
  renderForm() {
    const formInitialValues = {
      name: this.props?.calendar?.name,
      start: (this.props?.calendar?.startDate) ? moment(this.props?.calendar?.startDate) : null,
      end: (this.props?.calendar?.endDate) ? moment(this.props?.calendar?.endDate) : null,
    };
    
    return (
      <Form layout="vertical" initialValues={formInitialValues} onFinish={(event) => { this.setState({ calendarFormData: event }); this.saveCalendar(); this.props?.closed(); }}>
        <Form.Item label="Calendar Name" name="name" rules={[{ required: true, message: 'Please input a name.' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Date Range" name="dateRange" rules={[{ required: true, message: 'Please input a start and end date.' }]}>
          <DatePicker.RangePicker format={'MMMM D, YYYY'} />
        </Form.Item>

        <Form.Item>
          <Button className="antd-mod color-primary" type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    );
  }
  
  
  render() {
    return (
      <Modal className="CalendarModal" centered={false} visible={this.props?.visible} onCancel={this.props?.closed} closable={false} footer={null} keyboard={false} maskClosable={false} destroyOnClose={true}>
        <Button className="back-to-link typo-bold" onClick={() => { this.props?.closed() }} type="link" icon={<LeftOutlined />}>Close</Button>
        {this.renderForm()}
      </Modal>
    );
  }
  
}




export default CalendarModal;