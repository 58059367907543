// import './MultipleImageUploader.scss';

import { Upload, Modal, notification } from "antd";
import ImgCrop from "antd-img-crop";
import * as React from "react"

notification.config({
  duration: 12,
  top: 60
})


interface IMultipleImageUploaderProps {
  maxSize?: number; //max size of image
  maxCount?: number;
  croppable?: boolean;
  disabled?: boolean;
  uploadImageApi: string;
  savedImages: any[];
  addImage:(imageRes) => void;
  removeImage:(imageId) => void;
}

interface IMultipleImageUploaderState {
  images: any[];
  previewVisible: boolean;
  previewImage: string;
  previewTitle: string;
}



class MultipleImageUploader extends React.Component <IMultipleImageUploaderProps, IMultipleImageUploaderState> {

  static defaultProps = { croppable: false, disabled: false, maxCount: 30, maxSize: 8};

  componentDidMount(){
    this.setState({
      images: [],
      previewVisible: false,
      previewImage: '',
      previewTitle: ''
    })
  }


  componentDidUpdate(previousProps){
    if(previousProps.savedImages !== this.props?.savedImages){
      const images = this.props?.savedImages?.map(image => {
        const antdImageFormat = {
          uid: image.id,
          url: image.imageUrl,
          status: "done"
        }
        return antdImageFormat;
      })
      this.setState({images: images});
    }
  }

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({ message: 'You can only upload JPG/PNG file!', className: 'antd-mod center' });
    }
    const isLt2M = file.size / 1024 / 1024 < this.props?.maxSize;
    if (!isLt2M) {
      notification.error({ message: `Image must smaller than ${this.props?.maxSize}MB!`, className: 'antd-mod center'});
    }
    return isJpgOrPng && isLt2M;
  }


  handleChange = ({ fileList: newFileList, file }) => {
    if(file.status === "error"){
      notification.error({ message: file.response?.error, className: 'antd-mod center' });
    }

    if(file.status === "done"){
      var imageRes = file?.response?.data?.attributes;
      const image = {
        id: imageRes.id,
        imageUrl: imageRes.image_url
      } 
      this.props?.addImage(image);
    }
    this.setState({images: newFileList});
  };


  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  handlePreview = file => {
    let src = file.url;
    if (!src) {
      this.getBase64(file.originFileObj, imageUrl =>
        this.setState({
          previewImage: imageUrl,
          previewVisible: true,
          previewTitle: "Image " + file.uid
        })
      )
    }
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handleRemove = (file) => {
    const imageId = file.response?.data?.attributes?.id || file.uid;
    this.props?.removeImage(imageId);
  }

  renderUpload(){
    const list = this.state?.images;
    return (
      <Upload
            name="image" accept="image/*"
            maxCount={this.props?.maxCount}
            disabled={this.props?.disabled}
            action={this.props?.uploadImageApi}
            listType="picture-card"
            fileList={list} multiple
            beforeUpload={this.beforeUpload}
            onChange={this.handleChange}
            onPreview={this.handlePreview}
            onRemove={this.handleRemove}
          >
            + Upload
          </Upload>
    );
  }


  render() {
    return (
      <div className="MultipleImageUploader">
        {
          this.props?.croppable ? (
            <ImgCrop rotate grid={true}>
              {this.renderUpload()}
            </ImgCrop> 
          ) : this.renderUpload()
        }
        <Modal
          visible={this.state?.previewVisible}
          title={this.state?.previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={this.state?.previewImage} />
        </Modal>
      </div>
    );
  }
}

export default MultipleImageUploader
