// import './Tag.scss';

import * as React from 'react';


interface TagProps {
  text: string;
  variant?: number;
}


interface TagState {

}




class Tag extends React.Component<TagProps, TagState> {

  render() {
    const variantClass = (this.props?.variant) ? `variant-${this.props?.variant}` : '';
    
    return (
      <div className={`Tag ${variantClass}`}>{this.props?.text}</div>
    );
  }
  
}




export default Tag;