import axios from 'axios';
import { BlogTag } from "../interfaces/blog-Tag.interface";

class BlogTagAdminApi {

  getTags(page, per_page): Promise<{ tags: BlogTag[], total: number }>{
    const apiUrl = `/api/v1/admin/blog-tags?per_page=${per_page}&page=${page}`;

    return axios.get(apiUrl)
    .then(response => {
      const tags: BlogTag[] = [];
      response?.data?.data?.forEach(tagData => {
        tags.push(this.mapBlogTagResponse(tagData?.attributes));
      });
      
      return Promise.resolve({tags, total: response?.data?.meta?.total_count});
    });
  }


  addTag(tagData: BlogTag): Promise<BlogTag>{
    const apiUrl = `/api/v1/admin/blog-tags`;
    
    return axios({
      method: "post",
      url: apiUrl,
      data: tagData
    })
    .then((response)=> {
      return this.mapBlogTagResponse(response?.data?.data?.attributes);
    })
  }

  updateTag(tagId:number, tagData:BlogTag): Promise<BlogTag>{
    const apiUrl = `/api/v1/admin/blog-tags/${tagId}`;

    return axios({
      method: "put",
      url: apiUrl,
      data: tagData
    })
    .then((response)=> {
      return this.mapBlogTagResponse(response?.data?.data?.attributes);
    })
  }


  removeTag(tag:number){
    const apiUrl = `/api/v1/admin/blog-tags/${tag}`;
    return axios.delete(apiUrl).then(() => {
      return Promise.resolve(true);
    });
  }

  mapBlogTagResponse(tagData){
    return {
      id: tagData?.id,
      name: tagData?.name,
      createdAt: tagData?.created_at,
      slug: tagData?.slug,
      status: tagData?.status,
    };
  }

}

export default BlogTagAdminApi;
