// import './HomePage.scss';

import * as React from 'react';
import {Col, notification, Row} from 'antd';
import SwiperCore, { Autoplay, Pagination } from 'swiper';

import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import { User } from '../../../interfaces/user.interface';
import { Product } from '../../../interfaces/product.interface';
import { Album } from '../../../interfaces/album.interface';
import { ServiceCategory } from '../../../interfaces/service-category.interface';

import UserApi from '../../../api-services/user-api.service';
import AuthApi from '../../../api-services/auth-api.service';
import ProductApi from '../../../api-services/product-api.service';
import AlbumApi from '../../../api-services/album-api.service';
import ServiceCategoryApi from '../../../api-services/service-category-api.service';

import Countdown from '../../ui-components/countdown/Countdown';
import Footer from '../../basic-components/footer/Footer';
import Navbar from '../../basic-components/navbar/Navbar';
import ItemSlider from '../../basic-components/item-slider/ItemSlider';
import ProductCard from '../../basic-components/product-card/ProductCard';
import AlbumCard from '../../basic-components/album-card/AlbumCard';
import LoginModal from '../../modals/login-modal/LoginModal';
import PageAlert from '../../basic-components/page-alert/PageAlert';
import LabelCard from '../../basic-components/label-card/LabelCard';
import PageCoverSlider from '../../basic-components/page-cover-slider/PageCoverSlider';
import OffersSlider from '../../basic-components/offers-slider/OffersSlider';
import CampaignApi from '../../../api-services/campaign-api.service';
import { Campaign } from '../../../interfaces/campaign.interface';
import { Banner } from '../../../interfaces/banner.interface';
import BannerApi from '../../../api-services/banner-api.service';
import ForgotPasswordModal from '../../modals/forgot-password-modal/ForgotPasswordModal';

SwiperCore.use([Pagination, Autoplay]);
notification.config({
  duration: 12,
  top: 60
});

interface HomePageProps {
  isAccountSignedIn: boolean;
  googleSignInUrl: string;
  fbSignInUrl: string;
  csrfToken: string;
}

interface HomePageState {
  loggedOnUser: User;
  loginModalVisible: boolean;
  forgotPasswordModalVisible: boolean;
  loginModalRedirectUrl: string;
  weddingFairSpecialProducts: Product[];
  shoppingFestivalSaleProducts: Product[];
  weddingDealsProducts: Product[];
  microWeddingProducts: Product[];
  latestDealsProducts: Product[];
  allBridepayDeals: Product[];
  recommendedAlbums: Album[];
  recommendedMenus: Album[];
  categories: ServiceCategory[];
  subCategories: ServiceCategory[];
  campaigns: Campaign[];
  banners: Banner[];
}

class HomePage extends React.Component<HomePageProps, HomePageState> {
  userApi = new UserApi();
  authApi = new AuthApi();
  productApi = new ProductApi();
  albumApi = new AlbumApi();
  serviceCategoryApi = new ServiceCategoryApi();
  campaignApi = new CampaignApi();
  bannerApi = new BannerApi();

  sampleAlbum: Album = {
    id: 1,
    title: 'Diamond',
    description: '',
    coverImageUrl: 'https://picsum.photos/400/400',
    kind: 'image',
    mostRecentImages: [
      { id: 1, liked: true, url: 'https://picsum.photos/200/200' },
      { id: 2, liked: false, url: 'https://picsum.photos/210/210' },
      { id: 3, liked: true, url: 'https://picsum.photos/220/220' }
    ],
    totalVideos: 2,
    totalPhotos: 15
  };

  componentDidMount(): void {
    this.setState({
      loginModalVisible: false,
      forgotPasswordModalVisible: false
    });

    this.loadRecommendedAlbums();
    this.loadSignedInUserProfile();
    this.loadBanners();
    this.loadCampaigns();
    this.loadCampaignProducts();
    this.loadServiceCategory();
  }

  loadSignedInUserProfile(): void {
    if (!this.props?.isAccountSignedIn) {
      return null;
    }

    this.userApi
      .getUserProfile()
      .then((user) => {
        this.setState({
          loggedOnUser: user
        });
      })
      .catch((error) => {
        console.error(error);
        notification.error({
          message: "Error while loading the user's profile.",
          className: 'antd-mod center'
        });
      });
  }

  loadServiceCategory(): void {
    Promise.all([this.serviceCategoryApi.getCategories()]).then((result) => {
      // console.log("check result ===> ", result)
      this.setState(
        {
          categories: result[0]
        },
        () => {
          this.state?.categories?.map((category) => {
            this.serviceCategoryApi
              .getSubCategories(category.id)
              .then((result) => {
                const foundItem = this.state?.categories?.find(
                  (x) => x?.id === category?.id
                );
                foundItem.subs = result?.map((i) => i.name);
                this.setState({ categories: this.state?.categories });
              });
          });
        }
      );
    });
  }

  loadBanners(): void {
    this.bannerApi.getBanners().then((banners) => {
      this.setState({ banners: banners });
    });
  }

  loadCampaigns(): void {
    Promise.all([this.campaignApi.getCampaigns()]).then((result) => {
      this.setState(
        {
          campaigns: result[0]
        },
        () => {
          this.state?.campaigns?.map((campaign) => {
            this.productApi
              .getFilteredProducts(campaign.id)
              .then((products) => {
                const campaignObj = {};
                campaignObj[campaign.id] = products;
                this.setState(campaignObj);
              });
          });
        }
      );
    });
  }

  /**
   * Load products for different campaigns.
   */
  loadCampaignProducts(): void {
    Promise.all([
      this.productApi.getFilteredProducts(null, null, null, null, null, null, 1, 10),
      this.productApi.getFilteredProducts(null, null, null, null, null, null, 1, 100),
    ])
    .then(result => {
      this.setState({
        latestDealsProducts: result[0],
        allBridepayDeals: result[1]
      });
    });
  }

  loadRecommendedAlbums(): void {
    Promise.all([
      this.albumApi.getRecommendedAlbums('albums'),
      this.albumApi.getRecommendedAlbums('menus')
    ]).then((result) => {
      this.setState({
        recommendedAlbums: result[0],
        recommendedMenus: result[1]
      });
    });
  }

  showLoginModal(): void {
    this.setState({ loginModalVisible: true });
  }

  /**
   * Logout current user and reload window afterwards.
   */
  logout(): void {
    this.authApi
      .logout()
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        notification.error({
          message: 'An error occurred while logging out.',
          className: 'antd-mod center'
        });
      });
  }

  redirectToAlbumShowPage(vendorSlug: string, albumSlug: string): void {
    window.open(`/${vendorSlug}/albums/${albumSlug}`);
  }

  redirectToProductDetails(slug: string): void {
    window.open(`/products/${slug}`);
  }

  renderOffersSlider(placement: 'top' | 'middle' | 'bottom') {
    let offersImages = [];

    const banners = this.state?.banners?.filter(
      (b) => b.placement == placement
    );
    if (banners && banners.length > 0) {
      offersImages = banners.map((banner) => (
        <img
          src={banner.image}
          alt={banner.title}
          key={banner.id}
          onClick={() => {
            this.bannerApi.increaseClickCount(banner.id);
            banner.link == 'undefined' ? null : window.open(banner.link);
          }}
        />
      ));
      return <OffersSlider offersImages={offersImages} />;
    }
  }

  renderIconLabels() {
    return (
      <div>
        <div className='icon-labels-container'>
          <div className='line-1'>
            <div className='icon-label' key={1}>
              <img
                className='icon'
                src={'/images/human-network.png'}
                alt='Icon Label'
              />
              <div className='label typo-subheading-1'>Hundreds of Vendors</div>
              <div className='divider' />
            </div>

            <div className='icon-label' key={2}>
              <img
                className='icon'
                src={'/images/calculator.png'}
                alt='Icon Label'
              />
              <div className='label typo-subheading-1'>Discounts</div>
              <div className='divider' />
            </div>

            <div className='icon-label' key={3}>
              <img
                className='icon'
                src={'/images/archive-search.png'}
                alt='Icon Label'
              />
              <div className='label typo-subheading-1'>220+ Sub-Categories</div>
              <div className='divider' />
            </div>

            <div className='icon-label' key={4}>
              <img
                className='icon'
                src={'/images/file-search.png'}
                alt='Icon Label'
              />
              <div className='label typo-subheading-1'>
                Variety of Wedding Deals
              </div>
            </div>
          </div>
        </div>
        <div className='icon-labels-container-mobile'>
          <div className='line-1'>
            <div className='icon-label' key={1}>
              <img
                className='icon'
                src={'/images/human-network.png'}
                alt='Icon Label'
              />
              <div className='label typo-subheading-1'>Hundreds of Vendors</div>
              <div className='divider' />
            </div>

            <div className='icon-label' key={2}>
              <img
                className='icon'
                src={'/images/calculator.png'}
                alt='Icon Label'
              />
              <div className='label typo-subheading-1'>Discounts</div>
            </div>
          </div>

          <div className='line-2'>
            <div className='icon-label' key={3}>
              <img
                className='icon'
                src={'/images/archive-search.png'}
                alt='Icon Label'
              />
              <div className='label typo-subheading-1'>220+ Sub-Categories</div>
              <div className='divider' />
            </div>

            <div className='icon-label' key={4}>
              <img
                className='icon'
                src={'/images/file-search.png'}
                alt='Icon Label'
              />
              <div className='label typo-subheading-1'>
                Variety of Wedding Deals
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderBridepayDealsByCategory(subtitle_type) {
    let subtitle;

    if (subtitle_type == 1) {
      subtitle =
        'Save more with Bridepay! Wedding Deals, Everyday! Select by Category!';
    } else {
      subtitle = "Can't find what you are looking for? Select by Category!";
    }

    return (
      <div className='page-section bridepay-deals-by-category'>
        <div className='content'>
          <div className='header'>
            <div className='row-1'>
              <div className='title typo-headline'>Bridepay Vendors</div>
            </div>
            <div className='row-2'>
              <div className='subtitle'>{subtitle}</div>
            </div>
          </div>

          <div className='labels-container'>
            {this.state?.categories?.map((category, categoryIndex) => (
              <LabelCard
                slug={category.slug}
                label={category.name}
                key={categoryIndex}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  renderEditorChoice() {
    const items = this.state?.recommendedAlbums?.map((album) => (
      <AlbumCard
        album={album}
        clicked={() => {
          this.redirectToAlbumShowPage(album?.vendor?.slug, album.slug);
        }}
        vendorName={album?.vendor?.businessName}
        detailsAlignment='left'
        key={album.slug}
      />
    ));

    return (
      <div className='page-section editors-choice'>
        <div className='content'>
          <ItemSlider
            title='Recommended Albums'
            subtitle='Editor’s Choice - Browse Wedding Albums by recommended Vendors'
            items={items}
            responsive={true}
          />
        </div>
      </div>
    );
  }

  renderBestSellingMenus() {
    const items = this.state?.recommendedMenus?.map((album) => (
      <AlbumCard
        album={album}
        clicked={() => {
          this.redirectToAlbumShowPage(album?.vendor?.slug, album.slug);
        }}
        vendorName={album?.vendor?.businessName}
        detailsAlignment='left'
        key={album.slug}
      />
    ));

    return (
      <div className='page-section best-selling-menus'>
        <div className='content'>
          <ItemSlider
            title='Recommended Menus'
            subtitle='Editor’s Choice - Browse Wedding Menus by recommended Vendors'
            items={items}
            responsive={true}
          />
        </div>
      </div>
    );
  }

  renderCampaigns() {
    const renderCampaignProducts = (campaignId) => {
      return this.state[campaignId]?.map((product) => (
        <ProductCard
          key={product.id}
          product={product}
          wishlistButtonDisabled={!this.props?.isAccountSignedIn}
          clicked={() => {
            this.redirectToProductDetails(product?.slug);
          }}
          wishlistStatusChanged={async (wishlistStatus) => {
            await this.productApi.updateWishlistStatus(
              product.id,
              wishlistStatus
            );
          }}
        />
      ));
    };

    return this.state?.campaigns?.map((campaign) => (
      <div className='page-section' key={campaign.id}>
        <div className='content'>
          <ItemSlider
            title={campaign.title}
            titleExtra={
              campaign.showCountdown && (
                <Countdown targetDate={new Date(campaign.campaignPeriodEnd)} />
              )
            }
            subtitle={campaign.subtitle}
            items={renderCampaignProducts(campaign.id)}
            showMoreLink={`/campaigns/${campaign.id}/products/all-bridepay-deals`}
          />
        </div>
      </div>
    ));
  }

  renderLatestDealsForYou() {
    const items = this.state?.latestDealsProducts?.map((product) => (
      <ProductCard
        key={product.id}
        product={product}
        wishlistButtonDisabled={!this.props?.isAccountSignedIn}
        clicked={() => {
          this.redirectToProductDetails(product?.slug);
        }}
        wishlistStatusChanged={async (wishlistStatus) => {
          await this.productApi.updateWishlistStatus(
            product.id,
            wishlistStatus
          );
        }}
      />
    ));

    return (
      <div className='page-section latest-deals-for-you'>
        <div className='content'>
          <ItemSlider
            title='Latest Deals For You'
            subtitle='Find the latest wedding services and products posted by Vendors'
            items={items}
            showMoreLink='/products/c/all-bridepay-deals'
          />
        </div>
      </div>
    );
  }

  renderAllBridepayFlashDeals() {
    return (
      <div className='page-section all-bridepay-flash-deals'>
        <div className='content'>
          <div className='header'>
            <div className='row-1'>
              <div className='title typo-headline'>All Bridepay Deals</div>
              <div className='filler'/>
            </div>

            <div className='row-2'>
              <div className='subtitle'>
                Find the most complete wedding products and packages only at
                Bridepay!
              </div>
            </div>
          </div>
          <div className='products'>
            {this.state?.allBridepayDeals?.map((product, productIndex) => (
              <ProductCard
                product={product}
                key={productIndex}
                wishlistButtonDisabled={!this.props?.isAccountSignedIn}
                size='large'
                clicked={() => {
                  this.redirectToProductDetails(product?.slug);
                }}
                wishlistStatusChanged={async (wishlistStatus) => {
                  await this.productApi.updateWishlistStatus(
                    product.id,
                    wishlistStatus
                  );
                }}
              />
            ))}
          </div>
          {/*<Row className='products' gutter={15}>
            {this.state?.allBridepayDeals?.map((product, productIndex) => (
                <Col xs={24} sm={12} md={8} lg={6} key={productIndex}>
                  <ProductCard
                      product={product}
                      key={productIndex}
                      wishlistButtonDisabled={!this.props?.isAccountSignedIn}
                      clicked={() => {
                        this.redirectToProductDetails(product?.slug);
                      }}
                      wishlistStatusChanged={async (wishlistStatus) => {
                        await this.productApi.updateWishlistStatus(
                            product.id,
                            wishlistStatus
                        );
                      }}
                  />
                </Col>
            ))}
          </Row>*/}
        </div>
      </div>
    );
  }

  categoriesAndSubCategories() {
    return (
      <div className='page-section categories-and-sub-categories'>
        <div className='content'>
          <div className='header'>
            <div className='typo-headline categoryTitle'>
              Categories & Sub-Categories
            </div>
          </div>

          <div className='categoryContent'>
            <ResponsiveMasonry
              columnsCountBreakPoints={{
                350: 1,
                410: 2,
                615: 3,
                820: 4,
                1000: 5
              }}
            >
              <Masonry columnsCount={5}>
                {this.state?.categories?.map((item, itemIndex) => {
                  return (
                    <div className='categoryWrap' key={itemIndex}>
                      <div className='categoryName'>
                        <a href={'/products/c/' + item?.slug}>{item?.name}</a>
                      </div>
                      <div className='subCategoryNameWrapper'>
                        {item?.subs?.map((sub, subIndex) => {
                          return (
                            <div className='subCategoryName' key={subIndex}>
                              <a href={'/products/c/' + item?.slug}>{sub}</a>
                              {subIndex === item?.subs?.length - 1 ? null : (
                                <span>|</span>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </Masonry>
            </ResponsiveMasonry>
          </div>

          {/*
          <div className="categoryContentMobile">
              <Swiper
              modules={[Pagination]}
              slidesPerView={1}
              slidesPerColumn={6}
              slidesPerColumnFill="row"
              pagination={{ clickable: false }}
              spaceBetween={5}  className="mySwiper">
                {
                this.state?.categories?.map((item, itemIndex) => (
                  <SwiperSlide>
                    {({ isActive }) => (
                      <div className="categoryWrap" key={itemIndex}>
                      <div className="categoryName">
                        <a href={"/products/c/" + item?.slug}>
                          {item?.name}
                        </a>
                      </div>
                      <div className="subCategoryNameWrapper">
                      {
                        item?.subs?.map((sub, subIndex) => {
                          return (
                            <div className="subCategoryName">
                              <a href={"/products/c/" + item?.slug}>
                                {sub}
                              </a>
                              { subIndex === item?.subs?.length - 1 ? (null) : (<span>|</span>) }
                            </div>
                          )
                        })
                      }
                      </div>
                    </div>
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
          </div>
          */}
        </div>
      </div>
    );
  }

  renderPageCoverSlider() {
    let pageCoverImages = [];

    const banners = this.state?.banners?.filter((b) => b.placement == 'middle');
    if (banners && banners.length > 0) {
      pageCoverImages = banners.map((banner) => (
        <img
          loading='lazy'
          src={banner.image}
          alt={banner.title}
          key={banner.id}
          onClick={() => {
            this.bannerApi.increaseClickCount(banner.id);
            window.open(banner.link);
          }}
        />
      ));
      return <PageCoverSlider pageCoverImages={pageCoverImages} />;
    }
  }

  render() {
    return (
      <div className='HomePage'>
        <PageAlert
          texts={[
            'Become A Vendor Now! Email kris@bridepay.com',
            'Best-viewed with Large Screen - Desktop or Laptop'
          ]}
          link='mailto:kris@bridepay.com'
        />

        <Navbar
          isAccountSignedIn={this.props?.isAccountSignedIn}
          user={this.state?.loggedOnUser}
          activeNavItem={'home'}
          signInRequired={(redirectUrl) => {
            this.setState({
              loginModalVisible: true,
              loginModalRedirectUrl: redirectUrl
            });
          }}
        />

        <LoginModal
          visible={this.state?.loginModalVisible}
          googleSignInUrl={this.props?.googleSignInUrl}
          fbSignInUrl={this.props?.fbSignInUrl}
          csrfToken={this.props?.csrfToken}
          redirectUrl={this.state?.loginModalRedirectUrl}
          forgotPasswordClicked={() => {
            this.setState({
              loginModalVisible: false,
              forgotPasswordModalVisible: true
            });
          }}
          closed={() => {
            this.setState({ loginModalVisible: false });
          }}
          registerLink='/registration'
        />

        <ForgotPasswordModal
          visible={this.state?.forgotPasswordModalVisible}
          loginClicked={() => {
            this.setState({
              forgotPasswordModalVisible: false,
              loginModalVisible: true
            });
          }}
          closed={() => {
            this.setState({ forgotPasswordModalVisible: false });
          }}
        />

        {this.renderOffersSlider('top')}
        {this.renderIconLabels()}
        {this.renderBridepayDealsByCategory(1)}
        {this.renderEditorChoice()}
        {this.renderCampaigns()}
        {this.renderPageCoverSlider()}
        {this.renderLatestDealsForYou()}
        {this.renderBestSellingMenus()}
        {this.renderOffersSlider('bottom')}
        {this.renderBridepayDealsByCategory(2)}
        {this.renderAllBridepayFlashDeals()}
        {this.categoriesAndSubCategories()}
        <Footer userType={this.state?.loggedOnUser?.type} />
      </div>
    );
  }
}

export default HomePage;
