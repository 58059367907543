// import './VendorRegistrationPage.scss';

import * as React from 'react';
import { notification } from 'antd';

import { Country } from '../../../interfaces/country.interface';
import CountryApi from '../../../api-services/country-api.service';

import VendorRegistrationForm from '../../basic-components/vendor-registration-form/VendorRegistrationForm';
import VendorNavbar from '../../basic-components/vendor-navbar/VendorNavbar';
import VendorLoginModal from '../../modals/vendor-login-modal/VendorLoginModal';
import ForgotPasswordModal from '../../modals/forgot-password-modal/ForgotPasswordModal';


notification.config({
  duration: 12,
  top: 60
})


interface VendorRegistrationPageProps {
  csrfToken: string;
  isAccountSignedIn: boolean;
}

interface VendorRegistrationPageState {
  loginModalVisible: boolean;
  forgotPasswordModalVisible: boolean;
  countries: Country[];
}

class VendorRegistrationPage extends React.Component <VendorRegistrationPageProps, VendorRegistrationPageState> {

  
  componentDidMount(){
    setTimeout(() => {
      this.loadCountries();
    }, 500);
  }
  

  loadCountries(): void {
    const countryApi = new CountryApi;

    countryApi.getCountries()
    .then(countries => {
      this.setState({ countries: countries });
    })
    .catch(error => {
      notification.error({ message: 'An error occured while loading the data for countries', className: 'antd-mod center' });
    })
  }

  showLoginModal(): void {
    this.setState({ loginModalVisible: true });
  }


  closeLoginModal(): void {
    this.setState({ loginModalVisible: false });
  }


  showPasswordModal(): void {
    this.setState({ loginModalVisible: false, forgotPasswordModalVisible: true }) 
  }


  render() {
    return (
      <div className="VendorRegistrationPage">
        <VendorNavbar isAccountSignedIn={this.props?.isAccountSignedIn}></VendorNavbar>
        <div className="VendorRegistrationContent">
          <VendorLoginModal 
            visible={this.state?.loginModalVisible} 
            forgotPasswordClicked={() => {this.showPasswordModal()}}  
            closeLoginModal={this.closeLoginModal.bind(this)} 
            csrfToken={this.props.csrfToken}>
          </VendorLoginModal>
          
          <ForgotPasswordModal
            visible={this.state?.forgotPasswordModalVisible} 
            loginClicked={() => { this.setState({ forgotPasswordModalVisible: false, loginModalVisible: true }) }} 
            closed={() => { this.setState({ forgotPasswordModalVisible: false }) }} 
          ></ForgotPasswordModal>

          <VendorRegistrationForm countries={this.state?.countries} loginMethod={this.showLoginModal.bind(this)}></VendorRegistrationForm>
        </div>
      </div>
    );
  }

}

export default VendorRegistrationPage;
