// import './VendorProfileSetupStepsPage.scss';

import * as React from 'react';
import { notification } from 'antd';

import { Country } from '../../../interfaces/country.interface';
import { ServiceCategory } from '../../../interfaces/service-category.interface';

import CountryApi from '../../../api-services/country-api.service';
import VendorApi from '../../../api-services/vendor-api.service';

import BusinessInfoStepForm from '../../basic-components/business-info-step-form/BusinessInfoStepForm';
import ServiceCategoryApi from '../../../api-services/service-category-api.service';
import { Vendor } from '../../../interfaces/vendor-interface';
import VendorRegistrationGreetingModal from '../../modals/vendor-registration-greeting-modal/VendorRegistrationGreetingModal';
import { City } from '../../../interfaces/city.interface';



notification.config({
  duration: 12,
  top: 60
})


interface VendorProfileSetupStepsPageProps {
  isAccountSignedIn: boolean;
}


interface VendorProfileSetupStepsPageState {
  vendor: Vendor;
  currentFormStep: number;
  loading: boolean;
  countries: Country[];
  cities: City[];
  categories: ServiceCategory[];
  greetingsModalVisible: boolean;
}



class VendorProfileSetupStepsPage extends React.Component<VendorProfileSetupStepsPageProps, VendorProfileSetupStepsPageState> {

  countryApi = new CountryApi;
  phoneVerifyFormFormRef;
  
  
  componentDidMount() {
    this.setState({
      currentFormStep: 1,
      loading: false,
      countries: [],
      cities: [],
      categories: [],
      greetingsModalVisible: false
    });

    setTimeout(() => {
      this.loadSignedInUserProfile();
      this.loadCategories();
    }, 500);
  }
  


  loadSignedInUserProfile(): void {
    if(!this.props?.isAccountSignedIn) {
      return null;
    }
    
    const vendorApi = new VendorApi;

    vendorApi.getVendorProfile()
    .then(user => {
      this.setState({ vendor: user });
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: 'Error while loading the vendor\'s profile.', className: 'antd-mod center' });
    });
  }


  loadCategories(): void {
    const categoryApi = new ServiceCategoryApi;

    categoryApi.getCategories()
    .then(categories => {
      this.setState({ categories: categories });
    })
    .catch(error => {
      notification.error({ message: 'An error occured while loading the data for categories', className: 'antd-mod center' });
    })
  }


  renderBusinessInfoForm() {
    return (
      <div className="form-step" style={{ display: (this.state?.currentFormStep === 1) ? '':'none' }}>
        <BusinessInfoStepForm 
          categories={this.state?.categories}
          vendor={this.state?.vendor} 
          saved={() => this.setState({ greetingsModalVisible: true })}
          cancelled={() => { window.location.href = '/'; }} 
        ></BusinessInfoStepForm>
      </div>
    );
  }

  
  render() {
    return (
      <div className="VendorProfileSetupStepsPage">
        <div className="main-content">
          <div className="logo-container">
            <img src="/images/bridepay-for-business-logo.png" alt="Bridepay Logo"/>
          </div>
          
          {this.renderBusinessInfoForm()}
        </div>

        <VendorRegistrationGreetingModal visible={this.state?.greetingsModalVisible} closed={() => { window.location.href = '/business/dashboard/edit'; }}></VendorRegistrationGreetingModal>
      </div>
    );
  }
  
}




export default VendorProfileSetupStepsPage;