import axios from 'axios';
import { State } from '../interfaces/state.interface';


class StateApi {

  /* params: countryISO - iso code for country */
  /* params: keyword - state keyword */
  searchStatesByCountryAndCity(countryISO:string, keyword: string = "", perPage: number = 100): Promise<State[]> {
    const apiUrl = `/api/v1/states?country=${countryISO}&keyword=${keyword}&per_page=${perPage}`;

    return axios.get(apiUrl)
    .then(response => {
      const cities: State[] = [];

      response?.data?.data?.forEach(cityData => {
        cities.push({
          id: cityData?.attributes?.id,
          name: cityData?.attributes?.name,
          code: cityData?.attributes?.code,
        });
      });
      
      return Promise.resolve(cities);
    });
  }
  
}



export default StateApi;