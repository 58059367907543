import * as React from "react"
import { Button, Table, Image } from "antd";
import { BlogCategory } from "../../../interfaces/blog-category.interface";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";


interface IAdminBlogCategoriesTableProps {
  parentCategories:BlogCategory[];
  categories:BlogCategory[];
  editCategory: (category: BlogCategory)=>void;
  removeCategory: (id:number)=>void;
  onChange: (pagination:any)=>void;
  pagination: any;
  hideAction: boolean;
}

interface IAdminBlogCategoriesTableState {
  columns: any[];
}

class AdminBlogCategoriesTable extends React.Component <IAdminBlogCategoriesTableProps, IAdminBlogCategoriesTableState> {

  getParentName(ancestry: string): string {
    let result = '';

    if (ancestry) {
      let ids = ancestry.split('/');

      ids.forEach(id => {
        result += `, ${this.props?.parentCategories?.find(o => o.id === Number(id))?.name}`;
      });
      result = result.slice(2)
    }
    return result;
  }

  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'Image',
          dataIndex: 'image',
          width: 50,
          render: (text) => {
            return <Image width={50} src={text}/>
          }
        },
        {
          title: 'Name',
          dataIndex: 'name'
        },
        {
          title: 'Parent',
          dataIndex: 'ancestry',
          render: (text) => {
            return this.getParentName(text)
          }
        },
        {
          title: 'Position',
          dataIndex: 'position'
        },
        {
          title: 'Show in homepage',
          dataIndex: 'showInHomepage',
          render: (text) => {
            return (
              text ? <CheckOutlined twoToneColor="#52c41a"/> : <CloseOutlined twoToneColor="#ff4d4f"/>
            )
          }
        },
        {
          title: 'Show in nav button',
          dataIndex: 'showInNavButton',
          render: (text) => {
            return (
              text ? <CheckOutlined twoToneColor="#52c41a"/> : <CloseOutlined twoToneColor="#ff4d4f"/>
            )
          }
        },
        {
          title: 'Action',
          key: 'operation',
          width: 200,
          render: (text, record) => {
            return <>
              <Button type="link" onClick={()=>this.props?.editCategory(record)}>Edit</Button>
              <Button type="link" danger onClick={()=>this.props?.removeCategory(record.id)}>Remove</Button>
            </>
          },
        },
      ]
    };
  }

  componentDidMount() {
    if (this.props?.hideAction){
      this.setState({columns: [
          {
            title: 'Image',
            dataIndex: 'image',
            width: 50,
            render: (text) => {
              return <Image width={50} src={text}/>
            }
          },
          {
            title: 'Name',
            dataIndex: 'name'
          },
          {
            title: 'Parent',
            dataIndex: 'ancestry',
            render: (text) => {
              return this.getParentName(text)
            }
          },
          {
            title: 'Position',
            dataIndex: 'position'
          },
          {
            title: 'Show in homepage',
            dataIndex: 'showInHomepage',
            render: (text) => {
              return (
                text ? <CheckOutlined twoToneColor="#52c41a"/> : <CloseOutlined twoToneColor="#ff4d4f"/>
              )
            }
          },
          {
            title: 'Show in nav button',
            dataIndex: 'showInNavButton',
            render: (text) => {
              return (
                text ? <CheckOutlined twoToneColor="#52c41a"/> : <CloseOutlined twoToneColor="#ff4d4f"/>
              )
            }
          }
        ]});
    } else {
      this.setState({columns: [
          {
            title: 'Image',
            dataIndex: 'image',
            width: 50,
            render: (text) => {
              return <Image width={50} src={text}/>
            }
          },
          {
            title: 'Name',
            dataIndex: 'name'
          },
          {
            title: 'Parent',
            dataIndex: 'ancestry',
            render: (text) => {
              return this.getParentName(text)
            }
          },
          {
            title: 'Position',
            dataIndex: 'position'
          },
          {
            title: 'Show in homepage',
            dataIndex: 'showInHomepage',
            render: (text) => {
              return (
                text ? <CheckOutlined twoToneColor="#52c41a"/> : <CloseOutlined twoToneColor="#ff4d4f"/>
              )
            }
          },
          {
            title: 'Show in nav button',
            dataIndex: 'showInNavButton',
            render: (text) => {
              return (
                text ? <CheckOutlined twoToneColor="#52c41a"/> : <CloseOutlined twoToneColor="#ff4d4f"/>
              )
            }
          },
          {
            title: 'Action',
            key: 'operation',
            width: 200,
            render: (text, record) => {
              return <>
                <Button type="link" onClick={()=>this.props?.editCategory(record)}>Edit</Button>
                <Button type="link" danger onClick={()=>this.props?.removeCategory(record.id)}>Remove</Button>
              </>
            },
          },
        ]});
    }
  }


  render() {
    return (
      <div className="AdminBannersTable">
        <Table size="small" bordered rowKey="id"
          columns={this.state?.columns}
          dataSource={this.props?.categories}
          pagination={this.props?.pagination}
          onChange={this.props?.onChange}
        />
      </div>
    );
  }
}

export default AdminBlogCategoriesTable

