// import './GenericFilter.scss';

import * as React from 'react';


interface GenericFilterProps {
  title: string;
  filterItems: GenericFilterItem[];

  filterItemSelected?: (selectedItem: GenericFilterItem) => void;
}


interface GenericFilterState {

}


interface GenericFilterItem {
  value: number;
  label: string;
  selected?: boolean;
}



class GenericFilter extends React.Component<GenericFilterProps, GenericFilterState> {


  setSelectedFilterItem(clickedFilterItem: GenericFilterItem): void {
    this.props?.filterItems?.forEach(filterItem => {
      if(clickedFilterItem.value === filterItem.value) {
        filterItem.selected = true;
      } else {
        filterItem.selected = false;
      }
    });
  }


  emitFilterItemSelected(filterItem: GenericFilterItem): void {
    if(this.props?.filterItemSelected) {
      this.props?.filterItemSelected(filterItem);
    }
  }
  
  
  renderFilterItem(filterItem: GenericFilterItem) {
    const selectedClassName: string = (filterItem.selected) ? 'selected' : '';
    
    return (
      <div 
        className={`filter-item ${selectedClassName}`} key={filterItem.value}
        onClick={() => { this.emitFilterItemSelected(filterItem); this.setSelectedFilterItem(filterItem); this.setState({}); }} 
      >
        <span className="typo-bold-2">{filterItem.label}</span>
      </div>
    );
  }
  
  
  render() {
    return (
      <div className="GenericFilter">
        <div className="title typo-headline typo-bold">{this.props?.title}</div>

        <div className="filter-items-container">
          {this.props?.filterItems?.map(filterItem => this.renderFilterItem(filterItem))}
        </div>
      </div>
    )
  }
  
}





export default GenericFilter;