// import './VendorProductsTab.scss';

import * as React from 'react';

import { Product } from '../../../interfaces/product.interface';
import { Vendor } from '../../../interfaces/vendor-interface';

import ProductApi from '../../../api-services/product-api.service';

import ProductCard from '../../basic-components/product-card/ProductCard';
import Countdown from '../../ui-components/countdown/Countdown';
import ItemSlider from '../../basic-components/item-slider/ItemSlider';
import { Campaign } from '../../../interfaces/campaign.interface';
import CampaignApi from '../../../api-services/campaign-api.service';


interface VendorProductsTabProps {
  vendor: Vendor;
  isAccountSignedIn?: boolean;
}


interface VendorProductsTabState {
  campaigns: Campaign[];
}




class VendorProductsTab extends React.Component<VendorProductsTabProps, VendorProductsTabState> {

  productApi = new ProductApi();
  campaignApi = new CampaignApi();

  componentDidMount(): void {
    this.loadCampaigns();
  }


  /**
   * Load products for different campaigns.
   */
   loadCampaigns(): void {
    Promise.all([
      this.campaignApi.getCampaigns()
    ])
    .then(result => {
      this.setState({
        campaigns: result[0]
      }, () => {
        this.state?.campaigns?.map((campaign) => {
          this.productApi.getFilteredProducts(campaign.id, this.props?.vendor?.slug)
          .then((products) => {
            var campaignObj = {};
            campaignObj[campaign.id] = products;
            this.setState(campaignObj);
          })
        })
      });
    });
  }


  redirectToProductDetails(slug: string): void {
    window.open(`/products/${slug}`);
  }



  renderCampaigns(){
    const renderCampaignProducts = (campaignId) => {
      return this.state[campaignId]?.map(product => 
        <ProductCard 
          key={product.id} 
          product={product} 
          wishlistButtonDisabled={!this.props?.isAccountSignedIn}
          clicked={() => { this.redirectToProductDetails(product?.slug) }} 
          wishlistStatusChanged={(wishlistStatus) => { this.productApi.updateWishlistStatus(product.id, wishlistStatus) }}
        />
      );
    }
    
    return this.state?.campaigns?.map(campaign => {
      const items = this.state[campaign.id];
      if(items && items.length > 0){
        return (
          <div className="page-section" key={campaign.id}>
            <div className="content desktop">
              <ItemSlider title={campaign.title}
                          subtitle={campaign.subtitle}
                          items={renderCampaignProducts(campaign.id)}
              />
            </div>
            <div className="content mobile">
              <div>
                <div className="title">{campaign.title}</div>
                <div className="subtitle">{campaign.subtitle}</div>
              </div>
              {renderCampaignProducts(campaign.id)}
            </div>
          </div>
        )
      } else {
        return null;
      }
    });
  }


  render() {
    return (
      <div className="VendorProductsTab">
        {this.renderCampaigns()}
      </div>
    );
  }
  
}




export default VendorProductsTab;
