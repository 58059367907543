// import './ProductSetupPage.scss';

import * as React from "react"

import { Button, notification} from "antd";
import { LeftOutlined } from '@ant-design/icons'; 

import { ServiceCategory } from "../../../interfaces/service-category.interface";
import { City } from "../../../interfaces/city.interface";
import { Country } from "../../../interfaces/country.interface";
import { Location } from "../../../interfaces/location.interface";
import ServiceCategoryApi from "../../../api-services/service-category-api.service";
import CountryApi from "../../../api-services/country-api.service";

import ProductSetupForm from "../../basic-components/product-setup-form/ProductSetupForm";
import ProductApi from "../../../api-services/product-api.service";
import { Product, ProductImage } from "../../../interfaces/product.interface";
import CampaignApi from "../../../api-services/campaign-api.service";
import { Campaign } from "../../../interfaces/campaign.interface";
import VendorApi from "../../../api-services/vendor-api.service";
import { Vendor } from "../../../interfaces/vendor-interface";
import VendorNavbar from "../../basic-components/vendor-navbar/VendorNavbar";
import CalendarApi from "../../../api-services/calendar-api.service";
import { Calendar } from "../../../interfaces/calendar.interface";
import LocationApi from "../../../api-services/location-api.service";


notification.config({
  duration: 12,
  top: 60
})


interface IProductSetupPageProps {
  vendorServiceCategory?:ServiceCategory;
  productId?:number;
}

interface IProductSetupPageState {
  product:Product;
  images: ProductImage[];
  categories: ServiceCategory[];
  countries: Country[];
  locations: Location[];
  cities: City[];
  campaigns: Campaign[];
  mode: "add" | "edit";
  vendor: Vendor;
  calendars: Calendar[];
}

class ProductSetupPage extends React.Component <IProductSetupPageProps, IProductSetupPageState> {
  
  countryApi = new CountryApi;
  productApi = new ProductApi;
  campaignApi = new CampaignApi;
  calendarApi = new CalendarApi;
  locationApi = new LocationApi;


  componentDidMount(){
    this.setState({
      categories: [],
      cities: [],
      countries: [],
      campaigns: [],
      calendars: [],
      mode: "add"
    });

    this.loadSignedInUserProfile()

    if(this.props?.productId){
      this.getProduct();
    }

    setTimeout(() => {
      this.loadCategories();
      // this.loadCampaigns();
      this.loadCalendars();
    }, 500);

    setTimeout(() => {
      this.loadLocations();
    }, 500);
  }





  /**
   * Get the signed in user's profile from server if there is a user signed in.
   */
   loadSignedInUserProfile(): void {
    const vendorApi = new VendorApi;

    vendorApi.getVendorProfile()
    .then(user => {
      this.setState({ vendor: user });
    })
    .catch(error => {
      notification.error({ message: 'Error while loading the vendor\'s profile.', className: 'antd-mod center' });
    });
  }
  


  loadCategories(): void {
    const categoryApi = new ServiceCategoryApi;
    categoryApi.getSubCategories(this.props?.vendorServiceCategory?.id)
    .then(categories => {
      this.setState({ categories: categories });
    })
    .catch(error => {
      notification.error({ message: 'An error occured while loading the data for categories', className: 'antd-mod center' });
    })
  }



  loadLocations(): void {
    this.locationApi.getLocations()
    .then(locations => {
      this.setState({ locations: locations });
    })
    .catch(error => {
      notification.error({ message: 'An error occured while loading the data for countries', className: 'antd-mod center' });
    })
  }
  

  loadCities(countryISO:string = "MY"): void{
    this.countryApi.getCities(countryISO)
    .then(citiesData => this.setState({cities: citiesData}))
    .catch(error => {
      notification.error({ message: 'An error occured while loading the data for cities', className: 'antd-mod center' });
    });
  }
  

  loadCampaigns(): void{
    const campaignId = this.state?.product?.campaign || 3;
    const editCampaignUrl = "edit_product&campaign_id="+campaignId;
    this.campaignApi.getCampaigns(editCampaignUrl)
    .then(campaignsData => this.setState({campaigns: campaignsData}))
    .catch(error => {
      notification.error({ message: 'An error occured while loading the data for campaigns', className: 'antd-mod center' });
    });
  }
  

  loadCalendars(): void{
    this.calendarApi.getCalendars()
    .then(calendarsData => this.setState({calendars: calendarsData}))
    .catch(error => {
      notification.error({ message: 'An error occured while loading the data for calendars', className: 'antd-mod center' });
    });
  }



  getProduct(){
    const productId = this.props?.productId;

    this.productApi.getProduct(productId)
      .then(productResponse => {
        this.setState({product: productResponse});
        this.loadCampaigns();
        return this.productApi.getImages(productId);
      })
      .then(images => {
        this.setState({images: images});
      })
      .catch(error => {
        notification.error({ message: `Error while loading the vendor\'s products.`, className: 'antd-mod center' });
      })
      .finally(()=>{
        this.setState({mode: "edit"})
      })
  }


  redirectToProducts(){
    window.location.href = '/business/dashboard/products';
  }


  render() {
    return (
      <div className="ProductSetupPage">
        {/* <VendorNavbar isAccountSignedIn={true} vendor={this.state?.vendor}></VendorNavbar> */}
        
        <div className="main-content">
          <Button type="link" className="back-to-link typo-bold" icon={<LeftOutlined />} onClick={()=>this.redirectToProducts()}>Back to Products</Button>

          <ProductSetupForm product={this.state?.product} savedImages={this.state?.images} categories={this.state?.categories} saved={()=>this.redirectToProducts()}
            campaigns={this.state?.campaigns} calendars={this.state?.calendars} locations={this.state?.locations} getLocations={this.loadLocations.bind(this)} 
            primaryServiceCategory={this.props?.vendorServiceCategory?.name}/>
        </div>
      </div>
    );
  }
}

export default ProductSetupPage
