// import './MenuSetupPage.scss';

import * as React from "react"
import {startCase} from "lodash"

import { Button, Form, FormInstance, Input, notification, Space } from "antd";
import { LeftOutlined } from '@ant-design/icons'; 

import AlbumApi from "../../../api-services/album-api.service";
import { AlbumMedium } from "../../../interfaces/album.interface";
import MultipleImageUploader from "../../ui-components/multiple-image-uploader/MultipleImageUploader";
import VendorNavbar from "../../basic-components/vendor-navbar/VendorNavbar";
import VendorApi from "../../../api-services/vendor-api.service";
import { Vendor } from "../../../interfaces/vendor-interface";
import ReactQuill from 'react-quill';


const modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }]
  ],
}



const maxImageCount = 30;
notification.config({
  duration: 12,
  top: 60
})


interface IMenuSetupPageProps {
  albumId?:number;
}

interface IMenuSetupPageState {
  mode: "add" | "edit";
  savingAlbum: boolean;
  publishingAlbum: boolean;
  uploadedImages: AlbumMedium[];
  vendor: Vendor;
  description: string;
}

class MenuSetupPage extends React.Component <IMenuSetupPageProps, IMenuSetupPageState> {
  
  albumApi = new AlbumApi;
  albumSetupForm = React.createRef<FormInstance>();


  componentDidMount(){
    this.setState({
      mode: "add",
      uploadedImages: []
    });

    this.loadSignedInUserProfile();

    if(this.props?.albumId){
      this.getAlbum();
    }
  }





  /**
   * Get the signed in user's profile from server if there is a user signed in.
   */
   loadSignedInUserProfile(): void {
    const vendorApi = new VendorApi;

    vendorApi.getVendorProfile()
    .then(user => {
      this.setState({ vendor: user });
    })
    .catch(error => {
      notification.error({ message: 'Error while loading the vendor\'s profile.', className: 'antd-mod center' });
    });
  }

  capitalize(str){
    return str.replace(/\b(\w)/g, s => s.toUpperCase());
  }



  getAlbum(){
    const albumId = this.props?.albumId;

    this.albumApi.getAlbum(albumId)
      .then(albumResponse => {
        this.populateAlbumDetailsForm(albumResponse);

        return this.albumApi.getMediums(albumId);
      })
      .then(mediumResponse => {
        this.setState({uploadedImages: mediumResponse});
      })
      .catch(error => {
        notification.error({ message: `Error while loading the vendor\'s menus.`, className: 'antd-mod center' });
      })
      .finally(()=>{
        this.setState({mode: "edit"})
      })
  }


  populateAlbumDetailsForm(album){
    this.albumSetupForm.current.setFieldsValue({
      title: album.title
    });

    this.setState({description: album.description})
  }


  redirectToAlbums(){
    window.location.href = `/business/dashboard/menus`;
  }


  saveAlbum(){
    const formEvent = this.albumSetupForm.current!.getFieldsValue();
    const payload = {
      title: formEvent.title,
      description: this.state?.description
    }
    
    this.setState({savingAlbum: true})

    this.albumApi.updateAlbum(this.props?.albumId, payload)
      .then(albumResponse => {
        notification.success({ message: 'Changes saved successfully', className: 'antd-mod center' });
      })
      .catch(error => {
        console.error(error);
  
        const errorKeys = Object.keys(error?.response?.data?.errors);
        errorKeys.forEach(errorKeyName => {
          const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
          notification.error({ message: errorMessage, className: 'antd-mod center' });
        });
      })
      .finally(()=>{
        this.setState({savingAlbum: false})
      })
  }


  publishAlbum(){
    const disablePublish = this.state?.uploadedImages.length<4
    if(disablePublish){
      notification.error({ message: 'Needs to have at least 4 photos to be published', className: 'antd-mod center' });
    }else{
      this.setState({publishingAlbum: true})
  
      this.albumApi.publishAlbum(this.props?.albumId)
        .then(albumResponse => {
          this.redirectToAlbums();
        })
        .catch(error => {
          const errorKeys = Object.keys(error?.response?.data?.errors);
          errorKeys.forEach(errorKeyName => {
            const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
            notification.error({ message: errorMessage, className: 'antd-mod center' });
          });
        })
        .finally(()=>{
          this.setState({publishingAlbum: false})
        })
    }


  }

  addToImages(image){
    this.setState(prevState => ({
      uploadedImages: [...prevState.uploadedImages, image]
    }));
  }


  removeFromImages(imageId){
    const albumApi = new AlbumApi;

    albumApi.deleteMediums(imageId)
      .then(response => {
        this.setState(prevState => ({
          uploadedImages: prevState.uploadedImages.filter(image => image.id !== imageId)
        }));
      })
      .catch(err=>{
        notification.error({ message: 'Error while removing photo.', className: 'antd-mod center' });
      });
  }


  renderHeader(){
    const title = (this.state?.mode == "add" ? "Add New " : "Edit ") + "Menu";

    return (
      <div className="header sticky-header">
        <div>
          <div className="typo-title typo-bold title">{title}</div>
          <Button type="default" onClick={()=>this.saveAlbum()}>Save Draft</Button>
        </div>
      </div>
    )
  }


  renderBasicDetails(){
    return (
        <div className="basic-details-section">
          <div className="typo-subheading-2 typo-bold section-header">MENU DETAILS</div>
          <Form ref={this.albumSetupForm} layout="vertical" autoComplete="off">
            <Form.Item name="title" label="Menu Name" rules={[{ required: true, message: 'Name is required' }]}>
              <Input size="large" placeholder="Enter your menu name"/>
            </Form.Item>
            <Form.Item name="description" label="Describe your Menu">
              <div className="text-editor">
                <ReactQuill value={this.state?.description || ""} onChange={(val)=>this.setState({description: val})} theme="snow" modules={modules} placeholder="Sufficient information is the key success to selling your product. Otherwise, it will be difficult for customers to make buying decisions."/>
              </div>
            </Form.Item>
          </Form>
        </div>
    );
  }


  renderMediumUpload(){
    return (
      <section id="images">
        <div className="typo-subheading-2 typo-bold section-header">PHOTOS</div>
        <small className="note">Please upload a minimum of 4 photos and up to a maximum of 30 photos per album. JPEG or PNG files only (maximum 8 MB per photo)</small>
        <MultipleImageUploader croppable={false} uploadImageApi={`/api/v1/albums/my/${this.props?.albumId}/mediums?type=menus`} savedImages={this.state?.uploadedImages} 
          addImage={(newImage)=>this.addToImages(newImage)} removeImage={(imageId)=>this.removeFromImages(imageId)}/>
      </section>
    )
  }


  render() {
    return (
      <div className="MenuSetupPage">
        <VendorNavbar isAccountSignedIn={true} vendor={this.state?.vendor}></VendorNavbar>

        <div className="main-content">
          <Button type="link" className="back-to-link typo-bold" icon={<LeftOutlined />} onClick={()=>this.redirectToAlbums()}>Back to Menus</Button>
          
          <div className="album-form">
            
            { this.renderBasicDetails() }

            <div className="upload-section">
              { this.renderMediumUpload() }
            </div>

            <div className="form-actions">
              <Space>
                <Button type="primary" loading={this.state?.savingAlbum} className="antd-mod" size="large" onClick={()=>this.saveAlbum()}>
                  Save
                </Button>
                <Button type="primary" loading={this.state?.publishingAlbum} className="antd-mod" size="large" onClick={()=>this.publishAlbum()}>
                  Publish
                </Button>
              </Space>
            </div>

          </div>

        </div>
        
      </div>
    );
  }
}

export default MenuSetupPage
