// import './ProductDetailsPage.scss';

import * as React from 'react';
import { read_cookie } from 'sfcookies';
import { Button, notification, Rate } from 'antd';
import { ShareAltOutlined, FacebookFilled, InstagramFilled, TwitterSquareFilled, YoutubeFilled, LinkedinFilled } from '@ant-design/icons';

import { Product } from '../../../interfaces/product.interface';
import { User } from '../../../interfaces/user.interface';
import { ProductVariant } from '../../../interfaces/product-variant.interface';

import UserApi from '../../../api-services/user-api.service';
import ProductApi from '../../../api-services/product-api.service';

import Tag from '../../ui-components/tag/Tag';
import Footer from '../../basic-components/footer/Footer';
import Navbar from '../../basic-components/navbar/Navbar';
import ProductCard from '../../basic-components/product-card/ProductCard';
import BreadcrumbsNavigation from '../../basic-components/breadcrumbs-navigation/BreadcrumbsNavigation';
import AlbumCover from '../../basic-components/album-cover/AlbumCover';
import ItemSlider from '../../basic-components/item-slider/ItemSlider';
import ProductControlsCard from '../../basic-components/product-controls-card/ProductControlsCard';
import LoginModal from '../../modals/login-modal/LoginModal';
import GalleryModal from '../../modals/gallery-modal/GalleryModal';
import AgeConfirmationModal from '../../modals/age-confirmation-modal/AgeConfirmationModal';
import ForgotPasswordModal from '../../modals/forgot-password-modal/ForgotPasswordModal';
import AppointmentModal from "../../modals/appointment-modal/AppointmentModal";
import SocialShare from "../../basic-components/social-share/SocialShare";

notification.config({
  duration: 12,
  top: 60
})

interface ProductDetailsPageProps {
  isAccountSignedIn: boolean;
  googleSignInUrl: string;
  fbSignInUrl: string;
  csrfToken: string;
  productData?: any;
}

interface ProductDetailsPageState {
  product: Product;
  loggedOnUser: User;
  loginModalVisible: boolean;
  loginModalRedirectUrl: string;
  otherPackagesByThisVendor: Product[];
  relatedPackagesYouMightLike: Product[];
  breadcrumbsNavItems: Array<{ id?: number; label: string; link?: string }>;
  galleryModalVisible: boolean;
  ageConfirmationModalVisible: boolean;
  forgotPasswordModalVisible: boolean;
  appointmentModalVisible: boolean;
}

class ProductDetailsPage extends React.Component<ProductDetailsPageProps, ProductDetailsPageState> {
  userApi = new UserApi();
  productApi = new ProductApi()

  componentWillMount() {
    this.mapIntoProductObject(this.props?.productData);
  }

  componentDidMount(): void {
    this.setState({
      loginModalVisible: false,
      forgotPasswordModalVisible: false,
      appointmentModalVisible: false,
    });
    const ageConfirmed = read_cookie("age_21_and_non_muslim");
    this.setState({
      ageConfirmationModalVisible: (this.state?.product?.category?.slug == "wines-alcohol") && (ageConfirmed.length == 0 || JSON.parse(ageConfirmed) == false)
    });
    this.productApi.increaseViewCount(this.props?.productData?.id);
    this.loadSignedInUserProfile();
    this.loadProducts();
    this.setBreadcrumbsNavItems();
  }

  mapIntoProductObject(productData: any) {
    const product: Product = {
      id: productData.id,
      availabilityEnd: new Date(productData.availability_end),
      availabilityStart: new Date(productData.availability_start),
      description: productData.description,
      details: productData.details,
      defaultVariantValue: productData.default_variant,
      discountAmount: productData.discount_amount,
      discountPercentage: productData.discount_percent,
      discountedPrice: productData.discounted_price,
      easyReschedule: productData.flexi_reschedule,
      images: productData.image_urls,
      inStock: productData.in_stock,
      isPhysical: productData.is_physical,
      name: productData.name,
      option: productData.option,
      requirePartialPayment: productData.partial_payment_required,
      partialPaymentType: productData.partial_payment_type,
      paymentTerms: productData.payment_terms,
      price: productData.price,
      purchasable: productData.purchasable,
      partialPayment: productData.required_partial_payment,
      sku: productData.sku,
      wishlisted: productData?.like,
      slug: productData.slug,
      status: productData.status,
      terms: productData.terms_and_conditions,
      quantity: productData.total_qty_on_hand,
      trackInventory: productData.track_inventory,
      unit: productData.unit,
      rating: productData.rating,
      reviews: productData.reviews,
      campaign: {
        id: productData.campaign_id,
      },
      category: {
        id: productData.category?.id,
        name: productData.category?.name,
        slug: productData.category?.slug,
      },
      location: {
        id: productData.location?.id,
        name: productData.location?.name,
        slug: productData.location?.slug,
      },
      subCategories: productData.subcategories?.map(subCategory => {
        return {
          id: subCategory.id,
          name: subCategory.name,
          slug: subCategory.slug,
        };
      }),
      vendor: {
        id: productData.vendor?.id,
        businessName: productData.vendor?.name,
        slug: productData.vendor?.slug,
      },
      variants: productData?.variants?.map(variant => {
        return {
          id: variant.id,
          discountAmount: variant.discount_amount,
          discountPercentage: variant.discount_percent,
          discountedPrice: variant.discounted_price,
          optionValue: variant.option_value,
          position: variant.position,
          price: variant.price,
        };
      })
    };
    this.setState({ product: product });
  }

  loadSignedInUserProfile(): void {
    if (!this.props?.isAccountSignedIn) {
      return null;
    }
    this.userApi.getUserProfile()
      .then(user => {
        this.setState({
          loggedOnUser: user
        });
      })
      .catch(error => {
        console.error(error);
        notification.error({ message: 'Error while loading the user\'s profile.', className: 'antd-mod center' });
      });
  }

  setBreadcrumbsNavItems(): void {
    this.setState({
      breadcrumbsNavItems: [
        { id: 1, label: 'Home', link: '/' },
        { id: 2, label: this.state?.product?.category?.name, link: `/products/c/${this.state?.product?.category?.slug}` },
        { id: 3, label: this.state?.product?.vendor?.businessName, link: `/${this.state?.product?.vendor?.slug}` },
        { id: 4, label: this.state?.product?.name, link: '' },
      ]
    });
  }

  redirectToVendorProfile(slug: string): void {
    window.location.href = `/vendors/${slug}`;
  }

  redirectToProductDetails(slug: string): void {
    window.open(`/products/${slug}`);
  }

  loadProducts(): void {
    Promise.all([
      this.productApi.getFilteredProducts(null, this.state?.product?.vendor?.slug, this.state?.product.slug),
      this.productApi.getFilteredProducts(null, null, this.state?.product.slug),
    ])
      .then(result => {
        this.setState({
          otherPackagesByThisVendor: result[0],
          relatedPackagesYouMightLike: result[1]
        });
      });
  }

  /**
   * Update product's price info such as price, discountedPrice, discount percentage, etc based on the selected variant.
   */
  updatePriceInfoByVariant(variant: ProductVariant): void {
    const productCopy = { ...this.state?.product };
    productCopy.discountAmount = variant.discountAmount;
    productCopy.discountedPrice = variant.discountedPrice;
    productCopy.discountPercentage = variant.discountPercentage;
    productCopy.price = variant.price;
    this.setState({ product: productCopy });
  }

  handleAppointmentButton(): void {
    this.setState({ appointmentModalVisible: true });
  }

  handleSubmitAppointment(data): void {
    this.userApi.createAppointment(this.state?.product?.vendor.id, data.email, data.date, data.detail, data.name, data.mobile)
      .then(result => {
        if (result) {
          notification.success({ message: 'Appointment saved successfully', className: 'antd-mod center' });
          this.setState({ appointmentModalVisible: false });
        } else {
          notification.error({ message: 'Error while creating the appointment.', className: 'antd-mod center' });
        }
      });
  }

  render() {
    const otherPackagesByThisVendorItems = this.state?.otherPackagesByThisVendor?.map(product => <ProductCard product={product} clicked={() => { this.redirectToProductDetails(product?.slug) }} key={product.id} />);
    const relatedPackagesYouMightLikeItems = this.state?.relatedPackagesYouMightLike?.map(product => <ProductCard product={product} clicked={() => { this.redirectToProductDetails(product?.slug) }} key={product.id} />);

    return (
      <div className="ProductDetailsPage">
        <Navbar
          isAccountSignedIn={this.props?.isAccountSignedIn}
          user={this.state?.loggedOnUser}
          signInRequired={(redirectUrl) => { this.setState({ loginModalVisible: true, loginModalRedirectUrl: redirectUrl }); }}
        />

        <LoginModal
          visible={this.state?.loginModalVisible}
          googleSignInUrl={this.props?.googleSignInUrl}
          fbSignInUrl={this.props?.fbSignInUrl}
          csrfToken={this.props?.csrfToken}
          redirectUrl={this.state?.loginModalRedirectUrl}
          forgotPasswordClicked={() => { this.setState({ loginModalVisible: false, forgotPasswordModalVisible: true }) }}
          closed={() => { this.setState({ loginModalVisible: false }) }}
          registerLink="/registration"
        />

        <ForgotPasswordModal
          visible={this.state?.forgotPasswordModalVisible}
          loginClicked={() => { this.setState({ forgotPasswordModalVisible: false, loginModalVisible: true }) }}
          closed={() => { this.setState({ forgotPasswordModalVisible: false }) }}
        />

        <AppointmentModal
          visible={this.state?.appointmentModalVisible}
          onCreate={(data) => { this.handleSubmitAppointment(data) }}
          onCancel={() => { this.setState({ appointmentModalVisible: false }) }}
        />

        <AgeConfirmationModal visible={this.state?.ageConfirmationModalVisible} confirmed={() => this.setState({ ageConfirmationModalVisible: false })} closed={() => window.location.href = '/'} />
        <GalleryModal visible={this.state?.galleryModalVisible} imageUrls={this.state?.product?.images} closed={() => { this.setState({ galleryModalVisible: false }) }} />

        <div className="page-content">
          <BreadcrumbsNavigation items={this.state?.breadcrumbsNavItems} />
          <AlbumCover imageUrls={this.state?.product?.images} showAllClicked={() => { this.setState({ galleryModalVisible: true }) }} />
          <div className="product-details-container">
            <div className="column-1">
              <div className="header-details">
                <div className="title-container">
                  <div className="title typo-headline">{this.state?.product?.name}</div>
                  <div className="vendor-profile-link-container">
                    <Button className="antd-mod color-accent" href={`/vendors/${this.state?.product?.vendor?.slug}`} target="_blank">Visit Vendor Profile</Button>
                    {/*<Button type="primary" className="antd-mod color-primary share-button" icon={<ShareAltOutlined />} />*/}
                    <SocialShare product={this.state?.product} />
                  </div>
                </div>
                <div className="vendor">
                  <span>by</span>
                  &nbsp;&nbsp;
                  <span>{this.state?.product?.vendor?.businessName}</span>
                </div>
                {/*<div className="rating">
                  <Rate value={1} count={1}></Rate>
                  <div className="rating-text typo-subheading-2">{this.state?.product?.rating} ({this.state?.product?.reviews})</div>
                </div>*/}
                <div className="tags">
                  <Tag text={this.state?.product?.location?.name} />
                  <Tag text={this.state?.product?.category?.name} />
                  {this.state?.product?.subCategories?.map(subCategory => <Tag text={subCategory.name} variant={2} key={subCategory.id} />)}
                </div>
              </div>
              <div className="detail-section">
                <div className="title typo-title-2">Package Details</div>
                <div className="section-content typo-subheading-1" dangerouslySetInnerHTML={{ __html: this.state?.product?.details }} />
              </div>
              <div className="detail-section">
                <div className="title typo-title-2">Payment Terms</div>
                <div className="section-content typo-subheading-1" dangerouslySetInnerHTML={{ __html: this.state?.product?.paymentTerms }}>
                </div>
              </div>
              <div className="detail-section">
                <div className="title typo-title-2">Terms and Conditions</div>
                <div className="section-content typo-subheading-1" dangerouslySetInnerHTML={{ __html: this.state?.product?.terms }}>
                </div>
              </div>
            </div>
            <div className="column-2">
              <ProductControlsCard
                product={this.state?.product}
                wishlistButtonDisabled={!this.props?.isAccountSignedIn}
                variantSelected={(variant) => { this.updatePriceInfoByVariant(variant); }}
                wishlistStatusChanged={(wishlistStatus) => { this.productApi.updateWishlistStatus(this.state?.product?.id, wishlistStatus) }}
                onClickAppointment={() => { this.handleAppointmentButton() }}
              />
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="content">
            <ItemSlider title="Other Packages by This Vendor" subtitle="Related package you might like" items={otherPackagesByThisVendorItems} />
          </div>
        </div>
        <div className="page-section">
          <div className="content">
            <ItemSlider title="Related packages you might like" subtitle="Related packages you might like" items={relatedPackagesYouMightLikeItems} />
          </div>
        </div>
        <Footer userType={this.state?.loggedOnUser?.type} />
      </div>
    );
  }
}

export default ProductDetailsPage;
