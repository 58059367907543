// import './VendorFaqForm.scss';

import * as React from 'react';
import { startCase, map } from 'lodash';
import { Button, Card, Checkbox, Form, FormInstance, Input, notification, Radio, Skeleton, Space } from 'antd';

import { ServiceCategory } from '../../../interfaces/service-category.interface';
import { FAQ, FAQAnswerOptions } from '../../../interfaces/faq.interface';
import FAQApi from '../../../api-services/faq-api-service';

const { TextArea } = Input;

interface VendorFaqFormProps {
  // categories: ServiceCategory[];
  category: ServiceCategory;
  faqs: FAQ[];
  
  onDisplayAnswer?: (faqIndex: any) => void;
  onUpdate?: (faqIndex: any, newVal: any) => void;
}

interface VendorFaqFormState {
  loading: boolean;
  selectedCategory: ServiceCategory;
  selectedCategoryName: string;
  savingChanges: boolean;
}

class VendorFaqForm extends React.Component <VendorFaqFormProps, VendorFaqFormState> {

  faqAnswerForm = React.createRef<FormInstance>();

  componentDidMount(){
    this.setState({
      loading: true,
      savingChanges: false
    });
  }

  
  componentDidUpdate(previousProps: VendorFaqFormProps) {
    if(this.props?.faqs !== previousProps.faqs || this.props?.category !== previousProps.category) {
      this.setState({selectedCategory: this.props?.category, loading: false})
    }
  }


  saveChanges(index:number, faq:FAQ, formEvent: any){
    const faqApi = new FAQApi;
    var request = null;

    const payload = {
      answers: [].concat(formEvent.answer),
      other: formEvent.other,
      serviceCategoryId: this.props?.category?.id,
      kind: (typeof formEvent.answer === "string") ? "custom" : "default"
    }

    if(faq.vendorFaqs == undefined){
      request = faqApi.saveAnswer(faq, payload)
    }else{
      request = faqApi.updateAnswer(faq, payload)
    }

    request
      .then(faqResponse => {
        this.props?.onUpdate(index, faqResponse);
        notification.success({ message: 'Changes saved successfully', className: 'antd-mod center' });
      })
      .catch(error => {
        const errorKeys = Object.keys(error?.response?.data?.errors);
        errorKeys.forEach(errorKeyName => {
          const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
          notification.error({ message: errorMessage, className: 'antd-mod center' });
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }


  renderOtherOption(optionId:number, placeholder?:string){
    return (
      <Form.Item name="other" style={{marginTop: '10px'}}>
        <Input size="large" type="text" placeholder={placeholder}/>
      </Form.Item>
    )
  }



  renderFAQOption(type: string, placeholder?:string, options?:FAQAnswerOptions[], maxAnswer?:number){
    switch (type) {
      case "number":
        return <Input size="large" type="number" placeholder={placeholder}/>
      case "radio":
        return (
          <Radio.Group>
            <Space direction="vertical">
              {
                options.map(option => {
                  return (
                    <Radio key={option.id} value={option.id}>
                      {option.answer}
                      {option.other && this.renderOtherOption(option.id, option.hint) }
                    </Radio>
                  )
                })
              }
            </Space>
        </Radio.Group>
        )
      case "tick":
        return (
          <Checkbox.Group>
            <Space direction="vertical">
              {
                options.map(option => {
                  return (
                    <Checkbox key={option.id} value={option.id}> 
                      {option.answer} 
                      {option.other && this.renderOtherOption(option.id, option.hint) }
                    </Checkbox>
                  )
                })
              }
            </Space>
          </Checkbox.Group>
        )
    
      default:
        return <TextArea size="large" placeholder={placeholder}/>
    }
  }


  renderFAQs(){
    return (
      <section className="vendor-faqs">

        <Card title={<div className="typo-subheading-2 typo-bold"> {this.props?.category?.name} </div>} bordered>
          {
            this.props?.faqs.map((faq, index) => {
              const answer = map(faq.vendorFaqs?.answers, "answer") || null,
                    other = map(faq.vendorFaqs?.answers, "other") || null;
              return (
                <div className="faq" key={faq.id}>
                  <Card 
                    type="inner" 
                    title={ <div className="faq-question"> {faq.question} </div>} 
                    extra={ faq.displayAnswer && <Button type="link" danger onClick={()=>this.props?.onDisplayAnswer(index)}>Edit</Button>}>

                  <div className="faq-answer">

                    {faq.displayAnswer && <div className="">{faq.vendorFaqs?.formattedAnswer}</div>}
                    
                    <Form name="dynamic_form_item" initialValues={{answer: answer, other: other}}
                      onFinish={(formEvent)=>this.saveChanges(index, faq, formEvent)} 
                      style={faq.displayAnswer ? {display: 'none'} : {}}>
                      <Form.Item name="answer">
                          {this.renderFAQOption(faq.type, faq.placeholder, faq.options, faq.maxAnswer)}
                        </Form.Item>

                        <Form.Item className="action-buttons">
                          <Button type="default" onClick={()=>this.props?.onDisplayAnswer(index)}>Cancel</Button>
                          <Button type="primary" className="antd-mod" htmlType="submit">Save</Button>
                        </Form.Item>
                    </Form>
                  </div>
                    
                  </Card>
                </div>
              )
            })
          }
        </Card>
      </section>
    );
  }

  render() {
    return (
      <div className="VendorFaqForm">
        <div className="main-content">
          { this.props?.faqs == undefined ? <Skeleton active /> : this.renderFAQs() }
        </div>
      </div>
    );
  }
}

export default VendorFaqForm;
