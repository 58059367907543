import * as React from "react"

import { Button, Table, Image } from "antd";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import * as moment from "moment";
import { Banner } from "../../../interfaces/banner.interface";


interface IAdminBannersTableProps {
  hideAction: boolean;
  banners:Banner[];
  selectedBanners:number[];
  editBanner: (banner: Banner)=>void;
  publishBanner: (id: number)=>void;
  unpublishBanner: (id: number)=>void;
  removeBanner: (id:number)=>void;
  selectionChange: (selectedRows)=>void;
}

interface IAdminBannersTableState {
  columns: any[];
}

class AdminBannersTable extends React.Component <IAdminBannersTableProps, IAdminBannersTableState> {
  static defaultProps = { hideAction: false};

  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'Image',
          dataIndex: 'image',
          width: 50,
          render: (text) => {
            return <Image width={50} src={text}/>
          }
        },
        {
          title: 'Title',
          dataIndex: 'title'
        },
        {
          title: 'Link',
          dataIndex: 'link'
        },
        {
          title: 'Placement',
          dataIndex: 'placement'
        },
        {
          title: 'Position',
          dataIndex: 'position'
        },
        {
          title: 'Start Time',
          dataIndex: 'showStart',
          render: (showStart, record) => {
            return (
              <span>{record.showStart != null && `${moment(showStart).format('DD/MM/YYYY HH:mm:ss')}`}</span>
            )
          }
        },
        {
          title: 'End Time',
          dataIndex: 'showEnd',
          render: (showEnd, record) => {
            return (
              <span>{record.showEnd != null && `${moment(showEnd).format('DD/MM/YYYY HH:mm:ss')}`}</span>
            )
          }
        },
        {
          title: 'Click Count',
          dataIndex: 'clickCount'
        },
        {
          title: 'Action',
          key: 'operation',
          width: 200,
          render: (text, record) => {
            return <>
              <Button type="link" onClick={()=>this.props?.editBanner(record)}>Edit</Button>
              {record.mayPublish && <Button type="link" onClick={()=>this.props?.publishBanner(record.id)}>Publish</Button>}
              {record.mayUnpublish && <Button type="link" onClick={()=>this.props?.unpublishBanner(record.id)}>Unpublish</Button>}
              {/* <Button type="link" danger onClick={()=>this.props?.removeBanner(record.id)}>Remove</Button> */}
            </>
          },
        },
      ]
    };
  }

  componentDidMount() {
    if (this.props?.hideAction) {
      this.setState({
        columns: [
          {
            title: 'Image',
            dataIndex: 'image',
            width: 50,
            render: (text) => {
              return <Image width={50} src={text}/>
            }
          },
          {
            title: 'Title',
            dataIndex: 'title'
          },
          {
            title: 'Link',
            dataIndex: 'link'
          },
          {
            title: 'Placement',
            dataIndex: 'placement'
          },
          {
            title: 'Position',
            dataIndex: 'position'
          },
          {
            title: 'Start Time',
            dataIndex: 'showStart',
            render: (showStart, record) => {
              return (
                <span>{record.showStart != null && `${moment(showStart).format('DD/MM/YYYY HH:mm:ss')}`}</span>
              )
            }
          },
          {
            title: 'End Time',
            dataIndex: 'showEnd',
            render: (showEnd, record) => {
              return (
                <span>{record.showEnd != null && `${moment(showEnd).format('DD/MM/YYYY HH:mm:ss')}`}</span>
              )
            }
          },
          {
            title: 'Click Count',
            dataIndex: 'clickCount'
          }
        ]
      });
    } else {
      this.setState({
        columns: [
          {
            title: 'Image',
            dataIndex: 'image',
            width: 50,
            render: (text) => {
              return <Image width={50} src={text}/>
            }
          },
          {
            title: 'Title',
            dataIndex: 'title'
          },
          {
            title: 'Link',
            dataIndex: 'link'
          },
          {
            title: 'Placement',
            dataIndex: 'placement'
          },
          {
            title: 'Position',
            dataIndex: 'position'
          },
          {
            title: 'Start Time',
            dataIndex: 'showStart',
            render: (showStart, record) => {
              return (
                <span>{record.showStart != null && `${moment(showStart).format('DD/MM/YYYY HH:mm:ss')}`}</span>
              )
            }
          },
          {
            title: 'End Time',
            dataIndex: 'showEnd',
            render: (showEnd, record) => {
              return (
                <span>{record.showEnd != null && `${moment(showEnd).format('DD/MM/YYYY HH:mm:ss')}`}</span>
              )
            }
          },
          {
            title: 'Click Count',
            dataIndex: 'clickCount'
          },
          {
            title: 'Action',
            key: 'operation',
            width: 200,
            render: (text, record) => {
              return <>
                <Button type="link" onClick={()=>this.props?.editBanner(record)}>Edit</Button>
                {record.mayPublish && <Button type="link" onClick={()=>this.props?.publishBanner(record.id)}>Publish</Button>}
                {record.mayUnpublish && <Button type="link" onClick={()=>this.props?.unpublishBanner(record.id)}>Unpublish</Button>}
                {/* <Button type="link" danger onClick={()=>this.props?.removeBanner(record.id)}>Remove</Button> */}
              </>
            },
          },
        ]
      });
    }
  }


  render() {
    const rowSelection = {
      // selectedRowKeys: this.props?.selectedBanners,
      preserveSelectedRowKeys: false,
      onChange: (selectedRowKeys, selectedRows) => {
        this.props?.selectionChange(selectedRowKeys);
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };

    return (
      <div className="AdminBannersTable">
        <Table size="small" bordered rowKey="id"
          // rowSelection={{
          //   type: "checkbox",
          //   ...rowSelection,
          //   selectedRowKeys: this.props?.selectedBanners
          // }}
          columns={this.state?.columns}
          dataSource={this.props?.banners}
          pagination={{ defaultPageSize: 50}}
        />
      </div>
    );
  }
}

export default AdminBannersTable

