import axios from "axios";
import { AlbumImage } from "../interfaces/album-image.interface";

class AlbumImageApi {

  getLikedImages(): Promise<AlbumImage[]> {
    const apiUrl = `/api/v1/inspirations`;

    return axios.get(apiUrl)
    .then((response: any) => {
      const albumImages: AlbumImage[] = response?.data?.data?.map(imageData => {
        return {
          id: imageData.attributes?.id,
          height: imageData.attributes?.height,
          width: imageData.attributes?.width,
          url: imageData.attributes?.image_url,
          isCover: imageData.attributes?.is_cover,
          liked: imageData.attributes?.like,
          album: {
            id: imageData.attributes?.album_id
          }
        };
      });
      
      return Promise.resolve(albumImages);
    })
  }


  updateAlbumImageLikeStatus(albumImageId: number, likeStatus: boolean): Promise<any> {
    const apiUrl = `/api/v1/likes`;
    const payload = {
      like: {
        type: 'Medium',
        like: likeStatus,
        id: albumImageId
      }
    };

    return axios.post(apiUrl, payload).then(response => {
      return Promise.resolve(response);
    });
  }
  
  
}




export default AlbumImageApi;