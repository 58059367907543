import * as React from "react"

import { Button, Modal, notification, Table, Descriptions } from "antd";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'; 

import VendorAdminApi from "../../../api-services/vendor-admin-api.service";
import { Vendor } from "../../../interfaces/vendor-interface";
import * as moment from "moment";


interface IAdminVendorsTableProps {
  vendors:Vendor[];
  selectedVendors:number[];
  approveClicked: (id:number)=>void;
  rejectClicked: (id:number)=>void;
  sendReminderClicked: (id:number)=>void;
  reviewClicked: (id:number)=>void;
  suspendClicked: (id:number)=>void;
  deleteClicked: (id:number)=>void;
  downloadPhotos: (id:number)=>void;
  selectionChange: (selectedRows)=>void;
  onChange: (pagination:any)=>void;
  pagination: any;
  hideAction: boolean;
}

interface IAdminVendorsTableState {
  modalVisible:boolean;
  selectedVendor:Vendor;
  columns: any[];
}

class AdminVendorsTable extends React.Component <IAdminVendorsTableProps, IAdminVendorsTableState> {

  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      selectedVendor: null,
      columns: [
        {
          title: 'Business Name',
          dataIndex: 'businessName',
          render: (text, record) => {
            const vendorPage = `/preview/${record.slug}`;
            // return <a href={vendorPage} target="_blank">{text}</a>
            return <a onClick={()=>this.getVendorInfo(record.id)}>{text}</a>
          }
        },
        {
          title: 'Category',
          dataIndex: 'serviceCategories',
          render: (text, record) => {
            return <span>{
              record.serviceCategories?.map(category => {
                return <span key={category.id}> {category.name} </span>
              })
            }</span>
          }
        },
        {
          title: 'Verified',
          dataIndex: 'verified',
          render: (text) => {
            return (
              text ? <CheckOutlined twoToneColor="#52c41a"/> : <CloseOutlined twoToneColor="#ff4d4f"/>
            )
          }
        },
        {
          title: 'Status',
          dataIndex: 'status',
          render: (text, record) => {
            // const statusInfo = text=='rejected' ? `${text}` : text;
            return <span className="status">{text}</span>
          }
        },
        {
          title: 'Date Created',
          dataIndex: 'createdAt',
          render: (text) => {
            return <span>{moment(text).format('DD/MM/YYYY')}</span>
          }
        }
      ]
    };
  }

  componentDidMount() {
    if (this.props?.hideAction){
      this.setState({columns: [
          {
            title: 'Business Name',
            dataIndex: 'businessName',
            render: (text, record) => {
              const vendorPage = `/preview/${record.slug}`;
              // return <a href={vendorPage} target="_blank">{text}</a>
              return <a onClick={()=>this.getVendorInfo(record.id)}>{text}</a>
            }
          },
          {
            title: 'Category',
            dataIndex: 'serviceCategories',
            render: (text, record) => {
              return <span>{
                record.serviceCategories?.map(category => {
                  return <span key={category.id}> {category.name} </span>
                })
              }</span>
            }
          },
          {
            title: 'Verified',
            dataIndex: 'verified',
            render: (text) => {
              return (
                text ? <CheckOutlined twoToneColor="#52c41a"/> : <CloseOutlined twoToneColor="#ff4d4f"/>
              )
            }
          },
          {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => {
              // const statusInfo = text=='rejected' ? `${text}` : text;
              return <span className="status">{text}</span>
            }
          },
          {
            title: 'Date Created',
            dataIndex: 'createdAt',
            render: (text) => {
              return <span>{moment(text).format('DD/MM/YYYY')}</span>
            }
          }
        ]});
    } else {
      this.setState({columns: [
          {
            title: 'Business Name',
            dataIndex: 'businessName',
            render: (text, record) => {
              const vendorPage = `/preview/${record.slug}`;
              // return <a href={vendorPage} target="_blank">{text}</a>
              return <a onClick={()=>this.getVendorInfo(record.id)}>{text}</a>
            }
          },
          {
            title: 'Category',
            dataIndex: 'serviceCategories',
            render: (text, record) => {
              return <span>{
                record.serviceCategories?.map(category => {
                  return <span key={category.id}> {category.name} </span>
                })
              }</span>
            }
          },
          {
            title: 'Verified',
            dataIndex: 'verified',
            render: (text) => {
              return (
                text ? <CheckOutlined twoToneColor="#52c41a"/> : <CloseOutlined twoToneColor="#ff4d4f"/>
              )
            }
          },
          {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => {
              // const statusInfo = text=='rejected' ? `${text}` : text;
              return <span className="status">{text}</span>
            }
          },
          {
            title: 'Date Created',
            dataIndex: 'createdAt',
            render: (text) => {
              return <span>{moment(text).format('DD/MM/YYYY')}</span>
            }
          },
          {
            title: 'Action',
            key: 'operation',
            width: 400,
            render: (text, record) => {
              return <>
                <Button type="link" disabled={record.verified}  onClick={()=>this.props?.sendReminderClicked(record.id)}>Send Verification Reminder</Button>
                <Button type="link" disabled={!record.mayApprove} onClick={()=>this.props?.approveClicked(record.id)}>Approve</Button>
                <Button type="link" disabled={!record.mayReject} danger onClick={()=>this.props?.rejectClicked(record.id)}>Reject</Button>
                <Button type="link" disabled={!record.mayReview} onClick={()=>this.props?.reviewClicked(record.id)}>Review</Button>
                <Button type="link" disabled={!record.maySuspend} danger onClick={()=>this.props?.suspendClicked(record.id)}>Suspend</Button>
                <Button type="link" disabled={!record.mayDelete} danger onClick={()=>this.props?.deleteClicked(record.id)}>Delete</Button>
                <Button type="link" onClick={()=>this.props?.downloadPhotos(record.id)}>Download Photos</Button>
              </>
            },
          },
        ]});
    }
  }


  getVendorInfo(vendorId:number){
    const vendorApi = new VendorAdminApi;
    vendorApi.getVendor(vendorId)
      .then(vendorResponse => {
        this.setState({modalVisible: true, selectedVendor: vendorResponse});
      })
      .catch(error => {
        notification.error({ message: 'Error while fetching vendor info', className: 'antd-mod center' });
      });
  }
  

  renderDetailsModal(){
    const vendor = this.state?.selectedVendor;

    return (
      <Modal className="VendorDetailsModal" title={<span className="title typo-title">Vendor Details</span>} width={800} visible={this.state?.modalVisible} onOk={()=>this.setState({modalVisible:false})} onCancel={()=>this.setState({modalVisible:false})} cancelButtonProps={{ style: { display: 'none' } }}>
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Business Registered Name">{vendor?.businessRegisteredName}</Descriptions.Item>
          <Descriptions.Item label="Business Registration No">{vendor?.businessRegisteredNumber}</Descriptions.Item>
          <Descriptions.Item label="Business License"><a href={vendor?.businessLicense?.url} download>{vendor?.businessLicense?.name}</a></Descriptions.Item>
          <Descriptions.Item label="Primary Category">{vendor?.serviceCategories[0]?.name}</Descriptions.Item>
          <Descriptions.Item label="Email">{vendor?.email}</Descriptions.Item>
          <Descriptions.Item label="Contact #">{vendor?.primaryPhoneNumber?.number}</Descriptions.Item>
          <Descriptions.Item label="Address">{vendor?.fullAddress}</Descriptions.Item>

          <Descriptions.Item label="Business Website">{vendor?.website}</Descriptions.Item>
          <Descriptions.Item label="Facebook">{vendor?.facebook}</Descriptions.Item>
          <Descriptions.Item label="Instagram">{vendor?.instagram}</Descriptions.Item>
          <Descriptions.Item label="Youtube">{vendor?.youtube}</Descriptions.Item>
          <Descriptions.Item label="Tiktok">{vendor?.tiktok}</Descriptions.Item>
          <Descriptions.Item label="Contact Person">{vendor?.contactPerson}</Descriptions.Item>
          <Descriptions.Item label="Contact Person No">{vendor?.contactPersonNumber}</Descriptions.Item>
          <Descriptions.Item label="Contact Whatsapp No">{vendor?.whatsapp}</Descriptions.Item>

        </Descriptions>
      </Modal>
    )
  }

  render() {
    const rowSelection = {
      // selectedRowKeys: this.props?.selectedVendors,
      preserveSelectedRowKeys: false,
      onChange: (selectedRowKeys, selectedRows) => {
        this.props?.selectionChange(selectedRowKeys);
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };

    return (
      <div className="AdminVendorsTable">
        {this.renderDetailsModal()}

        <Table size="small" bordered rowKey="id"
          // rowSelection={{
          //   type: "checkbox",
          //   ...rowSelection,
          //   selectedRowKeys: this.props?.selectedVendors
          // }}
          columns={this.state?.columns}
          dataSource={this.props?.vendors}
          pagination={this.props?.pagination}
          onChange={this.props?.onChange}
        />
      </div>
    );
  }
}

export default AdminVendorsTable
