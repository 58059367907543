// import './BusinessInfoStepForm.scss';

import * as React from "react"
import { startCase } from 'lodash';

import { Button, Form, FormInstance, Input, notification, Select, Upload } from "antd";
import { UploadOutlined } from '@ant-design/icons'; 

import { Vendor } from "../../../interfaces/vendor-interface";
import { ServiceCategory } from "../../../interfaces/service-category.interface";

import VendorApi from "../../../api-services/vendor-api.service";
import { ReactSVG } from "react-svg";
import CityApi from "../../../api-services/city-api.service";
import LocationHierarchy from "../../ui-components/location-hierarchy/LocationHierarchy";

import ReactQuill from 'react-quill';
import { UploadFile } from "antd/lib/upload/interface";
import { Country } from "../../../interfaces/country.interface";


const facebook = require('@mdi/svg/svg/facebook.svg');
const instagram = require('@mdi/svg/svg/instagram.svg');
const youtube = require('@mdi/svg/svg/youtube.svg');
const tiktok = require('@mdi/svg/svg/music-note.svg');

const {TextArea} = Input;
const { Option } = Select;


const modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }]
  ],
}

notification.config({
  duration: 12,
  top: 60
})


interface IBusinessInfoStepFormProps {
  categories: ServiceCategory[];
  vendor: Vendor;
  cancelButtonText?: string;
  saved?: () => void;
  cancelled?: () => void;
}

interface IBusinessInfoStepFormState {
  savingChanges: boolean;
  selectedCountry:number;
  selectedState:string|number;
  selectedCity:number;
  savedBusinessLicense: UploadFile[];
  brandName: string;
}

class BusinessInfoStepForm extends React.Component <IBusinessInfoStepFormProps, IBusinessInfoStepFormState> {
  cityApi = new CityApi;
  
  businessInfoForm = React.createRef<FormInstance>();

  getServiceCityOptionsTimeout;


  componentDidMount(){
    this.setState({
      savingChanges: false,
      selectedCountry: 1,
      savedBusinessLicense: null
    });
  }


  componentDidUpdate(previousProps){
    if(previousProps.vendor !== this.props?.vendor){
      this.populateBusinessForm();
    }
  }


  populateBusinessForm(){
    const vendor = this.props?.vendor;
    this.businessInfoForm.current!.setFieldsValue({
      businessName: vendor.businessName,
      businessRegisteredName: vendor.businessRegisteredName,
      businessNo: vendor?.businessRegisteredNumber,
      businessLicense: vendor?.businessLicense,
      phoneKind: vendor?.primaryPhoneKind || "phone",
      phoneNumber: vendor?.primaryPhoneNumber.number,
      primaryCategory: vendor?.serviceCategories?.find(category => category.isPrimary)?.id,
      address: vendor?.address,
      country: vendor?.country != null ? vendor?.country?.id : 1,
      city: vendor?.city?.id,
      state: vendor?.state?.code,
      zipCode: vendor?.zipCode,
      
      website: vendor?.website,
      facebook: vendor?.facebook,
      instagram: vendor?.instagram,
      youtube: vendor?.youtube,
      tiktok: vendor?.tiktok
    });

    this.setState({
      selectedState: vendor?.state?.code,
      selectedCity: vendor?.city?.id,
      savedBusinessLicense: [{
        uid: vendor?.businessLicense?.id,
        name: vendor?.businessLicense?.name,
        url: vendor?.businessLicense?.url,
      }],
      brandName: vendor?.businessName
    });
  }

  updateBrandName():string{
    const name = this.state?.brandName;
    return name ? name.toLowerCase().trim().replace(/\s/g,"-"): "";
  }

  renderBrandNameNote(){
    return (
      <div className="brand-name">
        <div className="typo-body-small-2">www.bridepay.com/{this.updateBrandName()}</div>
        <div className="typo-body-small-2">This will be the name of your E-Shop. Brand name is required and cannot be changed.</div>
      </div>
    )
  }



  saveChanges(cb, isSubmitted=false,){
    const formEvent = this.businessInfoForm.current!.getFieldsValue();
    if(isSubmitted && formEvent.facebook == undefined && formEvent.instagram == undefined && formEvent.youtube == undefined  && formEvent.tiktok == undefined){
      notification.error({ message: "Please provide a minimum of one social media link.", className: 'antd-mod center' });
      return;
    }

    this.setState({ savingChanges: true });

    const vendorApi = new VendorApi;
    const payload = {
      businessName: formEvent.businessName,
      businessRegisteredName: formEvent.businessRegisteredName,
      businessRegisteredNumber: formEvent.businessNo,

      // phoneKind: formEvent.phoneKind,
      phoneNumber: formEvent.phoneNumber,

      description: formEvent.description,
      address: formEvent.address,
      zipCode: formEvent.zipCode,
      country: formEvent.country,
      state: formEvent.state,
      city: formEvent.city,

      website: formEvent.website,
      facebook: formEvent.facebook,
      instagram: formEvent.instagram,
      youtube: formEvent.youtube,
      tiktok: formEvent.tiktok,

      primaryCategory: formEvent.primaryCategory,
      submit: isSubmitted
    };

    vendorApi.saveBusinessDetails(payload)
    .then(response => {
      cb();
    })
    .catch(error => {
      const errorKeys = Object.keys(error?.response?.data?.errors);
      errorKeys.forEach(errorKeyName => {
        const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
        notification.error({ message: errorMessage, className: 'antd-mod center' });
      });
    })
    .finally(() => {
      this.setState({ savingChanges: false });
    });
  }


  submitVendorForApproval(){
    this.saveChanges(()=>{
      this.props?.saved();
    }, true)
  }


  setBusinessDescription(description){
    this.businessInfoForm.current!.setFieldsValue({
      description: description
    })
  }



  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
    if (!isJpgOrPng) {
      notification.error({ message: 'You can only upload JPG/PNG/PDF file!', className: 'antd-mod center'});
    }
    // const isLt2M = file.size / 1024 / 1024 < this.props?.maxSize;
    // if (!isLt2M) {
    //   notification.error({ message: `Image must smaller than ${this.props?.maxSize}MB!`, className: 'antd-mod center'});
    // }
    // return isJpgOrPng && isLt2M;
    return isJpgOrPng;
  }



  handleChange = ({ fileList: newFileList, file }) => {
    if(file.status === "error"){
      notification.error({ message: file.response?.error, className: 'antd-mod center' });
    }
    if(file.status == "done"){
      
    }
    this.setState({savedBusinessLicense: newFileList});
  };


  getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
   return e && e.fileList;
  };
  

  renderBusinessInfo(){
    return (
      <>
        <Form.Item name="businessRegisteredName" label="Business Registered Name (Sole Proprietor / Pte Ltd)" rules={[{ required: true, message: 'Business Name is required' }]}>
          <Input/>
        </Form.Item>
        
        <Form.Item name="businessName" label="Brand Name" rules={[{ required: true, message: 'Brand Name is required' }]} extra={this.renderBrandNameNote()}>
          <Input onKeyUp={(e)=>{this.setState({brandName: e.target["value"]})}}/>
        </Form.Item>

        <Form.Item name="phoneNumber" label="Business Phone Number" rules={[{ required: true, message: 'Phone number is required' }]}>
          <Input size="large" />
        </Form.Item>

        <Form.Item name="businessNo" label="Business Registration No" rules={[{ required: true, message: 'Business No is required' }]}>
          <Input size="large" placeholder="Enter Business Registration No" />
        </Form.Item>

        <Form.Item name='businessLicense' getValueFromEvent={this.getFile} label="Business Registration License (SSM, etc)"
          rules={[{ required: this.state?.savedBusinessLicense !== null, message: 'Business License is required' }]}>
          <Upload name="business_license" action="/api/v1/business/upload_license" accept="image/*, application/pdf" fileList={this.state?.savedBusinessLicense}
            beforeUpload={this.beforeUpload} onChange={this.handleChange} maxCount={1}
            itemRender={(originNode, file, currFileList) => (
              <a href={file.url} download>{file.name}</a>
            )}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item name="primaryCategory" label="Primary Business Category" rules={[{ required: true, message: 'Business Category is required' }]} 
          extra={<div className="typo-body-small-2">Please ensure you select the correct category for your business on bridepay.com. Once selected, it is fixed and cannot be changed.</div>}>
          <Select size="large" allowClear showSearch optionFilterProp="children"
              filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {this.props?.categories?.map(category => {
                return <Select.Option key={category.id} value={category.id}>{category.name}</Select.Option>
              })}
          </Select>
        </Form.Item>

        <Form.Item name="address" label="Address" rules={[{ required: true, message: 'Business Address is required' }]}>
          <TextArea size="large"/>
        </Form.Item>

        <Form.Item name="zipCode" label="Postal Code / Zip Code" rules={[{ required: true, message: 'Zip Code is required' }]} style={{ display: 'inline-block', width: 'calc(50% - 8px)'}}>
          <Input size="large" />
        </Form.Item>

        <Form.Item>
          <LocationHierarchy form={this.businessInfoForm} savedCountry={this.state?.selectedCountry} savedState={this.state?.selectedState} savedCity={this.state?.selectedCity}/>
        </Form.Item>
      </>
    )
  }



  renderSocialMedias(){
    const websitePattern = /^((?:https|ftp)s?:\/\/)?(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::\d+)?(?:\/?|[\/?]\S+)$/i;

    return (
      <>
        <Form.Item name="website" label="Your Business Website " rules={[{ pattern: websitePattern, message: "Must be a valid website url"}, { required: true, message: 'Please fill in your business website.' }]}>
          <Input size="large" prefix="https://" placeholder="e.g., www.abc.com"/>
        </Form.Item>
        
        <div className="social-medias">
          <Form.Item label="Social Media"> 
            <Form.Item className="facebook" name="facebook" rules={[{ pattern: /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9(\.\?)?]/, message: "Must be a valid facebook url"}]}>
              <Input size="large" addonBefore={<ReactSVG src={facebook} />} placeholder="Enter Facebook link"/>
            </Form.Item>
            <Form.Item className="instagram" name="instagram">
              <Input size="large" addonBefore={<ReactSVG src={instagram} />} placeholder="Enter Instagram link"/>
            </Form.Item>
            <Form.Item className="youtube" name="youtube">
              <Input size="large" addonBefore={<ReactSVG src={youtube} />} placeholder="Enter Youtube link"/>
            </Form.Item>
            <Form.Item className="tiktok" name="tiktok">
              <Input size="large" addonBefore={<ReactSVG src={tiktok} />} placeholder="Enter TikTok link"/>
            </Form.Item>
          </Form.Item>
        </div>
      </>
    );
  }
  
  
  render() {
    const saveCallback = ()=>{
      notification.success({ message: 'Changes saved successfully', className: 'antd-mod center' });
    };

    return (
      <div className="BusinessInfoStepForm">
        <Form ref={this.businessInfoForm} onFinish={()=>this.submitVendorForApproval()} layout="vertical" className="business-form" autoComplete="off">
          <div className="header">
            <div className="title typo-title">Business Information</div>
          </div>
          
          {this.renderBusinessInfo()}
          {this.renderSocialMedias()}
          

          <div className="action-buttons">
            <Button type="default" className="antd-mod" size="large" onClick={() => this.props?.cancelled()}> {this.props?.cancelButtonText || 'Cancel'} </Button>
            <Button type="primary" className="antd-mod" size="large" onClick={() => this.saveChanges(saveCallback)} loading={this.state?.savingChanges}>Save</Button>
            <Button type="primary" className="antd-mod" size="large" htmlType="submit" >Submit</Button>
          </div>
        </Form>
      </div>
    );
  }
}

export default BusinessInfoStepForm
