import {Modal, Button, Form, Input, Select} from 'antd';
import * as React from 'react';
import {ServiceCategory} from "../../../interfaces/service-category.interface";

interface NewCheckListProps {
    isOpen: boolean;
    closeDialog: () => void;
    services: ServiceCategory[];
    onSubmit: (values:any) => void;
}

const NewChecklist = ({isOpen, closeDialog, services, onSubmit} : NewCheckListProps) => {
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [category, setCategory] = React.useState<number | null>(null);
    const [form] = Form.useForm();

    const changeCategory = (value: number) => {
        setCategory(value);
    }

    React.useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleSubmit = (values: any) => {
        setLoading(true);
        // all submission logic here
        onSubmit(values);

        form.resetFields();
        setLoading(false);
        setOpen(false);
        closeDialog();
    };

    const handleCancel = () => {
        setOpen(false)
        form.resetFields();
        closeDialog();
    };

    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
        required: '${label} is required!'
    };
    /* eslint-enable no-template-curly-in-string */

    return (
        <Modal
            visible={open}
            title="New Checklist"
            onCancel={handleCancel}
            footer={[]}
            className="checklist-dialog"
        >
            <Form
                form={form}
                name="checklist"
                onFinish={handleSubmit}
                layout="vertical"
                validateMessages={validateMessages}
            >
                <Form.Item name='title' label="Title" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name='category' label="Category">
                    <Select
                        placeholder="Select A Category"
                        value={category}
                        onChange={changeCategory}
                        options={services.map(service => ({value: service.id, label: service.name}))}
                        style={{width: '100%'}}
                    />
                </Form.Item>
                <Form.Item name='remarks' label="Remarks">
                    <Input.TextArea />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading} className="mt-4">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
};

export default NewChecklist;