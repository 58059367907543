// import './Countdown.scss';

import * as React from 'react';
import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds, subDays } from 'date-fns';
import { subHours, subMinutes } from 'date-fns/esm';


interface CountdownProps {
  targetDate: Date;
}

interface CountdownState {
  remainingDays: string;
  remainingHours: string;
  remainingMinutes: string;
  remainingSeconds: string;
}




class Countdown extends React.Component<CountdownProps, CountdownState> {

  updateRemainingTimeInterval: any;


  componentDidMount() {
    this.updateRemainingTimeInterval = setInterval(() => {
      this.updateRemainingTime();
    }, 1000);
  }


  componentWillUnmount() {
    if(this.updateRemainingTimeInterval) {
      clearInterval(this.updateRemainingTimeInterval);
    }
  }


  updateRemainingTime(): void {
    if(new Date() >= this.props.targetDate) {
      this.setState({
        remainingDays: '0',
        remainingHours: '0',
        remainingMinutes: '0',
        remainingSeconds: '0',
      });
      return;
    }
    
    const remainingDays = differenceInDays(this.props.targetDate, new Date()).toString().padStart(2, '0');
    const daysRemoved = subDays(this.props.targetDate, parseInt(remainingDays));
    const remainingHours = differenceInHours(daysRemoved, new Date()).toString().padStart(2, '0');
    const daysHoursRemoved = subHours(daysRemoved, parseInt(remainingHours));
    const remainingMinutes = differenceInMinutes(daysHoursRemoved, new Date()).toString().padStart(2, '0');
    const daysHoursMinutesRemoved = subMinutes(daysHoursRemoved, parseInt(remainingMinutes));
    const remainingSeconds = differenceInSeconds(daysHoursMinutesRemoved, new Date()).toString().padStart(2, '0');
    
    this.setState({
      remainingDays: remainingDays,
      remainingHours: remainingHours,
      remainingMinutes: remainingMinutes,
      remainingSeconds: remainingSeconds,
    });
  }


  render() {
    return (
      <div className="Countdown">
        <div className="days date-unit-container">
          <div className="value">{this.state?.remainingDays}</div>
          <div className="unit">Days</div>
        </div>

        <div className="hours date-unit-container">
          <div className="value">{this.state?.remainingHours}</div>
          <div className="unit">Hrs</div>
        </div>

        <div className="minutes date-unit-container">
          <div className="value">{this.state?.remainingMinutes}</div>
          <div className="unit">Min</div>
        </div>

        <div className="seconds date-unit-container">
          <div className="value">{this.state?.remainingSeconds}</div>
          <div className="unit">Sec</div>
        </div>
      </div>
    );
  }
  
}




export default Countdown;