import * as React from 'react';



interface UserProfileNavigationProps {
  selectedNavigation: string; /* ID of the navigation item that you want to be highlighted. */
}


interface UserProfileNavigationState {
  navigationItems: NavigationItem[];
}


interface NavigationItem {
  id: string;
  label: string;
  href: string;
}


class UserProfileNavigation extends React.Component<UserProfileNavigationProps, UserProfileNavigationState> {

  componentDidMount(): void {
    this.setNavigationItems();
  }
  
  
  setNavigationItems(): void {
    this.setState({
      navigationItems: [
        { id: 'accountDetails', label: 'Account Details', href: '/profile' },
        { id: 'weddingDetails', label: 'Wedding Details', href: '/wedding_details' },
        { id: 'wishlist', label: 'Wishlist', href: '/wishlist' },
        { id: 'checklist', label: 'Checklist', href: '/checklist' },
        { id: 'inspirations', label: 'Inspirations', href: '/inspirations' },
      ]
    });
  }


  renderNavigationItems() {
    return this.state?.navigationItems?.map((navigationItem, navigationItemIndex) => {
      return <div key={navigationItemIndex} onClick={() => { window.location.href = navigationItem.href }} className={`navigation-item typo-subheading-1 typo-bold-2 ${this.props?.selectedNavigation === navigationItem.id ? 'active' : ''}`}>{navigationItem.label}</div>;
    })
  }
  
  
  render() {
    return (
      <div className="UserProfileNavigation">
        {this.renderNavigationItems()}
      </div>
    );
  }
  
}



export default UserProfileNavigation;