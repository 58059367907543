// import './AlbumGallery.scss';

import * as React from 'react';
import { Album } from '../../../interfaces/album.interface';


interface AlbumGalleryProps {
  album: Album;
  otherImageClicked?: (index: number) => void;
  coverImageClicked?: () => void;
}


interface AlbumGalleryState {

}




class AlbumGallery extends React.Component<AlbumGalleryProps, AlbumGalleryState> {

  emitOtherImageClicked(index: number): void {
    if(this.props?.otherImageClicked) {
      this.props.otherImageClicked(index);
    }
  }


  emitCoverImageClicked(): void {
    if(this.props?.coverImageClicked) {
      this.props.coverImageClicked();
    }
  }
  
  
  renderOtherImages() {
    return (
      <div className="other-images-container">{
        this.props?.album?.mostRecentImages?.map((image, imageIndex) => {
          return <div className="other-image" key={imageIndex} onClick={() => { this.emitOtherImageClicked(imageIndex) }}>
            <img src={image.url} alt="Other Image" />
          </div>
        })
      }</div>
    );
  }


  renderCoverImage() {
    return (
      <div className="cover-image">
        <img src={this.props?.album?.coverImageUrl} onClick={() => { this.emitCoverImageClicked() }} alt="Cover Image" />
      </div>
    );
  }
  
  
  render() {
    return (
      <div className="AlbumGallery">
        {this.renderCoverImage()}
        {this.renderOtherImages()}
      </div>
    );
  }

}




export default AlbumGallery;