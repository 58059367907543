import * as React from "react"
import {Button, Table, Image, Tag} from "antd";
import { BlogTag } from "../../../interfaces/blog-tag.interface";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {format} from "date-fns";


interface IAdminBlogTagsTableProps {
  blogTags:BlogTag[];
  selectedBlogTags:number[];
  removeBlogTag: (id:number)=>void;
  editBlogTag: (blogTag: BlogTag)=>void;
  selectionChange: (selectedRows)=>void;
  onChange: (pagination:any)=>void;
  pagination: any;
  hideAction: boolean;
}

interface IAdminBlogTagsTableState {
  columns: any[];
}

class AdminBlogTagsTable extends React.Component <IAdminBlogTagsTableProps, IAdminBlogTagsTableState> {

  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'Name',
          dataIndex: 'name'
        },
        {
          title: 'Slug',
          dataIndex: 'slug'
        },
        {
          title: 'Status',
          dataIndex: 'status',
          render: (text) => {
            return <Tag>{text}</Tag>
          }
        },
        {
          title: 'Date',
          dataIndex: 'createdAt',
          render: (text) => {
            return format(new Date(text), 'MMM dd, yyyy')
          }
        }
      ]
    };
  }

  componentDidMount() {
    if (this.props?.hideAction){
      this.setState({columns: [
          {
            title: 'Name',
            dataIndex: 'name'
          },
          {
            title: 'Slug',
            dataIndex: 'slug'
          },
          {
            title: 'Status',
            dataIndex: 'status',
            render: (text) => {
              return <Tag>{text}</Tag>
            }
          },
          {
            title: 'Date',
            dataIndex: 'createdAt',
            render: (text) => {
              return format(new Date(text), 'MMM dd, yyyy')
            }
          }
        ]});
    } else {
      this.setState({columns: [
          {
            title: 'Name',
            dataIndex: 'name'
          },
          {
            title: 'Slug',
            dataIndex: 'slug'
          },
          {
            title: 'Status',
            dataIndex: 'status',
            render: (text) => {
              return <Tag>{text}</Tag>
            }
          },
          {
            title: 'Date',
            dataIndex: 'createdAt',
            render: (text) => {
              return format(new Date(text), 'MMM dd, yyyy')
            }
          },
          {
            title: 'Action',
            key: 'operation',
            width: 200,
            render: (text, record) => {
              return <>
                <Button type="link" onClick={()=>this.props?.editBlogTag(record)}>Edit</Button>
                {/*<Button type="link" danger onClick={()=>this.props?.removeBlogTag(record.id)}>Remove</Button>*/}
              </>
            },
          }
        ]});
    }
  }

  render() {
    const rowSelection = {
      // selectedRowKeys: this.props?.selectedBlogTags,
      preserveSelectedRowKeys: false,
      onChange: (selectedRowKeys, selectedRows) => {
        this.props?.selectionChange(selectedRowKeys);
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };

    return (
      <div className="AdminBannersTable">
        <Table size="small" bordered rowKey="id"
          columns={this.state?.columns}
          dataSource={this.props?.blogTags}
          pagination={this.props?.pagination}
          onChange={this.props?.onChange}
        />
      </div>
    );
  }
}

export default AdminBlogTagsTable

