import * as React from 'react';
import { notification } from 'antd';

import UserApi from '../../../api-services/user-api.service';
import AuthApi from '../../../api-services/auth-api.service';

import Navbar from '../../basic-components/navbar/Navbar';
import LoginModal from '../../modals/login-modal/LoginModal';
import Footer from '../../basic-components/footer/Footer';
import BreadcrumbsNavigation from  '../../basic-components/breadcrumbs-navigation/BreadcrumbsNavigation';
import ForgotPasswordModal from '../../modals/forgot-password-modal/ForgotPasswordModal';

notification.config({
  duration: 12,
  top: 60
})

class PrivacyPage extends React.Component {

  userApi = new UserApi;
  authApi = new AuthApi;

  breadcrumbsNavItems = [
    { id: 1, label: 'Home', link: '/' },
    { id: 2, label: 'Terms of Use', link: '/terms' },
  ];

  componentDidMount(): void {
    this.setState({
      loginModalVisible: false,
      forgotPasswordModalVisible: false
    });

    this.loadSignedInUserProfile();
  }


  loadSignedInUserProfile(): void {
    if(!this.props?.isAccountSignedIn) {
      return null;
    }

    this.userApi.getUserProfile()
      .then(user => {
        this.setState({
          loggedOnUser: user
        });
      })
      .catch(error => {
        console.error(error);
        notification.error({ message: 'Error while loading the user\'s profile.', className: 'antd-mod center' });
      });
  }

  render() {
    return (
      <div className="ContactPage">
        <Navbar
          isAccountSignedIn={this.props?.isAccountSignedIn}
          user={this.state?.loggedOnUser}
          signInRequired={(redirectUrl) => { this.setState({ loginModalVisible: true, loginModalRedirectUrl: redirectUrl }); }}
        />

        <LoginModal
          visible={this.state?.loginModalVisible}
          googleSignInUrl={this.props?.googleSignInUrl}
          fbSignInUrl={this.props?.fbSignInUrl}
          csrfToken={this.props?.csrfToken}
          redirectUrl={this.state?.loginModalRedirectUrl}
          forgotPasswordClicked={() => { this.setState({ loginModalVisible: false, forgotPasswordModalVisible: true }) }}
          closed={() => { this.setState({ loginModalVisible: false }) }}
          registerLink="/registration"
        />

        <ForgotPasswordModal
          visible={this.state?.forgotPasswordModalVisible}
          loginClicked={() => { this.setState({ forgotPasswordModalVisible: false, loginModalVisible: true }) }}
          closed={() => { this.setState({ forgotPasswordModalVisible: false }) }}
        />

        <div className="page-content">
          <BreadcrumbsNavigation items={this.breadcrumbsNavItems} />
        </div>

        <div className="page-content main-content">
          <div className="category-header">
            <div className="content">
              <div className="title typo-headline">Terms of Use</div>
            </div>
          </div>

          <br/>
          <br/>
          <h3><strong>The User Terms Of Use were updated in 20th September 2021</strong></h3>
          <section>
            <p><strong>1. WHO WE ARE</strong></p>
            <div className="ml-5">
              <p>This e-Platform is owned and managed by Bridepay Sdn Bhd, a company incorporated in Malaysia ("<strong>Bridepay</strong>", "
                <strong>we</strong>", "<strong>us</strong>" or "<strong>our</strong>") pertaining to the use of Bridepay Services. Please refer to
                and read more about the "<strong>WHO WE ARE</strong>" as attached, published and included in the e-Platform.</p>

              <p>We appreciate and value your access to the e-Platform and to secure and safeguard your interest and ensure that you enjoy Bridepay Services provided herewith peacefully, we have set out terms and conditions to
                facilitate and govern your access and use of the e-Platform.</p>
            </div>
          </section>

          <section>
          <h3><strong>2. ACCEPTANCE </strong></h3>
            <div className="ml-5">
              <p>The terms of use set forth herein ("<strong>Terms of Use</strong>")
                govern your access and use of <strong>Bridepay</strong> and the use of any services,
                information and functions made available by us ("<strong>Bridepay Services</strong>") at the designated digital platforms which you have access to including
                but not limited to [<strong>www.bridepay.com</strong>] website or mobile application
                software through the "Android" or "iOS" mobile application or whatsoever digital
                platform available ("<strong>the e-Platform</strong>"). Your use and/ or
                continued usage of the e-Platform and Bridepay Services constitutes your full agreement to the Term of
                Use, our Privacy Policy and all other policies, which shall form a legally binding and enforceable contract
                between you and our company.</p>

              <p>Notwithstanding the above, we reserve the right (i) to refuse or bar your access to the e-Platform or
                to allow you to register an Account for any reason; and (ii) to refuse to authorize or grant you license to
                use and access to the e-Platform. If for any reason you do not agree with or cannot abide by the Terms of
                Use, our Privacy Policy and all other policies, you may exit this e-Platform immediately and delete all or
                any accounts that you have registered with the e-Platform.</p>

              <p>If you are a minor under the age of 18 or the legal age for giving consent hereunder pursuant to the
                applicable laws in your country, you must obtain permission from your parent(s) or legal guardian(s) to
                use and access the e-Platform. If you are the parent or legal guardian of a minor who is creating an
                account, you must accept and comply with the Terms of Use on the minor's behalf and you will be responsible for
                the minor's actions including any charges associated with the minor's use of the e-Platform
                and/or Bridepay Services or purchases made on the e-Platform. If you do not have consent from your parent(s) or
                legal guardian(s), you must stop using/accessing this e-Platform and/or Bridepay Services.</p>
            </div>
          </section>

          <section>
            <p><strong>3. VARIATIONS AND CHANGES</strong></p>
            <div className="ml-5">
              <p>We reserve the rights to update the Terms of Use including but not limited to amend, add or omit the
                Terms of Use from time to time and you are advised to read and review the Terms of Use regularly and to adhere
                with the Terms of Use to ensure that you are aware of your rights and obligations as the User of the
                e-Platform. Any changes and/or amendments will be effective when posted on the e-Platform with no other
                notices provided and you are deemed to be aware of and bound by any changes to the Terms of Use upon our
                publication of the same on the e-Platform.</p>
              <p>Any subsequent URL which may replace the e-Platform and your access and use or continued usage of the
                e-Platform and Bridepay Services shall be deemed as an acceptance of the Terms of Use, Bridepay Privacy
                Policy, Additional Policies and all other terms required, which shall form a legally binding and
                enforceable contract between you and the e-Platform.</p>
            </div>
          </section>

          <section>
            <p><strong>4. DEFINITION</strong></p>
            <div className="ml-5">
              <table>
                <tbody>
                <tr>
                  <td width="155">
                    <p>"<strong>Account</strong>"</p>
                  </td>
                  <td width="468">
                    <p>means a registered account which you create and register with us if you wish to access and
                      utilise the full facilities and features of the e-Platform and Bridepay Services, whether as a Buyer or
                      Vendor;</p>
                  </td>
                </tr>
                <tr>
                  <td width="155">
                    <p>"<strong>Additional Policies</strong>"</p>
                  </td>
                  <td width="468">
                    <p>means any and all other operating rules, policies, and / or guidelines in addition to the Terms
                      of Use, that may govern the use of the e-Platform and which is made known to you from time to
                      time;</p>
                  </td>
                </tr>
                <tr>
                  <td width="155">
                    <p>"<strong>Buyer</strong>"</p>
                  </td>
                  <td width="468">
                    <p>means any individual or company that has (i) used or browsed the e-Platform; and / or (ii)
                      submitted a request or offer to purchase for Wedding Deal offered by Vendor, via the e-Platform, in order to
                      receive price quotations, regardless of whether such Buyer ultimately hires or does not hire a
                      Vendor via the e-Platform;</p>
                  </td>
                </tr>
                <tr>
                  <td width="155">
                    <p>"<strong>Bridepay Privacy Policy</strong>"</p>
                  </td>
                  <td width="468">
                    <p>means a statement or legal document (in privacy law) that discloses some or the methods Bridepay
                      gathers, uses, discloses, and manages a User's data.</p>
                  </td>
                </tr>
                <tr>
                  <td width="155">
                    <p>"<strong>Bridepay Services</strong>"</p>
                  </td>
                  <td width="468">
                    <p>means the services and facilities provided by Bridepay including but not limited to: -</p>
                    <p>(i)&nbsp;allowing and facilitating the Vendor to publish and advertise the Vendor's Wedding Deal;</p>
                    <p>(ii)&nbsp;accepting Buyer's requests and disseminating them to Vendors;</p>
                    <p>(iii)&nbsp;facilitating Vendors to render offer, invitation to treat, or quotations on the e-Platform;</p>
                    <p>(iv)&nbsp;facilitating Buyers and Vendors to make contact and contract;</p>
                    <p>(v)&nbsp;maintaining profiles for Vendors; and</p>
                    <p>(vi)&nbsp;any other ancillary or related services;</p>
                  </td>
                </tr>
                <tr>
                  <td width="155">
                    <p>"<strong>Bridepay Store</strong>"</p>
                  </td>
                  <td width="468">
                    <p>means the e-store or online and digital page/ account created and registered and published by the
                      Vendor on the e-Platform to sell and intend or advertise to sell the Wedding Deal;</p>
                  </td>
                </tr>
                <tr>
                  <td width="155">
                    <p>"<strong>Content</strong>"</p>
                  </td>
                  <td width="468">
                    <p>means information, data and publication published, created and shown on the e-Platform included
                      but not limited to any creative element, for example, text, applications, images, archived messages,
                      data, information, e-services, audio and video files.</p>
                  </td>
                </tr>
                <tr>
                  <td width="155">
                    <p>"<strong>e-Platform</strong>"</p>
                  </td>
                  <td width="468">
                    <p>means:</p>
                    <p>(i)&nbsp;the websites bearing the domain [www.bridepay.com] and any other related microsites
                      and/or
                      mobile or web applications owned and operated by Bridepay which may or may not be of similar
                      names;
                      and</p>
                    <p>(ii)&nbsp;any related or ancillary facilities or functions such as e-mails, SMS, newsletters,
                      notifications or other communication or content.</p>
                  </td>
                </tr>
                <tr>
                  <td width="155">
                    <p>"<strong>Goods</strong>"</p>
                  </td>
                  <td width="468">
                    <p>means the goods made available for sale on the e-Platform by Vendors including any instalment of
                      the goods or any part of them;</p>
                  </td>
                </tr>
                <tr>
                  <td width="155">
                    <p>"<strong>Password</strong>"</p>
                  </td>
                  <td width="468">
                    <p>means the password which you create, register, provide and use in order to create and use an
                      Account and you are solely responsible to safeguard your Password during the usage of the e-Platform;</p>
                  </td>
                </tr>
                <tr>
                  <td width="155">
                    <p>"<strong>Products</strong>"</p>
                  </td>
                  <td width="468">
                    <p>means Goods and/or Services offered by the Vendor on the e-Platform;</p>
                  </td>
                </tr>
                <tr>
                  <td width="155">
                    <p>"<strong>Services</strong>"</p>
                  </td>
                  <td width="468">
                    <p>means the professional service offered and / or to be rendered by the Third-Party Service
                      provider in the course of his business to the Users of the e-Platform;</p>
                  </td>
                </tr>
                <tr>
                  <td width="155">
                    <p>"<strong>Vendor</strong>"</p>
                  </td>
                  <td width="468">
                    <p>means any individual or company that has signed up to offer their Goods and Services to the
                      general public and to Users via the e-Platform;</p>
                  </td>
                </tr>
                <tr>
                  <td width="155">
                    <p>"<strong>Transactions</strong>" or " <strong>Contract</strong>"</p>
                  </td>
                  <td width="468">
                    <p>means buying and selling activities of Wedding Deal and Products, between Buyers and Vendors
                      through the e-Platform.</p>
                  </td>
                </tr>
                <tr>
                  <td width="155">
                    <p><strong>"Terms of Use"</strong></p>
                  </td>
                  <td width="468">
                    <p>means, refer to and include the terms and conditions set out herein and the versions of the same
                      which may be modified from time to time;</p>
                  </td>
                </tr>
                <tr>
                  <td width="155">
                    <p>"<strong>User</strong>", "<strong>you</strong>" and
                      "<strong>your</strong>", as applicable</p>
                  </td>
                  <td width="468">
                    <p>means a person who accesses, uses, and/or participates in the e-Platform in any manner, whether
                      as a Buyer or Vendor; and</p>
                  </td>
                </tr>
                <tr>
                  <td width="155">
                    <p>"<strong>Wedding Deal</strong>"</p>
                  </td>
                  <td width="468">
                    <p>means the wedding packages created and developed by Vendor as one of Products at a discounted selling price.</p>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </section>

          <section>
            <p><strong>5. PRIVACY POLICY</strong></p>
            <div className="ml-5">
              <p>It is a pre-requisite condition that you must consent to the processing of your personal data as described
                in the Privacy Policy set out at Bridepay("
                <strong>Bridepay Privacy Policy</strong>") in order for your access, use and
                exploitation of the e-Platform and Bridepay Services. Once again, by accessing the e-Platform and/or using
                Bridepay Services, you agree to be bound by Bridepay Privacy Policy and any amendments to the foregoing
                issued by us from time to time. If you do not agree to Bridepay Privacy Policy, please do not access and/or
                use this e-Platform and/or Bridepay Services.</p>
            </div>
          </section>

          <section>
            <p><strong>6. USAGE OF THE e-PLATFORM AND/OR BRIDEPAY SERVICES</strong></p>
            <div className="ml-5">
              <p><strong>a)</strong> In consideration of your acceptance of the Terms of Use herein and your agreement to be bound by the
                Terms of Use, we grant you a revocable and non-transferable license to use the e-Platform and/or
                Bridepay
                Services which is effective until revoked, subject to these Terms of Use, for the purpose of accessing
                the
                e-Platform. Any breach of these Terms of Use shall result in the immediate revocation of the license
                granted herein without notice to you.
              </p>
              <p><strong>b)</strong> By accepting the Terms of Use of the e-Platform, you agree and accept not to: -</p>
              <ul>
                <li>Upload, create or otherwise make available any Content that containing whatsoever things, article,
                  image, words, data, information, photos, etc violated the laws of Malaysia or that will cause
                  injurious
                  to
                  public and private rights &nbsp;including but not limited to defamatory content, content with or
                  involving
                  violence, cruelty, nudity, sex, sexual deviancy, pornography, sexual degradation, solicitation of
                  prostitution, offensive language, crude references or hate speech; or content which is indecent,
                  obscene,
                  false or offensive in character, regardless of whether there is malice or intention to annoy, abuse,
                  threaten or harass any person or anything that is strictly prohibited under the law of Malaysia;
                </li>
                <li>use the e-Platform and/or Bridepay Services for the benefit of any third party or any manner not
                  permitted by the licenses granted herein;
                </li>
                <li>use Bridepay Services or upload Content to impersonate any person or entity;</li>
                <li>use Bridepay Services or upload Content in a manner that is fraudulent, unconscionable, false,
                  misleading or deceptive;
                </li>
                <li>cause, permit or authorize the modification, creation of derivative works, or translation of
                  Bridepay
                  Services without the express permission of Bridepay;
                </li>
                <li>open and/or operate multiple Accounts in connection with any conduct that violates these Terms of
                  Service;
                </li>
                <li>attempt to decompile, reverse engineer, disassemble or hack the e-Platform and/or Bridepay Services
                  (or any portion thereof), or to defeat or overcome any encryption technology or security measures
                  implemented by Bridepay with respect to Bridepay Services and/or data transmitted, processed or stored by
                  Bridepay;
                </li>
                <li>harvest or collect any information about or regarding other Users and/or Account holders, including
                  but not limited to any personal data or information;
                </li>
                <li>use Bridepay Services to collect or store personal data about other Users and/or Account holders in
                  connection with the prohibited conduct and activities set forth above;
                </li>
                <li>upload, email, post, transmit or otherwise make available any unsolicited or unauthorized
                  advertising, promotional materials, "<em>junk mail</em>", "<em>spam</em>", "<em>chain
                    letters</em>", "<em>pyramid schemes</em>", or any other unauthorized form of solicitation; and
                </li>
                <li>infringe the rights of Bridepay, including any intellectual property rights and any passing off of
                  the same thereof;
                </li>
              </ul>
              <p><strong>c)</strong> You understand that all Content, whether publicly posted or privately transmitted, is the sole
                responsibility of the person from whom such content originated. This means that you, and not Bridepay,
                are entirely responsible for all Content that you upload, post, email, transmit or otherwise make
                available through the Site. You understand that by using the e-Platform, you may be exposed to Content that you
                may consider to be offensive, indecent or objectionable. To the maximum extent permitted by applicable
                law, under no circumstances will Bridepay be liable in any way for any Content, including, but not limited
                to, any errors or omissions in any Content, or any loss or damage of any kind incurred as a result of the
                use of, or reliance on, any Content posted, emailed, transmitted or otherwise made available on the Site.
              </p>

              <p><strong>d)</strong> You acknowledge that Bridepay and its designees shall have the right (but not the obligation) in
                their sole discretion to pre-screen, refuse, delete, stop, suspend, remove or move any Content, including
                without limitation any Content or information posted by you, that is available on the e-Platform
                without any liability to you. Without limiting the foregoing, Bridepay and its designees shall have the
                absolute and discretionary right to remove any Content (i) that violates these Terms of Service; (ii) if we
                receive a complaint from another User; (iii) if we receive a notice or allegation of intellectual property
                infringement or other legal instruction or request for removal; or (iv) if such Content is violated
                the laws of Malaysia or otherwise objectionable. We may also block delivery of a communication (including,
                without limitation, status updates, postings, messages and/or chats) to or from Bridepay Services as
                part of our effort to protect Bridepay Services or our Users, or otherwise enforce the provisions of these
                Terms of Use. You agree that you must evaluate, and bear all risks associated with, the use of any
                Content, including, without limitation, any reliance on the accuracy, completeness, or usefulness of
                such Content. In this regard, you acknowledge that you have not and, to the maximum extent permitted by
                applicable law, may not rely on any Content created by Bridepay or submitted to Bridepay, including
                but not limited to information in all other parts of the e-Platform.
              </p>
            </div>
          </section>

          <section>
            <p><strong>7. WEDDING DEAL</strong></p>
            <div className="ml-5">
              <p>Buyers can find various types of Wedding Deal on the e-Platform offered by Vendors to facilitate the
                Buyers
                to find and make transactions to purchase Wedding Deal that suit the Buyer's needs, including but not
                limited to the following categories of Products:-</p>

              <p><em>Catering, jewelry, venues, bridal services, decoration &amp; lighting, dress &amp; attire,
                favor &amp; gifts, honeymoon trips, invitations, men's formal wear, photo booth, photography,
                videography,
                wedding planning, wedding cake, hair and makeup, entertainment (MC), entertainment (bands),
                entertainment
                (DJ), officiants , wedding shoes, health and beauty, boutonnieres and corsages, sweet corner, dance and
                choreography, event rentals, flowers, wedding accessories and all types of Products related and in
                connection to wedding ceremonies and activities.</em></p>
            </div>
          </section>

          <section>
            <p><strong>8. ACCOUNT SECURITY</strong></p>
            <div className="ml-5">
              <p>We take security matters very seriously and we shall use our best endeavor to secure and safeguard the
                security of e-Platform and the activities to be conducted on the e-Platform. Nevertheless, it is your
                duty
                and responsibility to ensure the following:</p>

              <ul>
                <li>maintaining adequate security and control over your Account sign-in details, including but not
                  limited
                  to any passwords, personal identification numbers, API keys, or any other codes associated with your
                  Account;
                </li>
                <li>enabling any additional security features available to you, including the activation of two factor
                  authentication on your Account;
                </li>
                <li>keeping your contact details up to date so that you can receive any notices or alerts we may send to
                  you by&nbsp;way of <a href="https://www.luno.com/en/legal/terms#electronic_communication"><u>Electronic
                    Communications</u></a>;
                </li>
                <li>maintaining security and control over the email mailbox, phone number and two-factor authentication
                  applications or devices associated with your Account.
                </li>
              </ul>

              <p>Failure to take the above measures, and any other security measures available to you, may result in or
                expose you to unauthorized access to your Account.</p>

              <p>Any loss, theft or damage to your Account attribute to you or due to your commission or omission or
                negligence shall be solely assumed by you alone. We shall have no liability to you for or in connection
                with
                any unauthorized access to your Account, where such unauthorized access was due to no fault at our end,
                and/or any failure by you to act upon any notice or alert that we send to you.</p>

              <p>The security of your Account may be compromised, or interruption caused to it, by phishing, spoofing or
                other attack, computer viruses, spyware, scareware, Trojan horses, worms or other malware that may
                affect
                your computer or other equipment. We strongly recommend that you regularly use reputable virus screening
                and
                prevention software and remain alert to the fact that SMS, email services and search engines are
                vulnerable
                to spoofing and phishing attacks.</p>

              <p>Care should be taken in reviewing messages purporting to originate from us and, should you have any
                uncertainty regarding the authenticity of any communication, you should log in to your Account through
                the
                e-Platform to review any transactions or required actions.</p>

              <p>To the maximum extent permitted by applicable law, you accept and agree that you have full
                responsibility
                for all activity that occurs in or through your Account and accept all risks of any unauthorized or
                authorized access to your Account.</p>
              <p><strong>Private keys.</strong>&nbsp;We securely store all private keys ("
                <strong>Private Keys</strong>") associated with any Account. You accept and agree
                that we shall retain full ownership and control of the Private Keys associated with your Account and
                that you shall have no control of, access to, or the ability to use, such Private Keys.</p>
            </div>
          </section>

          <section>
            <p><strong>9. BUYER'S TERMS</strong></p>
            <div className="ml-5">
              <p>We defined "Buyer" as any individual or company that has (a) used or browsed the e-Platform;
                and / or (b) submitted a request or offer to purchase for Wedding Deal or Products offered by Vendor,
                via the e-Platform, in order to receive price quotations, regardless of whether such Buyer ultimately hires
                or does not hire a Vendor via the e-Platform. In regards thereto, the Term of Use, together with the
                Privacy Policy and any Additional Policies shall constitute an agreement between the Buyer and the
                e-Platform.</p>

              <p>By accessing to the e-Platform, the Buyer understands, acknowledges, undertakes and agrees that: -</p>

              <ol type="a">
                <li>The Buyer is a capable person and is able to bind himself in a legal agreement.</li>
                <li>The Buyer is required and must provide an active telephone number, email address, mailing address
                  and
                  particulars required under the registration process when registering for an Account with Bridepay;
                </li>
                <li>Bridepay without prior notification to the Buyer, has the authority to take necessary actions for
                  any
                  alleged violation or violation of the Terms of Use and/or applicable legal regulations including but
                  not
                  limited to account moderation, account closure, order/ request/ purchase/ subscriptioncancellation,
                  Account suspension, and/or Account deletion.
                </li>
                <li>By accepting this Terms of Use, you are required to adhere strictly to the following prohibition: -
                </li>
                <ul>
                  <li>any copying in whatsoever format (electronic or otherwise);</li>
                  <li>any commercial use of any part of this e-Platform unless express written consent is obtained from
                    us;
                  </li>
                  <li>usage of any computer program tools or device program algorithm methodology or any similar
                    equivalent
                    processes to access, acquire, copy or monitor any part of the e-Platform or Content;
                  </li>
                  <li>reproducing or circumventing any navigational structure or presentation of the e-Platform or
                    Content;
                  </li>
                  <li>obtaining or attempt in obtaining any materials, documents, information by any means whatsoever
                    not
                    purposely made available through the e-Platform;
                  </li>
                  <li>copy, reproduction, republishing, uploading, encoding, modifying, translating, displaying,
                    exploiting
                    commercially, distribution, transmitting of any portion of this Website or make any derivative works
                    from
                    the e-Platform to another website without express written consent from us;
                  </li>
                  <li>posting or transmitting any unlawful, threatening, defamatory, obscene, scandalous, inflammatory,
                    pornographic, profane material or any material that could constitute or encourage conduct that would
                    be
                    considered a criminal offense or give rise to civil liability or otherwise breach any law;
                  </li>
                </ul>
              </ol>
              <p>We reserve all rights unless expressly stated otherwise and we shall not be responsible if you breach
                any
                intellectual property laws or any other laws due to your failure to comply with any of the prohibition
                above.</p>
            </div>
          </section>

          <section>
            <p><strong>10. VENDOR'S TERMS</strong></p>
            <div className="ml-5">
              <p>Subject to the agreement or terms of use separately signed by the Vendor, is it agreed that the Vendor
                understands, acknowledges, undertakes and agrees that: -</p>
              <ol type="a">
                <li>the Vendor is a capable person or entity and is able to bind himself/herself/itself in a legal
                  agreement.
                </li>
                <li>subject to Clause 2, the Vendor is required and must provide an active telephone number, email
                  address,
                  mailing address, person-in-charge contact, and particulars required under the registration process
                  when
                  registering for an Account with Bridepay;
                </li>
                <li>by setting up an Account with Bridepay, the Vendor has consented Bridepay to use the Vendor's
                  personal data or information if required; including, but not limited to, when Bridepay is conducting
                  investigation and verification of the Vendor's identity and any other Bridepay Services;
                </li>
                <li>for the purpose of promoting the sales of theWedding
                  Deal or Products listed by Vendor, Bridepay may post such
                  Wedding Deal or Products on third-party websites (such as portal sites and price comparison
                  sites)
                  and other websites (domestic or foreign) operated by Bridepay;
                </li>
                <li>the Vendor will be responsible for paying all taxes, customs and duties for the Wedding Dealand
                  Products
                  sold and Bridepay cannot provide any legal or tax advice in this regard. As tax laws and regulations
                  may
                  change from time to time, Vendors are advised to seek professional advice if in
                  doubt;</li>
                <li>the sale of any Wedding Dealand Products on the e-Platform is at Vendor's own risk;</li>
                <li>Bridepay shall not be liable for any direct, indirect, incidental, special, consequential or
                  exemplary
                  damages, including but not limited to, damages for loss of profits, goodwill, use, data or other
                  intangible losses resulting from the use of or inability to use the Bridepay Service;
                </li>
                <li>To the extent permitted by applicable laws, in no event shall Bridepay be liable for loss of profits
                  or
                  any special, incidental or consequential damages arising out of or in connection with the Platform or
                  Bridepay's Services or these Terms of Use. Bridepay is in no responsibility to indemnify and to hold
                  any of the Vendor, or Vendor's subsidiaries, affiliates, partners, officers, directors, agents,
                  employees, and suppliers harmless from any claim or demand, including reasonable attorneys' fees,
                  made by any third party due to or arising out of the Vendor's breach of these Terms of Use or the
                  documents it incorporates by reference, or the Vendor's violation of any law or the rights of a
                  third party.
                </li>
                <li>any Content, material or idea you post on or through the Bridepay Services, or otherwise transmit to
                  Bridepay by any means (each, a "<strong>Submission</strong>"), is not considered
                  confidential by Bridepay and may be disseminated or used by Bridepay without compensation or liability
                  to
                  you for any purpose whatsoever, including, but not limited to, developing, manufacturing and marketing
                  products. By making a Submission to Bridepay, the Vendor acknowledges and agrees that Bridepay and/or
                  other third parties may independently develop software, applications, interfaces, products and
                  modifications and enhancements of the same which are identical or similar in function, code or other
                  characteristics to the ideas set out in your Submission. Accordingly, you hereby grant Bridepay and
                  its
                  successors a perpetual, irrevocable, worldwide, non-exclusive, royalty-free, sub-licensable and
                  transferable license to develop the items identified above, and to use, copy, distribute, republish,
                  transmit, modify, adapt, create derivative works of, publicly display, and publicly perform any
                  Submission
                  on, through or in connection with the Bridepay Services in any media formats and through any media
                  channels, including, without limitation, for promoting and redistributing part of the Bridepay
                  Services
                  (and its derivative works). This provision does not apply to personal information that is subject to
                  our
                  privacy policy except to the extent that you make such personal information publicly available on or
                  through the Bridepay Services.
                </li>
                <li>The Vendor and Bridepay agree to use each party's Confidential Information solely as necessary for
                  performing its obligations under these Terms of Use and in accordance with any other obligations in
                  these
                  Terms of Use. The Vendor shall agrees that he shall take all reasonable steps, at least substantially
                  equivalent to the steps he takes to protect his own proprietary information, to prevent the
                  duplication,
                  disclosure or use of any such Confidential Information, other than (i) by or to his employees, agents
                  and
                  subcontractors who must have access to such Confidential Information to perform such party's
                  obligations hereunder, who each shall treat such Confidential Information as provided herein, and who
                  are
                  each subject to obligations of confidentiality to such party that are at least as stringent as those
                  contained herein; or (ii) as required by any law, regulation, or order of any court of proper
                  jurisdiction
                  over the parties and the subject matter contained in these Terms of Use, provided that, if legally
                  permitted, the receiving party shall give the disclosing party prompt written notice and use
                  commercially
                  reasonable efforts to ensure that such disclosure is accorded confidential treatment.
                </li>
              </ol>
              <p><em>10.1 Vendor's Obligations</em></p>

                <ol type="a">
                  <li>Vendor shall properly manage and ensure that relevant information such as the price and the
                    details
                    of
                    items, inventory amount and terms and conditions for sales is updated on Vendor's listing.
                  </li>
                  <li>The Vendor shall deliver the Wedding Deal and Products diligently.</li>
                  <li>The price of Wedding Deal and Products for sale will be determined by the Vendor at his/her own
                    discretion. The price of a Wedding Deal and Products and shipping charges (in relation to Goods)
                    shall
                    include the entire amount to be charged to Buyer such as sales tax, value-added tax, tariffs, etc.
                    and
                    Vendor shall not charge Buyer such amount additionally and separately.
                  </li>
                  <li>Vendor shall issue receipts, credit card slips or tax invoices to Buyer on request.</li>
                  <li>By accepting this Terms of Use, the Vendor shall not:</li>
                  <ul>
                    <li>use the Platform or Bridepay Services for any illegal, tortious or unauthorized purpose
                      (including
                      but
                      not limited to copyright laws);
                    </li>
                    <li>include any inappropriate Content in the Vendor's Store Account's profile, title, posting,
                      messages, emails, made in or through the Platform;
                    </li>
                    <li>offer, register or list on the Platform the category of any Goods and/or Services which you
                      believe
                      that
                      you are not able to provide or offer the Services and/or Goods to the Buyers of the Platform;
                    </li>
                    <li>offer any Services and/or Goods which are against the law;</li>
                    <li>use misleading keywords, messages, titles that do not accurately describe their Services and/or
                      Goods;
                    </li>
                    <li>assign or otherwise transfer the Terms of Use, or any of your rights or obligations hereunder,
                      to
                      any
                      third party without Bridepay's prior written consent, to be given or withheld in Bridepay's
                      sole discretion;
                    </li>
                  </ul>
                </ol>
            </div>
          </section>

          <section>
            <p><strong>11. CONTRACT </strong></p>
            <div className="ml-5">
              <ol type="a">
                <li>The Buyer understands and agrees that it is obligatory to make transactions through the payment
                  methods
                  that have been determined by the e-Platform. In each purchase, the Buyer makes payment using the
                  payment
                  method selected or previously selected by the Buyer, and then Bridepay will forward the funds (subject
                  to
                  the contract made between Vendor and us) to the Vendor if the procedures, steps and activities of the
                  Transaction or Contract at the Bridepay Store have been processed and/ or completed.
                </li>
                <li>The Buyer in making the purchase of or requesting for the Wedding Deal or any of the Products
                  understands and agrees that the Buyer is responsible for reading, understanding, and agreeing to all
                  information or the overall description of the Wedding Deal and any of the Products, including but not
                  limited to the color, quality, purpose, function, and/or size of the Wedding Deal and any of the
                  Product
                  before making an offer or commitment to purchase.
                </li>
                <li>The Buyer understands and agrees that the actual color of the Goods as seen on the e-Platform
                  depends on
                  the Buyer's computer monitor and shall serve as illustration purpose only.
                </li>
                <li>The Buyer is bound by a legally binding contract to purchase the Wedding Deal and any of the
                  Products
                  when the Buyer confirm the purchases, request, orders, subscriptions of the Goods and/or Services on
                  the
                  e-Platform by executing and taking steps and procedures set out in the e-Platform.
                </li>
                <li>Bridepay is not in the capacity to transfer legal ownership of the Wedding Deal and any of the
                  Products
                  from the Vendor to the Buyer. The Buyer understands and agrees that the availability of Wedding Deal
                  and
                  Products is the sole responsibility of the Vendor. Regarding the availability of Wedding Deal and
                  Products
                  at Bridepay, it may change at any time subject to first come first serve basis.
                </li>
                <li>The Buyer understands and agrees that all Transactions or Contracts carried out between the Buyer
                  and
                  the Vendor other than through the method determined by Bridepay and/or without Bridepay's knowledge,
                  including but not limited to facilities or private networks, messaging, special transaction
                  arrangements
                  outside the e-Platform is entirely the personal responsibility of the Buyer and Vendor.
                </li>
                <li>Bridepay has full authority to cancel payments without prior notice.</li>
                <li>Payment by the Buyer must be made immediately, no later than 2 days after the Buyer checks out from
                  the
                  cart. If within that time limit the payment or payment confirmation has not been made by the Buyer,
                  Bridepay has the authority to cancel the transaction which we shall not be liable for the
                  cancellation.
                  The Buyer shall have not right to claim or no claims against us or the Vendor in for the cancellation
                  of
                  the transaction to Bridepay.
                </li>
                <li>The Buyer understands and agrees that he/she does not notify or submit proof of payment and/or
                  payment
                  data to parties other than Bridepay. If there is a loss due to notification or submission of proof of
                  payment and/or payment data by the Buyer to another party, then it will be entirely the personal
                  responsibility of the Buyer.
                </li>
                <li>For Goods so ordered, payment by the Buyer is only allowed to credit and release to the Vendor when
                  the
                  Buyer confirms the receipt of the Goods from the Vendor by hitting the "<em>confirm
                    receipt</em>" button or upon the expiry of 7 days from the date of the delivery of the Goods,
                  whichever occurred earlier. For avoidance of doubt, it shall automatically deem that the Buyer has
                  received or accepted the Goods in good order and good merchantable quality upon the expiry of 7 days
                  from
                  the date of the delivery of the Goods.
                </li>
                <li>However, for Services so ordered, payment by the Buyer is allowed to credit and release to the
                  Vendor
                  after 7 days from the date of the Buyer's confirmation of the purchases, requests, orders,
                  subscriptions of the Services. The balance of the payment shall be released or paid to the Vendor
                  directly
                  in accordance with the terms and condition set out under each Wedding Deal.
                </li>
                <li>The Buyer understands and agrees that if there is a problem with the Transaction or Contract,
                  including
                  but not limited to refunds, and/or force majeure conditions carried out by the Buyer, it is entirely
                  the
                  responsibility of the Vendor.
                </li>
                <li>The Buyer understands and agrees that he/she waive all his/her rights to claims from Bridepay as a
                  platform provider for all risks arising from transaction problems including but not limited to
                  cancellation of payment and/or overpayment of the price in the order, out of stock, changes in product
                  prices, unable to provide Products on the date the Buyer wants, where the Buyer makes transactions at
                  the
                  e-Platform and the Buyer makes transactions other than through the payment method that has been
                  determined
                  by Bridepay and/or without Bridepay's knowledge.
                </li>
                <li>The Buyer understands and agrees that it is obligatory to make transactions through the payment
                  methods
                  that have been determined by the e-Platform. The Buyer makes a payment using the payment method
                  previously
                  selected by the Buyer, and then Bridepay will forward the funds to the Vendor if the stages of the
                  sale
                  and purchase transaction at the e-Platform have been completed.
                </li>
              </ol>

              <p><em><strong>11.1 Delivery</strong></em></p>
              <ul>
                <li>In respect of purchasing goods or any physical items at the e-Platform, the estimation of the
                  delivery
                  date shall be not more than 14 days from the date that the Buyer places booking or order or the date
                  of
                  scheduled delivery, whichever occur later; and
                </li>
                <li>In respect of purchasing services at the e-Platform, the estimation of the delivery date shall be on
                  the
                  actual schedule delivery date or not more than 14 days from the date of scheduled delivery subjection
                  to
                  Buyer's availability.
                </li>
              </ul>
            </div>
          </section>

          <section>
            <p><strong>12. PURCHASE AND SALE OF ALCOHOL</strong></p>
            <div className="ml-5">
              <ul>
                <li>The purchase and sale of alcoholic Products on the e-Platform is permitted by Bridepay subject to
                  the
                  terms and conditions of this Terms of Use. In terms of Products categorized and labelled with Alcohol,
                  you
                  understand and acknowledge that the sale of Alcohol to individuals below the age of 21 years old
                  and/or
                  Muslim individuals is strictly prohibited. If you fall below any of the categories in this Clause
                  12.1,
                  kindly exit the alcoholic Products category page immediately.
                </li>
                <li>If you are a Buyer of alcoholic Products, you will be deemed to have consented to the terms and
                  conditions in this Clause when you purchase alcoholic Products on the e-Platform. Similarly, if you
                  are an
                  approved Vendor of alcoholic Products, you will be deemed to have consented to the terms and
                  conditions in
                  this Clause when you sell alcoholic Products on the e-Platform.
                </li>
                <li>The Buyer is required to confirm that you are neither fall under the category of under-age of 21
                  years
                  old nor Muslim before you proceeded to place your purchase vide the e-Platform.
                </li>
                <li>The Vendor has the rights to terminate the order placed should the Vendor believe and suspect that
                  the
                  Buyer fall under one of the prohibited categories to purchase alcoholic Products.
                </li>
              </ul>
            </div>
          </section>

          <section>
            <p><strong>13. CANCELLATION, RETURN AND REFUND POLICY</strong></p>
            <div className="ml-5">
              <p><strong>13.1 Goods </strong></p>

              <ol type="a">
                <li>In the event that the Goods that the Buyer purchases from the Vendor via the e-Platform are
                  defective or
                  are damaged, Buyer may submit a refund request and thereafter returning the defective or damaged Goods
                  to
                  the Vendor or to Bridepay who will hold the alleged defective Goods for and on behalf of the Vendor
                  within
                  7 days from the date of receipt of the Goods. Upon receipt of the defective or damaged Goods, Bridepay
                  shall refund the cost of purchase of the product to the Buyer. Please note however that we, Bridepay
                  do
                  not bear the cost of transport of the Goods.
                </li>
                <li>Ordinarily, Buyer is only allowed to cancel his/her order to purchase the Goods prior to the payment
                  is
                  made.
                </li>
                <li>If the Buyer decides to cancel his/her orders 24 hours after the relevant order has been confirmed
                  and
                  payment has been made but prior to shipment, Bridepay or the Vendor shall be entitled to impose a
                  minimum
                  of <strong>5%</strong>of the total amount of the purchase price of the relevant Goods and
                  the processing fee as administrative fees, in addition to the transaction fees charged by the payment
                  partner banks. The balance after deduction of the administrative fees and the transaction fees will be
                  refunded to the Buyer.
                </li>
                <li>Where the Goods have been delivered to the Buyer even after such refunds have been claimed by the
                  Buyer,
                  Buyer shall immediately notify Bridepay of the delivery. The ownership in the Goods shall not pass to
                  the
                  Buyer and Buyer shall hold the Goods as Bridepay's trustee and bailee and shall keep the Goods
                  separate
                  from those of the Buyer. Bridepay shall be entitled at any time to demand the Buyer to deliver up the
                  Goods to Bridepay and in the event of non-compliance Bridepay reserves its right to take legal action
                  against the Buyer for the delivery of the Goods and also reserves its right to seek damages and all
                  other
                  costs including but not limited to legal fees against the Buyer.
                </li>
              </ol>

              <p><strong>13.2 Services</strong></p>

              <ol type="a">
                <li>A full and unconditional refund is not guaranteed in the event that the Services purchased by the
                  Buyer
                  from the e-Platform are not delivered by the Vendor due to the following reasons stated, Bridepay
                  shall
                  not be liable of the losses, damages and liability arises from our third party service provider: -

                  <ul>
                    <li>Vendor has wound up, adjudicated bankrupt, absconded, disappeared or failed to deliver due to
                      whatsoever reasons after the order has been placed;
                    </li>
                    <li>It was the Vendor's negligence and/or mistake in the booking of the events, venues and etc;
                      and
                    </li>
                    <li>Vendor double park the dates so ordered by the Buyer.</li>
                  </ul>
                  <p className="ml-10">However, Bridepay shall use its best endeavor to recover the costs and/or sum of the undelivered
                    services
                    from the Vendor with no admission as to liability.</p>
                </li>
                <li>Bridepay does not monitor and facilitate the cancellation, return and refund process which made
                  outside
                  the e-Platform between the Buyer and Vendor privately.
                </li>
                <li>NO refund shall be given to the Buyer arising from the Buyer's voluntary cancellation of the
                  Services so ordered by the Buyer from the Vendor.
                </li>
              </ol>
            </div>
          </section>

          <section>
            <p><strong>14. CUSTOMER SERVICE CENTRE</strong></p>
            <div className="ml-5">
              <ol type="a">
                <li>Customer Service Center is a service that will facilitate Buyers in dealing with obstacles in
                  transactions between Buyers and Vendors that occur on the e-Platform. Buyers can contact the Help
                  Center
                  service by sending an email to cs@bridepay.com
                </li>
                <li>By using this service, Buyers and Vendors are expected to provide evidence of appropriate and
                  required
                  information in the form of Order ID, Buyer's email address, chronology when facing transaction
                  problems,
                  which can be screenshots or video recordings, proof of transactions, and/or technical details that may
                  be
                  required such as the model and type of device , the operating system used, as well as the version of
                  the
                  Bridepay application installed as well as other supporting evidence that can be used as the basis for
                  justification for each argument issued by each Party.
                </li>
                <li>The resolution of problems and/or problems through this Customer Service can be in the form of
                  solutions
                  produced based on a mutual agreement between the Buyer and/or Vendor.
                </li>
                <li>If no agreement is found between the Buyer and the Vendor, Bridepay has the right to help resolve
                  the
                  problem after taking into account the evidence provided.
                </li>
                <li>The Buyer understands and agrees that the Customer Service Center service is helpful in resolving
                  transaction problems, as follows: -

                  <ul>
                    <li>Buyer cannot log in or register to the e-Platform;</li>
                    <li>The Buyer cannot make or proceed to the payment process;</li>
                    <li>Buyer cannot see Wedding Deal and Products offered by Vendors;</li>
                    <li>Buyer does not receive notifications of successful transactions;</li>
                    <li>Buyer receives or views error, failure, and/or interruption notifications;</li>
                    <li>Buyer can report abuse of photos or videos uploaded by Vendors in Vendor's portfolio albums;
                    </li>
                    <li>Buyer can report fraud by including proof of transactions with the Vendor. In this case,
                      Bridepay will only verify that every incoming report regarding fraud is true from the Vendor's end
                      Users, then the dispute resolution will be returned to the agreement of each Party; and
                    </li>
                    <li>Buyer may report Vendor, if it is known or found that Vendor is selling and/or displaying
                      prohibited Products and/or Content.
                    </li>
                  </ul>
                </li>
                <li>The Buyer understands and agrees that in the event of a dispute between the Buyer and/or the Vendor
                  regarding the Wedding Deal and Products offered and/or provided by the Vendor and problems with the
                  Refund transaction, it is beyond Bridepay's responsibility and control and is the Vendor's personal
                  responsibility as the service provider offered and/or given. The resolution of the dispute will be
                  returned to the discretion of each Party.
                </li>
              </ol>
            </div>
          </section>

          <section>
            <p><strong>15. INTELLECTUAL PROPERTY RIGHTS</strong></p>
            <div className="ml-5">
              <ol type="a">
                <li>Copyrights to all original materials, content and arrangement of the e-Platform including but not
                  limited to layout, visual interfaces, images, look and feel, design, sound etc, and any underlying
                  software and computer codes ("<strong>Data</strong>") but does not include
                  pictures, photographs and/or artworks uploaded by Users of the e-Platform belongs to and are
                  proprietary to us and/or our affiliates and subsidiaries, where applicable. Any name, logo, trade mark, service
                  mark, design, copyright, domain name or other intellectual properties appearing on this e-Platform is owned
                  by us and may not be used by you without our prior written consent.
                </li>
                <li>Any Data on the e-Platform is provided to you for your information and personal use only and may not
                  be used, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise
                  exploited for any other purposes whatsoever without the prior written consent of the respective owners.
                </li>
                <li>Bridepay reserves all rights not expressly granted in and to the e-Platform and the Data. You agree
                  not to use, copy, or distribute, any of the Data other than as expressly permitted herein, including any
                  use, copying, or distribution of Submitted Content obtained through the e-Platform for any commercial
                  purposes. If you download or print a copy of the Data for personal use, you must retain all copyright and other
                  proprietary notices contained thereon. You agree not to circumvent, disable or otherwise interfere
                  with security features of the e-Platform or features that prevent or restrict use or copying of any Data or
                  enforce limitations on use of the e-Platform or the Data therein.
                </li>
              </ol>
            </div>
          </section>

          <section>
            <p><strong>16. NO WARRANTIES, LIABILITY AND/OR INDEMNITY</strong></p>
            <div className="ml-5">
              <ol type="a">
                <li>Bridepay does not represent that the e-Platform or any Content or service related to the e-Platform
                  or any links from the e-Platform whether to third party websites will be error-free, uninterrupted or
                  that any errors will be corrected or that usage of any service of the e-Platform or links to third party
                  websites will provide any specific result. We shall not be responsible or liable for any damages
                  caused by viruses or other forms of contamination or destructive features that may affect your computer or data
                  from your account as a result of your use of the e-Platform or links to third party websites, including but
                  not limited to downloading any materials, texts, images or otherwise from the e-Platform or links to third
                  party websites.
                </li>
                <li>To the fullest extent permitted by law, we shall not be liable and you shall release, hold harmless
                  and shall indemnify us, our affiliates, subsidiaries, service providers (including but not limited to the
                  printing company), partners, employees, agents and servants in respect of any losses and damages of
                  any kind, including but not limited to any direct, indirect, special, incidental, consequential and
                  exemplary damages arising out of or in connection with the use of the e-Platform and or links to third party
                  websites.
                </li>
                <li>The e-Platform and all data and/or information contained therein are provided on an "as
                  is" and "as available" basis without any warranties, claims or representations made by
                  Bridepay of any kind either expressed, implied or statutory with respect to the e-Platform and/or
                  Bridepay Services, including, without limitation, warranties of non-infringement of third-party rights, title,
                  merchantability, satisfactory quality or fitness for a particular purpose. All data and/or information
                  contained in the e-Platform and/or Bridepay Services are provided for informational purposes only.
                </li>
              </ol>
            </div>
          </section>

          <section>
            <p><strong>17. TECHNICAL MATTERS AND LIMITATION</strong></p>
            <div className="ml-5">
              <p>Please be informed that Bridepay Services and this e-Platform is offered to Users in limited
                jurisdictions.
                This section of the Terms of Use applies to you if you access and/or use the e-Platform. In accessing
                and/or
                using Bridepay for any purpose, you agree and accept that:</p>
              <ol type="a">
                <li>all trades executed on Bridepay are executed automatically by way of algorithm or system created on
                  the
                  e-Platform, based on your request, order, or instruction which cannot be reversed. However, you may
                  withdraw or cancel any Transaction or Contract by resorting to Clause 14. In the case of technical
                  issues
                  affecting Bridepay's internal systems, Bridepay may, where possible and in its sole discretion, take
                  steps to reverse or otherwise amend a trade;
                </li>
                <li>Bridepay makes no guarantee that the e-Platform will be available without interruption; that there
                  will
                  be no delays, failures, errors, omissions or loss of transmitted information; or that any order will
                  be
                  executed, accepted, recorded, or remain open. Bridepay may, at any time and in our sole discretion,
                  refuse
                  any trade, impose limits on the trade amount permitted, or impose any other conditions or restrictions
                  upon your use of the e-Platform that we deem necessary. We may, for example, limit the number of open
                  orders that you may establish or restrict trades from certain locations. Bridepay reserves the right
                  to
                  take such action without prior notice.
                </li>
              </ol>
            </div>
          </section>

          <section>
            <p><strong><em>18. ELECTRONIC COMMUNICATIONS</em></strong></p>
            <div className="ml-5">
              <p><strong><em>18.1</em></strong> You agree that all communications, notices, agreements, renewals, statements, and
                disclosures from us are deemed to have been given to you: -
              </p>
              <ol type="a">
                <li>If communicated through any electronic media as we may select from time to time, will be deemed to
                  be
                  notified to you on the date of transmission, publication and/or broadcast;
                </li>
                <li>We may from time to time designate other acceptable modes of giving notices (including but not
                  limited
                  to e-mail or other forms of electronic communication) and the time or event by which such notice shall
                  be
                  deemed given.
                </li>
              </ol>

              <p><strong><em>18.2</em></strong> By using the e-Platform, you consent to receiving electronic communications from us. You agree that
                any notices, agreements, disclosures, or other communications that we send to you electronically will
                satisfy any legal communication requirements, including that those communications be in writing.
              </p>
            </div>
          </section>

          <section>
            <p><strong>19. MODIFICATION</strong></p>
            <div className="ml-5">
              <p>We reserve the rights to modify and/or update the Terms of Use from time to time by uploading the
                updated
                terms of use to the e-Platform and emailing a copy of the same to your last known correspondence (if you
                have provided the same to us) and it shall take immediate effect.</p>
            </div>
          </section>

          <section>
            <p><strong>20. APPLICABLE LAW</strong></p>
            <div className="ml-5">
              <p>This Terms of Use and all matters arising out of or in connection with this Terms of Use, are subject
                to and interpreted by the laws of Malaysia.</p>
            </div>
          </section>

          <section>
            <p><strong>21. SEVERABILITY</strong></p>
            <div className="ml-5">
              <p>If one or more of the provisions in this Terms of Use becomes invalid, invalid, unenforceable, or
                violates the law either in whole or in part, based on the laws and regulations, this will not nullify the
                validity and enforceability of other provisions in this Terms of Use. Accordingly, other provisions of this Terms
                of Use will remain in full force and effect, regardless of the provisions which are deemed invalid,
                invalid, unenforceable or in violation of the law.</p>
            </div>
          </section>
        </div>

        <Footer userType={this.state?.loggedOnUser?.type}/>
      </div>
    );
  }

}

export default PrivacyPage;