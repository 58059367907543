import * as React from "react"


class HelloWorld extends React.Component {  
  render() { 
    return (
      <div>
        <h1>HELLO WORLD</h1>      
      </div>
    );
  }
}

export default HelloWorld