import * as React from 'react';
import {Button, message} from 'antd';
import { ShareAltOutlined, FacebookFilled, InstagramFilled, TwitterSquareFilled, MailFilled, CopyFilled } from '@ant-design/icons';
import {Product} from "../../../interfaces/product.interface";

interface SocialShareProps {
  product?: Product;
}

interface SocialShareState {
  menuActive: boolean;
}

class SocialShare extends React.Component<SocialShareProps, SocialShareState> {
  constructor(props) {
    super(props);
    this.state = {menuActive: false};
  }

  handleToggleMenu = () => {
    this.setState({menuActive: !this.state.menuActive});
  };

  handleCopy = () => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(`https://www.bridepay.com/products/${this.props?.product.slug}`)
        .then(() => {
          // Set state to indicate that text has been copied
          message.success("Copied");
          // notification.success({ message: 'Copied', className: 'antd-mod center' });
          console.log("copied");
        })
        .catch(err => {
          console.error('Failed to copy:', err);
        });
    } else {
      // Fallback for browsers that don't support navigator.clipboard
      const textArea = document.createElement('textarea');
      textArea.value = `https://www.bridepay.com/products/${this.props?.product.slug}`;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      // Set state to indicate that text has been copied
      // notification.success({ message: 'Copied', className: 'antd-mod center' });
      message.success("Copied");
      console.log("copied");
    }
  }

  render() {
    return (
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <div className="social-container" style={{position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Button
            className={`antd-mod color-primary share-button share-social${this.state?.menuActive ? ' active' : ''}`}
            onClick={() => this.handleToggleMenu()}
            icon={<ShareAltOutlined />}
          />
          <Button
            className={`share-social social-link facebook${this.state?.menuActive ? ' active' : ''}`}
            href={`https://www.facebook.com/sharer.php?u=https://www.bridepay.com/products/${this.props?.product.slug}`}
            target="_blank"
            rel="noreferrer"
            role="button"
            icon={<FacebookFilled />}
          />
          <Button
            className={`share-social social-link twitter${this.state?.menuActive ? ' active' : ''}`}
            href={`https://twitter.com/intent/tweet?url=https://www.bridepay.com/products/${this.props?.product.slug}`}
            target="_blank"
            rel="noreferrer"
            role="button"
            icon={<TwitterSquareFilled />}
          />
          <Button
            className={`share-social social-link instagram${this.state?.menuActive ? ' active' : ''}`}
            href={`https://www.instagram.com/?utm_medium=share&utm_campaign=social_share&utm_source=https://www.bridepay.com/products/${this.props?.product.slug}`}
            target="_blank"
            rel="noreferrer"
            role="button"
            icon={<InstagramFilled />}
          />
          <Button
            className={`share-social social-link youtube${this.state?.menuActive ? ' active' : ''}`}
            // href={`https://www.youtube.com/channel/bridepay?url=https://www.bridepay.com/products/${this.props?.product.slug}`}
            target="_blank"
            rel="noreferrer"
            role="button"
            onClick={() => this.handleCopy()}
            icon={<CopyFilled />}
          />
          <Button
            className={`share-social social-link email${this.state?.menuActive ? ' active' : ''}`}
            href={`mailto:?subject=Bridepay%20is%20Malaysia’s%20largest%20online%20marketplace%20for%20Brides%20%26%20Bridegrooms&body=https://www.bridepay.com/products/${this.props?.product.slug}`}
            target="_blank"
            rel="noreferrer"
            role="button"
            icon={<MailFilled />}
          />
        </div>
      </div>
    );
  }
}

export default SocialShare;
