import axios from 'axios';
import { ServiceCategory } from '../interfaces/service-category.interface';


class ServiceCategoryApi {

  getCategories(): Promise<ServiceCategory[]> {
    const apiUrl = `/api/v1/service_categories?per_page=1000`;
    return axios.get(apiUrl)
    .then(response => {
      const categories: ServiceCategory[] = [];

      response?.data?.data?.forEach(categoryData => {
        categories.push({
          id: categoryData.attributes.id,
          name: categoryData.attributes.name,
          slug: categoryData.attributes.slug
        });
      });

      return Promise.resolve(categories);
    });
  }

  getSubCategories(categoryId:number): Promise<ServiceCategory[]> {
    const apiUrl = `/api/v1/service_categories/${categoryId}/subcategories?per_page=1000`;
    return axios.get(apiUrl)
    .then(response => {
      const categories: ServiceCategory[] = [];

      response?.data?.data?.forEach(categoryData => {
        categories.push({
          id: categoryData.attributes.id,
          name: categoryData.attributes.name,
          slug: categoryData.attributes.slug
        });
      });

      return Promise.resolve(categories);
    });
  }

}

export default ServiceCategoryApi;