// import './CalendarSlotModal.scss';

import * as React from 'react';
import { Modal, Form, Input, Button, DatePicker, Checkbox, Select, TimePicker } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import * as moment from 'moment';

import { CalendarSlot } from '../../../interfaces/calendar-slot.interface';



interface CalendarSlotModalProps {
  calendarSlot?: CalendarSlot;
  mode?: 'add'|'edit'|'view';
  visible: boolean;
  closed: () => void;
  calendarSlotCreated?: (calendarSlot: CalendarSlot, days: number[]) => void;
  calendarSlotSaved?: (calendarSlot: CalendarSlot) => void;
}


interface CalendarSlotModalState {
  calendarSlotFormData: any;
  allDay: boolean;
}



class CalendarSlotModal extends React.Component<CalendarSlotModalProps, CalendarSlotModalState> {


  get submitButtonText(): string {
    if(this.props?.calendarSlot) {
      return 'Save';
    } else {
      return 'Create';
    }
  }
  
  
  createCalendarSlot(): void {
    if(this.props?.calendarSlotCreated) {
      const calendarSlot: CalendarSlot = {
        allDay: this.state.calendarSlotFormData.allDay,
        numberBookings: this.state.calendarSlotFormData.numberBookings,
        start: this.state.calendarSlotFormData.timeRange[0]?.toDate(),
        end: this.state.calendarSlotFormData.timeRange[1]?.toDate()
      };
      
      if(this.props?.calendarSlotCreated) {
        this.props.calendarSlotCreated(calendarSlot, this.state.calendarSlotFormData.days);
      }
    }
  }


  saveCalendarSlot(): void {
    const calendarSlot: CalendarSlot = {
      id: this.props?.calendarSlot?.id,
      allDay: this.state.calendarSlotFormData.allDay,
      numberBookings: this.state.calendarSlotFormData.numberBookings,
      start: this.state.calendarSlotFormData.timeRange[0]?.toDate(),
      end: this.state.calendarSlotFormData.timeRange[1]?.toDate()
    };

    if(this.props?.calendarSlotSaved) {
      this.props?.calendarSlotSaved(calendarSlot);
    }
  }


  formSubmit(formEvent: any): void {
    this.setState({ calendarSlotFormData: formEvent });

    if(this.props?.calendarSlot) {
      this.saveCalendarSlot();
    } else {
      this.createCalendarSlot();
    }

    this.props?.closed();
  }
  
  
  renderForm() {
    const formInitialValues = {
      timeRange: [
        (this.props?.calendarSlot?.start) ? moment(this.props?.calendarSlot?.start) : null,
        (this.props?.calendarSlot?.end) ? moment(this.props?.calendarSlot?.end) : null,
      ],
      days: [this.props?.calendarSlot?.day],
      allDay: this.props?.calendarSlot?.allDay,
      numberBookings: this.props?.calendarSlot?.numberBookings ? this.props?.calendarSlot?.numberBookings : 1,
    };

    const dayOptions = [
      { label: 'Sunday', value: 0 },
      { label: 'Monday', value: 1 },
      { label: 'Tuesday', value: 2 },
      { label: 'Wednesday', value: 3 },
      { label: 'Thursday', value: 4 },
      { label: 'Friday', value: 5 },
      { label: 'Saturday', value: 6 },
    ];

    const daysEnabled = (this.props?.calendarSlot) ? true : false; /* We want to disable the days checkbox if we are editing a calendar slot. */
    
    return (
      <Form layout="vertical" initialValues={formInitialValues} onFinish={(event) => { this.formSubmit(event) }}>
        <Form.Item valuePropName="checked" name="allDay">
          <Checkbox onChange={(e)=>{this.setState({allDay: e.target.checked})}}>All Day</Checkbox>
        </Form.Item>

        <Form.Item label="Time Range" name="timeRange" rules={[{ required: !this.state?.allDay, message: 'Please input a start and end date.' }]}>
          <TimePicker.RangePicker format={'hh:mm a'} disabled={this.state?.allDay}/>
        </Form.Item>

        <Form.Item name="days">
          <Checkbox.Group options={dayOptions} disabled={(daysEnabled)} />
        </Form.Item>
        
        <Form.Item label="Number of Bookings" name="numberBookings">
          <Input type="number" min={1} />
        </Form.Item>

        <Form.Item>
          <Button className="antd-mod color-primary" type="primary" htmlType="submit">
            {this.submitButtonText}
          </Button>
        </Form.Item>
      </Form>
    );
  }
  
  
  render() {
    return (
      <Modal className="CalendarSlotModal" centered={false} visible={this.props?.visible} onCancel={this.props?.closed} closable={false} footer={null} keyboard={false} maskClosable={false} destroyOnClose={true}>
        <Button className="back-to-link typo-bold" onClick={() => { this.setState({allDay: false}); this.props?.closed() }} type="link" icon={<LeftOutlined />}>Close</Button>
        {this.renderForm()}
      </Modal>
    );
  }
  
}




export default CalendarSlotModal;