import axios from 'axios';
import {Comment} from "../interfaces/comment.interface";

class CommentAdminApi {

  getComments(page, per_page): Promise<{ comments: Comment[], total: number }>{
    const apiUrl = `/api/v1/admin/comments?per_page=${per_page}&page=${page}`;

    return axios.get(apiUrl)
    .then(response => {
      const comments: Comment[] = [];
      response?.data?.data?.forEach(commentsData => {
        comments.push(this.mapCommentResponse(commentsData?.attributes));
      });
      
      return Promise.resolve({comments, total: response?.data?.meta?.total_count});
    });
  }

  getComment(id:number): Promise<Comment>{
    const apiUrl = `/api/v1/admin/comments/${id}`;

    return axios.get(apiUrl)
        .then(response => {
          const comment: Comment = this.mapCommentResponse(response?.data?.data?.attributes);

          return Promise.resolve(comment);
        });
  }


  addComment(commentData: Comment): Promise<Comment>{
    const apiUrl = `/api/v1/admin/comments`;
    let temp = {
      name: commentData.name,
      content: commentData.content,
      email: commentData.email,
      website: commentData.website,
      article_id: commentData.article.id
    };
    
    return axios({
      method: "post",
      url: apiUrl,
      data: temp
    })
    .then((response)=> {
      const comment:Comment = this.mapCommentResponse(response?.data?.data?.attributes);
      return comment;
    })
  }

  updateComment(commentId:number, commentData:Comment): Promise<Comment>{
    const apiUrl = `/api/v1/admin/comments/${commentId}`;
    let status = 0;
    if (commentData.status === "pending") status = 0;
    if (commentData.status === "active") status = 1;
    if (commentData.status === "archived") status = 2;
    let temp = {
      name: commentData.name,
      content: commentData.content,
      email: commentData.email,
      website: commentData.website,
      status: status,
    };

    return axios({
      method: "put",
      url: apiUrl,
      data: temp
    })
    .then((response)=> {
      const comment:Comment = this.mapCommentResponse(response?.data?.data?.attributes);
      return comment;
    })
  }


  removeComment(commentId:number){
    const apiUrl = `/api/v1/admin/comments/${commentId}`;
    return axios.delete(apiUrl).then(response => {
      return Promise.resolve(true);
    });
  }

  publishComment(comment:number){
    const apiUrl = `/api/v1/admin/comments/${comment}/publish`;
    return axios.patch(apiUrl).then(response => {
      return Promise.resolve(true);
    });
  }

  archiveComment(comment:number){
    const apiUrl = `/api/v1/admin/comments/${comment}/archive`;
    return axios.patch(apiUrl).then(response => {
      return Promise.resolve(true);
    });
  }

  increaseViewCount(comment:number){
    const apiUrl = `/api/v1/admin/comments/${comment}/increase-view-count`;
    return axios.patch(apiUrl).then(response => {
      return Promise.resolve(true);
    });
  }

  mapCommentResponse(commentData){
    return {
      id: commentData?.id,
      name: commentData?.name,
      email: commentData?.email,
      content: commentData?.content,
      website: commentData?.website,
      status: commentData?.status,
      createdAt: commentData?.created_at,
      article: {
        id: commentData?.article?.id,
        name: commentData?.article?.name,
        content: commentData?.article?.content,
        credits: commentData?.article?.credits,
        colors: commentData?.article?.colors,
        viewCount: commentData?.article?.view_count,
        publishedAt: commentData?.article?.published_at,
        mayPublish: commentData?.article?.may_publish,
        mayReject: commentData?.article?.may_reject
      }
    };
  }
}

export default CommentAdminApi;