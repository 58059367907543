import * as React from "react"
import { Button, notification } from "antd";
import { LeftOutlined } from '@ant-design/icons'; 
import CalendarApi from "../../../api-services/calendar-api.service";
import CampaignApi from "../../../api-services/campaign-api.service";
import CountryApi from "../../../api-services/country-api.service";
import ProductAdminApi from "../../../api-services/product-admin-api.service";
import ProductApi from "../../../api-services/product-api.service";
import ServiceCategoryApi from "../../../api-services/service-category-api.service";
import { Calendar } from "../../../interfaces/calendar.interface";
import { Campaign } from "../../../interfaces/campaign.interface";
import { Product, ProductImage } from "../../../interfaces/product.interface";
import { ServiceCategory } from "../../../interfaces/service-category.interface";
import ProductSetupForm from "../../basic-components/product-setup-form/ProductSetupForm";


notification.config({
  duration: 12,
  top: 60
})


interface IAdminProductShowPageProps {
  productId?:number;
}

interface IAdminProductShowPageState {
  product:Product;
  images: ProductImage[];
  categories: ServiceCategory[];
  campaigns: Campaign[];
  mode: "add" | "edit";
  calendars: Calendar[];
  vendorServiceCategoryName?:string;
}

class AdminProductShowPage extends React.Component <IAdminProductShowPageProps, IAdminProductShowPageState> {
  
  countryApi = new CountryApi;
  productApi = new ProductApi;
  productAdminApi = new ProductAdminApi;
  campaignApi = new CampaignApi;
  calendarApi = new CalendarApi;

  componentDidMount(){
    this.setState({
      categories: [],
      campaigns: [],
      calendars: [],
      mode: "add"
    });

    if(this.props?.productId){
      this.getProduct();
    }
  }


  loadCategories(vendorServiceCategoryId:number): void {
    const categoryApi = new ServiceCategoryApi;
    categoryApi.getSubCategories(vendorServiceCategoryId)
    .then(categories => {
      // const vendorCategoryName = categories.find(c => c.id ==vendorServiceCategoryId)?.name;
      // this.setState({ categories: categories, vendorServiceCategoryName: vendorCategoryName });
      this.setState({ categories: categories });
    })
    .catch(error => {
      notification.error({ message: 'An error occurred while loading the data for categories', className: 'antd-mod center' });
    })
  }


  loadCampaigns(): void{
    this.campaignApi.getCampaigns()
    .then(campaignsData => this.setState({campaigns: campaignsData}))
    .catch(error => {
      notification.error({ message: 'An error occurred while loading the data for campaigns', className: 'antd-mod center' });
    });
  }


  getProduct(){
    const productId = this.props?.productId;

    this.productAdminApi.getProduct(productId)
      .then(productResponse => {
        this.setState({product: productResponse, vendorServiceCategoryName: productResponse?.category?.name});
        this.loadCategories(productResponse?.category?.id);
        this.loadCampaigns();
        // this.loadCalendars();
        this.calendarApi.getCalendarsForAdmin(productResponse.vendor.id)
          .then(calendarsData => this.setState({calendars: calendarsData}))
          .catch(error => {
            notification.error({ message: 'An error occurred while loading the data for calendars', className: 'antd-mod center' });
          });
        //
        return this.productAdminApi.getImages(productId);
      })
      .then(images => {
        this.setState({images: images});
      })
      .catch(error => {
        notification.error({ message: `Error while loading the vendor\'s products.`, className: 'antd-mod center' });
      })
      .finally(()=>{
        this.setState({mode: "edit"})
      })
  }


  redirectToProducts(){
    window.location.href = '/admin/products';
  }


  render() {
    return (
      <div className="AdminProductShowPage">
        <div className="main-content">
          <Button type="link" className="back-to-link typo-bold" icon={<LeftOutlined />} onClick={()=>this.redirectToProducts()}>Back to Products</Button>
          
          <ProductSetupForm product={this.state?.product}
                            savedImages={this.state?.images}
                            categories={this.state?.categories}
                            campaigns={this.state?.campaigns}
                            calendars={this.state?.calendars}
                            primaryServiceCategory={this.state?.vendorServiceCategoryName}
                            readonly={true}/>
        </div>
      </div>
    );
  }
}


export default AdminProductShowPage
