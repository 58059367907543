import * as React from "react"

import {Button, Table, Tag} from "antd";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'; 

import { Campaign } from "../../../interfaces/campaign.interface";
import {format} from "date-fns";


interface IAdminCampaignsTableProps {
  campaigns:Campaign[];
  selectedCampaigns:number[];
  editCampaign: (campaign: Campaign)=>void;
  publishCampaign: (id: number)=>void;
  unpublishCampaign: (id: number)=>void;
  removeCampaign: (id:number)=>void;
  selectionChange: (selectedRows)=>void;
  hideAction: boolean;
}

interface IAdminCampaignsTableState {
  columns: any[];
}

class AdminCampaignsTable extends React.Component <IAdminCampaignsTableProps, IAdminCampaignsTableState> {

  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'Title',
          dataIndex: 'title'
        },
        {
          title: 'Subtitle',
          dataIndex: 'subtitle'
        },
        {
          title: 'Description',
          dataIndex: 'description'
        },
        {
          title: 'Campaign Period',
          dataIndex: 'campaignPeriodStart',
          render: (campaignPeriodStart, record) => {
            return (
              <span>{record.campaignPeriodStart != null && `${campaignPeriodStart.format('DD/MM/YYYY')}-${record.campaignPeriodEnd.format('DD/MM/YYYY')}`}</span>
            )
          }
        },
        {
          title: 'Create Product Period',
          dataIndex: 'publishedPeriodStart',
          render: (publishedPeriodStart, record) => {
            return (
              <span>{record.publishedPeriodStart != null && `${publishedPeriodStart.format('DD/MM/YYYY')}-${record.publishedPeriodEnd.format('DD/MM/YYYY')}`}</span>
            )
          }
        },
        {
          title: 'Show Countdown',
          dataIndex: 'showCountdown',
          render: (text) => {
            return (
              text ? <CheckOutlined twoToneColor="#52c41a"/> : <CloseOutlined twoToneColor="#ff4d4f"/>
            )
          }
        },
        // {
        //   title: 'Lock Product',
        //   dataIndex: 'lockProductDuringCampaignPeriod',
        //   render: (text) => {
        //     return (
        //       text ? <CheckOutlined twoToneColor="#52c41a"/> : <CloseOutlined twoToneColor="#ff4d4f"/>
        //     )
        //   }
        // },
        {
          title: 'Allow Product Selling Period',
          dataIndex: 'allowProductSellingPeriod',
          render: (text) => {
            return (
              text ? <CheckOutlined twoToneColor="#52c41a"/> : <CloseOutlined twoToneColor="#ff4d4f"/>
            )
          }
        }
      ]
    };
  }

  componentDidMount() {
    if (this.props?.hideAction){
      this.setState({columns: [
          {
            title: 'Title',
            dataIndex: 'title'
          },
          {
            title: 'Subtitle',
            dataIndex: 'subtitle'
          },
          {
            title: 'Description',
            dataIndex: 'description'
          },
          {
            title: 'Campaign Period',
            dataIndex: 'campaignPeriodStart',
            render: (campaignPeriodStart, record) => {
              return (
                <span>{record.campaignPeriodStart != null && `${campaignPeriodStart.format('DD/MM/YYYY')}-${record.campaignPeriodEnd.format('DD/MM/YYYY')}`}</span>
              )
            }
          },
          {
            title: 'Create Product Period',
            dataIndex: 'publishedPeriodStart',
            render: (publishedPeriodStart, record) => {
              return (
                <span>{record.publishedPeriodStart != null && `${publishedPeriodStart.format('DD/MM/YYYY')}-${record.publishedPeriodEnd.format('DD/MM/YYYY')}`}</span>
              )
            }
          },
          {
            title: 'Show Countdown',
            dataIndex: 'showCountdown',
            render: (text) => {
              return (
                text ? <CheckOutlined twoToneColor="#52c41a"/> : <CloseOutlined twoToneColor="#ff4d4f"/>
              )
            }
          },
          // {
          //   title: 'Lock Product',
          //   dataIndex: 'lockProductDuringCampaignPeriod',
          //   render: (text) => {
          //     return (
          //       text ? <CheckOutlined twoToneColor="#52c41a"/> : <CloseOutlined twoToneColor="#ff4d4f"/>
          //     )
          //   }
          // },
          {
            title: 'Allow Product Selling Period',
            dataIndex: 'allowProductSellingPeriod',
            render: (text) => {
              return (
                text ? <CheckOutlined twoToneColor="#52c41a"/> : <CloseOutlined twoToneColor="#ff4d4f"/>
              )
            }
          }
        ]});
    } else {
      this.setState({columns: [
          {
            title: 'Title',
            dataIndex: 'title'
          },
          {
            title: 'Subtitle',
            dataIndex: 'subtitle'
          },
          {
            title: 'Description',
            dataIndex: 'description'
          },
          {
            title: 'Campaign Period',
            dataIndex: 'campaignPeriodStart',
            render: (campaignPeriodStart, record) => {
              return (
                <span>{record.campaignPeriodStart != null && `${campaignPeriodStart.format('DD/MM/YYYY')}-${record.campaignPeriodEnd.format('DD/MM/YYYY')}`}</span>
              )
            }
          },
          {
            title: 'Create Product Period',
            dataIndex: 'publishedPeriodStart',
            render: (publishedPeriodStart, record) => {
              return (
                <span>{record.publishedPeriodStart != null && `${publishedPeriodStart.format('DD/MM/YYYY')}-${record.publishedPeriodEnd.format('DD/MM/YYYY')}`}</span>
              )
            }
          },
          {
            title: 'Show Countdown',
            dataIndex: 'showCountdown',
            render: (text) => {
              return (
                text ? <CheckOutlined twoToneColor="#52c41a"/> : <CloseOutlined twoToneColor="#ff4d4f"/>
              )
            }
          },
          // {
          //   title: 'Lock Product',
          //   dataIndex: 'lockProductDuringCampaignPeriod',
          //   render: (text) => {
          //     return (
          //       text ? <CheckOutlined twoToneColor="#52c41a"/> : <CloseOutlined twoToneColor="#ff4d4f"/>
          //     )
          //   }
          // },
          {
            title: 'Allow Product Selling Period',
            dataIndex: 'allowProductSellingPeriod',
            render: (text) => {
              return (
                text ? <CheckOutlined twoToneColor="#52c41a"/> : <CloseOutlined twoToneColor="#ff4d4f"/>
              )
            }
          },
          {
            title: 'Action',
            key: 'operation',
            width: 200,
            render: (text, record) => {
              return <>
                <Button type="link" onClick={()=>this.props?.editCampaign(record)}>Edit</Button>
                {record.mayPublish && <Button type="link" onClick={()=>this.props?.publishCampaign(record.id)}>Publish</Button>}
                {record.mayUnpublish && <Button type="link" onClick={()=>this.props?.unpublishCampaign(record.id)}>Unpublish</Button>}
                {/* <Button type="link" danger onClick={()=>this.props?.removeCampaign(record.id)}>Remove</Button> */}
              </>
            },
          },
        ]});
    }
  }


  render() {
    const rowSelection = {
      // selectedRowKeys: this.props?.selectedCampaigns,
      preserveSelectedRowKeys: false,
      onChange: (selectedRowKeys, selectedRows) => {
        this.props?.selectionChange(selectedRowKeys);
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };

    return (
      <div className="AdminCampaignsTable">
        <Table size="small" bordered rowKey="id"
          // rowSelection={{
          //   type: "checkbox",
          //   ...rowSelection,
          //   selectedRowKeys: this.props?.selectedCampaigns
          // }}
          columns={this.state?.columns}
          dataSource={this.props?.campaigns}
          pagination={{ defaultPageSize: 50}}
        />
      </div>
    );
  }
}

export default AdminCampaignsTable

