// import './VendorSummary.scss';

import * as React from 'react';
import { Rate, Button } from 'antd';
import { ReactSVG } from 'react-svg';

import { Vendor } from '../../../interfaces/vendor-interface';
import { HighlightedFaq } from '../../../interfaces/highlighted-faq.interface';
import { preventDefault } from '@fullcalendar/common';
import PhoneNumberFormatter from '../../../util/phone-formatter';

const web = require('@mdi/svg/svg/web.svg');
const phone = require('@mdi/svg/svg/phone.svg');
const whatsapp = require('@mdi/svg/svg/whatsapp.svg');
const facebook = require('@mdi/svg/svg/facebook.svg');
const youtube = require('@mdi/svg/svg/youtube.svg');
const instagram = require('@mdi/svg/svg/instagram.svg');
const tiktok = require('@mdi/svg/svg/music-note.svg');


interface VendorSummaryProps {
  vendor: Vendor;
  signedIn: boolean;
  onClickAppointment?: () => void;
}

interface VendorSummaryState {

}

class VendorSummary extends React.Component<VendorSummaryProps, VendorSummaryState> {

  /**
   * Open the provided url in new window;
   * Will NOT if the provided URL is empty or null.
   */
  openURL(url: string): void {
    if(url) {
      if(url.includes('https://')){
        window.open(url);
      } else{
        window.open("https://"+url);
      }
    }
  }

  renderHeaderDetails() {
    return (
      <div className="header-details">
        <div className="vendor-image">
          <img src={this.props?.vendor?.avatarUrl} alt="Vendor Image" />
        </div>

        <div className="name-and-address">
          <div className="vendor-name typo-headline typo-bold">{this.props?.vendor?.businessName}</div>
          <div className="address">{this.props?.vendor?.fullAddress}</div>
        </div>

        <div className="filler" />

{/*        <div className="ratings">
          <Rate value={1} count={1}></Rate>
          <div className="value typo-title">{this.props?.vendor?.rating}</div>
        </div>*/}
      </div>
    );
  }

  /**
   * Use this to generate label/value detail from highlightedFaqs using the "position" provided.
   */
  renderHighlightedFaq(position: number) {
    const highlightedFaq: HighlightedFaq = this.props?.vendor?.highlightedFaqs[position - 1];

    if(highlightedFaq) {
      return (
        <div className="detail">
          <div className="label">{highlightedFaq.label}:</div>
            <div className="value">{highlightedFaq.value}</div>
        </div>
      );
    } else {
      return (
        <div className="detail" />
      );
    }
  }

  renderOtherDetails() {
    return (
      <div className="other-details">
        <div className="details-column contact-details">
          <div className="detail">
            <div className="label"><ReactSVG src={web} /></div>
            <a className="value vendor-website" href={`https://${this.props?.vendor?.website}`} target="_blank">{this.props?.vendor?.website}</a>
          </div>

          <div className="detail">
            <div className="label"><ReactSVG src={phone} /></div>
            {/*<div className="value">{this.props?.vendor?.primaryPhoneNumber?.number}</div>*/}
            <a className="value vendor-phone" href={`tel:${this.props?.vendor?.primaryPhoneNumber?.number}`}>
              {new PhoneNumberFormatter(this.props?.vendor?.primaryPhoneNumber?.number).format().string}
            </a>
          </div>

          {this.props?.vendor?.whatsapp && (
            <div className="detail">
              <div className="label"><ReactSVG src={whatsapp} /></div>
              <a className="value vendor-whatsapp" href={`https://api.whatsapp.com/send?phone=${this.props?.vendor?.whatsapp.replace(/\D/g, '')}`} target="_blank">
                {new PhoneNumberFormatter(this.props?.vendor?.whatsapp).format().string}
              </a>
            </div>
          )}

          {this.renderActionButtons()}
        </div>

        <div className="details-column summary-details">
          {this.renderHighlightedFaq(1)}
          {this.renderHighlightedFaq(2)}
          {this.renderHighlightedFaq(3)}
        </div>

        <div className="details-column summary-details">
          {this.renderHighlightedFaq(4)}
          {this.renderHighlightedFaq(5)}
          {this.renderHighlightedFaq(6)}
        </div>
      </div>
    );
  }

  renderActionButtons() {
    let whatsappurl = '';
    if (this.props?.vendor?.whatsapp) {
      let number = this.props?.vendor?.whatsapp.replace(/[^\w\s]/gi, '').replace(/ /g, '');
      whatsappurl = `https://api.whatsapp.com/send?phone=${number}`;
    }
    return (
      <div className="action-buttons">
        <Button type="primary"
                size="large"
                className="message-us"
                onClick={() => whatsappurl ? this.openURL(whatsappurl) : null}
        >
          Message Us
        </Button>
        <Button type="primary"
                size="large"
                className="appointment"
                onClick={() => {this.props?.onClickAppointment()}}
        >
          Set Appointment
        </Button>
        <div className="social-media">
          { this.props?.vendor?.facebook && (<ReactSVG className="facebook-link" src={facebook} onClick={() => { this.openURL(this.props?.vendor?.facebook) }} />) }
          { this.props?.vendor?.youtube && (<ReactSVG className="youtube-link" src={youtube} onClick={() => { this.openURL(this.props?.vendor?.youtube) }} />) }
          { this.props?.vendor?.instagram && (<ReactSVG className="instagram-link" src={instagram} onClick={() => { this.openURL(this.props?.vendor?.instagram) }} />) }
          { this.props?.vendor?.tiktok && (<ReactSVG className="tiktok-link" src={tiktok} onClick={() => { this.openURL(this.props?.vendor?.tiktok) }} />) }
         </div>
      </div>
    );
  }

  render() {
    return (
      <div className="VendorSummary">
        {this.renderHeaderDetails()}
        {this.renderOtherDetails()}
      </div>
    );
  }
}

export default VendorSummary;