import axios from 'axios';
import * as moment from 'moment';
import { Campaign } from "../interfaces/campaign.interface";

class CampaignAdminApi {

  getCampaigns(): Promise<Campaign[]>{
    const apiUrl = `/api/v1/admin/campaigns`;

    return axios.get(apiUrl)
    .then(response => {
      const campaigns: Campaign[] = [];
      response?.data?.data?.forEach(campaignData => {
        campaigns.push(this.mapCampaignResponse(campaignData?.attributes));
      });
      
      return Promise.resolve(campaigns);
    });
  }


  addCampaign(campaign:Campaign): Promise<Campaign>{
    const apiUrl = `/api/v1/admin/campaigns`;
    return axios.post(apiUrl, {
      campaign: this.mapCampaignPayload(campaign)
    }).then(response => {
      const campaign:Campaign = this.mapCampaignResponse(response?.data?.data?.attributes);
      return Promise.resolve(campaign);
    });
  }

  updateCampaign(campaignId:number, campaignPayload:Campaign): Promise<Campaign>{
    const apiUrl = `/api/v1/admin/campaigns/${campaignId}`;

    return axios.put(apiUrl, {
      campaign: this.mapCampaignPayload(campaignPayload)
    }).then(response => {
      const campaign:Campaign = this.mapCampaignResponse(response?.data?.data?.attributes);
      return Promise.resolve(campaign);
    });
  }


  removeCampaign(campaigns:number[]){
    const apiUrl = `/api/v1/admin/campaigns/${campaigns}`;
    return axios.delete(apiUrl).then(response => {
      return Promise.resolve(true);
    });
  }


  publishCampaign(campaignId:number): Promise<Campaign>{
    const apiUrl = `/api/v1/admin/campaigns/${campaignId}/publish`;
    return axios.patch(apiUrl).then(response => {
      const campaign:Campaign = this.mapCampaignResponse(response?.data?.data?.attributes);
      return Promise.resolve(campaign);
    });
  }

  unpublishCampaign(campaignId:number): Promise<Campaign>{
    const apiUrl = `/api/v1/admin/campaigns/${campaignId}/unpublish`;
    return axios.patch(apiUrl).then(response => {
      const campaign:Campaign = this.mapCampaignResponse(response?.data?.data?.attributes);
      return Promise.resolve(campaign);
    });
  }


  mapCampaignPayload(campaign){
    return {
      title: campaign.title,
      subtitle: campaign.subtitle,
      description: campaign.description,
      allow_product_selling_period: campaign.allowProductSellingPeriod,
      show_countdown: campaign.showCountdown,
      lock_product_during_campaign_period: campaign.lockProductDuringCampaignPeriod,
      campaign_start: campaign.campaignPeriodStart,
      campaign_end: campaign.campaignPeriodEnd,
      published_start: campaign.publishedPeriodStart,
      published_end: campaign.publishedPeriodEnd
    };
  }


  mapCampaignResponse(campaignData){
    return {
      id: campaignData?.id,
      title: campaignData?.title,
      subtitle: campaignData?.subtitle,
      description: campaignData?.description,
      allowProductSellingPeriod: campaignData?.allow_product_selling_period,
      showCountdown: campaignData?.show_countdown,
      mayPublish: campaignData?.may_publish,
      mayUnpublish: campaignData?.may_unpublish,
      lockProductDuringCampaignPeriod: campaignData?.lock_product_during_campaign_period,
      campaignPeriodStart: campaignData?.campaign_start != null ? moment(campaignData?.campaign_start) : null,
      campaignPeriodEnd: campaignData?.campaign_end != null ? moment(campaignData?.campaign_end) : null,
      publishedPeriodStart: campaignData?.published_start != null ? moment(campaignData?.published_start) : null,
      publishedPeriodEnd: campaignData?.published_end != null ? moment(campaignData?.published_end) : null,
    };
  }
}

export default CampaignAdminApi;