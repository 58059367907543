// import './VendorProfileTab.scss';

import * as React from 'react';
import { FormInstance, notification} from 'antd';

import { Vendor } from '../../../interfaces/vendor-interface';
import { FAQ } from '../../../interfaces/faq.interface';
import { ServiceCategory } from '../../../interfaces/service-category.interface';

import ServiceCategoryApi from '../../../api-services/service-category-api.service';
import FAQApi from '../../../api-services/faq-api-service';

import TabSideMenu from '../../ui-components/tab-side-menu/TabSideMenu';
import BusinessDetailsForm from '../../basic-components/business-details-form/BusinessDetailsForm';
import BusinessContactDetailsForm from '../../basic-components/business-contact-details-form/BusinessContactDetailsForm';
import VendorServiceCategoriesForm from '../../basic-components/vendor-service-categories-form/VendorServiceCategoriesForm';
import VendorFaqForm from '../../basic-components/vendor-faq-form/VendorFaqForm';


interface VendorProfileTabProps {
  vendor: Vendor;
  onVendorUpdate: () => void;
}

interface VendorProfileTabState {
  currentSelection: number;
  categories: ServiceCategory[];
  faqData: FAQ[];
  primaryServiceCategory: ServiceCategory;
}

class VendorProfileTab extends React.Component <VendorProfileTabProps, VendorProfileTabState> {

  faqApi = new FAQApi;
  
  
  contactsForm = React.createRef<FormInstance>();

  navigations = [
    {id: "business-details", label: "Business Details"},
    {id: "contact-details", label: "Contacts & Social Media"},
    {id: "service-category", label: "Category of Service"},
    {id: "faq", label: "FAQ", display: "Frequently Asked Questions"}
  ];

  componentDidMount(): void {
    this.setState({
      currentSelection: 0,
      categories: [],
      primaryServiceCategory: null,
    });

    this.loadCategories();
    this.initCurrentTabSelection();
  }


  componentDidUpdate(previousProps: VendorProfileTabProps){
    if(previousProps.vendor !== this.props?.vendor){
      const serviceCategories = this.props?.vendor?.serviceCategories;
      var primaryServiceCategory = serviceCategories.find(category => category.isPrimary) || serviceCategories[0];
      this.setState({primaryServiceCategory: primaryServiceCategory});
      this.loadFAQs(primaryServiceCategory.id);
    }
  }


  /**
   * Set initial value for "currentSelection" based on the current URL.
   */
  initCurrentTabSelection(): void {
    const url = window.location.href;
    
    if(url.indexOf('contact-details') !== -1) {
      this.setState({ currentSelection: 1 });
    } else if(url.indexOf('service-category') !== -1) {
      this.setState({ currentSelection: 2 });
    } else if(url.indexOf('faq') !== -1) {
      this.setState({ currentSelection: 3 });
    } else {
      this.setState({ currentSelection: 0 });
    }
  }


  loadCategories(): void {
    const categoryApi = new ServiceCategoryApi;

    categoryApi.getCategories()
      .then(categories => {
        this.setState({ categories: categories });
      })
      .catch(error => {
        notification.error({ message: 'An error occured while loading the data for categories', className: 'antd-mod center' });
      })
  }




  loadFAQs(selectedCategoryId:number = 1){
    var _faqs: any[] = [];

    this.faqApi.getFAQsByCategory(selectedCategoryId)
      .then(faqs => {
        _faqs = faqs;
        return this.faqApi.getVendorFaqs(selectedCategoryId);
      })
      .then(vendorFaqs => {
        _faqs.forEach((faq:FAQ) => {
          if(vendorFaqs.length > 0){
            faq.vendorFaqs = vendorFaqs.find(f => f.questionId == faq.id);
          }
          
          faq.displayAnswer = faq.vendorFaqs != undefined;
        });

        this.setState({ faqData: _faqs });
      })
      .catch(error => {
        notification.error({ message: 'Error while loading the vendor\'s FAQs.', className: 'antd-mod center' });
      });
  }


  toggleFaq(index: number){
    const faqs = this.state?.faqData;

    //temp fix;
    if(!faqs[index].vendorFaqs) return;

    faqs[index].displayAnswer = !faqs[index].displayAnswer; 
    this.setState({faqData: faqs});
  }


  onFaqUpdate(index: number, newVal: any){
    const faqs = this.state?.faqData;
    faqs[index] = newVal;
    this.setState({faqData: faqs});
  }



  handleSelectedMenu = (selected: any) => {
    this.setState({currentSelection: selected});
  }



  renderForm(){
    const vendor = this.props?.vendor;

    switch (this.state?.currentSelection) {
      case 1:
        return <BusinessContactDetailsForm vendor={vendor} onVendorUpdate={this.props?.onVendorUpdate} />
      case 2:
        return <VendorServiceCategoriesForm vendorCategories={vendor?.serviceCategories} categories={this.state?.categories} />
      case 3:
        return <VendorFaqForm
          faqs={this.state?.faqData} 
          category={this.state?.primaryServiceCategory} 
          onDisplayAnswer={(faqIndex)=>this.toggleFaq(faqIndex)} 
          onUpdate={(faqIndex, newVal)=>this.onFaqUpdate(faqIndex, newVal)}
        />
      default:
        return <BusinessDetailsForm vendor={vendor} onVendorUpdate={this.props?.onVendorUpdate}/>
    }
  }


  render() {
    const title = this.navigations[this.state?.currentSelection];

    return (
      <div className="VendorProfileTab">
        <TabSideMenu navigations={this.navigations} onSelectMenu={this.handleSelectedMenu}/>

        <div className="tab-content">
          <div className="title-container typo-headline">
            <div className="title">{title?.display || title?.label}</div>
          </div>
          <div className="content">
            {this.renderForm()}
          </div>
        </div>
      </div>
    );
  }
}

export default VendorProfileTab;
