// import './DocumentModal.scss';

import * as React from 'react';
import { Modal } from 'antd';

interface DocumentModalProps {
  visible: boolean;
  filePath: string;
  closed: () => void;
}

interface DocumentModalState {

}

class DocumentModal extends React.Component<DocumentModalProps, DocumentModalState> {

  render() {
    return (
      <Modal className="DocumentModal" centered={false} visible={this.props?.visible} onCancel={this.props?.closed} footer={null} closable={false} maskClosable={true} destroyOnClose={false} width="80vw">
        {/* <FileViewer
          fileType={'docx'}
          filePath={this.props?.filePath}
        ></FileViewer> */}

        <iframe src={this.props?.filePath} width="800px" height="600px" />
      </Modal>
    );
  }
  
}

export default DocumentModal;
