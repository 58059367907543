import axios from 'axios';
import { PhoneNumber } from '../interfaces/phone-number.interface';
import { ServiceCategory, ServiceCategoryLocations } from '../interfaces/service-category.interface';
import { Vendor } from '../interfaces/vendor-interface';
import {City} from "../interfaces/city.interface";
import {Country} from "../interfaces/country.interface";


interface registerVendorPayload {
  email: string;
  password: string;
  confirmPassword: string;
  businessRegisteredName: string; 
  businessNo: string;
  businessName: string; //brand
  phoneNumber?: string;
  phoneKind?: string;
}


interface saveVendorPayload extends saveVendorContactsPayload {
  firstName?: string;
  lastName?: string;
  businessName?: string;
  businessRegisteredNumber?: string;
  businessRegisteredName?: string;
  phoneNumber?: string;
  phoneKind?: string;
  description?: string;
  headOffice?: string;
  address?: string;
  zipCode?: string;
  country?: string; //country ISO
  state?: string; //state code 
  city?: string; //city ID
  
  primaryCategory?: number;
  secondaryCategory?: number;

  submit?:boolean;
}


interface saveVendorContactsPayload {
  whatsapp?: string;
  website?: string;
  facebook?: string;
  instagram?: string;
  youtube?: string;
  tiktok?: string;
  contactPerson?: string;
  contactPersonNumber?: string;

}


class VendorApi {

  async getVendors(category, country, city, page, per_page): Promise<Vendor[]> {
    let apiUrl = `/api/v1/vendors?per_page=${per_page}&page=${page}`;
    if (category) apiUrl += `&category_id=${category}`;
    if (country) apiUrl += `&country=${country}`;
    if (city) apiUrl += `&city=${city}`;

    const response = await axios.get(apiUrl);
    const vendors: Vendor[] = [];
    response?.data?.data?.forEach(vendorData => {
      const vendor: Vendor = {
        id: vendorData?.attributes?.id,
        address: vendorData?.attributes?.address,
        avatarUrl: vendorData?.attributes?.avatar_url,
        description: vendorData?.attributes?.about,
        businessName: vendorData?.attributes?.business_name,
        businessRegisteredName: vendorData?.attributes?.business_registered_name,
        businessRegisteredNumber: vendorData?.attributes?.business_registration_no,
        email: vendorData?.attributes?.email,
        facebook: vendorData?.attributes?.facebook,
        rating: vendorData?.attributes?.rating,
        reviews: vendorData?.attributes?.reviews,
        slug: vendorData?.attributes?.slug,
        tiktok: vendorData?.attributes?.tiktok,
        twitter: vendorData?.attributes?.twitter,
        website: vendorData?.attributes?.website,
        youtube: vendorData?.attributes?.youtube,
        zipCode: vendorData?.attributes?.zip_code,
        city: {
          id: vendorData?.attributes?.city?.id,
          name: vendorData?.attributes?.city?.name
        },
        country: {
          id: vendorData?.attributes?.country?.id,
          name: vendorData?.attributes?.country?.name,
          display: vendorData?.attributes?.country?.display,
          currency: vendorData?.attributes?.country?.currency
        },
        primaryPhoneNumber: {
          id: vendorData?.attributes?.primary_phone?.id,
          isBusiness: vendorData?.attributes?.primary_phone?.is_business,
          isPrimary: vendorData?.attributes?.primary_phone?.is_primary,
          number: vendorData?.attributes?.primary_phone?.number,
          verified: vendorData?.attributes?.primary_phone?.verified,
          countryCode: vendorData?.attributes?.primary_phone?.formatted?.country_code,
          national: vendorData?.attributes?.primary_phone?.formatted?.national,
          rawNational: vendorData?.attributes?.primary_phone?.formatted?.raw_national
        },
        serviceCategories: [],
      };

      // Populate vendor.serviceCategories
      vendorData?.attributes?.services?.forEach(service => {
        vendor.serviceCategories.push({
          id: service?.category?.id,
          name: service?.category?.name,
          slug: service?.category?.slug,
        });
      });

      vendors.push(vendor);
    });
    return vendors;
  }

  searchVendorsByName(search: string): Promise<Vendor[]> {
    const apiUrl = `/api/v1/vendors?search=${search}&per_page=${100}&page=${1}`;

    return axios.get(apiUrl)
      .then(response => {
        const vendors: Vendor[] = [];

        response?.data?.data?.forEach(vendorData => {
          const vendor: Vendor = {
            id: vendorData?.attributes?.id,
            description: vendorData?.attributes?.about,
            businessName: vendorData?.attributes?.business_name,
            businessRegisteredName: vendorData?.attributes?.business_registered_name,
            email: vendorData?.attributes?.email,
            slug: vendorData?.attributes?.slug
          };

          vendors.push(vendor);
        });

        return Promise.resolve(vendors);
      });
  }

  /**
   * Get the profile of the currently logged in vendor.
   * NOTE: Create an interface for Vendor.
   * @param includedDetails Put here the details you want to include with the vendor profile. Valid values are 'primary_phone'|'wedding_detail'.
   */
   getVendorProfile(includedDetails: string[] = []): Promise<Vendor> {
    let apiUrl = `/api/v1/profile`;

    return axios.get(apiUrl)
    .then(response => {
      const vendor: Vendor = {
        id: response?.data?.data?.attributes?.id,
        image: response?.data?.data?.attributes?.avatar_url,
        businessName: response?.data?.data?.attributes?.full_name,
        businessRegisteredName: response?.data?.data?.attributes?.business_details?.business_registered_name,
        businessRegisteredNumber: response?.data?.data?.attributes?.business_details?.business_registration_no,
        businessLicense: response?.data?.data?.attributes?.business_details?.business_license,
        country: response?.data?.data?.attributes?.business_details?.country,
        state: response?.data?.data?.attributes?.business_details?.state,
        city: response?.data?.data?.attributes?.business_details?.city,
        description: response?.data?.data?.attributes?.business_details?.about,
        address: response?.data?.data?.attributes?.business_details?.address,
        zipCode: response?.data?.data?.attributes?.business_details?.zip_code,
        contactPerson: response?.data?.data?.attributes?.business_details?.contact_person,
        contactPersonNumber: response?.data?.data?.attributes?.business_details?.contact_person_number,
        whatsapp: response?.data?.data?.attributes?.business_details?.whatsapp,
        email: response?.data?.data?.attributes?.business_details?.email,
        website: response?.data?.data?.attributes?.business_details?.website,
        rating: response?.data?.data?.attributes?.business_details?.rating,
        facebook: response?.data?.data?.attributes?.business_details?.facebook,
        youtube: response?.data?.data?.attributes?.business_details?.youtube,
        instagram: response?.data?.data?.attributes?.business_details?.instagram,
        tiktok: response?.data?.data?.attributes?.business_details?.tiktok,      
        albumTypes: response?.data?.data?.attributes?.business_details?.album_types,      
        primaryPhoneNumber: {
          id: response?.data?.data?.attributes?.business_details?.primary_phone?.id,
          isBusiness: response?.data?.data?.attributes?.business_details?.primary_phone?.is_business,
          isPrimary: response?.data?.data?.attributes?.business_details?.primary_phone?.is_primary,
          number: response?.data?.data?.attributes?.business_details?.primary_phone?.number,
          verified: response?.data?.data?.attributes?.business_details?.primary_phone?.verified,
          countryCode: response?.data?.data?.attributes?.business_details?.primary_phone?.formatted?.country_code,
          national: response?.data?.data?.attributes?.business_details?.primary_phone?.formatted?.national,
          rawNational: response?.data?.data?.attributes?.business_details?.primary_phone?.formatted?.raw_national
        },
        
        primaryCategory: response?.data?.data?.attributes?.business_details?.primary_category,
        secondaryCategory: response?.data?.data?.attributes?.business_details?.secondary_category,

        serviceCategories: response?.data?.data?.attributes?.business_details?.services?.map(service => {
          const serviceCategory: ServiceCategory = {
            id: service?.category?.id,                  
            name: service?.category?.name,
            slug: service?.category?.slug,
            isPrimary: service?.is_primary,
            status: service?.status,
          }

          return serviceCategory;
        }),

        mayPublish: response?.data?.data?.attributes?.business_details?.may_publish,
        status: response?.data?.data?.attributes?.business_details?.status,
      };

      return Promise.resolve(vendor);
    });
  }



  registerVendor(payload: registerVendorPayload): Promise<any> {
    return axios.post('/api/v1/business/accounts/register', {
      account: {
        email: payload.email,
        password: payload.password,
        password_confirmation: payload.confirmPassword,
        business_registered_name: payload.businessRegisteredName,
        business_name: payload.businessName,
        business_registration_no: payload.businessNo,
        primary_phone_number: payload.phoneNumber,
        primary_phone_kind: payload.phoneKind
      }
    });
  }


  checkEmail(email:string): Promise<any> {
    return axios.post('/api/v1/accounts/check_email', { email: email });
  }
  
  saveBusinessDetails(payload: saveVendorPayload): Promise<any> {
    const apiUrl = `/api/v1/business/business_details`;
    
  
    return axios.patch(apiUrl, {
      business_details: {
        business_name: payload.businessName,
        business_registered_name: payload.businessRegisteredName,
        business_registration_no: payload.businessRegisteredNumber,
        primary_phone_number: payload.phoneNumber,
        whatsapp: payload.whatsapp,
        about: payload.description,
        address: payload.address,
        zip_code: payload.zipCode,
        country_id: payload.country,
        state_id: payload.state,
        city_id: payload.city,
        head_office: payload.headOffice,

        website: payload.website,
        facebook: payload.facebook,
        instagram: payload.instagram,
        youtube: payload.youtube,
        tiktok: payload.tiktok,
        
        primary_category_id: payload.primaryCategory,
        submit: payload.submit
      }
    });
  }
  
  saveContacts(payload: saveVendorContactsPayload): Promise<any> {
    const apiUrl = `/api/v1/business/business_contact_details`;
  
    return axios.patch(apiUrl, {
      business_contact_details: {
        contact_person: payload.contactPerson,
        contact_person_number: payload.contactPersonNumber,
        whatsapp: payload.whatsapp,
        website: payload.website,
        facebook: payload.facebook,
        instagram: payload.instagram,
        youtube: payload.youtube,
        tiktok: payload.tiktok
      }
    });
  }


  publishProfile(){
    let apiUrl = `/api/v1/profile/publish`;
    return axios.patch(apiUrl).then(response => {
      return Promise.resolve(true);
    });
  }

  getCities(country: Number): Promise<City[]> {
    const apiUrl = `/api/v1/vendors/cities?country=${country}`;
    return axios.get(apiUrl)
        .then(response => {
          const cities: City[] = [];

          response?.data?.forEach(cityData => {
            cities.push({
              id: cityData.id,
              name: cityData.name,
              slug: cityData.slug,
            });
          });

          return Promise.resolve(cities);
        });
  }

  getCountries(): Promise<Country[]> {
    const apiUrl = `/api/v1/vendors/countries`;
    return axios.get(apiUrl)
        .then(response => {
          const countries: Country[] = [];

          response?.data?.forEach(countryData => {
            countries.push({
              id: countryData.id,
              name: countryData.name,
              iso: countryData.iso,
              iso3: countryData.iso3,
              slug: countryData.slug,
            });
          });

          return Promise.resolve(countries);
        });
  }
}



export default VendorApi;