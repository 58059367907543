// import './CalendarEventModal.scss';

import * as React from 'react';
import { Modal, Form, Input, Button, DatePicker } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { LeftOutlined } from '@ant-design/icons';
import * as moment from 'moment';

import { CalendarEvent } from '../../../interfaces/calendar-event.interface';



interface CalendarEventModalProps {
  calendarEvent?: CalendarEvent;
  mode?: 'add'|'edit'|'view';
  visible: boolean;
  closed: () => void;
  calendarEventSaved?: (calendarEvent: CalendarEvent) => void;
}


interface CalendarEventModalState {
  calendarEventFormData: any;
}



class CalendarEventModal extends React.Component<CalendarEventModalProps, CalendarEventModalState> {

  componentDidMount() {

  }


  saveCalendarEvent(): void {
    if(this.props?.calendarEventSaved) {
      this.props.calendarEventSaved(this.state?.calendarEventFormData);
    }
  }
  
  
  renderForm() {
    const formInitialValues = {
      title: this.props?.calendarEvent?.title,
      start: (this.props?.calendarEvent?.start) ? moment(this.props?.calendarEvent?.start) : null,
      end: (this.props?.calendarEvent?.end) ? moment(this.props?.calendarEvent?.end) : null,
      description: this.props?.calendarEvent?.description,
    };
    
    return (
      <Form layout="vertical" initialValues={formInitialValues} onFinish={(event) => { this.setState({ calendarEventFormData: event }); this.saveCalendarEvent(); this.props?.closed(); }}>
        <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Please input a title.' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Start" name="start" rules={[{ required: true, message: 'Please input a start date.' }]}>
          <DatePicker format={'MMMM D, YYYY hh:mm A'} showTime />
        </Form.Item>

        <Form.Item label="End" name="end" rules={[{ required: true, message: 'Please input an end date.' }]}>
          <DatePicker format={'MMMM D, YYYY hh:mm A'} showTime />
        </Form.Item>

        <Form.Item label="Description" name="description">
          <TextArea></TextArea>
        </Form.Item>

        <Form.Item>
          <Button className="antd-mod color-primary" type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    );
  }
  
  
  render() {
    return (
      <Modal className="CalendarEventModal" centered={false} visible={this.props?.visible} onCancel={this.props?.closed} closable={false} footer={null} keyboard={false} maskClosable={false} destroyOnClose={true}>
        <Button className="back-to-link typo-bold" onClick={() => { this.props?.closed() }} type="link" icon={<LeftOutlined />}>Back to Calendar View</Button>
        {this.renderForm()}
      </Modal>
    );
  }
  
}




export default CalendarEventModal;