// import './VendorNavbar.scss';

import * as React from 'react'

import { notification, Menu, Dropdown,Button, Avatar } from 'antd';
import { DownOutlined, SettingOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';

import { Vendor } from '../../../interfaces/vendor-interface';
import { NavbarLink } from '../../../interfaces/navbar-link.interface';

import AuthApi from '../../../api-services/auth-api.service';



interface VendorNavbarProps {
  vendor?: Vendor;
  activeNavItem?: string;
  isAccountSignedIn?: boolean;

  searchSubmitted?: (searchText: string) => void; /* Should be emitted when you submitted the search form */
  navbarLinkClicked?: (navbarLink: NavbarLink) => void; /* Should be emitted when you click a navbar link */
  signInRequired?: (redirectUrl: string) => void; /* Should be emitted when you click a navbar link that requires the user to sign in */
}


interface VendorNavbarState {
  accountMenus: NavbarLink[];
  navbarLinks: NavbarLink[];
  otherLinks: NavbarLink[];
  loginModalVisible: boolean;
}


class VendorNavbar extends React.Component<VendorNavbarProps, VendorNavbarState> {

  componentDidMount() {
    this.setState({
      accountMenus: [
        { id: 'preview-eshop', display: 'Preview Eshop', href: '/business/profile', newWindow:true, icon: <UserOutlined /> },
        { id: 'vendor-dashboard', display: 'Vendor Dashboard', href: '/business/dashboard/edit', newWindow:true, icon: <SettingOutlined/> },
        { id: 'logout', display: 'Logout', method: this.logout.bind(this), icon: <LogoutOutlined />},
      ],
      navbarLinks: [
        // { id: 'home', display: 'Home', href: '/' }, 
        // { id: 'bridepay-deals', display: 'Bridepay Deals', href: '/products/c/all-bridepay-deals' }, 
        // { id: 'vendors', display: 'Vendors', href: '/vendors' }, 
        // { id: 'bridepay-wallet', display: 'Bridepay Wallet', href: '' }, 
      ],
      otherLinks:[],
    });

    
    this.initAutoHide();

    setTimeout(() => {
      this.setActiveNavItem(this.props?.activeNavItem);
    });
  }


  emitNavbarLinkClicked(navbarLink: NavbarLink): void {
    if(this.props?.navbarLinkClicked) {
      this.props?.navbarLinkClicked(navbarLink);
    }
  }


  emitSignInRequired(redirectUrl: string): void {
    if(this.props?.signInRequired) {
      this.props?.signInRequired(redirectUrl);
    }
  }


  initAutoHide(): void {
    let prevScrollpos = window.pageYOffset;
    window.onscroll = function() {
    let currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        document.getElementById('VendorNavbar').style.top = '0';
      } else {
        document.getElementById('VendorNavbar').style.top = '-200px';
      }
      prevScrollpos = currentScrollPos;
    }
  }
  
  
  /**
   * Redirect to the URL provided;
   * Will NOT redirect if the provided URL is empty or null.
   */
  redirectToURL(url: string): void {
    if(url) {
      window.location.href = url;
    }
  }

  /**
   * Open the provided url in new window;
   * Will NOT if the provided URL is empty or null.
   */
  openURL(url: string): void {
    if(url) {
      window.open(url);
    }
  }


  /**
   * Run the provided method.
   * This is useful if you want to add an onClick handler to an element dynamically. 
   * Because running a null method will throw an error and this method will handle that scenario.
   */
  runProvidedMethod(method: Function): void {
    if(method) {
      method();
    }
  }


  setActiveNavItem(navId: any): void {
    const navbarLinks: NavbarLink[] = [];
    
    this.state?.navbarLinks?.forEach(navbarLink => {
      if(navbarLink.id === navId) {
        navbarLink.active = true;
      } else {
        navbarLink.active = false;
      }

      navbarLinks.push(navbarLink);
    });

    this.setState({
      navbarLinks: navbarLinks
    });
  }

  
  /**
   * Logout current user and reload window afterwards.
   */
  logout(): void {
    const authApi = new AuthApi;

    authApi.logout()
    .then(() => {
      window.location.reload();
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: 'An error occured while logging out.', className: 'antd-mod center' });
    })
  }

  /**
   * Adjust navbarlink's container layout based on the number of links.
   * This method will return a CSS style object;
   */
  adjustedNavbarLinksLayout(): any {
    if(this.state?.navbarLinks?.length < 3) {
      return {
        justifyContent: 'flex-start'
      };
    }

    else {
      return {};
    }
  }


  /**
   * Check if the provided NavbarLink requires user sign in.
   * Emit signInRequired event if true.
   * Redirect to URL if false.
   */
  checkForSignInRequiredLinks(navbarLink: NavbarLink): void {
    if(!this.props.isAccountSignedIn && navbarLink.signInRequired) {
      this.emitSignInRequired(navbarLink.href);
    } else {
      this.redirectToURL(navbarLink.href);
    }
  }


  renderNavbarLinks() {
    return this.state?.navbarLinks?.map(navbarLink => {
      return <div onClick={() => { this.emitNavbarLinkClicked(navbarLink); this.checkForSignInRequiredLinks(navbarLink); }} className={`nav-item typo-subheading-1 ${navbarLink.active ? 'active' : ''}`} key={navbarLink.id}>{navbarLink.display}</div>
    });
  }


  renderSignInLinks() {
    return this.state?.otherLinks?.map(otherLink => {
      return (
        <div onClick={() => { this.runProvidedMethod(otherLink?.method); this.redirectToURL(otherLink?.href) }} className="nav-item" key={otherLink.id}>{otherLink.display}</div>
      );
    });
  }


  renderDropdownNavbarLinks() {
    return (
      <Menu>
        {this.state?.navbarLinks?.map(navbarLink => {
          return <Menu.Item key={navbarLink.id}>
            <div className="nav-item typo-subheading-1">{navbarLink.display}</div>
          </Menu.Item>  
        })}
      </Menu>
    );
  }

  
  renderAccountMenu() {
    const menu = (
      <Menu className="account-menus">{
        this.state?.accountMenus.map(accountMenu => {
          return (
            <Menu.Item key={accountMenu.id} icon={accountMenu.icon}
              onClick={() => { this.runProvidedMethod(accountMenu?.method); accountMenu?.newWindow  ?  this.openURL(accountMenu?.href) : this.redirectToURL(accountMenu?.href) }}
            >
              {accountMenu.display}
            </Menu.Item>
          );
        })
      }</Menu>
    );
    
    return (
      <Dropdown overlay={menu} placement="bottomRight" arrow>
        <Button className="account-btn antd-mod hover-primary">
          <Avatar src={this.props?.vendor?.image} />
          {this.props?.vendor?.businessName}
          <DownOutlined />
        </Button>
      </Dropdown>
    );
  }
  

  
  render() {
    return (
      <div className="VendorNavbar" id="VendorNavbar">
        <div className="navbar-upper-section">
          <div className="content">
            <div className="logo-container" onClick={() => { this.redirectToURL('/') }}>
              <img src="/images/bridepay-for-business-logo.png" alt="Bridepay Logo"/>
            </div>

            <div className="other-links typo-subheading-1">
              { this.props?.isAccountSignedIn ? this.renderAccountMenu() : this.renderSignInLinks() }
            </div>
          </div>
        </div>

        { /*
        <div className="navbar-lower-section">
          <div className="content" style={this.adjustedNavbarLinksLayout()}>
            {this.renderNavbarLinks()}
          </div>
        </div>
        */ }
      </div>
    );
  }

  
}


export default VendorNavbar;
