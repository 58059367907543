import axios from 'axios';
import {Comment} from "../interfaces/comment.interface";

class CommentApi {

  getComments(article_id): Promise<Comment[]>{
    let apiUrl = `/api/v1/comments`;
    if (article_id !== null) apiUrl = `/api/v1/comments?article_id=${article_id}`;

    return axios.get(apiUrl)
    .then(response => {
      const comments: Comment[] = [];
      response?.data?.data?.forEach(commentsData => {
        comments.push(this.mapCommentResponse(commentsData?.attributes));
      });
      
      return Promise.resolve(comments);
    });
  }

  getComment(id:number): Promise<Comment>{
    const apiUrl = `/api/v1/comments/${id}`;

    return axios.get(apiUrl)
        .then(response => {
          const comment: Comment = this.mapCommentResponse(response?.data?.data?.attributes);

          return Promise.resolve(comment);
        });
  }

  addComment(commentData: Comment): Promise<Comment>{
    const apiUrl = `/api/v1/comments`;
    let temp = {
      name: commentData.name,
      content: commentData.content,
      email: commentData.email,
      website: commentData.website,
      article_id: commentData.article.id
    };

    return axios({
      method: "post",
      url: apiUrl,
      data: temp
    })
        .then((response)=> {
          const comment:Comment = this.mapCommentResponse(response?.data?.data?.attributes);
          return comment;
        })
  }

  mapCommentResponse(commentData){
    return {
      id: commentData?.id,
      name: commentData?.name,
      email: commentData?.email,
      content: commentData?.content,
      website: commentData?.website,
      createdAt: commentData?.created_at,
      article: {
        id: commentData?.article?.id,
        name: commentData?.article?.name,
        content: commentData?.article?.content,
        credits: commentData?.article?.credits,
        colors: commentData?.article?.colors,
        viewCount: commentData?.article?.view_count,
        publishedAt: commentData?.article?.published_at,
      }
    };
  }
}

export default CommentApi;