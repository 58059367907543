// import './CalendarSlotSelector.scss';

import * as React from 'react';
import { Button, Checkbox, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { format } from 'date-fns';

import { CalendarSlot } from '../../../interfaces/calendar-slot.interface';


interface CalendarSlotSelectorProps {
  calendarSlots: CalendarSlot[];
  addSlotClicked?: () => void;
  editSlotClicked?: (calendarSlot: CalendarSlot) => void;
  deleteSlotClicked?: (calendarSlotId: number) => void;
}


interface CalendarSlotSelectorState {

}




class CalendarSlotSelector extends React.Component<CalendarSlotSelectorProps, CalendarSlotSelectorState> {
  
  emitAddSlotClicked(): void {
    if(this.props?.addSlotClicked) {
      this.props.addSlotClicked();
    }
  }


  emitEditSlotClicked(calendarSlot: CalendarSlot): void {
    if(this.props?.editSlotClicked) {
      this.props.editSlotClicked(calendarSlot);
    }
  }


  emitDeleteSlotClicked(calendarSlotId: number): void {
    if(this.props?.deleteSlotClicked) {
      this.props.deleteSlotClicked(calendarSlotId);
    }
  }
  
  
  getDayName(dayNumber: number): string {
    if(dayNumber === 0) {
      return 'Sunday';
    } else if(dayNumber === 1) {
      return 'Monday';
    } else if(dayNumber === 2) {
      return 'Tuesday';
    } else if(dayNumber === 3) {
      return 'Wednesday';
    } else if(dayNumber === 4) {
      return 'Thursday';
    } else if(dayNumber === 5) {
      return 'Friday';
    } else if(dayNumber === 6) {
      return 'Saturday';
    } else {
      return '';
    }
  }
  
  
  renderCalendarSlotList() {        
    return (
      <div className="calendar-slot-list">{
        this.props?.calendarSlots?.map((calendarSlot, calendarSlotIndex) => {
          const start = format(new Date(calendarSlot.start), 'H:mm a')
          const end = format(new Date(calendarSlot.end), 'H:mm a')
          return (
            <Checkbox className="calendar-slot-item" key={calendarSlotIndex}>
              <div>
                {this.getDayName(calendarSlot.day)} {start} - {end}
              </div>
              <div className="actions">
                <Tooltip title="Delete"><Button onClick={() => { this.emitDeleteSlotClicked(calendarSlot.id) }} disabled={calendarSlot.hasBooking} type="link" icon={<DeleteOutlined />}></Button></Tooltip>
                <Tooltip title="Edit"><Button onClick={() => { this.emitEditSlotClicked(calendarSlot) }} disabled={calendarSlot.hasBooking} type="link" icon={<EditOutlined />}></Button></Tooltip>
              </div>
            </Checkbox>
          )
        })
      }</div>
    );
  }
  
  
  render() {
    return (
      <div className="CalendarSlotSelector">
        <div className="title-container">
          <div className="typo-subheading-2">Slots</div>
          <Button onClick={() => { this.emitAddSlotClicked() }} type="link" icon={<PlusOutlined />}></Button>
        </div>

        {this.renderCalendarSlotList()}
      </div>
    );
  }
  
}




export default CalendarSlotSelector;