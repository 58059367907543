// import './VendorRegistrationForm.scss';

import * as React from "react"
import { startCase } from 'lodash';

import { Button, Checkbox, Form, FormInstance, Input, notification, Select } from "antd";

import { Country } from "../../../interfaces/country.interface";
import VendorApi from "../../../api-services/vendor-api.service";
import DocumentModal from "../../modals/document-modal/DocumentModal";

interface VendorRegistrationFormProps {
  loginMethod: Function;
  countries: Country[];
}

interface VendorRegistrationFormState {
  loading:boolean;
  selectedCountryPhoneCode: string;
  brandName: string;
  agreed: boolean;
  documentModalVisible: boolean;
}

class VendorRegistrationForm extends React.Component <VendorRegistrationFormProps, VendorRegistrationFormState> {
  
  registrationForm = React.createRef<FormInstance>();

  componentDidMount(){
    this.setState({
      loading: false,
      agreed: false,
      selectedCountryPhoneCode: '60'
    });

    this.registrationForm.current!.setFieldsValue({
      phoneKind: "phone"
    })
  }
  
  /**
   * Vendor will be automatically logged in after a successful registration.
   * Redirect user to profile page after registration.
   */
   registerVendor(formEvent): void {
    const vendorApi = new VendorApi;

    const payload = {
      businessName: formEvent.businessName,
      businessRegisteredName: formEvent.businessRegisteredName,
      businessNo: formEvent.businessNo,
      email: formEvent.email,
      password: formEvent.password,
      confirmPassword: formEvent.confirmPassword,
      phoneKind: formEvent.phoneKind,
      phoneNumber: this.state.selectedCountryPhoneCode + formEvent.phoneNumber
    };

    this.setState({ loading: true });

    vendorApi.registerVendor(payload)
    .then(vendorResponse => {
      window.location.href = '/business/dashboard/setup';
    })
    .catch(error => {
      const errorKeys = Object.keys(error?.response?.data?.errors);
      errorKeys.forEach(errorKeyName => {
        const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
        notification.error({ message: errorMessage, className: 'antd-mod center' });
      });
    })
    .finally(()=>{
      this.setState({ loading: false });
    })
  }


  renderPhoneCodeDropdown() {
    const phoneCodeDropdown = (
      <Select defaultValue="MY 60" onChange={(selectedValue) => { this.setState({selectedCountryPhoneCode: selectedValue}) }} className="select-before">
        {this.props?.countries?.map(country => {
          return <Select.Option key={country.phoneCode} value={country.phoneCode}>{country.iso} {country.phoneCode}</Select.Option>
        })}
      </Select>
    );

    return phoneCodeDropdown;
  }

  updateBrandName():string{
    const name = this.state?.brandName;
    return name ? name.toLowerCase().trim().replace(/\s/g,"-"): "";
  }

  renderBrandNameNote(){
    return (
      <div className="brand-name">
        <div className="typo-body-small-2">www.bridepay.com/{this.updateBrandName()}</div>
        <div className="typo-body-small-2">This will be the name of your E-Shop. Brand name is required and cannot be changed.</div>
      </div>
    )
  }

  

  render() {
    return (
      <div className="VendorRegistrationForm">
        <DocumentModal filePath={'/documents/vendors-terms-of-use.pdf'} visible={this.state?.documentModalVisible} closed={() => { this.setState({ documentModalVisible: false }) }}></DocumentModal>
        
        <Form ref={this.registrationForm} autoComplete="off" onFinish={(event) => { this.registerVendor(event) }} className="registration-form">
          <div className="header">
            <div className="title typo-title">Grow your Wedding Business on Bridepay. </div>
          </div>
          
          <Form.Item name="businessRegisteredName" rules={[{ required: true, message: 'Business Name is required' }]}>
            <Input placeholder="Business Registered Name (Sole Proprietor / Pte Ltd)" />
          </Form.Item>
          
          <Form.Item name="businessName" rules={[{ required: true, message: 'Brand Name is required' }]} extra={this.renderBrandNameNote()}>
            <Input placeholder="Brand Name" onKeyUp={(e)=>{this.setState({brandName: e.target["value"]})}}/>
          </Form.Item>

          <div className="phone-inputs">
            <Form.Item name="phoneKind">
              <Select size="large">
                <Select.Option value="phone" >Phone</Select.Option>
                <Select.Option value="mobile">Mobile</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="phoneNumber" rules={[{ required: true, message: 'Phone number is required' }]}>
              <Input size="large" addonBefore={this.renderPhoneCodeDropdown()} placeholder="Business Phone Number"/>
            </Form.Item>
          </div>
          
          <Form.Item name="email" rules={[{ type: 'email', message: 'The input is not valid e-mail.' }, { required: true, message: 'Email is required' }]}
            extra={<div className="typo-body-small-2">This will be your BridepayforBusiness login username and business email address. Once submitted, email cannot be changed.</div>}>
            <Input placeholder="Business Email"/>
          </Form.Item>
          
          <Form.Item name="password" rules={[{ required: true, message: 'Password is required' }, {min: 6, message: "Password must be at least 6 characters long"}]}>
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Form.Item name="confirmPassword" dependencies={['password']} 
            rules={[{ required: true, message: 'Confirm Password is required' },{ min: 6, message: "Password must be at least 6 characters long"} ,  
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Does not match with password'));
                },
            })]}>
            <Input.Password placeholder="Confirm Password"/>
          </Form.Item>
          
          <Form.Item name="agreement" valuePropName="checked">
            <Checkbox onChange={(e)=>this.setState({agreed: e.target.checked})} className="antd-mod">I agree to Bridepay’s <a onClick={() => { this.setState({ documentModalVisible: true }) }} className="link">Terms of Use</a>.</Checkbox>
          </Form.Item>

          <Button className="continue" size="large" disabled={this.state?.agreed==false} htmlType="submit" loading={this.state?.loading}>
            Continue
          </Button>
        </Form>

        <div className="login-link">
          Already have an account?
          &nbsp;
          <a onClick={() => { this.props?.loginMethod() }}>Login to your dashboard</a>
        </div>
      </div>
    );
  }
}

export default VendorRegistrationForm;
