import * as React from 'react';
import { notification, Empty } from 'antd';

import { User } from '../../../interfaces/user.interface';
import { Product } from '../../../interfaces/product.interface';

import UserApi from '../../../api-services/user-api.service';
import ProductApi from '../../../api-services/product-api.service';

import Navbar from '../../basic-components/navbar/Navbar';
import UserProfileNavigation from '../../basic-components/user-profile-navigation/UserProfileNavigation';
import ProductCard from '../../basic-components/product-card/ProductCard';
import Footer from '../../basic-components/footer/Footer';


interface WishlistPageProps {
  isAccountSignedIn: boolean;
  googleSignInUrl: string;
  fbSignInUrl: string;
  csrfToken:string;
}


interface WishlistPageState {
  user: User;
  wishlistedProducts: Product[];
}


class WishlistPage extends React.Component<WishlistPageProps, WishlistPageState> {

  userApi = new UserApi;
  productApi = new ProductApi;

  componentDidMount(): void {
    this.loadSignedInUserProfile();
  }


  /**
   * Get the signed in user's profile from server if there is a user signed in.
   */
  loadSignedInUserProfile(): void {
    if(!this.props?.isAccountSignedIn) {
      return null;
    }

    this.userApi.getUserProfile()
    .then(user => {
      this.setState({ user: user });
      this.loadUserWishlistedProducts(user.id);
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: 'Error while loading the user\'s profile.', className: 'antd-mod center' });
    });
  }


  loadUserWishlistedProducts(userId: number): void {
    this.productApi.getWishlistedProducts()
    .then(products => {
      this.setState({
        wishlistedProducts: products
      });
    });
  }


  redirectToProductDetails(slug: string): void {
    window.open(`/products/${slug}`);
  }


  /**
   * Update product's wishlist status.
   * Remove the product from the view if it is removed from wishlist.
   */
  updateWishlistStatus(productId: number|string, wishlistStatus: boolean): void {
    this.productApi.updateWishlistStatus(productId, wishlistStatus)
    .then(() => {
      if(wishlistStatus === false) {
        const products = this.state?.wishlistedProducts?.filter(product => product.id !== productId);
        this.setState({ wishlistedProducts: products });
      }
    });
  }


  renderWishlistedProducts() {
    return this.state?.wishlistedProducts?.map((product, productIndex) => {
      return <ProductCard 
        key={productIndex} 
        product={product} 
        clicked={() => { this.redirectToProductDetails(product?.slug) }} 
        wishlistStatusChanged={(wishlistStatus) => { this.updateWishlistStatus(product.id, wishlistStatus) }}
      ></ProductCard>
    });
  }
  
  
  render() {
    return (
      <div className="WishlistPage">
        <Navbar isAccountSignedIn={this.props?.isAccountSignedIn} user={this.state?.user}></Navbar>

        <div className="main-content">
          <UserProfileNavigation selectedNavigation="wishlist"></UserProfileNavigation>
          <div className="wishlisted-products">
            {this.state?.wishlistedProducts?.length > 0 ? this.renderWishlistedProducts() : (<div className='empty-container'><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>) }
          </div>
        </div>

        <Footer userType={this.state?.user?.type}></Footer>
      </div>
    );
  }
  
}




export default WishlistPage;