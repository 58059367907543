// import './VendorBookingCalendarTab.scss';

import * as React from 'react';
import { Modal } from 'antd';

import { Calendar } from '../../../interfaces/calendar.interface';
import { CalendarEvent } from '../../../interfaces/calendar-event.interface';
import { CalendarSlot } from '../../../interfaces/calendar-slot.interface';

import CalendarApi from '../../../api-services/calendar-api.service';
import CalendarSlotApi from '../../../api-services/calendar-slot-api.service';

import VendorCalendar from '../../basic-components/vendor-calendar/VendorCalendar';
import CalendarSelector from '../../basic-components/calendar-selector/CalendarSelector';
import CalendarSlotSelector from '../../basic-components/calendar-slot-selector/CalendarSlotSelector';

import CalendarEventModal from '../../modals/calendar-event-modal/CalendarEventModal';
import CalendarModal from '../../modals/calendar-modal/CalendarModal';
import CalendarSlotModal from '../../modals/calendar-slot-modal/CalendarSlotModal';



interface VendorBookingCalendarTabProps {

}


interface VendorBookingCalendarTabState {
  calendars: Calendar[];
  calendarModalVisible: boolean;
  selectedCalendar: Calendar;
  calendarSlots: CalendarSlot[];
  calendarSlotModalVisible: boolean;
  selectedCalendarSlot: CalendarSlot;
  calendarEvents: CalendarEvent[];
  calendarEventModalVisible: boolean;
  calendarEventModalMode: 'add'|'edit'|'view';
  selectedCalendarEvent: CalendarEvent;
}



class VendorBookingCalendarTab extends React.Component<VendorBookingCalendarTabProps, VendorBookingCalendarTabState> {

  calendarApi = new CalendarApi();
  calendarSlotApi = new CalendarSlotApi();


  componentDidMount() {
    this.loadCalendars();
  }
  

  loadCalendars(): void {
    this.calendarApi.getCalendars()
    .then(calendars => {
      this.setState({ calendars: calendars });

      if(calendars.length) {
        this.setState({ selectedCalendar: calendars[0] });
        this.loadCalendarSlots(this.state?.selectedCalendar?.id);
      }
    });
  }


  loadCalendarSlots(calendarId: number): void {
    this.calendarSlotApi.getCalendarSlots(calendarId)
    .then(calendarSlots => {
      this.setState({ calendarSlots: calendarSlots });
    });
  }


  setSelectedCalendar(calendarId: number): void {
    const selectedCalendar = this.state?.calendars?.find(calendar => calendar.id === calendarId);
    this.setState({ selectedCalendar: selectedCalendar });
  }


  saveCalendar(calendar: Calendar): void {
    this.calendarApi.createCalendar(calendar).then(response => {
      this.loadCalendars();
    });
  }


  createCalendarSlot(calendarId: number, calendarSlot: CalendarSlot, days: number[]): void {
    this.calendarSlotApi.createCalendarSlot(calendarId, calendarSlot, days).then(data => {
      this.loadCalendarSlots(calendarId);
    })
  }


  saveCalendarSlot(calendarId: number, calendarSlot: CalendarSlot): void {
    this.calendarSlotApi.saveCalendarSlot(calendarId, calendarSlot)
    .then(() => {
      this.loadCalendarSlots(calendarId);
    });
  }


  confirmDeleteCalendarSlot(calendarId: number, calendarSlotId: number): void {
    Modal.confirm({
      title: 'Delete this calendar slot?',
      onOk: () => {
        this.deleteCalendarSlot(calendarId, calendarSlotId);
      }
    });
  }


  deleteCalendarSlot(calendarId: number, calendarSlotId: number): void {
    this.calendarSlotApi.deleteCalendarSlot(calendarId, calendarSlotId)
    .then(() => {
      this.loadCalendarSlots(calendarId);
    });
  }
  
  
  render() {
    return (
      <div className="VendorBookingCalendarTab">
        <CalendarModal
          visible={this.state?.calendarModalVisible} 
          calendar={this.state?.selectedCalendar}
          closed={() => { this.setState({ calendarModalVisible: false, selectedCalendar: null }) }}
          calendarSaved={(calendar) => { this.saveCalendar(calendar) }}
        ></CalendarModal>
        
        <CalendarSlotModal 
          visible={this.state?.calendarSlotModalVisible} 
          calendarSlot={this.state?.selectedCalendarSlot}
          closed={() => { this.setState({ calendarSlotModalVisible: false }) }}
          calendarSlotCreated={(calendarSlot, days) => { this.createCalendarSlot(this.state?.selectedCalendar?.id, calendarSlot, days) }}
          calendarSlotSaved={(calendarSlot) => { this.saveCalendarSlot(this.state?.selectedCalendar?.id, calendarSlot) }}
        ></CalendarSlotModal>
        
        <CalendarEventModal
          visible={this.state?.calendarEventModalVisible}
          closed={() => { this.setState({ calendarEventModalVisible: false, selectedCalendarEvent: null }) }}
        ></CalendarEventModal>
        
        <div className="selector-container">
          <CalendarSelector 
            calendars={this.state?.calendars} 
            addCalendarClicked={() => { this.setState({ selectedCalendar: null, calendarModalVisible: true }) }}
            selectionChanged={(calendarId) => { this.loadCalendarSlots(calendarId); this.setSelectedCalendar(calendarId) }}
          ></CalendarSelector>
          
          <CalendarSlotSelector 
            calendarSlots={this.state?.calendarSlots}
            addSlotClicked={() => { this.setState({ selectedCalendarSlot: null, calendarSlotModalVisible: true }) }}
            editSlotClicked={(calendarSlot) => { this.setState({ selectedCalendarSlot: calendarSlot, calendarSlotModalVisible: true }) }}
            deleteSlotClicked={(calendarSlotId) => { this.confirmDeleteCalendarSlot(this.state?.selectedCalendar?.id, calendarSlotId) }}
          ></CalendarSlotSelector>
        </div>

        <div className="calendar-container">
          <VendorCalendar 
            calendarEvents={this.state?.calendarEvents}
            addCalendarEventClicked={() => { this.setState({ calendarEventModalMode: 'add', calendarEventModalVisible: true }) }}
            calendarEventClicked={(calendarEvent) => { this.setState({ calendarEventModalMode: 'edit', selectedCalendarEvent: calendarEvent, calendarEventModalVisible: true }) }}
          ></VendorCalendar>
        </div>
      </div>
    );
  }
  
}





export default VendorBookingCalendarTab;