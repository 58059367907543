// import './EventCard.scss';

import * as React from 'react';
import { Button } from 'antd';
import { format } from 'date-fns';

import { Event } from '../../../interfaces/event.interface';

interface EventCardProps {
  event: Event;
}


class EventCard extends React.Component<EventCardProps> {

  renderDetails() {
    return (
      <div className="details">
        <div className="event-name typo-title typo-bold">{this.props.event.name}</div>
        <div className="date typo-bold">{format(new Date(this.props.event.date), 'MMM d, yyyy')}</div>
        <div className="date typo-bold">{format(new Date(this.props.event.date), 'h:mm a')}</div>
        <div className="filler"></div>
        <Button className="learn-more">Learn More</Button>
      </div>
    );
  }
  
  
  render() {
    return (
      <div className="EventCard">
        <img src={this.props.event?.imageUrl} alt=""/>
        <div className="vignette"></div>
        {this.renderDetails()}
      </div>
    );
  }
  
}




export default EventCard;