// import './FaqItem.scss';

import * as React from 'react';
import { VendorFaqItem } from '../../../interfaces/vendor-faq-item.interface';



interface FaqItemProps {
  item: VendorFaqItem;
}


interface FaqItemState {

}




/**
 * CONSIDER renaming this component into FaqListItem.
 */
class FaqItem extends React.Component<FaqItemProps, FaqItemState> {

  render() {
    return (
      <div className="FaqItem">
        <div className="question typo-bold-2">Q: {this.props?.item?.question}</div>
        <div className="answer">
          <span>A: </span>
          <span>{this.props?.item?.answer}</span>
        </div>
      </div>
    );
  }
  
}




export default FaqItem;