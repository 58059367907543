// import './VendorDashboardProductTab.scss';

import * as React from 'react';
import { Button, Modal, notification } from 'antd';

import { Product } from '../../../interfaces/product.interface';
import ProductApi from '../../../api-services/product-api.service';
import ProductCard from '../../basic-components/product-card/ProductCard';


interface IVendorDashboardProductTabProps {
}

interface IVendorDashboardProductTabState {
  products: Product[];
  loading: boolean;
}

class VendorDashboardProductTab extends React.Component <IVendorDashboardProductTabProps, IVendorDashboardProductTabState> {
  
  productApi = new ProductApi;

  componentDidMount() {
    this.setState({
      loading: true
    });
    
    setTimeout(() => {
      this.getProducts();
    }, 500);

  }

  getProducts(): void {
    this.productApi.getProducts()
    .then(products => {
      this.setState({ products: products });
    })
    .catch(error => {
      notification.error({ message: `There\'s an error while loading the products.`, className: 'antd-mod center' })
    })
    .finally(() => {
      this.setState({ loading: true });
    });
  }
  
  
  addNewProduct(): void {
    this.productApi.createProduct()
      .then(productId => {
        this.editProduct(productId);
      })
  }


  editProduct(productId: any){
    window.location.href = `/business/dashboard/products/${productId}/edit`;
  }
  

  removeProduct(productId: string|number): void {
    this.productApi.deleteProduct(productId)
      .then(productResponse => {
        this.setState(prevState => ({
          products: prevState.products.filter(product => product.id !== productId)
        }));
      })
  }

  
  renderControls() {
    return (
      <div className="controls">
        <Button className="antd-mod add-new" onClick={() => { this.addNewProduct() }} type="primary" size="large">Add New</Button>
      </div>
    );
  }


  renderProductList(){
    return (
      <div className="product-list">
        {this.state?.products?.map(product => <ProductCard product={product} key={product.id} editClicked={(product) => { this.editProduct(product.id) }} editable={true}
            removable={product.mayDelete} removeClicked={(productId) => { this.removeProduct(productId) } }></ProductCard>)}
      </div>
    );
  }
  

  render() {
    return (
      <div className="VendorDashboardProductTab">
         <div className="content">
          {this.renderControls()}
          {this.renderProductList()}
        </div>
      </div>
    );
  }
}

export default VendorDashboardProductTab;
