// import './ChangePasswordPage.scss';

import * as React from 'react';
import { notification } from 'antd';

import { User } from '../../../interfaces/user.interface';

import UserApi from '../../../api-services/user-api.service';
import AuthApi from '../../../api-services/auth-api.service';

import Navbar from '../../basic-components/navbar/Navbar';
import LoginModal from '../../modals/login-modal/LoginModal';
import ForgotPasswordModal from '../../modals/forgot-password-modal/ForgotPasswordModal';
import ChangePasswordForm from '../../basic-components/change-password-form/ChangePasswordForm';


notification.config({
  duration: 12,
  top: 60
})


interface ChangePasswordPageProps {
  isAccountSignedIn: boolean;
  googleSignInUrl: string;
  fbSignInUrl: string;
  csrfToken:string;
}

interface ChangePasswordPageState {
  loggedOnUser: User;
  loginModalVisible: boolean;
  loginModalRedirectUrl: string;
  forgotPasswordModalVisible: boolean;
}


class ChangePasswordPage extends React.Component<ChangePasswordPageProps, ChangePasswordPageState> {
  
  userApi = new UserApi;
  authApi = new AuthApi;
  
  
  componentDidMount(): void {
    this.setState({
      loginModalVisible: false,
      forgotPasswordModalVisible: false
    });
    
    this.loadSignedInUserProfile();
  }


  loadSignedInUserProfile(): void {
    if(!this.props?.isAccountSignedIn) {
      return null;
    }

    this.userApi.getUserProfile()
    .then(user => {
      this.setState({
        loggedOnUser: user
      });
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: 'Error while loading the user\'s profile.', className: 'antd-mod center' });
    });
  }


  showLoginModal(): void {
    this.setState({ loginModalVisible: true });
  }


  closeLoginModal(): void {
    this.setState({ loginModalVisible: false });
  }


  /**
   * Logout current user and reload window afterwards.
   */
  logout(): void {
    this.authApi.logout()
    .then(() => {
      window.location.reload();
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: 'An error occured while logging out.', className: 'antd-mod center' });
    })
  }
  
  
  render() {
    return (
      <div className="ChangePasswordPage">
        <Navbar 
          isAccountSignedIn={this.props?.isAccountSignedIn} 
          user={this.state?.loggedOnUser} 
          signInRequired={(redirectUrl) => { this.setState({ loginModalVisible: true, loginModalRedirectUrl: redirectUrl }); }}
        ></Navbar>

        <LoginModal 
          visible={this.state?.loginModalVisible} 
          googleSignInUrl={this.props?.googleSignInUrl} 
          fbSignInUrl={this.props?.fbSignInUrl} 
          csrfToken={this.props?.csrfToken} 
          redirectUrl={this.state?.loginModalRedirectUrl}  
          forgotPasswordClicked={() => { this.setState({ loginModalVisible: false, forgotPasswordModalVisible: true }) }} 
          closed={() => { this.setState({ loginModalVisible: false }) }} 
          registerLink="/registration"
        ></LoginModal>
        
        <ForgotPasswordModal
          visible={this.state?.forgotPasswordModalVisible} 
          loginClicked={() => { this.setState({ forgotPasswordModalVisible: false, loginModalVisible: true }) }} 
          closed={() => { this.setState({ forgotPasswordModalVisible: false }) }} 
        ></ForgotPasswordModal>
        
        <ChangePasswordForm loginClicked={() => { this.showLoginModal() }} googleSignInUrl={this.props?.googleSignInUrl} fbSignInUrl={this.props?.fbSignInUrl} csrfToken={this.props?.csrfToken}></ChangePasswordForm>
      </div>
    );
  }
  
  
}



export default ChangePasswordPage;