// import './ArticleSetupForm.scss';

import * as React from "react";
import {startCase} from "lodash";
import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Modal,
  notification,
  Popover,
  Select,
  Space,
  TreeSelect,
  Upload,
  Image as AntImage
} from "antd";
import {InfoCircleOutlined, LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import * as moment from 'moment';
import ReactQuill, {Quill} from 'react-quill';
// import { ImageDrop } from 'quill-image-drop-module';
// import ReactQuillImageUploader, {
//   saveImageSrc,
// } from 'react-quill-image-uploader';
// Quill.register('modules/imageDrop', ImageDrop);

import {BlogCategory} from "../../../interfaces/blog-category.interface";
import {Article} from "../../../interfaces/article.interface";
import ArticleApi from "../../../api-services/article-admin-api.service";
import {BlogTag} from "../../../interfaces/blog-tag.interface";

interface IArticleSetupFormProps {
  article?:Article;
  wholeCategories?: BlogCategory[];
  wholeTags?: BlogTag[];
  saved?: () => {};
  hideAction?: boolean;
  mode?: "add" | "edit";
}

interface IArticleSetupFormState {
  loading: boolean;
  savingArticle: boolean;
  publishingArticle: boolean;
  image: any[];
  imageURL: string;
  uploadingImage: boolean;
  articleName: string;
  articleContent: string;
  articleCredits: string;
  articleColors: string[];
  categories: any[];
  tags: any[];
  status?:string;
  submitAction?: "save" | "publish";
}

let Inline = Quill.import('blots/inline');
class CaptionBlockW100 extends Inline{
  static create(value){
    let node = super.create();
    node.setAttribute('class','caption-100');
    return node;
  }
}
class CaptionBlockW50 extends Inline{
  static create(value){
    let node = super.create();
    node.setAttribute('class','caption-50');
    return node;
  }
}

class ArticleSetupForm extends React.Component <IArticleSetupFormProps, IArticleSetupFormState> {

  static defaultProps = { mode: 'add', readonly: false, article: null, wholeCategories: [], wholeTags: [], saved: () => {}};

  articleForm = React.createRef<FormInstance>();
  articleApi = new ArticleApi;
  quillRef = null;
  // ReactQuillImageUploaderRef = null;

  componentDidMount(){
    this.setState({
      savingArticle: false,
      publishingArticle: false,
      loading: true,
      image: [],
      imageURL: '',
      uploadingImage: false,
      articleName: "",
      articleContent: "",
      articleCredits: "",
      articleColors: [],
      categories: [],
      tags: [],
    });
  }

  componentDidUpdate(prevProps){
    if(prevProps.article !== this.props?.article){
      if (this.props?.article !== null) {
        this.populateArticleForm();

        // let Half = Quill.import('formats/image');
        // let Whole = Quill.import('formats/image');
        //
        // Half.className = 'img-50';
        // Quill.register(Half, true);
        // Whole.className = 'img-100';
        // Quill.register(Whole, true);
        /////////////////////////////////////////////////////////////////////////////
        // var WidthClass = Quill.import('attributors/class/width');
        // var WidthStyle = Quill.import('attributors/style/width');
        // Quill.register(WidthClass, true);
        // Quill.register(WidthStyle, true);
        CaptionBlockW100.blotName = 'caption-100';
        CaptionBlockW100.tagName = 'div';
        Quill.register(CaptionBlockW100);
        CaptionBlockW50.blotName = 'caption-50';
        CaptionBlockW50.tagName = 'div';
        Quill.register(CaptionBlockW50);
        ////////////////////////////////////////////////////////////////////
        setTimeout(() => {this.quillDragDrop()}, 500);
      }
    }
  }

  populateArticleForm(){
    const article = this.props?.article;
    this.articleForm.current!.setFieldsValue({
      name: article.name,
      content: article.content,
      credits: article.credits,
      publishedAt: article.publishedAt? moment(article.publishedAt): null,
      // colors: article.colors,
      categories: article.categories.map(category => category.id),
      tags: article.tags.map(tag => tag.id),
      description: article.description,
      keywords: article.keywords,
    });

    this.setState({
      loading: false,
      articleName: article.content,
      articleContent: article.content,
      articleCredits: article.credits,
      articleColors: article.colors,
      categories: article.categories.map(category => category.id),
      tags: article.tags.map(tag => tag.id),
      status: article.mayReject? 'published': 'archived',
      imageURL: article.image
    });
  }

  /*addToImages(image){
    this.setState(prevState => ({
      images: [...prevState.images, image]
    }));
  }*/

  /*removeFromImages(imageId){
    const articleApi = new ArticleApi;

    articleApi.deleteImage(this.props?.article.id, imageId)
      .then(response => {
        this.setState(prevState => ({
          images: prevState.images.filter(image => image.id !== imageId)
        }));
      })
      .catch(err=>{
        notification.error({ message: 'Error while removing photo.', className: 'antd-mod center' });
      });
  }*/

  mapPayload(){
    const formData = new FormData();
    const form = this.articleForm?.current?.getFieldsValue();
    formData.append("name", form.name);
    if (form.description) formData.append("description", form.description);
    if (form.keywords) formData.append("keywords", form.keywords);
    if (this.state?.articleContent) formData.append("content", this.state?.articleContent);
    if (this.state?.articleCredits) formData.append("credits", this.state?.articleCredits);
    this.state?.image.forEach(file => {
      formData.append('image', file);
    });
    form.categories.forEach(category => formData.append("category_ids[]", category))
    form.tags.forEach(tag => formData.append("tag_ids[]", tag))
    // formData.append("category_ids[]", form.categories);
    if (form.publishedAt) formData.append("published_at", new Date(form.publishedAt.valueOf()).toString());

    return formData;
  }

  save(){
    this.setState({savingArticle: true});
    const payload = this.mapPayload();
    if (this.props?.mode === 'edit') {
      this.articleApi.updateArticle(this.props?.article?.id, payload)
        .then(articleResponse => {
          notification.success({ message: 'Changes saved successfully', className: 'antd-mod center' });
        })
        .catch(error => {
          const errorKeys = Object.keys(error?.response?.data?.errors);
          errorKeys.forEach(errorKeyName => {
            const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
            notification.error({ message: errorMessage, className: 'antd-mod center' });
          });
        })
        .finally(()=>{
          this.setState({savingArticle: false})
        })
    } else if (this.props?.mode === 'add') {
      this.articleApi.addArticle(payload)
        .then(articleResponse => {
          notification.success({ message: 'Created successfully', className: 'antd-mod center' });
          document.location = `/admin/articles/${articleResponse?.id}`
        })
        .catch(error => {
          const errorKeys = Object.keys(error?.response?.data?.errors);
          errorKeys.forEach(errorKeyName => {
            const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
            notification.error({ message: errorMessage, className: 'antd-mod center' });
          });
        })
        .finally(()=>{
          this.setState({savingArticle: false})
        })
    }

  }

  showPublishConfirmationMessage(): void {
    this.setState({publishingArticle: true});
    Modal.confirm({
      content: (
        <div>
          <p>Thank you for your submission.</p>
        </div>
      ),
      okText: "Yes, Proceed to Publish",
      cancelText: "Back",
      onOk: () => {
        this.publish();
        this.saveAndPublish();
      },
      onCancel: () => {
        this.setState({publishingArticle: false});
      }
    });
  }

  publish(){
    this.setState({publishingArticle: true});
    this.articleApi.publishArticle(this.props?.article?.id)
      .then(success => {
        this.setState({status: "published"});
      })
      .catch(error => {
        const errorKeys = Object.keys(error?.response?.data?.errors);
        errorKeys.forEach(errorKeyName => {
          const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
          notification.error({ message: errorMessage, className: 'antd-mod center' });
        });
      })
      .finally(()=>{
        this.setState({publishingArticle: false})
      })
  }

  saveAndPublish(){
    const payload = this.mapPayload();
    this.setState({savingArticle: true});

    this.articleApi.updateArticle(this.props?.article?.id, payload)
      .then(articleResponse => {
        notification.success({ message: 'It will be published successfully', className: 'antd-mod center' });
        this.props?.saved();
        this.articleForm.current!.setFieldsValue({
          publishedAt: moment()
        });
      })
      .catch(error => {
        const errorKeys = Object.keys(error?.response?.data?.errors);
        errorKeys.forEach(errorKeyName => {
          const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
          notification.error({ message: errorMessage, className: 'antd-mod center' });
        });
      })
      .finally(()=>{
        this.setState({publishingArticle: false, savingArticle: false})
      })
  }

  archive(){
    this.articleApi.archiveArticle(this.props?.article?.id)
      .then(success => {
        notification.success({ message: 'It will be archived successfully', className: 'antd-mod center' });
        this.props?.saved();
        this.setState({status: "archived"});
        this.articleForm.current!.setFieldsValue({
          publishedAt: null
        });
      })
      .catch(error => {
        const errorKeys = Object.keys(error?.response?.data?.errors);
        errorKeys.forEach(errorKeyName => {
          const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
          notification.error({ message: errorMessage, className: 'antd-mod center' });
        });
      })
  }

  handleChange(name, value) {
    let obj = {};
    obj[name] = value;

    this.setState(obj);
  }

  renderLabelWithInfo(id){
    const list = {
      "category": {
        label: "Blog Category",
        info: "Classify your article to the correct subcategory so it can easily be searched by customers."
      },
      "color": {
        label: "Colors",
        info: "Choose the article colors."
      },
      "photos": {
        label: "Cover Image",
        info: "Upload a cover photo that represent this article."
      }
    };

    const field = list[id];

    return (
      <>
        {field.label} &nbsp;
        <Popover content={field.info} placement="topLeft">
          <InfoCircleOutlined />
        </Popover>
      </>
    )
  }

  getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  handleImageChange = info => {
    this.getBase64(info.file.originFileObj, imageURL =>
      this.setState({
        imageURL,
        uploadingImage: false,
      }),
    );
  };

  beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({ message: 'You can only upload JPG/PNG file!', className: 'antd-mod center' });
    }else{
      this.setState({image: [file]});
    }

    return isJpgOrPng;
  };

  submitForm(){
    if(this.state?.submitAction == "save"){
      this.save();
    }else{
      this.showPublishConfirmationMessage();
    }
  }

  generateCategoryTreeData(categories) {
    return categories.map(category => {
      return {
        id: category.id,
        title: category.name,
        pId: (category.ancestry === null ? 0 : Number(category.ancestry)),
        value: category.id
      }
    });
  }

  onChangeCategoryTree = (values) => {
    this.setState({categories: values})
  }

  quillUploadImage = (api, id, file, quillRef) => {
    const reader = new FileReader();
    let width = 0;
    let height = 0;

    reader.addEventListener('load', () => {
      const img  = new Image();
      img.addEventListener('load', () => {
        width = img.width;
        height = img.height;
      });
      if (typeof reader.result === "string") {
        img.src = reader.result;
      }
    });
    reader.readAsDataURL(file);
    api.createImage(id, file)
      .then(result => {
        // let Image = Quill.import('formats/image');
        const range = quillRef.getEditorSelection();
        // if (height - width > 0) Image.className = 'img-50';
        // else Image.className = 'img-100';
        // Quill.register(Image, true);
        let htmlCode:string;
        if (height > width) {
          htmlCode = `<img src="${result}" width="50%" />`
        }
        else {
          htmlCode = `<img src="${result}" width="100%" />`
        }

        quillRef.getEditor().clipboard.dangerouslyPasteHTML(range.index, htmlCode, 'user') //.insertEmbed(range.index, 'image', result);
      })
      .catch(error => {
        const errorKeys = Object.keys(error?.response?.data?.errors);
        errorKeys.forEach(errorKeyName => {
          const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
          notification.error({ message: errorMessage, className: 'antd-mod center' });
        });
      });
  }

  quillDragDrop () {
    const api = this.articleApi;
    const quillRef = this.quillRef;
    const id = this.props?.article?.id;
    const handler = this.quillUploadImage;
    let dropZone = document.getElementsByClassName('ql-editor')[0];

    // Optional.   Show the copy icon when dragging over.  Seems to only work for chrome.
    // dropZone.addEventListener('dragover', (e) => {
    //   e.stopPropagation();
    //   e.preventDefault();
    //   e.dataTransfer.dropEffect = 'copy';
    // });

    // Get file data on drop
    dropZone.addEventListener('drop', function(e) {
      e.stopPropagation();
      e.preventDefault();
      let files = e.dataTransfer.files; // Array of all files

      for (let i=0, file; file=files[i]; i++) {
        if (file.type.match(/image.*/)) {
          handler(api, id, file, quillRef);
        }
      }
    });
  }

  quillImageHandler = () => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      let file: any = input.files[0];

      this.quillUploadImage(this.articleApi, this.props?.article?.id, file, this.quillRef);
    };
  }

  /*uploadImageCallBack = (file, base64) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({ message: 'You can only upload JPG/PNG file!', className: 'antd-mod center' });
    }

    const reader = new FileReader();
    let width = 0;
    let height = 0;

    reader.addEventListener('load', () => {
      const img  = new Image();
      img.addEventListener('load', () => {
        width = img.width;
        height = img.height;
      });
      if (typeof reader.result === "string") {
        img.src = reader.result;
      }
    });
    reader.readAsDataURL(file);

    return new Promise((resolve, reject) => {
      // submit file to server
      let src = 'https://picsum.photos/250/250' // demo image src
      if (base64) {
        src = base64
      }
      // upload img thing
      let uploadSuccess = false

      this.articleApi.createImage(this.props?.article?.id, file)
          .then(result => {
            src = result;
            uploadSuccess = true;
            if (width && width < height)
              this.ReactQuillImageUploaderRef.insertImg(src,'50%');
            else this.ReactQuillImageUploaderRef.insertImg(src);

            this.ReactQuillImageUploaderRef.toggle()
            // return data to save to plugin history
            resolve({
              data: {
                name: file.name || '',
                link: src,
              },
            })
          })
          .catch(error => {
            setTimeout(() => {
              return reject()
            }, 3 * 1000);
          });
      return Promise.resolve();
    })
  }*/

  modulesContent = {
    toolbar: {
      container: [
        [{'header': [1, 2, 3, 4, false]}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'script': 'sub'}, {'script': 'super'}],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
        ['link', {'color': []}, {'background': []}],
        ['image'],
        ['clean']
      ],
      handlers: {
        image: this.quillImageHandler
      },
    },
    // imageDrop: true
  }

  modulesCredits = {
    toolbar: {
      container: [
        [{'header': [1, 2, 3, 4, false]}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'script': 'sub'}, {'script': 'super'}],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
        ['link', {'color': []}, {'background': []}],
        ['clean']
      ]
    },
    // imageDrop: true
  }

  /*modulesReactQuillImageUploader = {
    toolbar: {
      container: [
        [{'header': [1, 2, 3, 4, false]}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'
        ],
        [{'script': 'sub'}, {'script': 'super'}],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
        ['link', {'color': []}, {'background': []}],
        ['image'],
        ['clean']
      ],
      handlers: {
        image: () => {
          const { clientX, clientY } = window.event;
          const position = { x: clientX, y: clientY } // position the plugin to show
          this.ReactQuillImageUploaderRef.toggle(position) // show or hide the plugin
          // toggle() is also ok
          // this.ReactQuillImageUploaderRef.toggle()
        }
      }
    }
  }*/

  render() {
    const uploadButton = (
      <div id="upload-button">
        {this.state?.uploadingImage ? <LoadingOutlined /> : <PlusOutlined />} Upload
      </div>
    );

    return (
      <div className="ProductSetupForm">
        <Form ref={this.articleForm} labelCol={{ span: 6 }} wrapperCol={{ span: 15 }}  onFinish={()=>this.submitForm()} autoComplete="off" scrollToFirstError={true}>
          <div className="form-content product-form">
            <Form.Item name="categories"
                       label="Blog Category"
                       rules={[{ required: true, message: 'Please choose at least one category' }]}
            >
              <TreeSelect
                showSearch
                style={{ width: '100%' }}
                value={this.state?.categories}
                treeDataSimpleMode
                treeData={this.generateCategoryTreeData(this.props?.wholeCategories)}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="Please select category"
                allowClear
                multiple
                treeDefaultExpandAll
                onChange={this.onChangeCategoryTree}
              />
            </Form.Item>
            <Form.Item name="tags"
                       label="Blog Tag"
                       rules={[{ required: true, message: 'Please choose at least one tag' }]}
            >
              <Select size="large"
                      mode="multiple"
                      value={this.state?.tags.map(i => i.id)}
              >
                {this.props?.wholeTags.map((it, idx) => {
                  return <Select.Option key={idx} value={it.id}>{it.name}</Select.Option>
                })}
              </Select>
            </Form.Item>
            <Form.Item name="name"
                       label="Article Name"
                       rules={[{ required: true, message: 'Name is required' }]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item name="description"
                       label="Meta Description"
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item name="keywords"
                       label="Meta Keywords"
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item name="image"
                       label={this.renderLabelWithInfo("photos")}
                       getValueFromEvent={this.getFile}
            >
              <Upload
                action="#"
                maxCount={1}
                showUploadList={false}
                fileList={this.state?.image}
                accept="image/*"
                beforeUpload={this.beforeUpload}
                onChange={this.handleImageChange}
                className="uploader"
              >
                {this.state?.imageURL ? <AntImage preview={false} src={this.state?.imageURL} width="50%" alt="article-image" /> : uploadButton}
              </Upload>
            </Form.Item>
            {this.props?.mode === 'edit' && (<Form.Item name="content" label="Content">
              <div className="text-editor">
                <ReactQuill value={this.state?.articleContent}
                            onChange={(val) => this.handleChange("articleContent", val)}
                            theme="snow"
                            modules={this.modulesContent}
                            ref={el => {
                              this.quillRef = el
                            }}
                />
                {/*<ReactQuillImageUploader
                    ref={el => {
                      this.ReactQuillImageUploaderRef = el
                    }}
                    isShowUploadFail={false} // default true, uopload fail history is hidden when false
                    isShowHistory={false} // default true, history is hidden when false
                    quill={this.state?.quill}
                    uploadCallback={this.uploadImageCallBack}
                />*/}
              </div>
            </Form.Item>)}
            {this.props?.mode === 'edit' && (<Form.Item name="credits" label="Credits">
              <div className="text-editor">
                <ReactQuill value={this.state?.articleCredits}
                            onChange={(val) => this.handleChange("articleCredits", val)}
                            theme="snow"
                            modules={this.modulesCredits}
                />
              </div>
            </Form.Item>)}
            {this.props?.mode === 'edit' && (<Form.Item name="publishedAt" label="Publish Date">
              <DatePicker size="large" format={'MMMM D, YYYY'} showTime={false}/>
            </Form.Item>)}
            {/*<Form.Item name="colors"
                       label={this.renderLabelWithInfo("color")}
                       rules={[{ required: true, message: 'Please choose at least one color' }]}
            >
              <Select mode="multiple"
                      disabled={this.state?.disabled}
                      size="large"
                      allowClear
                      showSearch
                      maxTagCount={5}
                      optionFilterProp="children"
                      filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {colorPatterns.map((color, index) => {
                  return <Select.Option key={index} value={color}>
                    <div style={{background: color, width: 10, height: 10, display: 'inline-block'}} />
                    {color}
                  </Select.Option>
                })}
              </Select>
            </Form.Item>*/}
          </div>

          <div className="form-actions">
            <Space>
              {
                this.props?.mode === "add" && (
                  <Button type="primary" size="large" loading={this.state?.savingArticle} className="antd-mod" htmlType="submit" onClick={()=>this.setState({submitAction: "save"})}>
                    Save
                  </Button>
                )
              }
              {
                (this.state?.status == 'published') && (
                  <>
                    <Button type="primary" size="large" loading={this.state?.savingArticle} className="antd-mod" htmlType="submit" onClick={()=>this.setState({submitAction: "save"})}>
                      Save
                    </Button>
                    <Button type="primary" size="large" className="antd-mod" href={`/admin/articles/${this.props?.article?.id}/preview`} target="_blank">
                      Preview
                    </Button>
                    {!this.props.hideAction && (
                      <Button type="primary" className="antd-mod" size="large" onClick={() => this.archive()}>
                        Archive
                      </Button>
                    )}
                  </>
                )
              }
              {
                (this.state?.status == 'archived') && (
                  <>
                    <Button type="primary" size="large" loading={this.state?.savingArticle} className="antd-mod" htmlType="submit" onClick={()=>this.setState({submitAction: "save"})}>
                      Save
                    </Button>
                    <Button type="primary" size="large" className="antd-mod" href={`/admin/articles/${this.props?.article?.id}/preview`} target="_blank">
                      Preview
                    </Button>
                    {!this.props.hideAction && (
                      <Button type="primary" size="large" loading={this.state?.publishingArticle} className="antd-mod" htmlType="submit"  onClick={()=>this.setState({submitAction: "publish"})}>
                        Publish
                      </Button>
                    )}
                  </>
                )
              }
            </Space>
          </div>
        </Form>
      </div>
    );
  }
}

export default ArticleSetupForm
