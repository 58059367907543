// import './VendorProfilePage.scss';

import * as React from 'react';
import { notification, Tabs } from 'antd';

import { Vendor } from '../../../interfaces/vendor-interface';
import { User } from '../../../interfaces/user.interface';
import { Album } from '../../../interfaces/album.interface';

import VendorApi from '../../../api-services/vendor-api.service';
import UserApi from '../../../api-services/user-api.service';
import AlbumApi from '../../../api-services/album-api.service';

import Navbar from '../../basic-components/navbar/Navbar';
import Footer from '../../basic-components/footer/Footer';
import VendorSummary from '../../basic-components/vendor-summary/VendorSummary';
import BreadcrumbsNavigation from  '../../basic-components/breadcrumbs-navigation/BreadcrumbsNavigation';
import VendorProductsTab from '../../tab-contents/vendor-products-tab/VendorProductsTab';
import VendorAboutTab from '../../tab-contents/vendor-about-tab/VendorAboutTab';
import VendorFaqTab from '../../tab-contents/vendor-faq-tab/VendorFaqTab';
import VendorAlbumsTab from '../../tab-contents/vendor-albums-tab/VendorAlbumsTab';
import LoginModal from '../../modals/login-modal/LoginModal';
import SingleImageModal from '../../modals/single-image-modal/SingleImageModal';
import ForgotPasswordModal from '../../modals/forgot-password-modal/ForgotPasswordModal';
import AppointmentModal from "../../modals/appointment-modal/AppointmentModal";

notification.config({
  duration: 12,
  top: 60
});

interface VendorProfilePageProps {
  isAccountSignedIn: boolean;
  googleSignInUrl: string;
  fbSignInUrl: string;
  csrfToken:string;
  vendorData: any; /* Vendor object generated by the backend. Map this object into Vendor object */
}

interface VendorProfilePageState {
  vendor: Vendor;
  user: User;
  loginModalVisible: boolean;
  loginModalRedirectUrl: string;
  // rawSpaceAlbums: Album[];
  // albums: Album[];
  // videos: Album[];
  // menus: Album[];
  albumsSet: Array<{name?: string, result?: Album[]}>
  breadcrumbsNavItems: Array<{ id?: any; label: string; link?: string; }>;
  videoModalVisible: boolean;
  currentVideoUrl: string;
  currentVideoDesc: string;
  forgotPasswordModalVisible: boolean;
  activeTab: string;
  appointmentModalVisible: boolean;
}

class VendorProfilePage extends React.Component<VendorProfilePageProps, VendorProfilePageState> {
  vendorApi = new VendorApi();
  userApi = new UserApi();
  albumApi = new AlbumApi();

  /**
   * vendorData Vendor object generated by the backend.
   */
  static getDerivedStateFromProps(props, state) {
    // this.mapIntoVendorObject(this.props?.vendorData);
    const vendorData = props?.vendorData;
    const vendor: Vendor = {
      id: vendorData?.id,
      address: vendorData?.address,
      fullAddress: vendorData?.full_address,
      avatarUrl: vendorData?.avatar_url,
      description: vendorData?.about,
      businessName: vendorData?.business_name,
      businessRegisteredName: vendorData?.business_registered_name,
      businessRegisteredNumber: vendorData?.business_registration_no,
      email: vendorData?.email,
      facebook: vendorData?.facebook,
      whatsapp: vendorData?.whatsapp,
      rating: vendorData?.rating,
      reviews: vendorData?.reviews,
      slug: vendorData?.slug,
      tiktok: vendorData?.tiktok,
      twitter: vendorData?.twitter,
      instagram: vendorData?.instagram,
      website: vendorData?.website,
      youtube: vendorData?.youtube,
      zipCode: vendorData?.zip_code,
      city: {
        id: vendorData?.city?.id,
        name: vendorData?.city?.name
      },
      country: {
        id: vendorData?.country?.id,
        name: vendorData?.country?.name,
        display: vendorData?.country?.display,
        currency: vendorData?.country?.currency
      },
      primaryPhoneNumber: {
        id: vendorData?.primary_phone?.id,
        isBusiness: vendorData?.primary_phone?.is_business,
        isPrimary: vendorData?.primary_phone?.is_primary,
        number: vendorData?.primary_phone?.number,
        verified: vendorData?.primary_phone?.verified,
        countryCode: vendorData?.primary_phone?.formatted?.country_code,
        national: vendorData?.primary_phone?.formatted?.national,
        rawNational: vendorData?.primary_phone?.formatted?.raw_national
      },
      albumTypes: vendorData?.album_types?.map(albumType => albumType),
      serviceCategories: [],
      highlightedFaqs: [],
      faqItems: []
    };

    // Populate vendor.serviceCategories
    vendorData?.services?.forEach(service => {
      vendor.serviceCategories.push({
        id: service?.category?.id,
        name: service?.category?.name,
        slug: service?.category?.slug,
      });
    });

    // Populate highlighted faqs
    vendorData?.highlighted_faqs?.forEach(faq => {
      vendor.highlightedFaqs.push({
        position: faq.position,
        label: faq.label,
        value: faq.value
      });
    });

    // Populate vendor.faqItems
    vendorData?.faqs?.forEach(faq => {
      vendor.faqItems.push({
        question: faq.question,
        answer: faq.answer
      });
    });

    // console.log('VENDOR', vendor);
    return{ vendor: vendor };
  }

  componentDidMount() {
    this.setBreadcrumbsNavItems();
    this.loadSignedInUserProfile();
    this.loadAlbums(this.state?.vendor?.albumTypes);
  }

  loadSignedInUserProfile(): void {
    if(!this.props?.isAccountSignedIn) {
      return null;
    }

    this.userApi.getUserProfile()
    .then(user => {
      this.setState({ user: user });
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: 'Error while loading the user\'s profile.', className: 'antd-mod center' });
    });
  }

  loadAlbums(albumTypes): void {
    let query_albums = []

    albumTypes.forEach(album_type => {
      query_albums.push(this.albumApi.getVendorAlbums(this.state?.vendor?.slug, album_type))
    });
    // Promise.all([
    //   this.albumApi.getVendorAlbums(this.state?.vendor?.slug, 'raw_space'),
    //   this.albumApi.getVendorAlbums(this.state?.vendor?.slug, 'album'),
    //   this.albumApi.getVendorAlbums(this.state?.vendor?.slug, 'video'),
    //   this.albumApi.getVendorAlbums(this.state?.vendor?.slug, 'menu'),
    // ])
    Promise.all(query_albums)
    .then(result => {
      console.log('RESULTS', result);
      let albumsSet = [];
      let activeTab = '1';
      result.forEach((r, i) => {
        albumsSet.push({
          name: albumTypes[i],
          result: r
        });
        if(albumTypes[i] == 'albums'){
          activeTab = (i + 2).toString();
        }
      });

      this.setState({
        // rawSpaceAlbums: result[0],
        // albums: result[1],
        // videos: result[2],
        // menus: result[3],
        albumsSet: albumsSet,
        activeTab: activeTab
      });
    });
  }

  changeTab = activeKey => {
    this.setState({
      activeTab: activeKey
    })
  }

  setBreadcrumbsNavItems(): void {
    this.setState({
      breadcrumbsNavItems: [
        { id: 1, label: 'Home', link: '/' },
        { id: 2, label: 'Vendors', link: '/vendors' },
        { id: 3, label: this.state?.vendor?.serviceCategories[0]?.name, link: '' },
        { id: this.state?.vendor?.id, label: this.state?.vendor?.businessName, link: '' },
      ]
    });
  }

  videoClick(album): void {
    this.setState({
      videoModalVisible: true,
      currentVideoUrl: album.videoUrl,
      currentVideoDesc: album.description
    })
    console.log(album);
  }

  redirectToAlbumShowPage(vendorSlug: string, albumSlug: string): void {
    window.open(`/${vendorSlug}/albums/${albumSlug}`,'_self');
  }

  capitalize(string){
    return string.replaceAll('_', ' ').replace(/(^\w)|(\s+\w)/g, letter => letter.toUpperCase());
  }

  renderAlbumTabByType(albumType: string) {
    // console.log('this.state?.albumsSet', this.state?.albumsSet);
    // console.log('albumType', albumType);
    // console.log('this.state?.vendor?.albumTypes', this.state?.vendor?.albumTypes)
    if(this.state?.albumsSet?.length){
      let index = this.state?.vendor?.albumTypes.indexOf(albumType)
      return (
        <Tabs.TabPane tab={(<div className="typo-subheading-1">{this.capitalize(albumType)}</div>)} key={index+2}>
          <SingleImageModal visible={this.state?.videoModalVisible} imageUrl={this.state?.currentVideoUrl} description={this.state?.currentVideoDesc} type="video" closed={() => { this.setState({ videoModalVisible: false }) }} />
          <VendorAlbumsTab tabTitle={this.capitalize(albumType)} albums={this.state?.albumsSet[index].result} albumClicked={(album) => { albumType == 'videos' ? this.videoClick(album) : this.redirectToAlbumShowPage(this.state?.vendor?.slug, album.slug) }} />
        </Tabs.TabPane>
      );
    }
  }

  handleAppointmentButton(): void {
      this.setState({appointmentModalVisible: true});
  }

  handleSubmitAppointment(data): void {
    this.userApi.createAppointment(this.state?.vendor.id, data.email, data.date, data.detail, data.name, data.mobile)
      .then(result => {
        if (result) {
          notification.success({ message: 'Appointment saved successfully', className: 'antd-mod center' });
          this.setState({appointmentModalVisible: false});
        } else {
          notification.error({ message: 'Error while creating the appointment.', className: 'antd-mod center' });
        }
      });
  }

  render() {
    return (
      <div className="VendorProfilePage">
        <Navbar 
          isAccountSignedIn={this.props?.isAccountSignedIn} 
          user={this.state?.user} 
          signInRequired={(redirectUrl) => { this.setState({ loginModalVisible: true, loginModalRedirectUrl: redirectUrl }); }}
        />

        <LoginModal 
          visible={this.state?.loginModalVisible} 
          googleSignInUrl={this.props?.googleSignInUrl} 
          fbSignInUrl={this.props?.fbSignInUrl} 
          csrfToken={this.props?.csrfToken} 
          redirectUrl={this.state?.loginModalRedirectUrl}  
          forgotPasswordClicked={() => { this.setState({ loginModalVisible: false, forgotPasswordModalVisible: true }) }} 
          closed={() => { this.setState({ loginModalVisible: false }) }} 
          registerLink="/registration"
        />

        <ForgotPasswordModal
          visible={this.state?.forgotPasswordModalVisible} 
          loginClicked={() => { this.setState({ forgotPasswordModalVisible: false, loginModalVisible: true }) }} 
          closed={() => { this.setState({ forgotPasswordModalVisible: false }) }} 
        />

        <AppointmentModal
          visible={this.state?.appointmentModalVisible}
          onCreate={(data) => {this.handleSubmitAppointment(data)}}
          onCancel={() => {this.setState({ appointmentModalVisible: false })}}
        />

        <div className="page-content">
          <BreadcrumbsNavigation items={this.state?.breadcrumbsNavItems} />
          <VendorSummary
            signedIn={this.props?.isAccountSignedIn}
            vendor={this.state?.vendor}
            onClickAppointment={() => { this.handleAppointmentButton() }}
          />
        </div>

        <Tabs className="antd-mod vendor-main-tabs" activeKey={this.state?.activeTab} onChange={this.changeTab}>
          <Tabs.TabPane tab={(<div className="typo-subheading-1">Wedding Deals</div>)} key="1">
            <VendorProductsTab vendor={this.state?.vendor} isAccountSignedIn={this.props?.isAccountSignedIn} />
          </Tabs.TabPane>

          {
            /* Render Album tabs that are specified in vendor.albumTypes */
            this.state?.vendor?.albumTypes?.map(albumType => this.renderAlbumTabByType(albumType))
          }

          <Tabs.TabPane tab={(<div className="typo-subheading-1">FAQS</div>)} key="16">
            <VendorFaqTab faqItems={this.state?.vendor?.faqItems} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={(<div className="typo-subheading-1">About</div>)} key="17">
            <VendorAboutTab about={this.state?.vendor?.description} />
          </Tabs.TabPane>
        </Tabs>

        <Footer />
      </div>
    );
  }
}

export default VendorProfilePage;
