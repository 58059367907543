// import './ProductCard.scss';

import * as React from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined} from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import Dotdotdot from 'react-dotdotdot';

import { Product } from '../../../interfaces/product.interface';


interface ProductCardProps {
  product: Product;
  size?: 'small'|'large';
  editable?: boolean;
  removable?: boolean;
  wishlistButtonDisabled?: boolean;
  clicked?: () => void;
  editClicked?: (product: Product) => void;
  removeClicked?: (productId: string|number) => void;
  wishlistStatusChanged?: (status: boolean) => void;
}


interface ProductCardState {
  wishlisted: boolean;
}



class ProductCard extends React.Component<ProductCardProps, ProductCardState> {

  
  componentDidMount() {
    this.setState({ wishlisted: this.props?.product?.wishlisted });
  }
  
  
  emitClicked(): void {
    if(this.props?.clicked) {
      this.props?.clicked();
    }
  }
  
  
  emitEditClicked(): void {
    if(this.props?.editClicked) {
      this.props?.editClicked(this.props?.product);
    }
  }


  emitRemoveClicked(){
    if(this.props?.removeClicked) {
      this.props?.removeClicked(this.props?.product?.id);
    }
  }


  emitWishlistStatusChanged(wishlistStatus: boolean): void {
    if(this.props?.wishlistStatusChanged) {
      this.props?.wishlistStatusChanged(wishlistStatus);
    }
  }


  /**
   * Show a confirmation message first when removing a product from wishlist.
   * The reason is to prevent accidental "Remove from wishlist" misclicks.
   * Do NOT show a confimation when adding a product to wishlist.
   */
  confirmWishlistStatusChange(wishlistStatus: boolean): void {
    if(wishlistStatus === false) {
      const confirm = window.confirm(`Remove this product (${this.props?.product?.name}) from wishlist?`);
      if(confirm) {
        this.props?.wishlistStatusChanged(wishlistStatus);
        this.setState({ wishlisted: false });
      }
    }

    else {
      this.props?.wishlistStatusChanged(wishlistStatus);
      this.setState({ wishlisted: true });
    }
  }


  renderEasyReschedTag() {
    if(this.props?.product?.easyReschedule) {
      return (
        <div className="easy-resched-tag typo-body-small-2 typo-bold">Easy Reschedule</div>
      );
    } else {
      return null;
    }
  }


  renderWeddingFairSpecialTag() {
    return (
      <div className="wedding-fair-special-tag typo-body-small-2">Wedding <br/> Fair Special</div>
    );
  }


  renderShoppingEventTag() {
    return (
      <div className="shopping-event-tag">6.6</div>
    );
  }
  

  renderEditButton() {
    if(this.props?.editable) {
      return <Button className="edit" onClick={() => { this.emitEditClicked() }} icon={this.props?.product?.status == 'draft' ? <EditOutlined /> : <EyeOutlined />}></Button>
    } else {
      return null;
    }
  }
  

  renderRemoveButton() {
    if(this.props?.removable && this.props?.product?.status == 'draft') {
      return (
        <Popconfirm title={`Are you sure you want to delete this ${this.props?.product?.type}?`} onConfirm={() => this.emitRemoveClicked()}>
          <Button type="primary" danger icon={<DeleteOutlined />}></Button>
        </Popconfirm>
      ) 
    } else {
      return null;
    }
  }


  /**
   * Show only the product status of "editable" is true.
   */
  renderStatus() {
    if(this.props?.editable) {
      return (
        <>
          <div className={`status typo-bold-small ${this.props?.product?.status}`}>
            {this.props?.product?.status.replace("_", " ")}
          </div>
          <div className="typo-body-small-2"> {this.props?.product?.campaign?.title} </div>
        </>
      )
    }

    else {
      return null;
    }
  }


  renderAllInTag() {
    return (
      <div className="all-in-tag">
        <div className="content">
          <img className="background" src="/images/all-in-tag-background.png" alt="All In Background" />
          <div className="text">All <br/> In</div>
        </div>
      </div>
    );
  }


  renderBridepaySpecialTag() {
    return (
      <img className="bridepay-special-tag" src="/images/bridepay-special.png" alt="Bridepay Special" />
    );
  }
  
  
  renderProductImage() {
    const image = (this.props?.product?.image) ? <img loading='lazy' src={this.props?.product?.image} alt="Product Card"/> : <div className="empty-image-placeholder"></div>;
    
    return (
      <div className="image-container" onClick={() => { this.emitClicked() }}>
        {image}
        <div className="on-image-details">
          <div className="action-buttons">
            {this.renderEditButton()}
            {this.renderRemoveButton()}
          </div>
          {/*{this.renderEasyReschedTag()}*/}
          {/* {this.renderWeddingFairSpecialTag()} */}
          {/* {this.renderShoppingEventTag()} */}
          {/* {this.renderAllInTag()} */}
          {/*this.renderBridepaySpecialTag()*/}
        </div>
      </div>
    );
  }


  renderWishlistButtons() {
    if(this.props?.wishlistButtonDisabled) {
      return null;
    }

    else {
      return (
        <div className="wishlist-buttons">
          <Tooltip title="Add to wishlist">
            <img src="/images/wishlist-inactive.png" onClick={() => { this.confirmWishlistStatusChange(true) }} className={`icon ${this.state?.wishlisted ? 'hidden' : ''}`} alt="Add to wishlist" />
          </Tooltip>
          <Tooltip title="Remove from wishlist">
            <img src="/images/wishlist-active.png" onClick={() => { this.confirmWishlistStatusChange(false) }} className={`icon ${this.state?.wishlisted ? '' : 'hidden'}`} alt="Remove wishlist" />
          </Tooltip>
        </div>
      );
    }
  }


  renderDiscountTag() {
    if(this.props?.product?.discountPercentage) {
      return (
        <div className="discount-tag-container">
          <div className="discount-tag">
            <img src="/images/discount-tag-ribbon.png" alt="" />
            <div className="texts">
              <div className="line-1 typo-body-small-2">SAVE</div>
              <NumberFormat className="line-2" value={Number.isInteger(this.props?.product?.discountAmount) ? this.props?.product?.discountAmount : this.props?.product?.discountAmount.toFixed(2)} thousandSeparator={true} displayType={'text'}></NumberFormat>
            </div>
          </div>
        </div>
      );
    }

    else {
      return null;
    }
  }


  renderPrice() {
    const discountPercentageHiddenClass: string = (!this.props?.product?.discountPercentage) ? 'hidden' : '';
    let discountPercent = '';
    if (this.props?.product?.discountPercentage) {
      if (Number.isInteger(this.props?.product?.discountPercentage)) {
        discountPercent = this.props?.product?.discountPercentage.toString(10);
      } else {
        discountPercent = this.props?.product?.discountPercentage.toFixed(2);
      }
    }
    
    return (
      <div className="price-container">
        <div className="discount-details">
          <div className={`discount-percentage typo-bold ${discountPercentageHiddenClass}`}>{discountPercent}%</div>
          <div className="old-price">
            <NumberFormat value={Number.isInteger(this.props?.product?.price) ? this.props?.product?.price : this.props?.product?.price.toFixed(2)} thousandSeparator={true} displayType={'text'} prefix={'RM '} />{this.props.product?.unit != null ? ('/'  + this.props.product?.unit) : ''}
            <div className="custom-line-through"></div>
          </div>
        </div>
        
        <div className="current-price typo-subheading-1 typo-bold">
          <div className="currency">RM</div>
          <NumberFormat className="price" value={(this.props?.product?.discountedPrice) ? (Number.isInteger(this.props?.product?.discountedPrice) ? this.props?.product?.discountedPrice : this.props?.product?.discountedPrice.toFixed(2)) : (Number.isInteger(this.props?.product?.price) ? this.props?.product?.price : this.props?.product?.price.toFixed(2))} thousandSeparator={true} displayType={'text'} />{this.props.product?.unit != null ? ('/'  + this.props.product?.unit) : ''}
        </div>
      </div>
    );
  }


  /**
   * Render a maximum of two tags only,
   * For example, a product has 5 categories to be displayed as tags, display the first two categories as tags and display a third tag showing the count of the remaining tags something like "+3".
   * @param tagItems An array of any objects, as long as the object has name:string and slug:string in it.
   */
  renderTags(tagItems: any[]) {
    const tagsElem: JSX.Element[] = [];
    const hiddenTagsCount: number = (tagItems?.length > 2) ? tagItems?.length - 2 : 0;

    tagItems?.forEach((tagItem, tagItemIndex) => {
      if(tagItemIndex < 2) {
        tagsElem.push(<div className={`tag typo-body-small-2`} title={tagItem?.name} key={tagItemIndex}>{tagItem?.name}</div>);
      }
    });

    // Display the count of remaining/hidden tags as the third tag.
    if(hiddenTagsCount) {
      tagsElem.push(<div className={`tag hidden-tags-count typo-body-small-2`} key={3}>+{hiddenTagsCount}</div>);
    }

    return tagsElem;
  }

  
  renderDetails() {
    return (
      <div className="details">
        <div className="title-container">
          <Dotdotdot className="title" clamp={2}>{this.props?.product?.name}</Dotdotdot>
        </div>
          
        <div className="row-1">
          <Tooltip title={this.props.product?.vendor?.businessName}>
            <Dotdotdot className="vendor" clamp={1}>by {this.props.product?.vendor?.businessName}</Dotdotdot>
          </Tooltip>
        </div>
        {this.renderPrice()}
        <div className="tags categories">
          {this.renderTags([this.props?.product?.location, this.props?.product?.category])}
        </div>
        <div className="tags sub-categories">
          {this.renderTags(this.props?.product?.subCategories)}
        </div>
        {this.renderStatus()}
      </div>
    );
  }
  
  
  render() {
    return (
      <div className={`ProductCard ${this.props?.size}`}>
        {this.renderProductImage()}
        {this.renderWishlistButtons()}
        {this.renderDiscountTag()}
        {this.renderDetails()}
      </div>
    );
  }
  
}




export default ProductCard;