// import './RegistrationGreetingModal.scss';

import * as React from 'react';
import {Button, Modal} from 'antd';



interface RegistrationGreetingModalProps {
  visible: boolean;

  closed?: () => void;
}


interface RegistrationGreetingModalState {

}



class RegistrationGreetingModal extends React.Component<RegistrationGreetingModalProps, RegistrationGreetingModalState> {

  emitClosedEvent() {
    if(this.props?.closed) {
      this.props.closed();
    }
  }


  renderActionButtons() {
    return (
      <div className="action-buttons-container">
        <Button className="antd-mod color-primary" type="primary" onClick={() => { this.emitClosedEvent() }}>Ok</Button>
      </div>
    );
  }
  
  
  render() {
    return (
      <Modal className="RegistrationGreetingModal" centered={false} closable={false} visible={this.props?.visible} footer={null}>
        <div className="title typo-title">THANK YOU</div>
        <p>Your Bridepay Account has been created.</p>
        <p>Help us to give you better content by telling us a bit more about you.</p>
        {this.renderActionButtons()}
      </Modal>
    );
  }

}




export default RegistrationGreetingModal;