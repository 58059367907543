// import './OffersSlider.scss';

import * as React from 'react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Navigation, Pagination, Autoplay]);


interface PageCoverSliderProps {
  offersImages: JSX.Element[]; /* List of <img /> tags to be displayed in the slider. */
}


interface PageCoverSliderState {

}




class OffersSlider extends React.Component<PageCoverSliderProps, PageCoverSliderState> {

  render() {
    const navigationConfig = {
      nextEl: `div.OffersSlider div.navigation-container img.nav-button.next`,
      prevEl: `div.OffersSlider div.navigation-container img.nav-button.prev`
    };
    
    return (
      <div className="OffersSlider">
        <Swiper className="item-slider"
                loop
                freeMode
                autoplay
                pagination
                slidesPerView="auto"
                navigation={navigationConfig}
        >
          {this.props?.offersImages?.map((offersImage, offersImageIndex) => <SwiperSlide key={offersImageIndex}>{offersImage}</SwiperSlide>)}
        </Swiper>

        <div className="navigation-container">
          <div className="content">
            <img className="nav-button prev" src="/images/nav-prev-2.png" alt="Previous" />
            <img className="nav-button next" src="/images/nav-next-2.png" alt="Next" />
          </div>
        </div>
      </div>
    );
  }
  
}




export default OffersSlider;