// import './ChangePasswordForm.scss';

import * as React from 'react';
import { Button, Input, Divider, Checkbox, notification, Form, FormInstance } from 'antd';
import { startCase } from 'lodash';

import UserApi from '../../../api-services/user-api.service';


interface ChangePasswordFormProps {
  loginClicked: () => void;
  googleSignInUrl: string;
  fbSignInUrl: string;
  csrfToken: string;
}

interface ChangePasswordFormState {
  submitting: boolean;
}


class ChangePasswordForm extends React.Component<ChangePasswordFormProps, ChangePasswordFormState> {
  
  registrationForm = React.createRef<FormInstance>();
  
  
  componentDidMount(): void {
    this.setState({
      submitting: false
    });
  }
  
  
  /**
   * User will be automatically logged in after a successful registration.
   * Redirect user to profile page after registration.
   */
  changePassword(formEvent: any): void {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const userApi = new UserApi;
    const payload = {
      password: formEvent.password,
      confirmPassword: formEvent.confirmPassword,
      resetPasswordToken: urlSearchParams.get('reset_password_token')
    };

    this.setState({ submitting: true });

    userApi.changePassword(payload)
    .then(registerUserResponse => {
      notification.success({ message: 'Change password successful.', className: 'antd-mod center' });
      window.location.href = '/';
    })
    .catch(error => {
      console.error(error);

      // CONSIDER creating a helper method for parsing the error messages from error response.
      const errorKeys = Object.keys(error?.response?.data?.errors);
      errorKeys.forEach(errorKeyName => {
        const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
        notification.error({ message: errorMessage, className: 'antd-mod center' });
      });
      
    })
    .finally(() => {
      this.setState({ submitting: false });
    });
  }
  
  
  render() {
    return (
      <div className="ChangePasswordForm">
        
        <Form ref={this.registrationForm} onFinish={(event) => { this.changePassword(event) }} className="password-form">
          <div className="header">
            <div className="title typo-headline">Change Password</div>
            <div className="login-link typo-subheading-1 typo-bold" onClick={() => { this.props?.loginClicked() }}>Log In</div>
          </div>
          
          <Form.Item name="password" rules={[{ required: true, message: 'Password is required' },  {min: 6, message: "Password must be at least 6 characters long"}]}>
            <Input.Password placeholder="New Password" autoComplete="new-password"/>
          </Form.Item>

          <Form.Item name="confirmPassword" 
              rules={[{ required: true, message: 'Confirm Password is required' },{ min: 6, message: "Password must be at least 6 characters long"} ,  
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Does not match with password'));
                },
            })]}>
            <Input.Password placeholder="Confirm New Password" autoComplete="new-password"/>
          </Form.Item>

          <Button loading={this.state?.submitting} className="continue" size="large" htmlType="submit">Change My Password</Button>
          
        </Form>
      </div>
    );
  }
  
  
}




export default ChangePasswordForm;
