// import './LocationHierarchy.scss';

import { Cascader, Form, FormInstance, notification, Select, Space } from "antd";
import * as React from "react"
import CityApi from "../../../api-services/city-api.service";
import CountryApi from "../../../api-services/country-api.service";
import StateApi from "../../../api-services/state-api.service";
import { City } from "../../../interfaces/city.interface";
import { Country } from "../../../interfaces/country.interface";
import { Location } from "../../../interfaces/location.interface";
import { State } from "../../../interfaces/state.interface";


interface ILocationHierarchyProps {
  multiple?:boolean;
  disabled?:boolean;
  form?:any;
  savedCountry?:number;
  savedState?:number|string;
  savedCity?:number;
}

interface ILocationHierarchyState {
  // options: Location[];
  countries: Country[];
  states: State[];
  cities: City[];
  stateRequired: boolean;
  cityRequired: boolean;
}

class LocationHierarchy extends React.Component <ILocationHierarchyProps, ILocationHierarchyState> {
  
  static defaultProps = { disabled: false, savedCountry: 1 };

  getOptionsTimeout;
  countryApi = new CountryApi;
  stateApi = new StateApi;
  cityApi = new CityApi;

  constructor(props){
    super(props);
    this.state = {
      countries: [],
      states: [],
      cities: [],
      stateRequired: true,
      cityRequired: true
    };
  }

  componentDidMount(){
    // this.setState({
    //   // options: []
    //   countries: [],
    //   states: [],
    //   cities: [],
    //   stateRequired: true,
    //   cityRequired: true
    // });

    setTimeout(() => {
      this.loadCountries();
    }, 500);

    setTimeout(() => {
      console.log("this.props?.form?.current.getFieldsValue(['country'])", this.props?.form?.current.getFieldsValue(['country']).country)
      this.filterStates(this.props?.form?.current.getFieldsValue(['country']).country);
    }, 5000);

  }


  loadCountries(){
    this.countryApi.getCountries()
    .then(countries => {
      this.setState({ countries: countries });

      if(this.props?.savedCountry){
        this.filterStates(this.props?.savedCountry);
      }
    })
    .catch(error => {
      notification.error({ message: 'An error occured while loading the data for countries', className: 'antd-mod center' });
    })
  }


  filterStates(countryId){
    this.stateApi.searchStatesByCountryAndCity(countryId)
    .then(states => {
      this.setState({ states: states });

      if(this.props?.savedState){
        this.filterCities(this.props?.savedState);
      }
    });
    if(countryId === 2){
      this.setState({
        stateRequired: false,
        cityRequired: false
      })
    } else {
      this.setState({
        stateRequired: true,
        cityRequired: true
      })
    }

  }


  filterCities(stateId){
    this.cityApi.searchCitiesByCountryAndState(stateId)
    .then(cities => {
      this.setState({ cities: cities });
    });
  }


  reset(name:string){
    this.props?.form?.current?.resetFields([name]);
  }


  render() {
    return (
      <div className="LocationHierarchy">
        <Space>
          <Form.Item name="country" label="Country" rules={[{ required: true, message: 'Country is required' }]}>
            <Select disabled={this.props?.disabled} size="large" placeholder="Choose Country" className="select-before"
              onChange={(selected)=>this.filterStates(selected)}>
              {this.state?.countries?.map(country => {
                return <Select.Option key={country.id} value={country.id}>{country.display}</Select.Option>
              })}
            </Select>
          </Form.Item>
          <Form.Item name="state" label="State" rules={[{ required: this.state.stateRequired, message: 'State is required' }]}>
            <Select size="large" disabled={this.props?.disabled} allowClear showSearch optionFilterProp="children"
              filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(selected)=>{{this.filterCities(selected)}; this.reset('city')}}>
              {this.state?.states?.map(state => {
                return <Select.Option key={state.id} value={state.code}>{state.name}</Select.Option>
              })}
            </Select>
          </Form.Item>
          <Form.Item name="city" label="City" rules={[{ required: this.state.cityRequired, message: 'City is required' }]}>
            <Select size="large" disabled={this.props?.disabled} allowClear showSearch optionFilterProp="children"
              filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {this.state?.cities?.map(city => {
                return <Select.Option key={city.id} value={city.id}>{city.name}</Select.Option>
              })}
            </Select>
          </Form.Item>
        </Space>
      </div>
    );
  }
}

export default LocationHierarchy
