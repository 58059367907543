import axios from 'axios';
import { BlogCategory } from "../interfaces/blog-category.interface";

class BlogCategoryAdminApi {

  getCategories(page, per_page): Promise<{ categories: BlogCategory[], total: number }>{
    const apiUrl = `/api/v1/admin/blog-categories?per_page=${per_page}&page=${page}`;

    return axios.get(apiUrl)
    .then(response => {
      const categories: BlogCategory[] = [];
      response?.data?.data?.forEach(categoryData => {
        categories.push(this.mapCategoryResponse(categoryData?.attributes));
      });
      
      return Promise.resolve({categories, total: response?.data?.meta?.total_count});
    });
  }

  getSubcategories(id: number): Promise<BlogCategory[]>{
    const apiUrl = `/api/v1/admin/blog-categories/${id}/subcategories`;

    return axios.get(apiUrl)
        .then(response => {
          const categories: BlogCategory[] = [];
          response?.data?.data?.forEach(categoryData => {
            categories.push(this.mapCategoryResponse(categoryData?.attributes));
          });

          return Promise.resolve(categories);
        });
  }


  addCategory(categoryData:FormData): Promise<BlogCategory>{
    const apiUrl = `/api/v1/admin/blog-categories`;
    
    return axios({
      method: "post",
      url: apiUrl,
      data: categoryData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response)=> {
      const category:BlogCategory = this.mapCategoryResponse(response?.data?.data?.attributes);
      return category;
    })
  }

  updateCategory(categoryId:number, categoryData:FormData): Promise<BlogCategory>{
    const apiUrl = `/api/v1/admin/blog-categories/${categoryId}`;

    return axios({
      method: "put",
      url: apiUrl,
      data: categoryData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response)=> {
      const category:BlogCategory = this.mapCategoryResponse(response?.data?.data?.attributes);
      return category;
    })
  }


  removeCategory(categories:number[]){
    const apiUrl = `/api/v1/admin/blog-categories/${categories}`;
    return axios.delete(apiUrl).then(response => {
      return Promise.resolve(true);
    });
  }


  mapCategoryPayload(category){
    return {
      image_url: category.image,
      name: category.name,
      ancestry: category.ancestry,
      position: category.position,
      show_in_homepage: category.showInHomepage,
      show_in_nav_button: category.showInNavButton
    };
  }


  mapCategoryResponse(categoryData){
    return {
      id: categoryData?.id,
      name: categoryData?.name,
      ancestry: categoryData?.ancestry,
      position: categoryData?.position,
      image: categoryData?.image_url,
      showInHomepage: categoryData?.show_in_homepage,
      showInNavButton: categoryData?.show_in_nav_button
    };
  }
}

export default BlogCategoryAdminApi;