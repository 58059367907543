// import './CalendarSelector.scss';

import * as React from 'react';
import { Button, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { Calendar } from '../../../interfaces/calendar.interface';


interface CalendarSelectorProps {
  calendars: Calendar[];
  addCalendarClicked?: () => void;
  editCalendarClicked?: (calendar: Calendar) => void;
  deleteCalendarClicked?: (calendarId: number) => void;
  selectionChanged?: (calendarId: number) => void;
}


interface CalendarSelectorState {
  selectedCalendarId: number;
}




class CalendarSelector extends React.Component<CalendarSelectorProps, CalendarSelectorState> {
  
  componentDidUpdate(previousProps: CalendarSelectorProps) {
    if(this.props?.calendars !== previousProps?.calendars) {
      this.setDefaultSelectedCalendarId();
    }
  }
  
  
  emitAddCalendarClicked(): void {
    if(this.props?.addCalendarClicked) {
      this.props.addCalendarClicked();
    }
  }


  emitEditCalendarClicked(calendar: Calendar): void {
    if(this.props?.editCalendarClicked) {
      this.props.editCalendarClicked(calendar);
    }
  }


  emitDeleteCalendarClicked(calendarId: number): void {
    if(this.props?.deleteCalendarClicked) {
      this.props.deleteCalendarClicked(calendarId);
    }
  }


  emitSelectionChanged(calendarId: number): void {
    if(this.props?.selectionChanged) {
      this.props.selectionChanged(calendarId)
    }
  }


  setDefaultSelectedCalendarId(): void {
    if(this.props?.calendars?.length) {
      this.setState({
        selectedCalendarId: this.props?.calendars[0].id
      });
    }
  }


  renderCalendarList() {
    const formInitialValues = {
      selectedCalendarId: this.state?.selectedCalendarId
    };
    
    return (
      <div className="calendar-list">
        <Select allowClear value={this.state?.selectedCalendarId} onChange={(calendarId: number) => { this.setState({ selectedCalendarId: calendarId }); this.emitSelectionChanged(calendarId) }}>{
          this.props?.calendars?.map((calendar, calendarIndex) => <Select.Option value={calendar.id} key={calendarIndex}>{calendar.name}</Select.Option>)
        }</Select>
      </div>
    );
  }
  
  
  render() {
    return (
      <div className="CalendarSelector">
        <div className="title-container">
          <div className="typo-subheading-2">Calendars</div>
          <Button onClick={() => { this.emitAddCalendarClicked() }} type="link" icon={<PlusOutlined />}></Button>
        </div>

        {this.renderCalendarList()}
      </div>
    );
  }
  
}




export default CalendarSelector;