import axios from "axios";
import { ServiceCategory } from "../interfaces/service-category.interface";
import { Vendor } from "../interfaces/vendor-interface";


class VendorAdminApi {

  getVendorsForApproval(page, per_page): Promise<any> {
    const apiUrl = `/api/v1/admin/vendors?per_page=${per_page}&page=${page}`;

    return axios.get(apiUrl)
    .then((response: any) => {
      const vendors: Vendor[] = [];

      response?.data?.data?.forEach((vendorData: any) => {
        vendors.push({
          id: vendorData.attributes?.id,
          businessName: vendorData.attributes?.business_name,
          slug: vendorData.attributes?.slug,
          status: vendorData.attributes?.status,
          remarks: vendorData.attributes?.remarks,
          verified: vendorData?.attributes?.verified,
          mayApprove: vendorData.attributes?.may_approve,
          mayReject: vendorData.attributes?.may_reject,
          mayDelete: vendorData.attributes?.may_delete,
          mayReview: vendorData.attributes?.may_review,
          maySuspend: vendorData.attributes?.may_suspend,
          createdAt: vendorData.attributes?.created_at,

          serviceCategories: vendorData.attributes?.services?.map(service => {
            const serviceCategory: ServiceCategory = {
              id: service?.category?.id,                  
              name: service?.category?.name
            }

            return serviceCategory;
          }),
        });
      });
      
      return Promise.resolve({vendors: vendors, total: response?.data?.meta?.total_count});
    });
  }
  
  
   getVendor(vendorId:number): Promise<Vendor> {
    let apiUrl = `/api/v1/admin/vendors/${vendorId}`;

    return axios.get(apiUrl)
    .then(response => {
      const vendor: Vendor = this.mapVendorResponse(response?.data?.data?.attributes);
      return Promise.resolve(vendor);
    });
  }


  approveVendor(vendorIds:number[]): Promise<Vendor> {
    const apiUrl = `/api/v1/admin/vendors/${vendorIds}/approve`;
    return axios.patch(apiUrl).then(response => {
      const vendor = {
        id: response?.data?.data?.attributes?.id,
        businessName: response?.data?.data?.attributes?.business_name,
        slug: response?.data?.data?.attributes?.slug,
        status: response?.data?.data?.attributes?.status,
        verified: response?.data?.data?.attributes?.verified,
        remarks: response?.data?.data?.attributes?.remarks,
        mayApprove: response?.data?.data?.attributes?.may_approve,
        mayReject: response?.data?.data?.attributes?.may_reject,
      }
      return Promise.resolve(vendor);
    });
  }


  rejectVendor(vendorIds:number[], remarks?:string): Promise<Vendor> {
    const apiUrl = `/api/v1/admin/vendors/${vendorIds}/reject`;
    return axios.patch(apiUrl, {
      remarks: remarks
    }).then(response => {
      const vendor = {
        id: response?.data?.data?.attributes?.id,
        businessName: response?.data?.data?.attributes?.business_name,
        slug: response?.data?.data?.attributes?.slug,
        status: response?.data?.data?.attributes?.status,
        verified: response?.data?.data?.attributes?.verified,
        remarks: response?.data?.data?.attributes?.remarks,
        mayApprove: response?.data?.data?.attributes?.may_approve,
        mayReject: response?.data?.data?.attributes?.may_reject,
      }
      return Promise.resolve(vendor);
    });
  }


  sendReminder(vendorId:number): Promise<Vendor> {
    const apiUrl = `/api/v1/admin/vendors/${vendorId}/send_verification_reminder`;
    return axios.post(apiUrl).then(response => {
      const vendor = this.mapVendorShortResponse(response?.data?.data);
      return Promise.resolve(vendor);
    });
  }


  reviewVendor(vendorId:number): Promise<Vendor> {
    const apiUrl = `/api/v1/admin/vendors/${vendorId}/review`;
    return axios.patch(apiUrl).then(response => {
      const vendor = this.mapVendorShortResponse(response?.data?.data);
      return Promise.resolve(vendor);
    });
  }

  suspendVendor(vendorId:number): Promise<Vendor> {
    const apiUrl = `/api/v1/admin/vendors/${vendorId}/suspend`;
    return axios.patch(apiUrl).then(response => {
      const vendor = this.mapVendorShortResponse(response?.data?.data);
      return Promise.resolve(vendor);
    });
  }


  deleteVendor(vendorId:number): Promise<boolean> {
    const apiUrl = `/api/v1/admin/vendors/${vendorId}`;
    return axios.delete(apiUrl).then(response => {
      return Promise.resolve(true);
    });
  }


  mapVendorShortResponse(serverResponse){
    return {
      id: serverResponse?.attributes?.id,
      businessName: serverResponse?.attributes?.business_name,
      slug: serverResponse?.attributes?.slug,
      status: serverResponse?.attributes?.status,
      verified: serverResponse?.attributes?.verified,
      remarks: serverResponse?.attributes?.remarks,
      mayApprove: serverResponse?.attributes?.may_approve,
      mayReject: serverResponse?.attributes?.may_reject,
    }
  }


  mapVendorResponse(serverResponse){
    return {
      id: serverResponse.id,
      image: serverResponse.avatar_url,
      businessName: serverResponse.full_name,
      businessRegisteredName: serverResponse?.business_registered_name,
      businessRegisteredNumber: serverResponse?.business_registration_no,
      businessLicense: serverResponse?.business_license,
      country: serverResponse?.country,
      state: serverResponse?.state,
      city: serverResponse?.city,
      description: serverResponse?.about,
      address: serverResponse?.address,
      zipCode: serverResponse?.zip_code,
      fullAddress: serverResponse?.full_address,
      email: serverResponse?.email,
      website: serverResponse?.website,
      rating: serverResponse?.rating,
      facebook: serverResponse?.facebook,
      youtube: serverResponse?.youtube,
      instagram: serverResponse?.instagram,
      tiktok: serverResponse?.tiktok, 
      contactPerson: serverResponse?.contact_person,
      contactPersonNumber: serverResponse?.contact_person_number,
      whatsapp: serverResponse?.whatsapp,
      albumTypes: serverResponse?.album_types,
      primaryPhoneNumber: {
        id: serverResponse?.primary_phone?.id,
        isBusiness: serverResponse?.primary_phone?.is_business,
        isPrimary: serverResponse?.primary_phone?.is_primary,
        number: serverResponse?.primary_phone?.number,
        verified: serverResponse?.primary_phone?.verified,
        countryCode: serverResponse?.primary_phone?.formatted?.country_code,
        national: serverResponse?.primary_phone?.formatted?.national,
        rawNational: serverResponse?.primary_phone?.formatted?.raw_national
      },
      
      primaryCategory: serverResponse?.primary_category,
      secondaryCategory: serverResponse?.secondary_category,

      serviceCategories: serverResponse?.services?.map(service => {
        const serviceCategory: ServiceCategory = {
          id: service?.category?.id,                  
          name: service?.category?.name,
          slug: service?.category?.slug,
          isPrimary: service?.is_primary,
          status: service?.status,
        }

        return serviceCategory;
      })
    };
  }
  
}

export default VendorAdminApi;