// import './AdminSidenav.scss';

import * as React from "react"
import { ReactSVG } from "react-svg";

const tag = require('@mdi/svg/svg/tag.svg');
const flag = require('@mdi/svg/svg/flag.svg');
const accountTie = require('@mdi/svg/svg/account-tie.svg');
const imageArea = require('@mdi/svg/svg/image-area.svg');
const attachment = require('@mdi/svg/svg/attachment.svg');
const diff = require('@mdi/svg/svg/vector-difference.svg');
const comment = require('@mdi/svg/svg/comment.svg');

interface IAdminSidenavProps {
}

interface IAdminSidenavState {
}

class AdminSidenav extends React.Component <IAdminSidenavProps, IAdminSidenavState> {

  navItems = [
    {label: "Vendors", link: "/admin/vendors", icon: <ReactSVG src={accountTie} />},
    {label: "Products", link: "/admin/products", icon: <ReactSVG src={tag} />},
    {label: "Campaigns", link: "/admin/campaigns", icon: <ReactSVG src={flag} />},
    {label: "Banners", link: "/admin/banners", icon: <ReactSVG src={imageArea} />},
    {label: "Users", link: "/admin/users", icon: <ReactSVG src={accountTie} />},
    {label: "Articles", link: "/admin/articles", icon: <ReactSVG src={attachment} />},
    {label: "Blog Banners", link: "/admin/blog-banners", icon: <ReactSVG src={imageArea} />},
    {label: "Blog Categories", link: "/admin/blog-categories", icon: <ReactSVG src={diff} />},
    {label: "Blog Comments", link: "/admin/comments", icon: <ReactSVG src={comment} />},
    {label: "Blog Tags", link: "/admin/blog-tags", icon: <ReactSVG src={tag} />},
  ]


  redirectTo(url: string): void {
    if(url) {
      window.location.href = url;
    }
  }

  render() {
    return (
      <div className="AdminSidenav">
        <div>
          {
            this.navItems.map((nav, index) => {
              return (
                <div key={index} className="nav-item" onClick={()=>this.redirectTo(nav.link)}>
                  <span className="nav-icon"> {nav.icon} </span>
                  <span className="typo-title typo-bold"> {nav.label} </span>
                </div>
              )
            })
          }
        </div>
      </div>
    );
  }
}

export default AdminSidenav
