// import './AlbumCover.scss';

import * as React from 'react';

interface AlbumCoverProps {
  imageUrls: string[];
  showAllClicked?: () => void;
}

interface AlbumCoverState {

}

class AlbumCover extends React.Component<AlbumCoverProps, AlbumCoverState> {

  emitShowAllClicked(): void {
    if(this.props?.showAllClicked) {
      this.props.showAllClicked();
    }
  }

  /**
   * Return an img tag that will serve as a signle thumbnail.
   * Will return an empty div if the imageUrl is not found.
   */
  renderSingleImagePreview(index: number) {
    const imageUrl = this.props?.imageUrls[index];

    if(imageUrl) {
      return <img src={imageUrl} alt="Preview/Thumbnail" />;
    } else {
      return <div className="empty-image-placeholder"></div>;
    }
  }

  render() {
    return (
      <div className="AlbumCover">
        <div className="lg">
          <div className="preview-container">
            <div className="single-photo-preview">
              {this.renderSingleImagePreview(0)}
            </div>
          </div>
          <div className="preview-container">
            <div className="single-photo-preview">
              {this.renderSingleImagePreview(1)}
            </div>
          </div>
          <div className="preview-container">
            <div className="multiple-photo-preview">
              <div className="image-container">
                {this.renderSingleImagePreview(2)}
              </div>
              <div className="image-container">
                {this.renderSingleImagePreview(3)}
              </div>
              <div className="image-container">
                {this.renderSingleImagePreview(4)}
              </div>
              <div className="image-container last-item">
                {this.renderSingleImagePreview(5)}
                <div className="overlay">
                  <div className="show-all typo-bold typo-subheading-1" onClick={() => {
                    this.emitShowAllClicked()
                  }}>SHOW ALL
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md">
          <div className="preview-container">
            <div className="single-photo-preview">
              {this.renderSingleImagePreview(0)}
            </div>
          </div>
          <div className="preview-container">
            <div className="multiple-photo-preview">
              <div className="image-container">
                {this.renderSingleImagePreview(1)}
              </div>
              <div className="image-container">
                {this.renderSingleImagePreview(2)}
              </div>
              <div className="image-container">
                {this.renderSingleImagePreview(3)}
              </div>
              <div className="image-container last-item">
                {this.renderSingleImagePreview(4)}
                <div className="overlay">
                  <div className="show-all typo-bold typo-subheading-1" onClick={() => {
                    this.emitShowAllClicked()
                  }}>SHOW ALL
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sm">
          <div className="preview-container">
            <div className="multiple-photo-preview">
              <div className="image-container">
                {this.renderSingleImagePreview(0)}
              </div>
              <div className="image-container">
                {this.renderSingleImagePreview(1)}
              </div>
              <div className="image-container">
                {this.renderSingleImagePreview(2)}
              </div>
              <div className="image-container last-item">
                {this.renderSingleImagePreview(3)}
                <div className="overlay">
                  <div className="show-all typo-bold typo-subheading-1" onClick={() => {
                    this.emitShowAllClicked()
                  }}>SHOW ALL
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AlbumCover;
