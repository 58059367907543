import * as React from 'react';
import { notification, Modal, Col, Row } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Overview from "../../ui-components/check-list/Overview";
import Filter from "../../ui-components/check-list/Filter";
import CheckList from "../../ui-components/check-list/List";
import { User } from '../../../interfaces/user.interface';
// import { ChecklistItem } from '../../../interfaces/checklistItem.interface';

import UserApi from '../../../api-services/user-api.service';
import ChecklistApi from '../../../api-services/checklist-api.service';
import ServiceCategoryApi from '../../../api-services/service-category-api.service';

import Navbar from '../../basic-components/navbar/Navbar';
import UserProfileNavigation from '../../basic-components/user-profile-navigation/UserProfileNavigation';
import Footer from '../../basic-components/footer/Footer';
import {ServiceCategory} from "../../../interfaces/service-category.interface";
const { confirm } = Modal;

interface ChecklistPageProps {
  isAccountSignedIn: boolean;
  googleSignInUrl: string;
  fbSignInUrl: string;
  csrfToken:string;
}


interface ChecklistPageState {
  user: User;
  serviceCategories: ServiceCategory[];
  checklistGroups: any[];
  checklistItems: any[];
  filterOptions: any;
  filteredChecklistItems: any[];
}


class ChecklistPage extends React.Component<ChecklistPageProps, ChecklistPageState> {

  userApi = new UserApi();
  checklistApi = new ChecklistApi();
  serviceCategoryApi = new ServiceCategoryApi();

  constructor(props: ChecklistPageProps) {
    super(props);
    this.state = {
      checklistGroups: [],
      serviceCategories: [],
      user: undefined,
      checklistItems: [],
      filterOptions: {
        group: 0,
        status: 0,
        search: ""
      },
      filteredChecklistItems: []
    };
  }

  componentDidMount(): void {
    this.loadSignedInUserProfile();
    this.loadServiceCategories();
    this.loadChecklistItems();
  }

  /*shouldComponentUpdate(nextProps: Readonly<ChecklistPageProps>, nextState: Readonly<ChecklistPageState>, nextContext: any): boolean {
    return this.state.filteredChecklistItems !== nextState.filteredChecklistItems;
  }*/

  componentDidUpdate(prevProps: Readonly<ChecklistPageProps>, prevState: Readonly<ChecklistPageState>) {
    if (
      prevState.checklistItems !== this.state.checklistItems ||
      prevState.filterOptions !== this.state.filterOptions
    ) {
      this.filterChecklistItems();
    }
  }

  filterChecklistItems() {
    let tmp = [...this.state.checklistItems];
    const { group, status, search } = this.state.filterOptions;

    if (group !== 0) {
      tmp = tmp.filter(o => o.checklistGroupId === group);
    }
    if (status !== 0) {
      tmp = tmp.filter(o => o.status === (status - 1));
    }
    if (search.trim() !== "") {
      const searchLower = search.trim().toLowerCase();
      tmp = tmp.filter(o =>
        `${o.title} ${o.description ?? ''} ${o.remarks ?? ''}`.toLowerCase().includes(searchLower)
      );
    }

    this.setState({
      filteredChecklistItems: tmp
    });
  }

  /**
   * Get the signed in user's profile from server if there is a user signed in.
   */
  loadSignedInUserProfile(): void {
    if(!this.props?.isAccountSignedIn) {
      return null;
    }

    this.userApi.getUserProfile()
    .then(user => {
      this.setState({ user: user });
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: 'Error while loading the user\'s profile.', className: 'antd-mod center' });
    });
  }

  loadServiceCategories(): void {
    this.serviceCategoryApi.getCategories()
      .then(categories => {
        this.setState({serviceCategories: categories});
      });
  }

  loadChecklistItems(): void {
    this.checklistApi.getChecklistGroups()
      .then(result => {
        this.setState({
          checklistGroups: result
        });
      });
    this.checklistApi.getChecklistItems()
      .then(result => {
        this.setState({
          checklistItems: result
        });
      });
  }

  handleToggleItem(id:number, isDone: boolean): void {
    let tmp = [...this.state.checklistItems];
    let index = tmp.findIndex(o => o.id === id);
    tmp[index].status = isDone;
    this.setState({
      checklistItems: [...tmp]
    });
  }

  handleFilter(group, status, search): void {
    this.setState({filterOptions: {group, status, search}});
  }

  handleNewSubmit(group, itemData): void {
    let payload = {
      title: itemData?.title,
      description: '',
      link: '',
      date: '',
      remarks: itemData?.remarks,
      serviceCategoryId: itemData?.category,
      checklistGroupId: group,
    };
    this.checklistApi.createChecklistItem(payload).then((res) => {
      this.setState({checklistItems: [...this.state.checklistItems, res]});
      notification.success({ message: 'Successfully created your checklist item.', className: 'antd-mod center' });
    }).catch((error) => {
      console.error(error);
      notification.error({ message: 'Error while creating the your checklist item.', className: 'antd-mod center' });
    });
  }

  handleUpdateSave(id:number, values:any): void {
    let payload = {
      remarks: values?.remarks
    };
    this.checklistApi.updateChecklistItem(id, payload).then((res) => {
      let tmp = [...this.state.checklistItems];
      let index = tmp.findIndex(o => o.id === id);
      tmp[index] = res;
      this.setState({checklistItems: tmp});
      notification.success({ message: 'Successfully updated your checklist item.', className: 'antd-mod center' });
    }).catch((error) => {
      console.error(error);
      notification.error({ message: 'Error while updating your checklist item.', className: 'antd-mod center' });
    });
  }

  handleDeleteItem(id:number): void {
    confirm({
      title: 'Are you sure delete this checklist item?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          const res = await (this.checklistApi.deleteChecklistItem(id));
          if (res) {
            let tmp = [...this.state.checklistItems];
            let index = tmp.findIndex(o => o.id === id);
            tmp = [...tmp.slice(0, index), ...tmp.slice(index + 1)];
            this.setState({checklistItems: tmp});
            notification.success({message: 'Successfully deleted your checklist item.', className: 'antd-mod center'});
          }
        } catch (error) {
          console.error(error);
          notification.error({message: 'Error while deleting your checklist item.', className: 'antd-mod center'});
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  
  render() {
    return (
      <div className="ChecklistPage">
        <Navbar isAccountSignedIn={this.props?.isAccountSignedIn} user={this.state?.user}></Navbar>

        <div className="main-content">
          <UserProfileNavigation selectedNavigation="checklist"></UserProfileNavigation>
          <div className="checklist-items px-5 py-5">
            <Row>
              <Col md={24} className='mb-5'>
                <Overview
                  bookedMerchants={0}
                  dayLeft={Math.floor((new Date(this.state?.user?.weddingDetail?.date??null).getTime() - new Date().getTime())/89600000)}
                  totalTask={this.state?.checklistItems?.length}
                  completedTask={this.state?.checklistItems?.filter(o => (o.status === 1)).length}
                />
              </Col>
              <Col md={24} className="my-5">
                <Filter groups={this.state?.checklistGroups} onFilter={(group, status, search) => this.handleFilter(group, status, search)} />
              </Col>
              <Col md={24} className="mb-5 w-full">
                <CheckList groups={this.state?.checklistGroups}
                           items={this.state?.filteredChecklistItems}
                           services={this.state?.serviceCategories}
                           onToggleItem={(id:number, isDone:boolean) =>this.handleToggleItem(id, isDone)}
                           onNewSubmit={(checklistGroup, values) => this.handleNewSubmit(checklistGroup, values)}
                           onUpdateSave={(id, values) => this.handleUpdateSave(id, values)}
                           onDeleteItem={(id) => this.handleDeleteItem(id)}
                />
              </Col>
            </Row>
          </div>
        </div>

        <Footer userType={this.state?.user?.type}></Footer>
      </div>
    );
  }
}

export default ChecklistPage;