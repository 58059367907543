// import './VendorRegistrationGreetingModal.scss';

import * as React from 'react';
import {Button, Modal} from 'antd';



interface VendorRegistrationGreetingModalProps {
  visible: boolean;

  closed?: () => void;
}


interface VendorRegistrationGreetingModalState {

}



class VendorRegistrationGreetingModal extends React.Component<VendorRegistrationGreetingModalProps, VendorRegistrationGreetingModalState> {

  emitClosedEvent() {
    if(this.props?.closed) {
      this.props.closed();
    }
  }


  renderActionButtons() {
    return (
      <div className="action-buttons-container">
        <Button className="antd-mod" type="primary" onClick={() => { this.emitClosedEvent() }}>Ok</Button>
      </div>
    );
  }
  
  
  render() {
    return (
      <Modal className="VendorRegistrationGreetingModal" centered={false} closable={false} visible={this.props?.visible} footer={null}>
        <div className="title typo-title">THANK YOU FOR SIGNING UP WITH BRIDEPAY</div>

        <p>Your business has been submitted for approval. We will notify you within 48 hours.</p>
        
        <p>
        Meanwhile, we have sent you a verification email. Please verify your Bridepay username email to confirm your account. 
        After that, you can continue to build your shop on Bridepay but you will not be able to publish until your business is approved by Bridepay.  
        Refer to your Profile Strength as a guide to optimise your shop on Bridepay. 
        </p>
        
        {this.renderActionButtons()}
      </Modal>
    );
  }

}




export default VendorRegistrationGreetingModal;