import * as React from "react"

import {Button, notification, Modal, FormInstance, Form, Input, Select, Upload, Checkbox, TreeSelect} from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { BlogCategory } from "../../../interfaces/blog-category.interface";
import AdminCategoriesTable from "../../basic-components/admin-blog-categories-table/AdminBlogCategoriesTable";
import BlogCategoryAdminApi from "../../../api-services/blog-category-admin-api.service";


interface IAdminBlogCategoriesPageProps {
  hideAction: boolean;
}

interface IAdminBlogCategoriesPageState {
  currentPageCategories: BlogCategory[];
  parentCategories: BlogCategory[];
  modalVisible: boolean;
  selectedCategory: BlogCategory;
  parentCategory: string;
  uploading: boolean;
  savedImage;
  imageUrl: string;
  treeData: any[],
  pagination: any;
}


class AdminBlogCategoriesPage extends React.Component <IAdminBlogCategoriesPageProps, IAdminBlogCategoriesPageState> {
  
  categoryApi = new BlogCategoryAdminApi();
  categorySetupForm = React.createRef<FormInstance>();

  componentDidMount(){
    this.setState({
      currentPageCategories: [],
      parentCategories: [],
      parentCategory: null,
      selectedCategory: null,
      modalVisible: false,
      uploading: false,
      savedImage: [],
      treeData: [],
      pagination: {
        current: 1,
        pageSize: 20,
        showSizeChanger: false
      }
    });

    setTimeout(() => {
      this.loadCategories(1);
      this.categoryApi.getCategories(1, 10000)
        .then(categoriesData => {
          this.setState({parentCategories: categoriesData.categories});
        })
        .catch(error => {
          notification.error({ message: 'An error occurred while loading the data for blog categories', className: 'antd-mod center' });
        });
    }, 500);

    
  }

  loadCategories(page): void{
    this.categoryApi.getCategories(page, this.state?.pagination.pageSize)
    .then(categoriesData => {
      const pagination = { ...this.state.pagination };
      pagination.current = page;
      pagination.total = categoriesData.total;
      this.setState({currentPageCategories: categoriesData.categories, pagination});
    })
    .catch(error => {
      notification.error({ message: 'An error occurred while loading the data for blog categories', className: 'antd-mod center' });
    });
  }

  handleTableChange = (pagination) => {
    this.loadCategories(pagination.current)
  }

  saveCategory(newCategory): void{
    const formData = new FormData();
    if(typeof newCategory.ancestry == 'string' && newCategory.ancestry.length > 0) {
      let ancestry: string;
      if (newCategory.ancestry === '0') ancestry = '0';
      else {
        let parent = this.state?.parentCategories.find(o => o.id === Number(newCategory.ancestry));
        ancestry = parent.id.toString();
        if (parent.ancestry) ancestry = `${parent.ancestry}/${parent.id}`;
      }
      formData.append("ancestry", ancestry);
    }
    formData.append("name", newCategory.name);
    formData.append("position", newCategory.position.toString());
    formData.append("show_in_homepage", (!!newCategory.show_in_homepage).toString());
    formData.append("show_in_nav_button", (!!newCategory.show_in_nav_button).toString());

    this.state?.savedImage.forEach(file => {
      formData.append('image', file);
    });

    if(this.state?.selectedCategory){
      const categoryId = this.state?.selectedCategory?.id;
      this.categoryApi.updateCategory(categoryId, formData)
        .then(categoryResponse => {
          const categoryIndex = this.state?.currentPageCategories?.findIndex(c => c.id == categoryId);
          if(categoryIndex > -1){
            let categories = [...this.state?.currentPageCategories];
            categories[categoryIndex] = categoryResponse;
            this.categorySetupForm.current!.resetFields();
            this.setState({
              currentPageCategories: categories,
              modalVisible: false
            });
            notification.success({ message: 'Changes saved successfully', className: 'antd-mod center' });
          }
        })
        .catch(error => {
          notification.error({ message: 'An error occurred while updating blog category', className: 'antd-mod center' });
        });
    }else{
      this.categoryApi.addCategory(formData)
        .then(categoryResponse => {
          this.setState(prevState => ({
            currentPageCategories: [...prevState.currentPageCategories, categoryResponse],
            modalVisible: false
          }));
          this.categorySetupForm.current!.resetFields();
        })
        .catch(error => {
          console.log("error", error)
          notification.error({ message: 'An error occurred while adding blog category', className: 'antd-mod center' });
        });
    }
    this.onCancelCategory();
  }

  onAddCategory(): void{
    this.categorySetupForm.current!
      .validateFields()
      .then(values => this.saveCategory(values))
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  }

  handleClickAddButton() {
    this.setState({modalVisible: true, selectedCategory: null});
    this.generateCategoryTreeData(this.state?.parentCategories);
  }
  

  onEditCategory(category:BlogCategory): void{
    this.setState({modalVisible: true, selectedCategory: category, imageUrl: category.image});
    let pid = 0;
    if (category.ancestry !== null) {
      let ids = category.ancestry.split('/');
      pid = Number(ids[ids.length - 1]);
    }
    setTimeout(() => {
      this.generateCategoryTreeData(this.state?.parentCategories);
      this.categorySetupForm.current!
        .setFieldsValue({
          name: category.name,
          ancestry: pid.toString(),
          position: category.position,
          show_in_homepage: category.showInHomepage,
          show_in_nav_button: category.showInNavButton,
        });
    }, 100);
  }

  onCancelCategory():void{
    this.categorySetupForm.current!.resetFields();
    this.setState({modalVisible: false, savedImage: [], imageUrl: "", selectedCategory: null});
  }
  

  removeCategories(categoryId :number): void{
    const categoriesToRemove = [categoryId];
    this.categoryApi.removeCategory(categoriesToRemove)
    .then(res => {
      this.setState(prevState => ({
        currentPageCategories: prevState.currentPageCategories.filter(category => !categoriesToRemove.includes(category.id)),
        parentCategory: null
      }));
    })
    .catch(error => {
      notification.error({ message: 'An error occurred while removing blog category', className: 'antd-mod center' });
    })
  }


  getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
   return e && e.fileList;
  };


  beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({ message: 'You can only upload JPG/PNG file!', className: 'antd-mod center' });
    }else{
      this.setState({savedImage: [file]});
    }

    return isJpgOrPng;
  };


  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }


  handleChange = info => {
    this.getBase64(info.file.originFileObj, imageUrl =>
      this.setState({
        imageUrl,
        uploading: false,
      }),
    );
  };

  getParentIdFromAncestry(ancestry) {
    let pid = -1;
    if (ancestry !== null) {
      let ids = ancestry.split('/');
      pid = Number(ids[ids.length - 1]);
    }
    return pid;
  }

  generateCategoryTreeData(categories) {
    let tree = [{id: '0', title: 'No parent',pId: '-1', value: '0'}];
    if (this.state?.selectedCategory) {
      let exceptList = [this.state.selectedCategory.id];

      this.categoryApi.getSubcategories(this.state.selectedCategory.id)
          .then(result => {
            result.forEach(category => {
              exceptList.push(category.id);
            });
            // console.log(exceptList);
            categories?.forEach(category => {
              if (exceptList.indexOf(category.id) === -1) {
                tree.push({
                  id: category.id.toString(),
                  title: category.name,
                  pId: this.getParentIdFromAncestry(category.ancestry).toString(),
                  value: category.id.toString()
                });
              }
            });
            this.setState({treeData: tree});
          });
    } else {
      categories?.forEach(category => {
        tree.push({
          id: category.id.toString(),
          title: category.name,
          pId: this.getParentIdFromAncestry(category.ancestry).toString(),
          value: category.id.toString()
        });
      });
      this.setState({treeData: tree});
    }
  }

  onChangeCategoryTree = (value): void => {
    this.setState({parentCategory: value})
  }

  renderAddCategoryModal(){
    const uploadButton = (
      <div id="upload-button">
        {this.state?.uploading ? <LoadingOutlined /> : <PlusOutlined />} Upload
      </div>
    );

    return (
      <Modal 
        title="Add Blog Category"
        visible={this.state?.modalVisible} 
        maskClosable={false} 
        okText="Save"
        onOk={()=>this.onAddCategory()}
        onCancel={()=>this.onCancelCategory()}
        width={600}
      >
        <Form id="banner-form" ref={this.categorySetupForm} layout="vertical" autoComplete="off">
          <Form.Item name='image' getValueFromEvent={this.getFile} label="Image">
            <Upload
                action="#"
                maxCount={1}
                showUploadList={false}
                fileList={this.state?.savedImage}
                accept="image/*"
                beforeUpload={this.beforeUpload}
                onChange={this.handleChange}
                className="uploader"
            >
              {this.state?.imageUrl ? <img src={this.state?.imageUrl} className="banner-image" alt="category-image"/> : uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Name is required' }]}>
            <Input size="large"/>
          </Form.Item>
          <Form.Item name="ancestry" label="Parent Category">
            <TreeSelect
                size='large'
                style={{ width: '100%' }}
                value={this.state?.parentCategory?.toString()}
                treeDataSimpleMode
                treeData={this.state?.treeData}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="Select category"
                treeDefaultExpandAll
                onChange={this.onChangeCategoryTree}
                defaultValue='0'
            />
          </Form.Item>
          <Form.Item name="position" label="Position">
            <Input type="number" size="large"/>
          </Form.Item>
          <Form.Item name="show_in_homepage" valuePropName="checked">
            <Checkbox checked={false}>Show in homepage</Checkbox>
          </Form.Item>
          <Form.Item name="show_in_nav_button" valuePropName="checked">
            <Checkbox checked={false}>Show in nav button</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    )
  }


  render() {
    return (
      <>
        <div className="AdminBannersPage">
          <div className="title">
            <div className="typo-title-2 typo-bold">Categories</div>
            {!this.props?.hideAction && (
              <div className="actions">
                <Button type="default" size="large" icon={<PlusOutlined />} onClick={()=>this.handleClickAddButton()}>Add</Button>
                {/* <Button type="default" danger size="large" disabled={this.state?.selectedCategories?.length == 0} icon={<DeleteOutlined />} onClick={()=>this.removeCategories()}>Remove</Button> */}
              </div>
            )}
          </div>
          <div className="main-content">
            {this.renderAddCategoryModal()}

            <AdminCategoriesTable
                hideAction={this.props?.hideAction}
                parentCategories={this.state?.parentCategories}
                categories={this.state?.currentPageCategories}
                pagination={this.state?.pagination}
                onChange={this.handleTableChange}
                editCategory={(category)=>this.onEditCategory(category)}
                removeCategory={(categoryId)=>this.removeCategories(categoryId)}
            />
          </div>
        </div>
      </>
    );
  }
}

export default AdminBlogCategoriesPage
