// import './VendorAlbumsTab.scss';

import * as React from 'react';
import { Album } from '../../../interfaces/album.interface';

import AlbumCard from '../../basic-components/album-card/AlbumCard';
import AlbumPinCard from '../../basic-components/album-pin-card/AlbumPinCard';
import SingleImageModal from '../../modals/single-image-modal/SingleImageModal';



interface VendorAlbumsTabProps {
  albums: Album[];
  tabTitle?: string;
  tabSubTitle?: string;
  albumClicked?: (album: Album) => void;
}


interface VendorAlbumsTabState {

}




class VendorAlbumsTab extends React.Component<VendorAlbumsTabProps, VendorAlbumsTabState> {

  get tabTitle(): string {
    if(this.props?.tabTitle) {
      return this.props.tabTitle;
    } else {
      return 'Albums';
    }
  }


  get tabSubTitle(): string {
    if(this.props?.tabSubTitle) {
      return this.props.tabSubTitle;
    } else {
      return '';
    }
  }
  
  
  emitAlbumClicked(album: Album): void {
    if(this.props?.albumClicked) {
      this.props?.albumClicked(album);
    }
  }
  
  
  render() {
    return (
      <div className="VendorAlbumsTab">
        <div className="content">
          <div className="header">
            <div className="title typo-headline">{this.tabTitle}</div>
            <div className="subtitle">{this.tabSubTitle}</div>
          </div>

          <div className="content albums-list">
            {this.props?.albums?.map((album, albumIndex) => <AlbumCard album={album} key={albumIndex} clicked={() => { this.emitAlbumClicked(album) }}></AlbumCard>)}
          </div>
        </div>
      </div>
    );
  }
  
  
}






export default VendorAlbumsTab;