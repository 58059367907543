// import './NavbarMenu.scss';

import * as React from 'react';
import { NavbarLink } from '../../../interfaces/navbar-link.interface';


interface NavbarMenuProps {
  subNavbarLinks?: NavbarLink[];
}

interface NavbarMenuState {
  menus: NavbarLink[];
}

class NavbarMenu extends React.Component <NavbarMenuProps, NavbarMenuState> {

  componentDidMount(){
    this.setState({
      menus: [
        { display: 'Home', href: '/' }, 
        { display: 'Bridepay Deals', href: '/' }, 
        { display: 'Vendors', href: '/vendors' },
        { display: 'Bridepay Wallet', href: '' }, 
      ],
    })
  }


  componentDidUpdate(prevProps: NavbarMenuProps){
    if(prevProps.subNavbarLinks !== this.props?.subNavbarLinks){
      this.setState({menus: this.props?.subNavbarLinks})
    }
  }
  
  
  redirectToURL(url: string): void {
    if(url) {
      window.location.href = url;
    }
  }


  render() {
    return (
      <div className="NavbarMenu sticky-header">
        <div className="content">
          {
            this.state?.menus?.map((navbarLink, index) => {
              return <div onClick={() => { this.redirectToURL(navbarLink.href) }} className={`nav-item typo-subheading-1 typo-bold-2 ${navbarLink.active ? 'active' : ''}`} key={index}>{navbarLink.display}</div>
            })
          }
        </div>
      </div>
    );
  }
}

export default NavbarMenu;
