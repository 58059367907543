import {Button, Form, Input, Modal, Typography} from "antd";
import * as React from 'react';

interface EditTaskProps {
    isOpen: boolean;
    title: string;
    description: string;
    remarks?: string;
    updatedAt?: Date;
    closeDialog: () => void;
    onSuccess: (value:any) => void;
}

const { Title } = Typography;

const EditTask = ({isOpen, title, description, remarks = '', updatedAt = new Date(), onSuccess, closeDialog} : EditTaskProps) => {
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [form] = Form.useForm();

    React.useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    React.useEffect(() => {
        form.setFieldsValue({ remarks });
    }, [remarks, form]);

    const handleSubmit = (value: any) => {
        setLoading(true);
        // if success
        onSuccess(value);
        form.resetFields();
        setLoading(false);
        setOpen(false);
        closeDialog();
    };

    const handleCancel = () => {
        setOpen(false)
        form.resetFields();
        closeDialog();
    };

    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
        required: '${label} is required!'
    };
    /* eslint-enable no-template-curly-in-string */

    return (
        <Modal
            visible={open}
            title={title}
            onCancel={handleCancel}
            footer={[]}
            className="checklist-dialog"
        >
            <Form
                form={form}
                name="checklist"
                onFinish={handleSubmit}
                layout="vertical"
                validateMessages={validateMessages}
                initialValues={{remarks}}
            >
                <Title level={5}>{description}</Title>
                <Form.Item name={'remarks'} label="Remarks">
                    <Input.TextArea placeholder="No Remark"/>
                </Form.Item>
                <Form.Item>
                    <div className="flex items-center justify-between mt-4">
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Save
                        </Button>
                        <span className='fs-xxs'>
                            Updated at {new Date(updatedAt).toLocaleString()}
                        </span>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    )
};

export default EditTask;