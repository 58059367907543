import * as React from 'react';
import { notification } from 'antd';

import UserApi from '../../../api-services/user-api.service';
import AuthApi from '../../../api-services/auth-api.service';

import Navbar from '../../basic-components/navbar/Navbar';
import LoginModal from '../../modals/login-modal/LoginModal';
import Footer from '../../basic-components/footer/Footer';
import BreadcrumbsNavigation from  '../../basic-components/breadcrumbs-navigation/BreadcrumbsNavigation';
import ForgotPasswordModal from '../../modals/forgot-password-modal/ForgotPasswordModal';

notification.config({
  duration: 12,
  top: 60
})

class PrivacyPage extends React.Component {
  
  userApi = new UserApi;
  authApi = new AuthApi;
  
  breadcrumbsNavItems = [
    { id: 1, label: 'Home', link: '/' },
    { id: 2, label: 'Privacy Policy', link: '/privacy_policy' },
  ];
  
  componentDidMount(): void {
    this.setState({
      loginModalVisible: false,
      forgotPasswordModalVisible: false
    });
    
    this.loadSignedInUserProfile();
  }


  loadSignedInUserProfile(): void {
    if(!this.props?.isAccountSignedIn) {
      return null;
    }

    this.userApi.getUserProfile()
    .then(user => {
      this.setState({
        loggedOnUser: user
      });
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: 'Error while loading the user\'s profile.', className: 'antd-mod center' });
    });
  }
  
  render() {
    return (
      <div className="ContactPage">
        <Navbar 
          isAccountSignedIn={this.props?.isAccountSignedIn} 
          user={this.state?.loggedOnUser} 
          signInRequired={(redirectUrl) => { this.setState({ loginModalVisible: true, loginModalRedirectUrl: redirectUrl }); }}
        />

        <LoginModal 
          visible={this.state?.loginModalVisible} 
          googleSignInUrl={this.props?.googleSignInUrl} 
          fbSignInUrl={this.props?.fbSignInUrl} 
          csrfToken={this.props?.csrfToken} 
          redirectUrl={this.state?.loginModalRedirectUrl}  
          forgotPasswordClicked={() => { this.setState({ loginModalVisible: false, forgotPasswordModalVisible: true }) }} 
          closed={() => { this.setState({ loginModalVisible: false }) }} 
          registerLink="/registration"
        />
        
        <ForgotPasswordModal
          visible={this.state?.forgotPasswordModalVisible} 
          loginClicked={() => { this.setState({ forgotPasswordModalVisible: false, loginModalVisible: true }) }} 
          closed={() => { this.setState({ forgotPasswordModalVisible: false }) }} 
        />

        <div className="page-content">
          <BreadcrumbsNavigation items={this.breadcrumbsNavItems} />
        </div>

        <div className="page-content main-content">
          <div className="category-header">
            <div className="content">
              <div className="title typo-headline">Privacy Policy</div>
            </div>
          </div>

          <br/>
          <br/>
          <p><strong>THIS PRIVACY POLICY APPLIES TO BRIDEPAY COLLECTING, USING,
            PROCESSING, DISCLOSING YOUR PERSONAL DATA FOR ONE OR MORE OF THE PURPOSES DESCRIBED IN THIS
            DOCUMENT.</strong></p>

          <p>This Personal Data Protection Notice ("<strong>Notice</strong>") is issued pursuant to the
            Personal Data Protection Act 2010 ("<strong>Act</strong>") and for the purpose
            of this Notice, "<strong>Personal Data</strong>" shall have the meaning as ascribed to it
            under the Act. The Personal Data Protection Act 2010 ("<strong>PDPA</strong>") regulates and
            authorizes the processing of personal data in respect to commercial transactions.</p>

          <p>All the abbreviation, acronym, words defined in the Terms of Use published herewith shall be adopted and
            applied herein and shall carry the same meanings and definition when the same are referred to herein.</p>

          <p>In line with the PDPA, this Notice is to inform you of how Bridepay Sdn Bhd ("
            <strong>Bridepay</strong>") collects, records, holds, stores, uses, discloses and/or processes your
            personal data which may include but not limited to, your name, gender, age, date of birth, nationality,
            identity card/passport number, contact details (such as telephone number, fax number, email address,
            address, profile photo), occupation, user name, race, health condition, marital status, bank account
            details, details and/or other information/data relating to you ("<strong>Your Personal Data</strong>").
            If applicable, we may also collect, record, hold, store, use, disclose and/or
            otherwise process your family member's Personal Data from time to time.</p>

          <p>This Privacy Policy does not apply to the practices of company that we do not own or control, or to people
            that we do not employ or manage. Our e-Platform, if any, may contain links to other websites. Please note
            that we are not responsible for the privacy practices of such other websites and we advise you to read the
            privacy statements of each website you visit which collects personal information.</p>

          <br/>
          <section>
            <h3><strong>1. Source of Information</strong></h3>
            <div className="ml-5">
              <p><strong>1.1</strong> Your Personal Data is collected by us through the following methods, which
                include but are not limited to:</p>
              <p><strong>(i) </strong> Information, data or comments you have provided to us by whatsoever means and/or in
                whatsoever manner; and</p>
              <p><strong>(ii) </strong> Information and data obtained independently by us or given to us from other lawful
                sources.</p>
              <p>Please note that the provision of your Personal Data is voluntary. If however you do not provide us with
                your Personal Data, we may not be able to communicate and/or provide the Products and/or Bridepay Services
                that you require from us.</p>
            </div>
          </section>
          <br/>
          <section>
          <h3><strong>2. Purpose of Collection and Usage of Information</strong></h3>
            <div className="ml-5">
              <p>Please be informed that your Personal Data obtained shall be applicable and used by us in the following
                manners and areas.</p>
              <p><strong>2.1 </strong> <strong>Applicable to Buyer: -</strong></p>
              <p><strong>(i) </strong> <strong>Providing Services</strong></p>
              <ul>
                <li>For registration of your account with us;</li>
                <li>To facilitate your use and/or access to the e-Platform;</li>
                <li>To administer your account (if any) with us;</li>
                <li>To display your name, username or profile on the e-Platform (including on any reviews you may post);
                </li>
                <li>To respond to your queries, feedback, claims or disputes, whether directly or through our third
                  party
                  service providers; and
                </li>
                <li>To facilitate the integration of third party's loyalty membership programs (including those
                  supported by sellers or brands) with our e-Platform.
                </li>
              </ul>
              <p><strong>(ii) </strong> <strong>Processing your order for Products </strong></p>
              <ul>
                <li>To process orders, offers, subscriptions, purchases and/ or requests you submit through the
                  e-Platform;
                </li>
                <li>To deliver the Products you have purchased, ordered, subscribed and/ or requested through the
                  e-Platform;
                </li>
                <li>To update you on the delivery of the Products;</li>
                <li>To provide customer support for your orders, offers, subscriptions, purchases and/ or requests;
                  and
                </li>
                <li>To verify and carry out payment transactions (including any credit card payments, bank transfers,
                  offline payments or remittances) in relation to your orders, offers, subscriptions, purchases and/ or
                  requests on the e-Platform. In order to verify and carry out such payment transactions, payment
                  information which may include personal data, will be transferred to third parties such as our payment
                  service providers/ affiliates.
                </li>
              </ul>
              <p><strong>(iii) </strong> <strong>Marketing and advertising</strong></p>
              <ul>
                <li>To provide you with information we think you may find useful or which you have requested from us
                  (provided you have opted to receive such information);
                </li>
                <li>To send you marketing or promotional information about our or third-party sellers' Products on our
                  e-Platform from time to time (provided you have opted to receive such information);
                </li>
                <li>To help us conduct marketing and advertising; and</li>
                <li>To promote Bridepay Services and use information that you give to us, such as user-generated content
                  (including video content) that you can choose to upload on our e-Platform and will be accessible
                  online
                  and may be shared by public (the latter of which is not within our control), as part of our
                  advertising
                  and marketing campaigns to promote the e-Platform.
                </li>
              </ul>
              <p><strong>(iv) </strong> <strong>Legal and operational purposes</strong></p>
              <ul>
                <li>To ascertain your identity in connection with fraud detection purposes;</li>
                <li>To compare information, and verify with third parties in order to ensure that the information is
                  accurate;
                </li>
                <li>To process any complaints, feedback, enforcement action and take-down requests in relation to any
                  Content you have uploaded to the e-Platform;
                </li>
                <li>To produce statistics and research for internal and statutory reporting and/or record-keeping
                  requirements;
                </li>
                <li>To store, host, back up your Personal Data;</li>
                <li>To prevent or investigate any actual or suspected violations of our Terms of Use, Privacy Policy,
                  fraud,
                  unlawful activity, omission or misconduct, whether relating to your use of Bridepay Services or any
                  other
                  matter arising from your relationship with us; and
                </li>
                <li>To comply with legal and regulatory requirements (including, where applicable, the display of your
                  name,
                  contact details and company details), including any law enforcement requests, in connection with any
                  legal
                  proceedings, or otherwise deemed necessary by us; and
                </li>
              </ul>
              <p><strong>(v) </strong> <strong>Analytics, research, business and development</strong></p>
              <ul>
                <li>To understand your user experience with the e-Platform;</li>
                <li>To identify visitors on the e-Platform;</li>
                <li>To further develop our products and services; and</li>
                <li>To know our buyers better.</li>
              </ul>
              <p><strong>(vi) </strong> <strong>Other</strong></p>
              <ul>
                <li>Any other purpose to which your consent has been obtained;</li>
              </ul>

              <p><strong>2.2 </strong> <strong>Applicable to Seller: -</strong></p>
              <p><strong>(i) </strong> <strong>Providing Services</strong></p>
              <ul>
                <li>To facilitate your use of Bridepay Services or access to the e-Platform;</li>
                <li>To ship or deliver the Products you have listed or sold through the e-Platform. We may pass your
                  personal information on to a third party (e.g. our business/logistic partners) or relevant regulatory
                  authority (e.g. customs) in order to carry out shipping or delivery of the products and/or services
                  listed
                  or sold by you;
                </li>
                <li>To respond to your queries, feedback, claims or disputes, whether directly or through our third
                  party
                  service agents;
                </li>
                <li>To verify your documentation submitted to us facilitate your onboarding with us as a seller on the
                  e-Platform;
                </li>
                <li>To administer your account (if any) with us;</li>
                <li>To display your name, username or profile on the e-Platform;</li>
                <li>To verify and carry out payment transactions (including any credit card payments, bank transfers,
                  offline payments or remittances) in relation to your orders, offers, subscriptions, purchases and/ or
                  requests on the e-Platform. In order to verify and carry out such payment transactions, payment
                  information which may include personal data, will be transferred to third parties such as our payment
                  service providers/ affiliates; and
                </li>
                <li>To facilitate the return of products to you (which may be through our logistics partner).</li>
              </ul>
              <p><strong>(ii) </strong> <strong>Marketing and advertising</strong></p>
              <ul>
                <li>To send you marketing or promotional materials about our or third-party sellers' Products on our
                  e-Platform from time to time (provided you have opted to receive such information); and
                </li>
                <li>To help us conduct marketing and advertising.</li>
              </ul>
              <p><strong>(iii) </strong> <strong>Legal and operational purposes</strong></p>
              <ul>
                <li>To produce statistics and research for internal and statutory reporting and/or record-keeping
                  requirements;
                </li>
                <li>To store, host, back up your personal data;</li>
                <li>To prevent or investigate any actual or suspected violations of our Terms of Use, Privacy Policy,
                  fraud,
                  unlawful activity, omission or misconduct, whether relating to your use of Bridepay Services or any
                  other
                  matter arising from your relationship with us;
                </li>
                <li>To comply with legal and regulatory requirements (including, where applicable, the display of your
                  name,
                  contact details and company details), including any law enforcement requests, in connection with any
                  legal
                  proceedings or otherwise deemed necessary by us;
                </li>
                <li>To process any complaints, feedback, enforcement action and take-down requests in relation to any
                  content you have uploaded to the e-Platform;
                </li>
                <li>To compare information, and verify with third parties in order to ensure that the information is
                  accurate;
                </li>
                <li>To ascertain your identity in connection with fraud detection purposes; and</li>
                <li>To facilitate the takedown of prohibited and controlled items from our e-Platform.</li>
              </ul>
              <p><strong>(iv) </strong> <strong>Analytics, research, business and development</strong></p>
              <ul>
                <li>To understand the user experience with Bridepay Services and the e-Platform;</li>
                <li>To derive further attributes relating to you based on personal data provided by you (whether to us
                  or
                  third parties), in order to provide you with more targeted and/or relevant information;
                </li>
                <li>To further develop Bridepay Services; and</li>
                <li>To know our sellers better.</li>
              </ul>
              <p><strong>(v) </strong> <strong>Other</strong></p>
              <ul>
                <li>Any other purpose to which your consent has been obtained; and</li>
              </ul>
            </div>
          </section>
          <br/>
          <section>
          <h3><strong>3. Disclosure of Information</strong></h3>
            <div className="ml-5">
              <p><strong>3.1</strong> Ordinarily all Personal Data shall be kept private and confidential by the
                receiver.
              </p>
              <p><strong>3.2</strong> Personal Data may be required to be disclosed to third parties, including but not
                limited to the
                following, for one or more of the purposes as listed above:</p>
              <p><strong>(i) </strong> Agents, contractors and/or third party service providers who provide operational
                service to us e.g. information technology service providers;</p>
              <p><strong>(ii) </strong> Third party business partners collaborating on corporate promotional events with
                us;
              </p>
              <p><strong>(iii) </strong> Our auditors, accountants, lawyers, or other professional advisers;</p>
              <p><strong>(iv) </strong> Any person to whom we are required to act under the law or in relation to any
                order
                of judgement of a court, or in response to relevant government regulators, governmental authorities,
                public
                agencies, law enforcement agencies, statutory boards or similar authorities; and</p>
              <p><strong>(v) </strong> Any other party(s) to whom you authorize us to disclose your Personal Data.</p>
              <p><strong>3.3</strong> In disclosing your personal data to them, we endeavor to ensure that the third
                parties
                keep your
                personal data secure from unauthorized access, collection, use, disclosure, processing or similar risks
                and
                retain your personal data only for as long as your personal data is needed to achieve the
                above-mentioned
                purposes.</p>
            </div>
          </section>

          <br/>
          <section>
            <h3><strong>4. Accuracy of Information</strong></h3>
            <div className="ml-5">
              <p><strong>4.1</strong> We assume and deem that your Personal Data inserted, uploaded and provided to on
                the
                e-Platform is
                accurate, complete, not misleading and has been kept up-to-date.</p>
              <p><strong>4.2</strong> You can update your personal data anytime by accessing your account on the
                e-Platform.
              </p>
              <p><strong>4.3</strong> We will not be liable for relying or using incomplete or inaccurate Personal Data
                arising from your
                failure, negligence or refusal to provide the latest updates to us.</p>
            </div>
          </section>

          <br/>
          <section>
            <h3><strong>5. Retention of Information Accuracy of Information</strong></h3>
            <div className="ml-5">
              <p><strong>5.1</strong> We will retain your Personal Data for the period necessary to fulfil the purposes
                outlined in this
                notice unless a longer retention period is required or as may be required by applicable law.</p>
              <p><strong>5.2</strong> When destroying your Personal Data, we will take commercially reasonable and
                technically feasible
                measures to make the Personal Data irrecoverable and irreproducible, as may be required by applicable
                law.</p>
            </div>
          </section>
          <br/>

          <section>
            <h3><strong>6. Access and Correction of Information</strong></h3>
            <div className="ml-5">
              <p><strong>6.1</strong> Should you require to access/ update/ complete/ delete your Personal Data stored with
                us, you may
                request to do so by making a written request to us (contact details as below) via email or letter.</p>
              <p><strong>6.2</strong> Should you wish to object/ withdraw, in full or part of, your consent given to us to
                use certain
                Personal Data for the purposes listed above, you may request to do so by making a written request to us via
                email or letter stating your objections.</p>
              <p><strong>6.3</strong> Please note that your request above shall only be effective upon our acknowledgment of
                receipt thereof
                and may be subjected to applicable laws and/or prescribed fees under any prevailing data protection
                laws.</p>
            </div>
          </section>

          <br/>
          <section>
            <h3><strong>7. Security and Safeguards</strong></h3>
            <div className="ml-5">
              <p><strong>7.1</strong> We aim to keep and process Personal Data in a secure manner. We will take all
                precautions necessary,
                where practicable, to implement appropriate administrative and security safeguards on our database to
                protect your Personal Data disclosed to use and prevent unauthorized access, collection, usage,
                disclosure,
                copying, modification and/or disposal of your Personal Data.</p>
              <p><strong>7.2</strong> Please note that even with such preventive safeguards and procedures in place, no
                electronic
                transmission can be entirely secure. While security cannot be guaranteed, we strive to protect the
                security
                of your information and are constantly reviewing and enhancing our information security measures.</p>
              <p><strong>7.3</strong> Any user identification and/or passwords used on the e-Platform should be kept
                secure
                and not shared
                with anyone. You are to ensure that you exercise caution to ensure that your information is protected
                and
                not accessible by anyone other than yourself. We cannot assume responsibility for any unauthorized third
                party access arising from your failure to keep such information confidential.</p>
            </div>
          </section>

          <br/>
          <section>
            <h3><strong>8. Acknowledgement and Consent</strong></h3>
            <div className="ml-5">
              <p><strong>8.1</strong> By continuing to communicate with us and/or by engaging us to provide Bridepay
                Services to you and or
                by using/accessing the e-Platform at www.bridepay.com (or any subsequent URL which may replace it), you
                hereby consent to the collection, recording, holding, storing, use and or otherwise processing of your
                Personal Data by us and all persons involved in the provision of Bridepay Services by us.</p>
            </div>
          </section>

          <br/>
          <p>We reserve the right to modify, update and/or amend this Privacy Policy at any time by placing the updated
            Privacy Policy on this website, copy of which shall also be sent to your last known email address.</p>
        </div>

        <Footer userType={this.state?.loggedOnUser?.type}/>
      </div>
    );
  }

}

export default PrivacyPage;