// import './VendorAboutTab.scss';

import * as React from 'react';



interface VendorAboutTabProps {
  about: string;
}


interface VendorAboutTabState {

}




class VendorAboutTab extends React.Component<VendorAboutTabProps, VendorAboutTabState> {

  render() {
    return (
      <div className="VendorAboutTab">
        <div className="content">
          <div className="header">
            <div className="title typo-headline">About</div>
            <div className="subtitle">Know more about the vendor</div>
          </div>
          <div className="content" dangerouslySetInnerHTML={{__html: this.props?.about}}></div>
        </div>
      </div>
    );
  }
  
  
}






export default VendorAboutTab;