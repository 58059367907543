// import './PageAlert.scss';

import * as React from 'react';

interface PageAlertProps {
  texts: string[]; /* List of texts to be rendered. One text item will e rendered in one line. */
  link: string;
}

interface PageAlertState {
}

class PageAlert extends React.Component<PageAlertProps, PageAlertState> {
  renderTexts() {
    return this.props?.texts?.map((text, textIndex) => <div key={textIndex}>{text}</div>);
  }

  render() {
    return (
      <a className="PageAlert" href={this.props.link ?? '#'}>
        {this.renderTexts()}
      </a>
    );
  }
}




export default PageAlert;