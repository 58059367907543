import * as React from 'react';
import { notification, Empty } from 'antd';

import { User } from '../../../interfaces/user.interface';
import { AlbumImage } from '../../../interfaces/album-image.interface';

import UserApi from '../../../api-services/user-api.service';
import AlbumImageApi from '../../../api-services/album-image-api.service';

import Navbar from '../../basic-components/navbar/Navbar';
import UserProfileNavigation from '../../basic-components/user-profile-navigation/UserProfileNavigation';
import Footer from '../../basic-components/footer/Footer';
import LikeableImage from '../../basic-components/likeable-image/LikeableImage';


interface InspirationsPageProps {
  isAccountSignedIn: boolean;
  googleSignInUrl: string;
  fbSignInUrl: string;
  csrfToken:string;
}


interface InspirationsPageState {
  user: User;
  likedImages : AlbumImage[];
}


class InspirationsPage extends React.Component<InspirationsPageProps, InspirationsPageState> {

  userApi = new UserApi;
  albumImageApi = new AlbumImageApi;
  
  
  componentDidMount(): void {
    this.loadSignedInUserProfile();
  }


  /**
   * Get the signed in user's profile from server if there is a user signed in.
   */
  loadSignedInUserProfile(): void {
    if(!this.props?.isAccountSignedIn) {
      return null;
    }

    this.userApi.getUserProfile()
    .then(user => {
      this.setState({ user: user });
      this.loadUserLikedImages(user.id);
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: 'Error while loading the user\'s profile.', className: 'antd-mod center' });
    });
  }


  loadUserLikedImages(userId: number): void {
    this.albumImageApi.getLikedImages()
    .then(albumImages => {
      this.setState({ likedImages: albumImages });
    });
  }


  /**
   * Like/Unlike image.
   * Remove the image from the view after unliking it.
   */
  updateAlbumImageLikeStatus(imageId: number, likeStatus): void {
    this.albumImageApi.updateAlbumImageLikeStatus(imageId, likeStatus)
    .then(() => {
      if(likeStatus === false) {
        const likedImages = this.state?.likedImages?.filter(image => image.id !== imageId);
        this.setState({ likedImages: likedImages });
      }
    });
  }


  redirectToProductDetails(slug: string): void {
    window.open(`/products/${slug}`);
  }


  renderLikedImages() {
    return this.state?.likedImages?.map((albumImage, albumImageIndex) => {
      return <LikeableImage 
        imageUrl={albumImage.url} 
        likeStatus={true}
        likeButtonDisabled={!this.props?.isAccountSignedIn}
        key={albumImageIndex}
        likeStatusChanged={(likeStatus: boolean) => { this.updateAlbumImageLikeStatus(albumImage.id, likeStatus) }}
      />
    });
  }
  
  
  render() {
    return (
      <div className="InspirationsPage">
        <Navbar isAccountSignedIn={this.props?.isAccountSignedIn} user={this.state?.user}></Navbar>

        <div className="main-content">
          <UserProfileNavigation selectedNavigation="inspirations"></UserProfileNavigation>
          <div className="liked-images">
            {this.state?.likedImages?.length > 0 ? this.renderLikedImages() : (<div className='empty-container'><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>) }
          </div>
        </div>

        <Footer userType={this.state?.user?.type}></Footer>
      </div>
    );
  }
  
}




export default InspirationsPage;