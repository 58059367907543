// import './ProductControlsCard.scss';

import * as React from 'react';
import { Rate, Radio, Button, DatePicker } from 'antd';
import { orderBy } from 'lodash';

import { Product } from '../../../interfaces/product.interface';
import { ProductVariant } from '../../../interfaces/product-variant.interface';



interface ProductControlsCardProps {
  product: Product;
  variantSelected?: (variant: ProductVariant) => void;
  wishlistStatusChanged?: (status: boolean) => void;
  onClickAppointment?: () => void;
  wishlistButtonDisabled?: boolean;
}


interface ProductControlsCardState {
  wishlisted: boolean;
}




class ProductControlsCard extends React.Component<ProductControlsCardProps, ProductControlsCardState> {

  /**
   * Will return the original price if there is no discountedPrice data available.
   */
  get price(): number {
    if(this.props?.product?.discountedPrice) {
      return this.props?.product?.discountedPrice;
    } else {
      return this.props?.product?.price;
    }
  }


  get discountPercent(): string {
    if(this.props?.product?.discountPercentage) {
      let discountPercent;
      if (Number.isInteger(this.props?.product?.discountPercentage)) {
        discountPercent = this.props?.product?.discountPercentage.toString(10);
      } else {
        discountPercent = this.props?.product?.discountPercentage.toFixed(2);
      }
      return `${discountPercent}%`;
    } else {
      return '0%';
    }
  }


  componentDidMount(): void {
    this.initializeSelectedVariant();
  }


  emitVariantSelected(variantId: number): void {
    if(this.props?.variantSelected) {
      const variant: ProductVariant = this.props?.product?.variants?.find(variant => variant.id === variantId);
      this.props?.variantSelected(variant);
    }
  }


  emitWishlistStatusChanged(wishlistStatus: boolean): void {
    if(this.props?.wishlistStatusChanged) {
      this.props?.wishlistStatusChanged(wishlistStatus);
      this.setState({ wishlisted: wishlistStatus });
    }
  }


  initializeSelectedVariant(): void {
    const defaultSelectedVariantId = this?.props?.product?.variants?.find(variant => variant.optionValue === this.props?.product?.defaultVariantValue)?.id;
    this.setState({ wishlisted: this?.props?.product?.wishlisted });
    if(defaultSelectedVariantId) {
      this.emitVariantSelected(defaultSelectedVariantId);
    }
  }


  renderOldPrice() {
    if(this.props?.product?.discountedPrice) {
      return <div className="old-price">RM {this.props.product?.unit != null ? ('/'  + this.props.product?.unit) : ''}</div>
    } else {
      return null;
    }
  }


  renderVariantsSelector() {
    const variantName = (this.props?.product?.option) ? this.props.product.option : 'Variant';
    const sortedVariants: ProductVariant[] = orderBy(this?.props?.product?.variants, ['position']);
    const defaultSelectedVariantId = this?.props?.product?.variants?.find(variant => variant.optionValue === this.props?.product?.defaultVariantValue)?.id;
    const variantOptions = sortedVariants?.map(variant => {
      return { id: variant.id, label: variant.optionValue, value: variant.id };
    });
    
    if(this?.props?.product?.variants?.length) {
      return (
        <div className="variants-selector">
          <div className="title typo-subheading-2">Select {variantName}</div>
          <Radio.Group defaultValue={defaultSelectedVariantId} options={variantOptions} onChange={(event) => { this.emitVariantSelected(event.target.value) }} optionType="button" className="antd-mod typo-bold" />
        </div>
      );
    }

    else {
      return null;
    }
  }


  /**
   * Backend have not yet implemented the "wishlist" functionality so we just use a temporary condition/logic for now.
   */
  renderWishlistButtons() {
    if(this.props?.wishlistButtonDisabled) {
      return null;
    }
    if(!this.state?.wishlisted) {
      return <Button className="add-to-wishlist antd-mod color-primary typo-subheading-2" type="default" size="large" onClick={() => { this.emitWishlistStatusChanged(true) }}>Add to Wishlist</Button>  ;
    } else {
      return <Button className="remove-from-wishlist antd-mod color-primary typo-subheading-2" type="default" size="large" onClick={() => { this.emitWishlistStatusChanged(false) }}>Remove from Wishlist</Button>;
    }
  }

  /**
   * button for appointment feature.
   */
  renderAppointmentButton() {
    return <Button className="book-now antd-mod color-accent" type="default" size="large" onClick={() => {this.props?.onClickAppointment()}}>Set Appointment</Button>  ;
  }

  redirectToVendorProfile(slug: string): void {
    window.location.href = `/vendors/${slug}`;
  }
  
  
  render() {
    return (
      <div className="ProductControlsCard">
        <div className="title-container">
          <div className="title typo-headline">Summary</div>
        </div>


        <div className="vendor typo-subheading-1">
          <span>by</span>
          &nbsp;&nbsp;
          <span>{this.props?.product?.vendor?.businessName}</span>
        </div>


        <div className="divider"></div>


        {/*  <div className="rating">
          <Rate value={1} count={1}></Rate>
          <div className="rating-text typo-subheading-2">{this.props?.product?.rating}</div>
        </div>*/}


        <div className="price label-value-container typo-subheading-1">
          <div className="label">PRICE:</div>
          <div className="values-container">
            <div className="old-price">RM {Number.isInteger(this.props?.product?.price) ? this.props?.product?.price : this.props?.product?.price.toFixed(2)}{this.props.product?.unit != null ? ('/'  + this.props.product?.unit) : ''}</div>
            <div className="current-price">RM {(this.props?.product?.discountedPrice) ? (Number.isInteger(this.props?.product?.discountedPrice) ? this.props?.product?.discountedPrice : this.props?.product?.discountedPrice.toFixed(2)) : (Number.isInteger(this.props?.product?.price) ? this.props?.product?.price : this.props?.product?.price.toFixed(2))}{this.props.product?.unit != null ? ('/'  + this.props.product?.unit) : ''}</div>
          </div>
        </div>


        <div className="price label-value-container typo-subheading-1">
          <div className="label">SAVE:</div>
          <div className="values-container">
            <div className="discount">{this.discountPercent}</div>
          </div>
        </div>


        <div className="divider"></div>

        {this.renderVariantsSelector()}

        {/*<div className="check-availability">
          <div className="typo-bold-2 typo-subheading-2">Check Availability</div>
          <DatePicker bordered={false} />
        </div>*/}

        <div className="chat-and-wishlist-container">
          {/*<Button className="chat antd-mod color-accent" type="primary" size="large">Chat</Button>*/}
          {this.renderWishlistButtons()}
        </div>

        {/*<Button className="book-now antd-mod color-primary" type="primary" size="large" disabled={true}>Book Now</Button>
        { (new Date() < Date.parse("Dec 20 2021 00:00:00 +8")) &&
          (
            <div className="fair-announcement">
              Book your Bridepay Deals at KLPJ Wedding Fair, Mid Valley Exhibition Centre on 17-19 Dec 2021
            </div>
          )
        }*/}
        {/*<Button className="book-now antd-mod color-accent" href={`/${this.props?.product?.vendor?.slug}`} target="_blank">Visit Vendor Profile</Button>*/}
        {this.renderAppointmentButton()}
      </div>
    );
  }
  
}




export default ProductControlsCard;