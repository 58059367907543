import * as React from "react"

import {Button, notification, Modal, FormInstance, Form, Input, Select, Upload, Checkbox, Radio} from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { Comment } from "../../../interfaces/comment.interface";
import AdminCommentsTable from "../../basic-components/admin-comments-table/AdminCommentsTable";
import CommentAdminApi from "../../../api-services/comment-admin-api.service";
import {bool} from "prop-types";

interface IAdminCommentsPageProps {
  hideAction: boolean;
}

interface IAdminCommentsPageState {
  comments: Comment[];
  modalVisible: boolean;
  selectedComment: Comment;
  selectedComments: number[];
  uploading: boolean;
  pagination: any;
}

class AdminCommentsPage extends React.Component <IAdminCommentsPageProps, IAdminCommentsPageState> {

  commentApi = new CommentAdminApi();
  commentSetupForm = React.createRef<FormInstance>();

  componentDidMount(){
    this.setState({
      comments: [],
      selectedComments: [],
      selectedComment: null,
      modalVisible: false,
      uploading: false,
      pagination: {
        current: 1,
        pageSize: 25,
        showSizeChanger: false
      }
    });

    setTimeout(() => {
      this.loadComments(1);
    }, 500);
  }

  loadComments(page): void{
    this.commentApi.getComments(page, this.state?.pagination.pageSize)
    .then(commentsData => {
      const pagination = { ...this.state.pagination };
      pagination.current = page;
      pagination.total = commentsData.total;
      this.setState({comments: commentsData.comments, pagination});
    })
    .catch(error => {
      notification.error({ message: 'An error occurred while loading the data for comments', className: 'antd-mod center' });
    });
  }

  handleTableChange = (pagination) => {
    this.loadComments(pagination.current)
  }

  saveComment(newComment): void{
    if(this.state?.selectedComment){
      const commentId = this.state?.selectedComment?.id;
      this.commentApi.updateComment(commentId, newComment)
        .then(commentResponse => {
          const commentIndex = this.state?.comments?.findIndex(c => c.id == commentId);
          if(commentIndex > -1){
            let comments = [...this.state?.comments];
            comments[commentIndex] = commentResponse;
            this.setState({
              comments: comments,
              modalVisible: false
            });
            notification.success({ message: 'Changes saved successfully', className: 'antd-mod center' });
          }
        })
        .catch(error => {
          notification.error({ message: 'An error occurred while updating comment', className: 'antd-mod center' });
        });
    }else{
      this.commentApi.addComment(newComment)
        .then(commentResponse => {
          this.setState(prevState => ({
            comments: [...prevState.comments, commentResponse],
            modalVisible: false
          }));
        })
        .catch(error => {
          console.log("error", error)
          notification.error({ message: 'An error occurred while adding  comment', className: 'antd-mod center' });
        });
    }
  }

  updateCommentList(newComment){
    const commentIndex = this.state?.comments?.findIndex(c => c.id == newComment.id);
    if(commentIndex > -1){
      let comments = [...this.state?.comments];
      comments[commentIndex] = newComment;
      this.setState({comments: comments});

      notification.success({ message: 'Changes saved successfully', className: 'antd-mod center' });
    }
  }

  onAddComment(): void{
    this.commentSetupForm.current!
        .validateFields()
        .then(values => this.saveComment(values))
        .catch(info => {
          console.log('Validate Failed:', info);
        });
  }

  onEditComment(comment:Comment): void{
    this.setState({modalVisible: true, selectedComment: comment});

    setTimeout(() => {
      this.commentSetupForm.current!
          .setFieldsValue({
            name: comment.name,
            email: comment.email,
            website: comment.website,
            content: comment.content,
            status: comment.status,
          });
    }, 300);
  }

  onCancelComment():void{
    this.commentSetupForm.current!.resetFields();
    this.setState({modalVisible: false});
  }

  removeComment(commentId?:number): void{
    this.commentApi.removeComment(commentId)
    .then(res => {
      this.setState(prevState => ({
        comments: prevState.comments.filter(comment => (comment.id !== commentId)),
        selectedComments: []
      }));
    })
    .catch(error => {
      notification.error({ message: 'An error occurred while removing comment', className: 'antd-mod center' });
    })
  }

  publishComment(commentId?:number): void{
    this.commentApi.publishComment(commentId)
        .then(res => {
          this.loadComments(this.state.pagination.current)
        })
        .catch(error => {
          notification.error({ message: 'An error occurred while publishing comment', className: 'antd-mod center' });
        })
  }

  archiveComment(commentId?:number): void{
    this.commentApi.archiveComment(commentId)
        .then(res => {
          this.loadComments(this.state.pagination.current)
        })
        .catch(error => {
          notification.error({ message: 'An error occurred while archiving comment', className: 'antd-mod center' });
        })
  }

  renderAddCommentModal(){
    return (
      <Modal
        title="Add Comment"
        visible={this.state?.modalVisible}
        maskClosable={false}
        okText="Save"
        onOk={()=>this.onAddComment()}
        onCancel={()=>this.onCancelComment()}
        width={600}
      >
        <Form id="banner-form" ref={this.commentSetupForm} layout="vertical" autoComplete="off">
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Name is required' }]}>
            <Input size="large"/>
          </Form.Item>
          <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Email is required' }, { type: 'email', message: 'The input is not valid Email!' }]}>
            <Input size="large"/>
          </Form.Item>
          <Form.Item name="website" label="Website" rules={[{ required: true, message: 'Website is required' }, { type: 'url', message: 'The input is not valid URL!' }]}>
            <Input size="large"/>
          </Form.Item>
          <Form.Item name="content" label="Content" rules={[{ required: true, message: 'Content is required' }]}>
            <Input size="large"/>
          </Form.Item>
          {/*<Form.Item name="status" label="Status">
            <Select size="large">
              {['pending', 'active', 'archived'].map((status, index) => {
                return <Select.Option key={index} value={status}>
                  {status}
                </Select.Option>
              })}
            </Select>
          </Form.Item>*/}
          <Form.Item name="status" label="Status">
            <Radio.Group buttonStyle="solid">
              {['pending', 'active', 'archived'].map((status, index) => {
                return <Radio.Button key={index} value={status}>{status.toUpperCase()}</Radio.Button>
              })}
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  render() {
    return (
      <>
        <div className="AdminBannersPage">
          <div className="title">
            <div className="typo-title-2 typo-bold">Comments</div>
          </div>
          <div className="main-content">
            {this.renderAddCommentModal()}

            <AdminCommentsTable
                hideAction={this.props?.hideAction}
                comments={this.state?.comments}
                pagination={this.state?.pagination}
                onChange={this.handleTableChange}
                selectedComments={this.state?.selectedComments}
                selectionChange={
                  (selectedComments)=>{
                    this.setState({
                      selectedComments: selectedComments
                    })
                  }
                }
                editComment={(comment)=>this.onEditComment(comment)}
                removeComment={(commentId)=>this.removeComment(commentId)}
                publishComment={(commentId)=>this.publishComment(commentId)}
                archiveComment={(commentId)=>this.archiveComment(commentId)}
            />
          </div>
        </div>
      </>
    );
  }
}

export default AdminCommentsPage
