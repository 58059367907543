// import './VendorsListPage.scss';

import * as React from 'react';
import { read_cookie } from 'sfcookies';
import {notification, Modal, Button, Select, Spin, Col, Row} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
// import InfiniteScroll from 'react-infinite-scroller';
// import {isMobile} from 'react-device-detect';

import {Vendor} from '../../../interfaces/vendor-interface';
import {User} from '../../../interfaces/user.interface';

import VendorApi from '../../../api-services/vendor-api.service';
import UserApi from '../../../api-services/user-api.service';

import Navbar from '../../basic-components/navbar/Navbar';
import Footer from '../../basic-components/footer/Footer';
import BreadcrumbsNavigation from '../../basic-components/breadcrumbs-navigation/BreadcrumbsNavigation';
import VendorCard from '../../basic-components/vendor-card/VendorCard';
import LoginModal from '../../modals/login-modal/LoginModal';
import ForgotPasswordModal from '../../modals/forgot-password-modal/ForgotPasswordModal';
import {DownOutlined} from "@ant-design/icons";
import {ServiceCategory} from "../../../interfaces/service-category.interface";
import ServiceCategoryApi from "../../../api-services/service-category-api.service";
import AgeConfirmationModal from "../../modals/age-confirmation-modal/AgeConfirmationModal";
import CountryApi from "../../../api-services/country-api.service";
import ProductCard from "../../basic-components/product-card/ProductCard";

notification.config({
  duration: 12,
  top: 60
})

interface VendorsListPageProps {
  isAccountSignedIn: boolean;
  googleSignInUrl: string;
  fbSignInUrl: string;
  csrfToken: string;
  selectedCategoryData: any;
}

interface VendorsListPageState {
  user: User;
  vendors: Vendor[];
  loadingVendors: boolean;
  loginModalVisible: boolean;
  loginModalRedirectUrl: string;
  forgotPasswordModalVisible: boolean;
  currentPage: number;
  hasMore: boolean;
  categoryModalVisible: boolean;
  categories: ServiceCategory[];
  subCategories: ServiceCategory[];
  ageConfirmationModalVisible: boolean;
  selectedSubCategory: any[];
  countries: any[];
  selectedCountry: number;
  selectedCity: number;
  cities: any[];
}

const perPage = 20;

class VendorsListPage extends React.Component<VendorsListPageProps, VendorsListPageState> {
  vendorApi = new VendorApi;
  userApi = new UserApi;
  countryApi = new CountryApi;
  serviceCategoryApi = new ServiceCategoryApi;
  breadcrumbsNavItems = [
    {id: 1, label: 'Home', link: '/'},
    {id: 2, label: 'Vendors', link: '/vendors'},
  ];

  componentDidMount() {
    const ageConfirmed = read_cookie("age_21_and_non_muslim");
    this.setState({
      ageConfirmationModalVisible: (this.props?.selectedCategoryData && this.props?.selectedCategoryData?.slug == "wines-alcohol") && (ageConfirmed.length==0 || JSON.parse(ageConfirmed)==false),
      loadingVendors: false,
      vendors: [],
      currentPage: 1,
      countries: [],
      cities: [],
      selectedCountry: -1,
      selectedCity: -1,
      hasMore: true
    });
    this.loadServiceCategory();
    this.loadCountries();
    this.loadVendors(null, null, 1);

    setTimeout(() => {
      this.loadSignedInUserProfile();
    }, 500);
  }

  onSelectSubCategory(slug): void {
    const foundItem = this.state?.selectedSubCategory?.find(x => x.includes(slug))
    let newItems = this.state?.selectedSubCategory === undefined ? [] : this.state?.selectedSubCategory

    if (foundItem) {
      this.setState({
        selectedSubCategory: newItems.filter(x => x !== slug)
      });
    } else {
      newItems.push(slug)
      this.setState({
        selectedSubCategory: newItems
      });
    }
  };

  loadCountries(): void {
    this.vendorApi.getCountries()
      .then(result => {
        this.setState({countries: result});
      });
  }

  loadCities():void {
    if (this.state.selectedCountry == -1) {
      this.setState({cities: []});
      return;
    }
    this.vendorApi.getCities(this.state.countries[this.state.selectedCountry].id)
      .then(result => this.setState({cities: result}));
  }

  onSelectCountry = (value: number):void => {
    let category = this.props?.selectedCategoryData?.slug;
    let country = value > -1 ? this.state.countries[value].id : null;

    if (this.props?.selectedCategoryData?.slug == 'all-vendors') category = null;

    this.loadVendors(country, null, 1);
    this.setState({selectedCountry: value, selectedCity: -1}, () => this.loadCities());
  }

  onSelectCity = (value: number):void => {
    let country = null;
    let city = null;

    if (this.state.selectedCountry > -1) country = this.state.countries[this.state.selectedCountry].id;
    if (value > -1) city = this.state.cities[value].id;

    this.loadVendors(country, city, 1);
    this.setState({selectedCity: value});
  }

  loadServiceCategory(): void {
    Promise.all([
      this.serviceCategoryApi.getCategories()
    ])
      .then(result => {
        this.setState({
          categories: result[0]
        }, () => {
          if (this.props?.selectedCategoryData?.slug !== 'all-vendors') {
            this.loadServiceSubCategory(this.state?.categories?.find(x => x.slug.includes(this.props?.selectedCategoryData.slug))?.id);
          }
        });
      });
  }

  loadServiceSubCategory(categoryId): void {
    Promise.all([
      this.serviceCategoryApi.getSubCategories(categoryId)
    ])
      .then(result => {
        this.setState({
          subCategories: result[0]
        });
      });
  }

  loadVendors(country, city, page): void {
    let category = this.props?.selectedCategoryData?.slug;
    if (this.props?.selectedCategoryData?.slug == 'all-vendors') category = null;
    this.vendorApi.getVendors(category, country, city, page, perPage)
      .then(vendors => {
        let vendorList: any[];

        if (page === 1) vendorList = [...vendors];
        else vendorList = [...this.state.vendors, ...vendors]
        this.setState({
          loadingVendors: true,
          vendors: vendorList,
          currentPage: page,
          hasMore: perPage === vendors.length
        });
      })
      .catch((error) => {
        console.error(error);
        notification.error({message: 'Error while loading the list of vendors', className: 'antd-mod center'});
      })
      .finally(() => {
        this.setState({ loadingVendors: false });
      });
  }

  loadMoreVendors = () => {
    let country = null;
    let city = null;

    if (this.state.selectedCountry > -1) country = this.state.countries[this.state.selectedCountry].id;
    if (this.state.selectedCity > -1) city = this.state.cities[this.state.selectedCity].id;
    this.loadVendors(country, city, this.state.currentPage + 1)
  }

  loadSignedInUserProfile(): void {
    if (!this.props?.isAccountSignedIn) {
      return null;
    }

    this.userApi.getUserProfile()
      .then(user => {
        this.setState({user: user});
      })
      .catch(error => {
        console.error(error);
        notification.error({message: 'Error while loading the user\'s profile.', className: 'antd-mod center'});
      });
  }

  renderMainFilters() {
    return (
      <div className="main-filters">
        <div className="content">
          <div className="filter-set">
            <div className="label">Filter by:</div>

            <Select defaultValue="all">
              <Select.Option value="all">All Categories</Select.Option>
              <Select.Option value="1">Option One</Select.Option>
            </Select>

            <Select defaultValue="all">
              <Select.Option value="all">All Sub-Categories</Select.Option>
              <Select.Option value="1">Option One</Select.Option>
            </Select>

            <Select defaultValue="all">
              <Select.Option value="all">All Countries</Select.Option>
              <Select.Option value="1">Option One</Select.Option>
            </Select>

            <Select defaultValue="all">
              <Select.Option value="all">All Cities</Select.Option>
              <Select.Option value="1">Option One</Select.Option>
            </Select>
          </div>
        </div>
      </div>
    );
  }

  checkCategoryInVendor(vendor: Vendor, categorySlug: string) {
    let result: ServiceCategory;
    vendor.serviceCategories.forEach(c => {
      if (c.slug.includes(categorySlug)) {
        result = c;
      }
    });
    return result;
  }

  renderCategoryHeader() {
    return (
      <div className="category-header">
        <div className="content">
          <div>
            <div className="header">
              <div className="row-1">
                <div className="title typo-headline">
                  {
                    this.props?.selectedCategoryData?.slug == 'all-vendors' ? "All Vendors" : this.state?.categories?.find(x => x.slug.includes(this.props?.selectedCategoryData?.slug))?.name
                  }
                </div>
              </div>

              <div className="row-2">
                <div>
                  <Select className="typo-body mr-3"
                          value={this.state?.selectedCountry}
                          style={{width: 200}}
                          placeholder="Country"
                          defaultActiveFirstOption={false}
                          filterOption={false}
                          size="large"
                          onChange={this.onSelectCountry}
                          notFoundContent={null}
                  >
                    <Select.Option value={-1}>All Countries</Select.Option>
                    {this.state?.countries.map((country, index) =>
                      <Select.Option value={index} key={index}>{country.name}-({country.iso})</Select.Option>
                    )}
                  </Select>
                  <Select className="typo-body mt-3"
                          value={this.state?.selectedCity}
                          defaultValue={-1}
                          style={{width: 200}}
                          showSearch
                          placeholder="City"
                          defaultActiveFirstOption={false}
                          size="large"
                          optionFilterProp="children"
                          filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input)}
                          filterSort={(optionA, optionB) =>
                            (optionA!.children as unknown as string)
                              .toLowerCase()
                              .localeCompare((optionB!.children as unknown as string).toLowerCase())
                          }
                          onSelect={this.onSelectCity}
                          notFoundContent={null}
                  >
                    <Select.Option value={-1}>All Cities</Select.Option>
                    {this.state?.cities.map((city, index) =>
                      <Select.Option value={index} key={index}>{city.name}</Select.Option>
                    )}
                  </Select>
                </div>
                <Button
                  style={{backgroundColor: '#fff', color: '#000', borderColor: '#000', height: 39}}
                  onClick={
                    () => this.setState({categoryModalVisible: true})
                  }
                  type="primary"
                  icon={<DownOutlined/>}
                >
                  All Categories
                </Button>
              </div>
              <div className="subtitle">
                Showing results for <span>
                  {
                    this.props?.selectedCategoryData?.slug == 'all-vendors' ? "All Categories" : this.state?.categories?.find(x => x.slug.includes(this.props?.selectedCategoryData?.slug))?.name
                  }
                </span> in <span>
                  {
                    this.state?.selectedCountry == -1 ? "All Countries" : this.state?.countries[this.state.selectedCountry]?.name
                  },
                {
                  this.state?.selectedCity == -1 ? "All Cities" : this.state?.cities[this.state.selectedCity]?.name
                }</span>
              </div>
            </div>
          </div>
          <Modal
            title=""
            centered
            visible={this.state?.categoryModalVisible}
            onOk={() => this.setState({categoryModalVisible: false})}
            onCancel={() => this.setState({categoryModalVisible: false})}
            footer={null}
            width={1000}
          >
            <div style={{textAlign: 'center'}}>
              <div style={{fontFamily: 'Playfair Display', fontSize: 22, marginBottom: '20px'}}>Choose a category</div>
              {/*<Button onClick={() => { window.location.href = '/vendors/c/all-vendors' }} style={{ borderRadius: '20px', margin: '5px', lineHeight: 1.2 }}>All Vendors</Button>*/}
              {this.state?.categories?.map(category => <Button onClick={() => { window.location.href = '/vendors/c/' + category.slug }} style={{ borderRadius: '20px', margin: '5px', lineHeight: 1.2 }} key={category.id}>{category.name}</Button>)}
            </div>
          </Modal>
        </div>
      </div>
    );
  }


  renderAllVendors() {
    let vendorArray = this.state?.vendors;
    /*if (this.props?.selectedCategoryData?.slug == 'all-vendors') {
      vendorArray = this.state?.vendors;
    } else {
      if (this.state?.selectedSubCategory === undefined || this.state?.selectedSubCategory?.length === 0) {
        // vendorArray = this.state?.vendors?.filter(x => x.category?.slug?.includes(this.props?.selectedCategoryData?.slug))
        vendorArray = this.state?.vendors?.filter(x => this.checkCategoryInVendor(x, this.props?.selectedCategoryData?.slug));
      } else {
        vendorArray = this.state?.vendors?.filter(x => this.checkCategoryInVendor(x, this.props?.selectedCategoryData?.slug))
          .filter(v => v.serviceCategories?.map(sub => sub?.slug)
            .some(r => this.state?.selectedSubCategory?.includes(r))); // includes(this.state?.selectedSubCategory))
      }
    }*/
    return (
      <div className="page-section all-vendors">
        <div className="content">
          <div id="vendors-list" className="vendors-list">
            <InfiniteScroll
              dataLength={this.state?.vendors?.length || 0} //This is important field to render the next data
              next={this.loadMoreVendors}
              hasMore={this.state?.hasMore || false}
              loader={<div className="loading"><Spin/></div>}
            >
              {vendorArray?.map(vendor => <VendorCard key={vendor.id} vendor={vendor}/>)}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="VendorsListPage">
        <Navbar
          isAccountSignedIn={this.props?.isAccountSignedIn}
          user={this.state?.user}
          activeNavItem={'vendors'}
          signInRequired={(redirectUrl) => {
            this.setState({loginModalVisible: true, loginModalRedirectUrl: redirectUrl});
          }}
        />

        <LoginModal
          visible={this.state?.loginModalVisible}
          googleSignInUrl={this.props?.googleSignInUrl}
          fbSignInUrl={this.props?.fbSignInUrl}
          csrfToken={this.props?.csrfToken}
          redirectUrl={this.state?.loginModalRedirectUrl}
          forgotPasswordClicked={() => {
            this.setState({loginModalVisible: false, forgotPasswordModalVisible: true})
          }}
          closed={() => {
            this.setState({loginModalVisible: false})
          }}
          registerLink="/registration"
        />

        <ForgotPasswordModal
          visible={this.state?.forgotPasswordModalVisible}
          loginClicked={() => {
            this.setState({forgotPasswordModalVisible: false, loginModalVisible: true})
          }}
          closed={() => {
            this.setState({forgotPasswordModalVisible: false})
          }}
        />

        <AgeConfirmationModal visible={this.state?.ageConfirmationModalVisible} confirmed={()=>this.setState({ageConfirmationModalVisible: false})} closed={() => window.location.href = '/'}/>

        <div className="page-content">
          <BreadcrumbsNavigation items={this.breadcrumbsNavItems}/>
        </div>

        <div className="page-content main-content">
          {this.renderCategoryHeader()}
          {this.renderAllVendors()}
        </div>

        <Footer userType={this.state?.user?.type}/>
      </div>
    );
  }

}

export default VendorsListPage;
