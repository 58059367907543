// import './ContactModal.scss';

import * as React from 'react';
import { Modal } from 'antd';


interface ContactModalProps {
  visible: boolean;
  closed?: () => void;
}
 


class ContactModal extends React.Component<ContactModalProps> {
  
  
  componentDidMount(): void {
  
  }

  
  /**
   * Modal using the credentials entered into the form.
   * Reload window if modal was successful.
   */
  modal(formEvent: any): void {
  }


  render() {  
    return (
      <Modal className="ContactModal" centered={false} visible={this.props?.visible} onCancel={this.props?.closed} width={500} footer={null} keyboard={false} maskClosable={false} destroyOnClose={true}>
        <div>
          <h2>Contact Us</h2>
          <p><b>Bridepay Sdn Bhd</b><br/>
          <i>202101012115 (1412414-M)</i>
          </p>
          <p><b>Address</b><br/>
          A-03-09, Dataran Cascades,<br/>
          No.13A, Jalan PJU 5/1,<br/>
          Kota Damansara PJU 5,<br/>
          47810 Petaling Jaya,<br/>
          Selangor.<br/>
          </p>
          <p><b>Tel No</b><br/>
          03-76254301 / 03-76254302</p>
        </div>
      </Modal>
    );
  }
  
  
}


export default ContactModal;