import axios from 'axios';
import { FAQ, VendorFaq, FAQAnswerOptions } from '../interfaces/faq.interface';

interface SaveAnswerPayload {
  id?:number;
  serviceCategoryId?: number;
  answers?:any;
  kind?:string;
  other?:string;
}


class FAQApi {


  getFAQsByCategory(categoryId: number): Promise<FAQ[]> {
    const apiUrl = `/api/v1/faqs?category_id=${categoryId}&include=answer_options`;
    return axios.get(apiUrl)
      .then(response => {
        const faqs: FAQ[] = [];

        var includedAnswerOptions = {};
        if(response?.data?.included && response?.data?.included.length > 0){
          response?.data?.included.forEach(data => {
            var option = data.attributes;
            includedAnswerOptions[option.id] = {
              id: option.id,
              answer: option.answer,
              other: option.other,
              hint: option.hint,
            };
          });
        }

        response?.data?.data?.forEach(faqData => {
          //answer options mapping
          var answerOptions:FAQAnswerOptions[] = [];

          if(faqData?.relationships?.answer_options.data.length > 0){
            faqData?.relationships?.answer_options.data.forEach(option => {
              if(option.type == "faq_answer_option"){
                answerOptions.push(includedAnswerOptions[option.id]);
              }
            });
          }

          faqs.push({
            id: faqData?.attributes?.id,
            question: faqData?.attributes?.question,
            type: faqData?.attributes?.kind,
            placeholder: faqData?.attributes?.placeholder,
            maxAnswer: faqData?.attributes?.max_answer,
            options: answerOptions
          });
        });

        return Promise.resolve(faqs);
      });
  }


  getVendorFaqs(categoryId: number): Promise<VendorFaq[]> {
    const apiUrl = `/api/v1/faqs/my?category_id=${categoryId}`;

    return axios.get(apiUrl)
      .then(vendorFaqResponse => {
        var vendorFAQs:VendorFaq[] = [];
        vendorFaqResponse?.data?.data?.forEach(vendorFaq => {
          const faqAnswer = this.mapFaqAnswer(vendorFaq?.attributes);

          vendorFAQs.push({
            id: vendorFaq?.attributes?.id,
            questionId: vendorFaq?.attributes?.question_id,
            formattedAnswer: faqAnswer.formattedAnswer.join(", "),
            answers: faqAnswer.answers
          })
        });
        
        return Promise.resolve(vendorFAQs);
      });

  }


  saveAnswer(faq: FAQ, payload: SaveAnswerPayload): Promise<FAQ> {
    const apiUrl = `/api/v1/faqs/my`;

    return axios.post(apiUrl, {
      faq: {
        id: faq.id,
        service_category_id: payload.serviceCategoryId,
        answers: payload.answers.map(answer => {
          if(payload.kind == 'default'){
            return {id: answer, answer: null, kind: payload.kind, other_answer: payload.other}
          }else{
            return {id: null, answer: answer, kind: payload.kind, other_answer: null}
          }
        
        })
      }
    })
      .then(response => {
        const newFaq = {...faq};
        const faqAnswer = this.mapFaqAnswer(response?.data);
        newFaq.displayAnswer = true;
        newFaq.vendorFaqs = {
          id: response?.data?.id,
          questionId: response?.data?.question?.id,
          formattedAnswer: faqAnswer.formattedAnswer.join(", "),
          answers: faqAnswer.answers
        }
        return Promise.resolve(newFaq);
      })
  }



  updateAnswer(faq: FAQ, payload: SaveAnswerPayload): Promise<any> {
    const apiUrl = `/api/v1/faqs/my/${faq.vendorFaqs?.id}`;

    return axios.put(apiUrl, {
      faq: {
        answers: payload.answers.map(answer => {
          if(payload.kind == 'default'){
            return {id: answer, kind: payload.kind, other_answer: payload.other}
          }else{
            return {id: faq.vendorFaqs.answers[0]?.id, answer: answer, kind: payload.kind, other_answer: null}
          }
        
        })
      }
    })
      .then(response => {
        const newFaq = {...faq};
        const faqAnswer = this.mapFaqAnswer(response?.data);
        newFaq.displayAnswer = true;
        newFaq.vendorFaqs = {
          id: response?.data?.id,
          questionId: response?.data?.question?.id,
          formattedAnswer: faqAnswer.formattedAnswer.join(", "),
          answers: faqAnswer.answers
        }
        return Promise.resolve(newFaq);
      })
  }


  mapFaqAnswer(serverResponse){
    var formattedAnswer = [], answers = [];

    serverResponse?.answers?.forEach(answerResponse => {
      var _answer = {}, _formattedAnswer = answerResponse.answer;

      if(serverResponse?.kind == 'tick' || serverResponse?.kind == 'radio'){
        _answer["answer"] = answerResponse.id;
        if(answerResponse.other_answer != null){
          _formattedAnswer += ` ${answerResponse.other_answer}`;
          _answer["other"] = answerResponse.other_answer;
        } 
      } else{
        _answer["answer"] = _formattedAnswer;
      }
      
      formattedAnswer.push(_formattedAnswer);
      answers.push(_answer);
    });

    return {formattedAnswer: formattedAnswer, answers: answers}
  }

  
}



export default FAQApi;