import * as React from "react"

import { Button, notification, Table, Statistic } from "antd";
import { CloudDownloadOutlined } from '@ant-design/icons';

import { User } from "../../../interfaces/user.interface";
import UserAdminApi from "../../../api-services/user-admin-api.service";
import * as moment from "moment";

interface IAdminUsersPageProps {
}

interface IAdminUsersPageState {
  users: User[];
  pagination: any;
}


class AdminUsersPage extends React.Component <IAdminUsersPageProps, IAdminUsersPageState> {
  
  userApi = new UserAdminApi();

  componentDidMount(){
    this.setState({
      users: [],
      pagination: {
        current: 1,
        pageSize: 50,
        showSizeChanger: false
      }
    });

    setTimeout(() => {
      this.loadUsers(1);
    }, 500);

    
  }

  loadUsers = (page) => {
    this.userApi.getUsers(page, this.state.pagination.pageSize)
    .then(data => {
      const pagination = { ...this.state.pagination };
      pagination.current = page;
      pagination.total = data.total;
      this.setState({
        users: data.users,
        pagination: pagination
      });
    })
    .catch(error => {
      notification.error({ message: 'An error occured while loading the data for users', className: 'antd-mod center' });
    });
  }

  handleTableChange = (pagination) => {
    this.loadUsers(pagination.current)
  }

  exportCSV = () => {
    window.open('/admin/users/export.csv');
  }

  renderUserTable(){
    const columns = [
      {
        title: 'Email',
        dataIndex: 'email'
      },
      {
        title: 'Name',
        dataIndex: 'fullName'
      },
      {
        title: 'Phone',
        dataIndex: 'primaryPhoneNumber'
      },
      {
        title: 'Date Created',
        dataIndex: 'createdAt',
          render: (text) => {
          return <span>{moment(text).format('DD/MM/YYYY')}</span>
        }
      },
      {
        title: 'Role',
        dataIndex: ['weddingDetail', 'role']
      },
      {
        title: 'Partner Name',
        dataIndex: ['weddingDetail', 'partnerFullName']
      },
      {
        title: 'City',
        dataIndex: ['weddingDetail', 'cityName']
      },
      {
        title: 'Home City',
        dataIndex: ['weddingDetail', 'homeCityName']
      },
      {
        title: 'Budget',
        dataIndex: ['weddingDetail', 'budget']
      },
      {
        title: 'Guests',
        dataIndex: ['weddingDetail', 'guests']
      }
    ];
    return(
      <Table size="small" bordered rowKey="id"
        // rowSelection={{
        //   type: "checkbox",
        //   ...rowSelection,
        //   selectedRowKeys: this.props?.selectedBanners
        // }}
        columns={columns}
        dataSource={this.state?.users}
        pagination={this.state?.pagination}
        onChange={this.handleTableChange}

      />
    )
  }

  render() {
    return (
      <>
        <div className="AdminUsersPage">
          <div className="title">
            <div className="typo-title-2 typo-bold">Users</div>
            <div className="actions">
               <Button type="default" danger size="large" icon={<CloudDownloadOutlined />} onClick={()=>this.exportCSV()}>Export</Button>
            </div>
          </div>
          <div className="statistic">
            <Statistic title="Total Users" value={this.state?.pagination?.total} />
          </div>
          <div className="main-content">
            {this.renderUserTable()}
          </div>
        </div>
      </>
    );
  }
}

export default AdminUsersPage
