import axios from 'axios';
import { localeData } from 'moment';
import { Location } from '../interfaces/location.interface';


class LocationApi {

  getLocations(parentId?:number, perPage: number = 100): Promise<Location[]> {
    let apiUrl = `/api/v1/locations?per_page=${perPage}`;

    if(parentId) {
      apiUrl += `&parent_id=${parentId}`;
    }

    return axios.get(apiUrl)
    .then(response => {
      const locations: Location[] = [];

      response?.data?.data?.forEach(locationData => {
        locations.push({
          id: locationData?.id,
          name: locationData?.name,
          slug: locationData?.slug,
          children: locationData?.children,
          isLeaf: locationData?.children?.length > 0
        });
      });
      
      return Promise.resolve(locations);
    });
  }
  
}



export default LocationApi;