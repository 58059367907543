// import './BusinessContactDetailsForm.scss';

import * as React from 'react';
import { Button, Form, FormInstance, Input, notification, Skeleton } from 'antd';
import {startCase} from 'lodash';
import { ReactSVG } from 'react-svg';

import { Vendor } from '../../../interfaces/vendor-interface';
import VendorApi from '../../../api-services/vendor-api.service';

const facebook = require('@mdi/svg/svg/facebook.svg');
const instagram = require('@mdi/svg/svg/instagram.svg');
const youtube = require('@mdi/svg/svg/youtube.svg');
const tiktok = require('@mdi/svg/svg/music-note.svg');


interface BusinessContactDetailsFormProps {
  vendor: Vendor;
  onVendorUpdate: () => void;
}

interface BusinessContactDetailsFormState {
  saving: boolean;
  loading: boolean;
}

class BusinessContactDetailsForm extends React.Component <BusinessContactDetailsFormProps, BusinessContactDetailsFormState> {
  
  form = React.createRef<FormInstance>();

  componentDidMount(){
    this.setState({
      saving: false,
      loading: true,
    });
    
    this.populateContactForm();
  }


  componentDidUpdate(previousProps: BusinessContactDetailsFormProps) {
    if(this.props?.vendor !== previousProps.vendor) {
      this.populateContactForm();
    }
  }


  componentDidCatch(){
    this.setState({loading: false});
  }


  populateContactForm(){
    this.form.current!.setFieldsValue({
      contactPerson: this.props?.vendor?.contactPerson,
      contactPersonNumber: this.props?.vendor?.contactPersonNumber,
      whatsapp: this.props?.vendor?.whatsapp,
      email: this.props?.vendor?.email,
      website: this.props?.vendor?.website,
      facebook: this.props?.vendor?.facebook,
      instagram: this.props?.vendor?.instagram,
      youtube: this.props?.vendor?.youtube,
      tiktok: this.props?.vendor?.tiktok
    });

    this.setState({loading: false});
  }


  saveChanges(formEvent: any){
    this.setState({ saving: true });

    const vendorApi = new VendorApi;
    const payload = {
      contactPerson: formEvent.contactPerson,
      contactPersonNumber: formEvent.contactPersonNumber,
      whatsapp: formEvent.whatsapp,
      email: formEvent.email,
      website: formEvent.website,
      facebook: formEvent.facebook,
      instagram: formEvent.instagram,
      youtube: formEvent.youtube,
      tiktok: formEvent.tiktok,
    };

    vendorApi.saveContacts(payload)
    .then(response => {
      notification.success({ message: 'Changes saved successfully', className: 'antd-mod center' });
    })
    .catch(error => {
      console.error(error);

      const errorKeys = Object.keys(error?.response?.data?.errors);
      errorKeys.forEach(errorKeyName => {
        const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
        notification.error({ message: errorMessage, className: 'antd-mod center' });
      });
    })
    .finally(() => {
      this.props.onVendorUpdate();
      this.setState({ saving: false });
    });

  }

  
  render() {
    const websitePattern = /^((?:https|ftp)s?:\/\/)?(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::\d+)?(?:\/?|[\/?]\S+)$/i;

    return (
      <div className="BusinessContactDetailsForm">
        <Form ref={this.form} onFinish={(formEvent)=>this.saveChanges(formEvent)} layout="vertical" autoComplete="off">
          {
              this.state?.loading ? <Skeleton active /> : (
                <>
                  <Form.Item>
                    <Form.Item name="email" label="Your Email Address" style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0'  }}>
                      <Input size="large" disabled/>
                    </Form.Item>
                    <Form.Item name="website" label="Your Business Website " rules={[{ pattern: websitePattern, message: "Must be a valid website url"}, { required: true, message: 'Please fill in your business website.' }]}
                      style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px'  }}>
                      <Input size="large" prefix="https://" placeholder="e.g., www.abc.com"/>
                    </Form.Item>
                  </Form.Item>
                  
                  <Form.Item label="Social Media"> 
                    <Form.Item className="facebook" name="facebook" rules={[{ pattern: /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9(\.\?)?]/, message: "Must be a valid facebook url"}]} style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0'  }}>
                      <Input size="large" addonBefore={<ReactSVG src={facebook} />} placeholder="Enter Facebook link"/>
                    </Form.Item>
                    <Form.Item className="instagram" name="instagram" style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px'  }}>
                      <Input size="large" addonBefore={<ReactSVG src={instagram} />} placeholder="Enter Instagram link"/>
                    </Form.Item>
                    <Form.Item className="youtube" name="youtube" style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '8px 0'  }}>
                      <Input size="large" addonBefore={<ReactSVG src={youtube} />} placeholder="Enter Youtube link"/>
                    </Form.Item>
                    <Form.Item className="tiktok" name="tiktok" style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '8px'  }}>
                      <Input size="large" addonBefore={<ReactSVG src={tiktok} />} placeholder="Enter Tiktok link"/>
                    </Form.Item>
                  </Form.Item>

                  <div id="contact-person-header" className="ant-col ant-form-item-label">
                    <label title="Contact Person">Contact Person</label>
                    <small>Person-in-charge for administrative purpose. This name & contact will not published.</small>
                  </div>

                  <Form.Item>
                    <Form.Item name="contactPerson" label="Name" style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0'  }}>
                      <Input size="large" placeholder="Person in charge"/>
                    </Form.Item>
                    <Form.Item name="contactPersonNumber" label="Direct Contact Number" style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px'  }}>
                      <Input size="large"/>
                    </Form.Item>
                    <Form.Item name="whatsapp" label="Whatsapp Number" style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '10px 0'  }}>
                      <Input size="large"/>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" className="antd-mod" htmlType="submit" loading={this.state?.saving}>Save</Button>
                  </Form.Item>
                </>
              )
          }
        </Form>
      </div>
    );
  }
}

export default BusinessContactDetailsForm;
