// import './BreadcrumbsNavigation.scss';

import * as React from 'react';


interface BreadcrumbsNavigationItem {
  id?: any;  
  label: string;
  link?: string; 
}


interface BreadcrumbsNavigationProps {
  items: BreadcrumbsNavigationItem[];
}


interface BreadcrumbsNavigationState {

}




/**
 * NOTES:
 * - Add support for clikable links.
 * - Add support for link redirector via react routes because we will convert some parts into SPA.
 */
class BreadcrumbsNavigation extends React.Component<BreadcrumbsNavigationProps, BreadcrumbsNavigationState> {

  redirectToUrl(url: string): void {
    window.location.href = url;
  }
  
  
  renderNavItems() {
    const lastItemIndex: number = this.props?.items?.length - 1;
    
    return this.props?.items?.map((item, itemIndex) => {
      if(itemIndex === lastItemIndex) {
        return (
          <div className="navigation-item last-item" key={itemIndex}>
            <span className="label">{item.label}</span>
          </div>
        );
      }

      else {
        return (
          <div className="navigation-item" key={itemIndex} onClick={() => { this.redirectToUrl(item.link) }}>
            <span className="label">{item.label}</span>
            <span className="separator">/</span>
          </div>
        );
      }
    });
  }
  
  
  render() {
    return (
      <div className="BreadcrumbsNavigation">
        {this.renderNavItems()}
      </div>
    );
  }
  
}




export default BreadcrumbsNavigation;