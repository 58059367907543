// import './ProductSetupForm.scss';

import * as React from "react"
import {startCase} from "lodash"

import { AutoComplete, Button, Cascader, Col, DatePicker, Form, FormInstance, Input, InputNumber, Modal, notification, Popover, Radio, Row, Select, Space, Switch } from "antd";
import { PlusOutlined, DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons'; 

import { Country } from "../../../interfaces/country.interface";
import { ServiceCategory } from "../../../interfaces/service-category.interface";
import MultipleImageUploader from "../../ui-components/multiple-image-uploader/MultipleImageUploader";

import ReactQuill from 'react-quill';
import TextArea from "antd/lib/input/TextArea";
import { Product, ProductImage } from "../../../interfaces/product.interface";
import ProductApi from "../../../api-services/product-api.service";
import { City } from "../../../interfaces/city.interface";
import { Campaign } from "../../../interfaces/campaign.interface";
import * as moment from "moment";
import { Location } from "../../../interfaces/location.interface";
import { Calendar } from "../../../interfaces/calendar.interface";
import LocationApi from "../../../api-services/location-api.service";

const { RangePicker } = DatePicker;

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }]
  ],
}

interface IProductSetupFormProps {
  product?:Product;
  savedImages?:ProductImage[];
  categories: ServiceCategory[];
  countries?: Country[];
  locations?: Location[];
  cities?: City[];
  campaigns: Campaign[];
  calendars: Calendar[];
  primaryServiceCategory?:string;
  getLocations: (locationId) => void;
  saved?: () => void;
  readonly?: boolean;
}

interface IProductSetupFormState {
  loading: boolean;
  mode: "add" | "edit";
  disabled: boolean;
  savingProduct: boolean;
  publishingProduct: boolean;
  displayQuantityField: boolean;
  displayShippingFields: boolean;
  requirePartialPayment: boolean;
  displayUnitField: boolean;
  disablePartialPayment: boolean;
  disablePartialPaymentType: boolean;
  flexiReschedule: boolean;
  images: any[];
  videos: any[];
  productDetails: string;
  productDescription: string;
  termsAndCondition: string;
  paymentTerms: string;
  type: 'product' | 'package';
  variantOption:string;
  status?:string;
  disableSellingPeriod:boolean;
  submitAction?: "save" | "publish";
}

class ProductSetupForm extends React.Component <IProductSetupFormProps, IProductSetupFormState> {
  
  static defaultProps = { readonly: false};
  
  productForm = React.createRef<FormInstance>();
  productApi = new ProductApi;
  locationApi = new LocationApi;


  tailLayout = {
    wrapperCol: {
      offset: 6,
      span: 15
    }
  };

  statuses = [
    {id: "draft", label: "Draft"},
    {id: "published", label: "Published"},
    {id: "archived", label: "Archived"},
  ]

  componentDidMount(){
    this.setState({
      mode: "add",
      savingProduct: false,
      publishingProduct: false,
      loading: true,
      type: 'product',
      displayQuantityField: false,
      displayShippingFields: false,
      requirePartialPayment: false,
      displayUnitField: false,
      disablePartialPayment: false,
      disablePartialPaymentType: this.props?.primaryServiceCategory == "Wedding Venues",
      flexiReschedule: false,
      images: [],
      videos: [],
      productDetails: "",
      productDescription: "",
      termsAndCondition: "",
      paymentTerms: "",
      disabled: false,
      disableSellingPeriod: false,
      variantOption: ""
    });
  }


  componentDidUpdate(prevProps){
    if(prevProps.product !== this.props?.product){
      this.populateProductForm();
    }

    if(prevProps.campaigns !== this.props?.campaigns){
      if(this.props?.product){
        const campaign = this.props?.product.campaign || 3;
        this.handleCampaignChange(campaign);
      }
    }

    if(prevProps.savedImages !== this.props?.savedImages){
      this.setState({ images: this.props?.savedImages})
    }
  }


  populateProductForm(){
    const product = this.props?.product;
    this.productForm.current!.setFieldsValue({
      name: product.name,
      type: product.type,
      categories: product.subCategories,
      displayUnitField: (product.unit != "" && product.unit != null),
      unit: product.unit,
      minPerBooking: product.minPerBooking,
      flexiReschedule: product.easyReschedule,
      campaign: product.campaign || 3, //Wedding Deals Everyday
      sku: product.sku,
      paymentTerms: product.paymentTerms,
      quantity: product.quantity,
      price: product.price,
      discount: product.discountPercentage,
      partialPaymentType: product.partialPaymentType,
      partialPayment: product.partialPayment,
      sellingPeriod: product.availabilityStart != null ? [moment(product.availabilityStart), moment(product.availabilityEnd)] : null,
      trackQty: product.trackInventory,
      displayShippingFields: product.isPhysical,
      requirePartialPayment: product.requirePartialPayment,
      outstationFees: product.outstationFees,
      option: product.option,
      optionValues:product.optionValues,
      cutOffDays: product.cutOffDays,
      calendarId: product.calendarId,
      variants: product.variants,
      nonMuslim: product.nonMuslim,
      above21: product.above21,
      requiredServiceDate: product.requiredServiceDate,
      requiredServiceLocation: product.requiredServiceLocation,
    });
    
    if(product.discountPercentage){
      this.handleDiscountedPriceChange(product.discountPercentage);
    }


    this.setState({
      loading: false, 
      disabled: product.status != "draft" || this.props?.readonly,
      type: product.type, 
      productDetails: product.details, 
      productDescription: product.description, 
      termsAndCondition: product.terms,
      paymentTerms: product.paymentTerms,
      displayQuantityField: product.trackInventory,
      displayShippingFields: product.isPhysical,
      requirePartialPayment: product.requirePartialPayment,
      displayUnitField: (product.unit != "" && product.unit != null),
      variantOption: product.option,
      status: product.status
    });

    this.changeType(product.type);

  }


  
  addToImages(image){
    this.setState(prevState => ({
      images: [...prevState.images, image]
    }));
  }

  removeFromImages(imageId){
    const productApi = new ProductApi;

    productApi.deleteImages(this.props?.product.id, imageId)
      .then(response => {
        this.setState(prevState => ({
          images: prevState.images.filter(image => image.id !== imageId)
        }));
      })
      .catch(err=>{
        notification.error({ message: 'Error while removing photo.', className: 'antd-mod center' });
      });
  }


  mapPayload(){
    const form = this.productForm?.current?.getFieldsValue();
    var payload = {
      name: form.name,
      type: form.type,
      details: this.state.productDetails,
      description: this.state.productDescription,
      terms: this.state.termsAndCondition,
      paymentTerms: this.state.paymentTerms,
      price: form.price,
      discountedPrice: form.discount,
      sku: form.sku,
      trackInventory: this.state.displayQuantityField,
      isPhysical: this.state.displayShippingFields,
      requirePartialPayment: this.state.requirePartialPayment,
      flexiReschedule: form.flexiReschedule,
      campaign: form.campaign,
      categories: form.categories,
      partialPaymentType: form.partialPaymentType,
      partialPayment: form.partialPayment,
      option: form.option,
      unit: form.unit,
      minPerBooking: form.minPerBooking,
      cutOffDays: form.cutOffDays,
      calendarId: form.calendarId,
      variants: form.variants,
      publish: false,
      nonMuslim: form.nonMuslim,
      above21: form.above21,
      requiredServiceDate: form.requiredServiceDate,
      requiredServiceLocation: form.requiredServiceLocation,
    }

    if(this.state?.displayUnitField){
      payload['unit'] = form.unit;
      payload['minPerBooking'] = form.minPerBooking;
    } else {
      payload['unit'] = "";
      payload['minPerBooking'] = 1;
    }

    if(this.state?.displayQuantityField){
      payload["quantity"] = form.quantity
    }

    if(form.sellingPeriod){
      payload["sellingPeriodStart"] = form.sellingPeriod[0].utc().format('DD/MM/YYYY HH:mm:ss');
      payload["sellingPeriodEnd"] = form.sellingPeriod[1].utc().format('DD/MM/YYYY HH:mm:ss');
    }


    if(form.outstationFees?.length>0){
      payload["outstationFees"] = form.outstationFees?.map(o => {
        var locationId = o.location[o.location.length-1];
        return {locationId: locationId, fee: o.fee}
      })
    }

    if(this.state?.type == "product" || (this.state?.type == "package" && this.props?.primaryServiceCategory != "Wedding Venues")){
      payload.partialPaymentType = "percentage";
    }

    return payload;
  }


  save(){
    const payload = this.mapPayload();
    this.setState({savingProduct: true});

    this.productApi.updateProduct(this.props?.product?.id, payload)
      .then(productResponse => {
        notification.success({ message: 'Changes saved successfully', className: 'antd-mod center' });
      })
      .catch(error => {
        const errorKeys = Object.keys(error?.response?.data?.errors);
        errorKeys.forEach(errorKeyName => {
          const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
          notification.error({ message: errorMessage, className: 'antd-mod center' });
        });
      })
      .finally(()=>{
        this.setState({savingProduct: false})
      })
  }



  showPublishConfirmationMessage(): void {
    this.setState({publishingProduct: true});
    Modal.confirm({
      content: (
        <div>
          <p>Thank you for your submission. Your Wedding Deal will be reviewed and approved within 2 working days. Once approved, it will automatically publish except for Event365 campaigns.</p>
          <p>Please ensure that you have checked the price, package information, terms & conditions, payment terms and all other details before publishing. Once published, you are NOT ALLOWED TO EDIT again.</p>
        </div>
      ),
      okText: "Yes, Proceed to Publish",
      cancelText: "Back",
      onOk: () => {
        // this.publish();
        this.saveAndPublish();
      },
      onCancel: () => {
        this.setState({publishingProduct: false});
      }
    });
  }


  publish(){
    this.productApi.publishProduct(this.props?.product?.id)
      .then(success => {
        Modal.success({
          content: (
            <div>
              <p>Your Bridepay Deal has been sent for review. Once approved, it will automatically be published.</p>
            </div>
          ),
          onOk: () => {
            this.setState({disabled: true, status: "publish"});
          }
        })
      })
      .catch(error => {
        const errorKeys = Object.keys(error?.response?.data?.errors);
        errorKeys.forEach(errorKeyName => {
          const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
          notification.error({ message: errorMessage, className: 'antd-mod center' });
        });
      })
      .finally(()=>{
        this.setState({publishingProduct: false})
      })
  }
  saveAndPublish(){
    const payload = this.mapPayload();
    payload.publish = true;    
    this.setState({savingProduct: true});

    this.productApi.updateProduct(this.props?.product?.id, payload)
      .then(productResponse => {
        notification.success({ message: 'Changes saved successfully', className: 'antd-mod center' });
        Modal.success({
          content: (
            <div>
              <p>Your Bridepay Deal has been sent for review. Once approved, it will automatically be published.</p>
            </div>
          ),
          onOk: () => {
            this.props?.saved();
            // this.setState({disabled: true, status: "publish"});
          }
        })
      })
      .catch(error => {
        const errorKeys = Object.keys(error?.response?.data?.errors);
        errorKeys.forEach(errorKeyName => {
          const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
          notification.error({ message: errorMessage, className: 'antd-mod center' });
        });
      })
      .finally(()=>{
        this.setState({publishingProduct: false, savingProduct: false})
      })    
  }

  archive(){
    this.productApi.archiveProduct(this.props?.product?.id)
      .then(success => {
        this.props?.saved();
      })
      .catch(error => {
        const errorKeys = Object.keys(error?.response?.data?.errors);
        errorKeys.forEach(errorKeyName => {
          const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
          notification.error({ message: errorMessage, className: 'antd-mod center' });
        });
      })
  }


  handleChange(name, value) {
    var obj = {};
    obj[name] = value;

    this.setState(obj);
  }


  changeType(newType){
    const form = this.productForm.current;
    if(newType == 'package'){
      //!this.state?.disablePartialPaymentType or category != Wedding Venues
      if(this.props?.primaryServiceCategory != "Wedding Venues"){
        form.setFieldsValue({
          requirePartialPayment: true,
          partialPaymentType: "percentage",
          partialPayment: 50
        });
      }
      // this.setState({requirePartialPayment: true, disablePartialPayment: true})
    }
    // else{
    //   form.setFieldsValue({
    //     partialPaymentType: "fixed",
    //   });
    // }
    this.setState({type: newType});
  }


  renderLabelWithInfo(id){
    const list = {
      "category": {
        label: "Sub Category",
        info: "Classify your product/package to the correct subcategory so it can easily be searched by customers."
      },
      "product": {
        label: "Product",
        info: "Single item product. You can choose to charge 100% or 50% payment through Bridepay."
      },
      "package": {
        label: "Package",
        info: "Services or a mix of products & services. You can only charge 50% payment through Bridepay. \nSubsequent payments are collected at your end based on your payment terms."
      },
      "photos": {
        label: "Upload Photos",
        info: "Upload accurate photos that represent this product or package. Do not mislead customers. We recommend a minimum of 6 photos."
      },
      "terms": {
        label: "Terms & Conditions",
        info: "Please ensure you inform customers about your terms & conditions to avoid future disputes."
      },
      "unit": {
        label: "Measurement Unit (Optional)",
        info: "Indicate your minimum quantity required per booking. E.g. 30 tables, 50 pax, 100 pcs."
      },
      "minPerBooking": {
        label: "Min Quantity Per Booking",
        info: "Indicate your minimum quantity required per booking.\nE.g; 30 tables, 50 pax, 100 pcs.\n\nEg;\nPackage Name: Wedding Dinner Package for 30 tables\nMeasurement Unit: per table\nMin Quantity Per Booking: 30"
      },
      "paymentTerms": {
        label: "Payment Terms",
        info: "Please ensure you inform customers about your payment terms to avoid future disputes."
      },
      "sku": {
        label: "SKU (Stock Keeping Unit)",
        info: "Only if applicable, SKU is a unique number (e.g. barcode number) used by businesses to internally track their inventory and sales."
      },
      "trackQty": {
        label: "Track Quantity",
        info: "If you have limited quantity of this product or package, please ensure you track quantity to ensure you do not oversell."
      },
      "easyReschedule": {
        label: "Easy Reschedule",
        info: "If you allow customers to reschedule your service deliverable date, please click this."
      },
      "outstationFees": {
        label: "Outstation Fees (Optional)",
        info: "If you have additional charges for outstation or overseas weddings, please input the details."
      },
      "required_date":{
        label: "Required Service Date",
        info: "Require customer to fix a delivery/service date"
      },
      "required_location":{
        label: "Required Service Location",
        info: "Require customer to fix a delivery/service location"
      },
      "variations": {
        label: "Variations",
        info: "Variations are different options within the same listing. Variations allow customers to make their selection based on different attributes including sizes, colours, or other characteristics from the same product."
      },
      "payment": {
        label: "Payment Collection",
        info: "For products, you can set your payment collection at 50% or 100% depending on the nature of your product."
      }
    };

    if(this.props?.primaryServiceCategory == "Wedding Venues"){
      list["package"].info = "Applicable for wedding packages or venue rentals. \nYou can set your booking deposit payment at any amount (Eg; RM5,000 or RM10,000). Subsequent payments are collected at your end based on your payment terms.";
    }

    if(this.state?.type == 'package'){
      if(this.props?.primaryServiceCategory == "Wedding Venues"){
        list["payment"].info = "You can set your booking deposit payment at any amount (Eg; RM5,000 or RM10,000). Subsequent payments are collected at your end based on your payment terms.";
      } else {
        list["payment"].info = "For services, you are only allowed to set your deposit collection at 50% to secure the event date. The remaining balance will be collected directly by vendor according to your payment terms.";        
      }
    }

    const field = list[id];

    return (
      <>
        {field.label} &nbsp;
        <Popover content={field.info} placement="topLeft">
          <InfoCircleOutlined />
        </Popover>
      </>
    )
  }


  renderDetails(){
    return (
      <section>
        <div className="typo-subheading-2 typo-bold section-header">ADD A NEW PRODUCT/PACKAGE</div>
          <Form.Item name="name" label="Product/Package Name" rules={[{ required: true, message: 'Name is required' }]}>
            <TextArea disabled={this.state?.disabled} size="large" maxLength={120} showCount={true} rows={1}/>
          </Form.Item>
          <Form.Item name="categories" label={this.renderLabelWithInfo("category")} 
            rules={[{ required: true, message: 'Please choose at least one category' }]}>
            <Select mode="multiple" disabled={this.state?.disabled} size="large" allowClear showSearch optionFilterProp="children"
                filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {this.props?.categories?.map(category => {
                  return <Select.Option key={category.id} value={category.id}>{category.name}</Select.Option>
                })}
            </Select> 
          </Form.Item>
          <Form.Item name="type" label="Type">
            <Radio.Group disabled={this.state?.disabled} onChange={(e)=>this.changeType(e.target.value)}>
              <Space direction="vertical">
              <Radio value="product">{this.renderLabelWithInfo("product")}</Radio>
              <Radio value="package">{this.renderLabelWithInfo("package")}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
      </section>
    );
  }


  getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
   return e && e.fileList;
  };


  renderInformation(){
    const type = this.state?.type;
    return (
      <section>
        <div className="typo-subheading-2 typo-bold section-header">INFORMATION</div>
          <Form.Item name="photos" label={this.renderLabelWithInfo("photos")} getValueFromEvent={this.getFile}
          rules={[{ required: this.state?.images.length == 0, message: 'Please upload at least one product photo' }]}>
            <MultipleImageUploader disabled={this.state?.disabled} uploadImageApi={`/api/v1/products/my/${this.props?.product?.id}/images`} savedImages={this.state?.images} 
                addImage={(imageResponse)=>this.addToImages(imageResponse)} removeImage={(imageId)=>this.removeFromImages(imageId)}/>
          </Form.Item>
          <Form.Item name="details" label={`${type} Details`}>
            <div className="text-editor">
              <ReactQuill readOnly={this.state?.disabled} value={this.state?.productDetails} onChange={(val)=>this.handleChange("productDetails", val)} theme="snow" modules={modules}/>
            </div>
          </Form.Item>
          {/* <Form.Item name="description" label={`${type} Description`}>
            <div className="text-editor">
              <ReactQuill readOnly={this.state?.disabled} value={this.state?.productDescription} onChange={(val)=>this.handleChange("productDescription", val)} theme="snow" modules={modules}/>
            </div>
          </Form.Item> */}
          <Form.Item name="terms" label={this.renderLabelWithInfo("terms")}>
            <div className="text-editor">
              <ReactQuill readOnly={this.state?.disabled} value={this.state?.termsAndCondition} onChange={(val)=>this.handleChange("termsAndCondition", val)} theme="snow" modules={modules}/>
            </div>
          </Form.Item>
      </section>
    );
  }

  renderMedia(){
    return (
      <section>
        <div className="typo-subheading-2 typo-bold section-header">MEDIA</div>
        <Form.Item name="photos" label="Upload Photos">
          <MultipleImageUploader uploadImageApi={`/api/v1/products/my/${this.props?.product?.id}/images`} savedImages={this.state?.images} 
              addImage={(imageResponse)=>this.addToImages(imageResponse)} removeImage={(imageId)=>this.removeFromImages(imageId)}/>
        </Form.Item>
      </section>
    );
  }



  resetPricing(price){
    const discountedPrice = this.productForm.current.getFieldValue("discountedPrice");
    const savingPercentage = this.productForm.current.getFieldValue("savingPercentage");
    if(price == null){
      this.productForm.current.setFieldsValue({
        discount: 0,
        savingAmount: 0,
        savingPercentage: null,
      })
    }else{
      if(discountedPrice){
        this.handleDiscountedPriceChange(discountedPrice);
      }else if(savingPercentage){
        this.handleDiscountPercentageChange(savingPercentage);
      }
    }
  }


  handleDiscountedPriceChange(discountedPrice){
    const price = this.productForm.current.getFieldValue("price");
    if(price){
      var savedAmount = this.computeSavingAmount(price, discountedPrice);
      this.productForm.current.setFieldsValue({
        savingAmount: savedAmount,
        savingPercentage: this.computeSavingPercentage(price, savedAmount)
      })
    }
  }


  handleSavedAmountChange(savedAmount){
    const price = this.productForm.current.getFieldValue("price");
    if(price){
      this.productForm.current.setFieldsValue({
        discount: price - savedAmount,
        savingPercentage: this.computeSavingPercentage(price, savedAmount)
      })
    }
  }


  handleDiscountPercentageChange(discountPercentage){
    const price = this.productForm.current.getFieldValue("price");
    if(price){
      var discountedPrice = this.computeDiscountedPrice(price, discountPercentage);
      this.productForm.current.setFieldsValue({
        discount: discountedPrice,
        savingAmount: this.computeSavingAmount(price, discountedPrice)
      })
    }
  }


  computeSavingPercentage(price, savedAmount){
    return (savedAmount/price*100).toFixed(2);
  }


  computeSavingAmount(price, discountedPrice){
    return (price - discountedPrice).toFixed(2);
  }


  computeDiscountedPrice(price, discountPercentage){
    return (price - (price * discountPercentage / 100 )).toFixed(2);
  }

  handleRequirePartialPaymentChange(requirePartialPay){
    const form = this.productForm.current;
    if(this.state?.type == 'product'  && !this.state?.disablePartialPaymentType){
      form.setFieldsValue({
        partialPaymentType: "percentage",
        partialPayment: 50
      });

      this.setState({disablePartialPayment: true})
    }else{
      form.setFieldsValue({
        partialPaymentType: "fixed",
      });
    }

    this.setState({requirePartialPayment: requirePartialPay})
  }

  renderPricing(){
    const disableRequirePartialPayment = this.state?.disabled || (this.state?.type == 'package' && !this.state?.disablePartialPaymentType);

    return (
      <section>
        <div className="typo-subheading-2 typo-bold section-header">PRICING INPUT</div>
          <Form.Item name="price" label="Normal Price" rules={[{ required: true, message: 'Price is required' }]}>
            <InputNumber disabled={this.state?.disabled} size="large" placeholder="0.00" onChange={(val)=>this.resetPricing(val)}
              formatter={value => `RM ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\RM\s?|(,*)/g, '')}
            />
          </Form.Item>
          
          <Form.Item name="discount" label="Discounted Price" rules={[{ required: true, message: 'Discounted Price is required' }]}>
            <InputNumber disabled={this.state?.disabled} size="large" placeholder="0.00"onChange={(val)=>this.handleDiscountedPriceChange(val)}
              formatter={value => `RM ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\RM\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item name="savingPercentage" label="Discount %">
            <InputNumber disabled={this.state?.disabled} size="large" onChange={(val)=>this.handleDiscountPercentageChange(val)}/>
          </Form.Item>
          <Form.Item name="savingAmount" label="Discount Amount">
            <InputNumber disabled={this.state?.disabled} size="large" placeholder="0.00" onChange={(val)=>this.handleSavedAmountChange(val)}
              formatter={value => `RM ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\RM\s?|(,*)/g, '')}/>
          </Form.Item>
          <Form.Item valuePropName="checked" name="displayUnitField" label={this.renderLabelWithInfo("unit")}>
            <Switch disabled={this.state?.disabled} defaultChecked={false} checked={this.state?.displayUnitField} onChange={(checked)=>{this.setState({displayUnitField: checked})}}/>
          </Form.Item>
          {
            this.state?.displayUnitField && (
              <Form.Item name="unit" label="Per">
                <Select disabled={this.state?.disabled} size="large">
                  <Select.Option value="Bottle">Bottle</Select.Option>
                  <Select.Option value="Day">Day</Select.Option>
                  <Select.Option value="Hour">Hour</Select.Option>
                  <Select.Option value="Package">Package</Select.Option>
                  <Select.Option value="Pax">Pax</Select.Option>
                  <Select.Option value="Piece">Piece</Select.Option>
                  <Select.Option value="Session">Session</Select.Option>
                  <Select.Option value="Set">Set</Select.Option>
                  <Select.Option value="Table">Table</Select.Option>
                </Select>
              </Form.Item>
            )
          }
          {
            this.state?.displayUnitField && (
              <Form.Item name="minPerBooking" label={this.renderLabelWithInfo("minPerBooking")}>
                <InputNumber disabled={this.state?.disabled} size="large" min={1} />
              </Form.Item>
            )
          }
          <Form.Item valuePropName="checked" name="requirePartialPayment" label={this.renderLabelWithInfo("payment")}>
            <Switch defaultChecked={false} disabled={disableRequirePartialPayment} checked={this.state?.requirePartialPayment} onChange={(checked)=>{this.handleRequirePartialPaymentChange(checked)}}/>
          </Form.Item>
          {
            this.state?.requirePartialPayment && (
              <>
                
                {
                  (this.state?.type == "product" ) && (
                    <Form.Item label=" ">
                      <Form.Item name="partialPayment" rules={[{ required: true, message: 'Payment Collection is required' }]}>
                          <Radio.Group>
                            <Radio value={50}>50%</Radio>
                            <Radio value={100}>100%</Radio>
                          </Radio.Group>
                      </Form.Item>
                    </Form.Item>
                  )
                }
                {
                  ((this.state?.type == "package" && this.props?.primaryServiceCategory != "Wedding Venues")) && (
                    <Form.Item label=" ">
                      <Form.Item name="partialPayment" rules={[{ required: true, message: 'Payment Collection is required' }]}>
                          <Radio.Group>
                            <Radio value={50}>50%</Radio>
                          </Radio.Group>
                      </Form.Item>
                    </Form.Item>
                  )
                }
                {
                  (this.state?.type == "package" && this.props?.primaryServiceCategory == "Wedding Venues") && (
                    <Form.Item label="Deposit">
                      <Space>
                        <Form.Item name="partialPaymentType" style={{width: "70px"}}>
                          <Select  disabled={this.state?.disabled || this.state?.disablePartialPayment} size="large">
                            <Select.Option value="fixed">RM</Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="partialPayment" rules={[{ required: true, message: 'Payment Collection is required' }]}>
                          <InputNumber disabled={this.state?.disabled || this.state?.disablePartialPayment} size="large" placeholder="0.00"
                            formatter={value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/(,*)/g, '')}
                          />
                        </Form.Item>
                      </Space>
                     </Form.Item>
                  )
                }
                
              </>
            )
          }
          
          <Form.Item name="terms" label={this.renderLabelWithInfo("paymentTerms")}>
            <div className="text-editor">
              <ReactQuill readOnly={this.state?.disabled} value={this.state?.paymentTerms} onChange={(val)=>this.handleChange("paymentTerms", val)} theme="snow" modules={modules}/>
            </div>
          </Form.Item>
      </section>
    );
  }

  renderAvailability(){
    return (
      <section>
        <div className="typo-subheading-2 typo-bold section-header">AVAILABILITY (Optional)</div>
          <Form.Item name="calendarId" label="Booking Calendar">
            {/* Temporary disabled the Booking Calendar until the feature is done */}
            <Select disabled={this.state?.disabled || true} size="large" allowClear showSearch optionFilterProp="children"
                filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {this.props?.calendars?.map(calendar => {
                  return <Select.Option key={calendar.id} value={calendar.id}>{calendar.name}</Select.Option>
                })}
            </Select>
          </Form.Item>
          <Form.Item name="sku" label={this.renderLabelWithInfo("sku")}>
            <Input disabled={this.state?.disabled} size="large"/>
          </Form.Item>
          <Form.Item valuePropName="checked" name="trackQty" label={this.renderLabelWithInfo("trackQty")}>
            <Switch disabled={this.state?.disabled} defaultChecked={false} checked={this.state?.displayQuantityField} onChange={(checked)=>{this.setState({displayQuantityField: checked})}}/>
          </Form.Item>
          {
            this.state?.displayQuantityField && (
              <Form.Item name="quantity" label="Quantity">
                <Input type="number" size="large"/>
              </Form.Item>
            )
          }
          <Form.Item valuePropName="checked" name="flexiReschedule"  label={this.renderLabelWithInfo("easyReschedule")}>
            <Switch disabled={this.state?.disabled} defaultChecked={false} checked={this.state?.flexiReschedule} onChange={(checked)=>{this.setState({flexiReschedule: checked})}}/>
          </Form.Item>
      </section>
    );
  }



  renderOutstationFees(){
    return (
      <section>
        <div className="typo-subheading-2 typo-bold section-header">{this.renderLabelWithInfo("outstationFees")}</div>
        <div className="ant-col ant-col-15 ant-col-offset-6 variants">
          <Form.List name="outstationFees">
            {(fields, { add, remove }) => (
              <>
                {fields.map(field => (
                    <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.location !== curValues.location || prevValues.fee !== curValues.fee
                        }
                      >
                        {() => (
                          <Form.Item
                            {...field}
                            {...{wrapperCol:{span: 24}}}
                            label=""
                            name={[field.name, 'location']}
                            fieldKey={[field.fieldKey, 'location']}
                            rules={[{ required: true, message: 'Location required' }]}
                          >
                            <Cascader disabled={this.state?.disabled} size="large" 
                              fieldNames={{ label: 'name', value: 'id' }}
                              options={this.props?.locations} placeholder="Select location" changeOnSelect />
                          </Form.Item>
                        )}
                      </Form.Item>
                      <Form.Item
                        {...field}
                        {...{wrapperCol:{span: 24}}}
                        label=""
                        name={[field.name, 'fee']}
                        fieldKey={[field.fieldKey, 'fee']}
                        rules={[{ required: true, message: 'Price required' }]}
                      >
                        <InputNumber disabled={this.state?.disabled} size="large" placeholder="0.00"
                            formatter={value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/(,*)/g, '')}
                          />
                      </Form.Item>
                      <Form.Item {...{wrapperCol:{span: 2}}}>
                        <DeleteOutlined disabled={this.state?.disabled} className="remove-field" onClick={() => remove(field.name)} />
                      </Form.Item>
                    </Space>
                ))}

                <div className="add-location-btn">
                  <Form.Item {...{wrapperCol:{span: 24}}}>
                    {/* Temporary disabled the Outstation until the location change to country/state/city */}
                    <Button disabled={this.state?.disabled || true} size="large" type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add Location & Price 
                    </Button>
                  </Form.Item>
                </div>
              </>
            )}
          </Form.List>
        </div>
                  
      </section>
    );
  }


  renderServices(){
    return (
      <section>
        {/* <div className="typo-subheading-2 typo-bold section-header">VARIANTS</div> */}
        <Form.Item valuePropName="checked" name="requiredServiceDate" label={this.renderLabelWithInfo("required_date")}>
          <Switch disabled={this.state?.disabled} defaultChecked={false}/>
        </Form.Item>
        <Form.Item valuePropName="checked" name="requiredServiceLocation" label={this.renderLabelWithInfo("required_location")}>
          <Switch disabled={this.state?.disabled} defaultChecked={false}/>
        </Form.Item>
      </section>
    )
  }

  handleCampaignChange(selected){
    const campaign = this.props?.campaigns.find(campaign => campaign.id == selected);
    this.setState({disableSellingPeriod: !campaign?.allowProductSellingPeriod});
  }


  renderSellingPeriod(){
    return (
      <section>
        <div className="typo-subheading-2 typo-bold section-header">SELLING PERIOD</div>
          <Form.Item name="campaign" label="Campaign" rules={[{ required: true, message: 'Campaign is required' }]}>
            <Select disabled={this.state?.disabled} size="large" allowClear showSearch optionFilterProp="children"
                filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(selected, option)=>this.handleCampaignChange(selected)}>
                {this.props?.campaigns?.map(campaign => {
                  return (
                    <Select.Option key={campaign.id} value={campaign.id}>
                      {campaign.title}
                      {campaign.campaignPeriodStart != null && ` (${campaign.campaignPeriodStart.format('DD/MM/YYYY')} - ${campaign.campaignPeriodEnd.format('DD/MM/YYYY')})`}
                    </Select.Option>
                  ) 
                })}
            </Select>
          </Form.Item>
          <Form.Item name="sellingPeriod" label="Selling Period" rules={[{ required: !this.state?.disableSellingPeriod, message: 'Selling Period is required' }]}>
            <RangePicker showTime disabled={this.state?.disabled || this.state?.disableSellingPeriod} size="large" showNow={true}/>
          </Form.Item>
      </section>
    );
  }

  updateVariantOption(val){
    this.setState({variantOption: val})
  }

  updateVariantsTable(optionValues){
    const formValues = this.productForm?.current?.getFieldsValue();
    const variants = optionValues.map(optionVal => {
        var variant = formValues.variants.find(v => v.option == optionVal);
        if(variant == undefined){
          variant = {
            id: null,
            option: optionVal,
            details: "",
            price: formValues.price,
            discountedPrice: formValues.discount,
            savingAmount: formValues.savingAmount,
            sku: null,
            stock: null,
            trackInventory: formValues.trackQty,
          };
        }

        return variant;
      });

    this.productForm.current!.setFieldsValue({
      variants: variants
    });
  }

  renderVariants(){
    const optionTypes = [
      {value: "Size"}, {value: "Color"}, {value: "Material"}, {value: "Performance"}, {value: "Live Band"}, {value: "Singers"}
    ];

    return (
      <section id="variants">
        <div className="typo-subheading-2 typo-bold section-header">{this.renderLabelWithInfo("variations")}</div>
          <Form.Item label="Option" name="option">
            {/* Temporary disabled the Variations */}
            <AutoComplete disabled={this.state?.disabled || true} options={optionTypes} onSelect={(value)=>this.updateVariantOption(value)}
              filterOption={(inputValue, option) =>
                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }>
              <Input size="large" placeholder="Select or enter variant type" onChange={(e)=>this.updateVariantOption(e.target.value)}/>
            </AutoComplete>
          </Form.Item>
          
          <div className="variants">
            { this.renderVariantsTable() }
          </div>
      </section>
    );
  }


  resetVariantPricing(idx, price){
    const variants = this.productForm.current.getFieldValue("variants"),
          selectedVariant = variants[idx];
          
    if(price){
      if(selectedVariant.discountedPrice){
        selectedVariant.savingAmount =  this.computeSavingAmount(price, selectedVariant.discountedPrice);
      }
      if(selectedVariant.savingAmount){
        selectedVariant.discountedPrice =  this.computeSavingAmount(price, selectedVariant.savingAmount);
      }
    }else{
      selectedVariant.savingAmount = 0;
      selectedVariant.discountedPrice = 0;
    }
    this.productForm.current.setFieldsValue({
      variants: variants
    })
  }


  handleVariantDiscountedPriceChange(idx, discountedPrice){
    const variants = this.productForm.current.getFieldValue("variants"),
          selectedVariant = variants[idx];
          
    if(selectedVariant.price){
      selectedVariant.savingAmount =  this.computeSavingAmount(selectedVariant.price, discountedPrice);
      this.productForm.current.setFieldsValue({
        variants: variants
      })
    }
  }


  handleVariantSavingAmountChange(idx, savedAmount){
    const variants = this.productForm.current.getFieldValue("variants"),
          selectedVariant = variants[idx];
          
    if(selectedVariant.price){
      selectedVariant.discountedPrice =  this.computeSavingAmount(selectedVariant.price, savedAmount);
      this.productForm.current.setFieldsValue({
        variants: variants
      })
    }
  }

  removeVariant(field, cb){
    const variants = this.productForm.current.getFieldValue("variants"),
        variant = variants[field.key];

    if(variant && variant.id){
      this.productApi.deleteVariant(this.props?.product.id, variant.id)
        .then(success => {
          cb(field.name);
        })
    }else{
      cb(field.name);
    }
  }

  renderVariantsTable(){
    return (
      <Form.List name="variants">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, idx) => (
                    <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="start">
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.option !== curValues.option
                        }
                      >
                        {() => (
                          <Form.Item
                            {...field}
                            {...{wrapperCol:{span: 24}}}
                            label={ idx == 0 ? (this.state?.variantOption || " "): ''}
                            name={[field.name, 'option']}
                            fieldKey={[field.fieldKey, 'option']}
                          >
                            <Input disabled={this.state?.disabled}/>
                          </Form.Item>
                        )}
                      </Form.Item>
                      <Form.Item
                        {...field}
                        {...{wrapperCol:{span: 24}}}
                        label={ idx == 0 ? 'Normal Price': ''}
                        name={[field.name, 'price']}
                        fieldKey={[field.fieldKey, 'price']}
                      >
                        <Input disabled={this.state?.disabled} onChange={(e)=>this.resetVariantPricing(idx,e.target.value)}/>
                      </Form.Item>
                      <Form.Item
                        {...field}
                        {...{wrapperCol:{span: 24}}}
                        label={ idx == 0 ? 'Price Aft Discount': ''}
                        name={[field.name, 'discountedPrice']}
                        fieldKey={[field.fieldKey, 'discountedPrice']}
                      >
                        <Input disabled={this.state?.disabled} onChange={(e)=>this.handleVariantDiscountedPriceChange(idx,e.target.value)}/>
                      </Form.Item>
                      <Form.Item
                        {...field}
                        {...{wrapperCol:{span: 24}}}
                        label={ idx == 0 ? 'Discount Amount': ''}
                        name={[field.name, 'savingAmount']}
                        fieldKey={[field.fieldKey, 'savingAmount']}
                      >
                        <Input disabled={this.state?.disabled} onChange={(e)=>this.handleVariantSavingAmountChange(idx,e.target.value)}/>
                      </Form.Item>
                      <Form.Item
                        {...field}
                        {...{wrapperCol:{span: 24}}}
                        label={ idx == 0 ? 'Stock': ''}
                        name={[field.name, 'stock']}
                        fieldKey={[field.fieldKey, 'stock']}
                      >
                        <Input disabled={this.state?.disabled} />
                      </Form.Item>
                      <Form.Item 
                        {...field}
                        {...{wrapperCol:{span: 24}}}
                        label={ idx == 0 ? 'Booking Calendar': ''}
                        name={[field.name, 'calendarId']}
                        fieldKey={[field.fieldKey, 'calendarId']}>
                        <Select disabled={this.state?.disabled} allowClear showSearch optionFilterProp="children"
                            filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {this.props?.calendars?.map(calendar => {
                              return <Select.Option key={calendar.id} value={calendar.id}>{calendar.name}</Select.Option>
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item {...{wrapperCol:{span: 2}}} style={idx==0 ? {marginTop: 42} : {}}>
                        <DeleteOutlined disabled={this.state?.disabled} className="remove-field" onClick={() => {this.removeVariant(field, remove)}} />
                      </Form.Item>
                    </Space>
                ))}
                <div className="add-location-btn">
                  <Form.Item {...{wrapperCol:{span: 24}}}>
                    {/* Temporary disabled the Variations */}
                    <Button disabled={this.state?.disabled || true} size="large" type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add Variant
                    </Button>
                  </Form.Item>
                </div>
              </>
            )}
          </Form.List>
    )
  }


  renderConsents(){
    return (
      <section>
        {/* <div className="typo-subheading-2 typo-bold section-header">VARIANTS</div> */}
        <Form.Item valuePropName="checked" name="nonMuslim" label="Non-Muslim">
          <Switch disabled={this.state?.disabled} defaultChecked={false}/>
        </Form.Item>
        <Form.Item valuePropName="checked" name="above21" label="Above 21">
          <Switch disabled={this.state?.disabled} defaultChecked={false}/>
        </Form.Item>
      </section>
    )
  }


  submitForm(){
    if(this.state?.submitAction == "save"){
      this.save();
    }else{
      this.showPublishConfirmationMessage();
    }
  }


  render() {
    const displayNone = this.props?.readonly ? {display:'none'}: {};

    return (
      <div className="ProductSetupForm">
        <Form ref={this.productForm} labelCol={{ span: 6 }} wrapperCol={{ span: 15 }}  onFinish={()=>this.submitForm()} autoComplete="off" scrollToFirstError={true}>
          <div className="form-content product-form">
            {this.renderSellingPeriod()}
            {this.renderDetails()}
            {this.renderInformation()}
            {/* {this.renderMedia()} */}
            {this.renderPricing()}
            {this.renderAvailability()}
            {this.renderOutstationFees()}
            {/*this.renderServices()*/}
            {this.renderVariants()}
            {/* {this.renderConsents()} */}
          </div>

          <div className="form-actions" style={displayNone}>
            <Space>
              {
                this.props?.product?.mayArchive ? (
                  <>
                    <Button type="primary" size="large" className="antd-mod" href={`/products/${this.props?.product?.id}`} target="_blank">
                      Preview
                    </Button>
                    <Button type="primary" className="antd-mod" size="large" onClick={()=>this.archive()}>
                      Archive
                    </Button>
                  </>
                ) : (
                  <>
                    <Button type="primary" size="large" loading={this.state?.savingProduct} disabled={this.state?.status != 'draft'} className="antd-mod" htmlType="submit" onClick={()=>this.setState({submitAction: "save"})}>
                      Save
                    </Button>
                    <Button type="primary" size="large" className="antd-mod" href={`/products/${this.props?.product?.id}`} target="_blank">
                      Preview
                    </Button>
                    <Button type="primary" size="large" loading={this.state?.publishingProduct} disabled={!this.props?.product?.mayPublish} className="antd-mod" htmlType="submit"  onClick={()=>this.setState({submitAction: "publish"})}>
                      Publish
                    </Button>
                  </>
                )
              }
            </Space>
          </div>
        </Form>
      </div>
    );
  }
}

export default ProductSetupForm
