import * as React from "react";
import {Collapse, List, Typography} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import NewChecklist from "./NewChecklist";
import TaskItem from "./TaskItem";

const CheckList = ({groups, items, services, onToggleItem, onNewSubmit, onUpdateSave, onDeleteItem}) => {
  const {Panel} = Collapse;

  const [openNewDialog, setOpenNewDialog] = React.useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = React.useState<number | string>(0);

  const genExtra = (option: number | string) => (
    <PlusOutlined
      style={{lineHeight: 1.4, fontSize: '20px'}}
      onClick={event => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
        setSelectedGroup(option);
        setOpenNewDialog(true);
      }}
    />
  );

  const handleToggle = (id:number, isDone:boolean) => {
    onToggleItem(id, isDone);
  };

  const handleNewSubmit = (values:any) => {
    onNewSubmit(selectedGroup, values);
  };

  const handleUpdated = (id:number, formData:any) => {
    onUpdateSave(id, formData);
  };

  const handleDelete = (id:number) => {
    onDeleteItem(id);
  };

  return (
    <div className="checklist">
      <Collapse
        className="bg-white"
        defaultActiveKey={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
        expandIconPosition="right"
        bordered={false}
      >
        {
          groups?.map((group) => (
            <Panel className="checklist-accordian-header"
                   header={<Typography.Title style={{display: 'inline', marginBottom: 0}} level={4}>Countdown to {group.name}</Typography.Title>}
                   key={group.id}
                   extra={genExtra(group.id)}
            >
              <div className='my-2'>
                {
                  <List
                    bordered
                    dataSource={items?.filter(item => item.checklistGroupId === group.id)}
                    renderItem={(item:any) => (
                      <List.Item className='flex items-center items-center justify-between'>
                        <TaskItem title={item.title}
                                  id={item.id}
                                  key={`item-${item.id}`}
                                  description={item.description}
                                  remarks={item.remarks}
                                  updatedAt={item.updatedAt}
                                  link={`/products/c/${services?.find(o => o.id === item.serviceCategoryId)?.slug}`}
                                  linkTitle={services?.find(o => o.id === item.serviceCategoryId)?.name}
                                  onToggle={(isDone) => handleToggle(item.id, isDone)}
                                  onUpdated={(formData) => handleUpdated(item.id, formData)}
                                  onDelete={() => handleDelete(item.id)}
                                  isDone={item.status}
                        />
                        {/*<Typography.Text mark>[ITEM]</Typography.Text> {item}*/}
                      </List.Item>
                    )}
                  />
                }
                {
                  /*items?.map(item => (
                    item.checklistGroupId === group.id && (
                      <TaskItem title={item.title}
                                id={item.id}
                                key={`item-${item.id}`}
                                description={item.description}
                                remarks={item.remarks}
                                updatedAt={item.updatedAt}
                                link={`/products/c/${services?.find(o => o.id === item.serviceCategoryId)?.slug}`}
                                linkTitle={services?.find(o => o.id === item.serviceCategoryId)?.name}
                                onToggle={(isDone) => handleToggle(item.id, isDone)}
                                onUpdated={(formData) => handleUpdated(item.id, formData)}
                                onDelete={() => handleDelete(item.id)}
                                isDone={item.status}
                      />
                    )
                  ))*/
                }
              </div>
            </Panel>
          ))
        }
      </Collapse>

      {/* New checklist dialog */}
      <NewChecklist
        // type={checkoutListType}
        closeDialog={() => setOpenNewDialog(false)}
        isOpen={openNewDialog}
        services={services ?? []}
        onSubmit={(values) => handleNewSubmit(values)}
      />
    </div>
  )
};

export default CheckList;