// import './VendorServiceCategoriesForm.scss';

import * as React from 'react'
import { Button, Skeleton } from 'antd';
import {map} from 'lodash'

import { ServiceCategory } from '../../../interfaces/service-category.interface';


interface VendorServiceCategoriesFormProps {
  categories: ServiceCategory[];
  vendorCategories: ServiceCategory[];
}

interface VendorServiceCategoriesFormState {
}


class VendorServiceCategoriesForm extends React.Component <VendorServiceCategoriesFormProps, VendorServiceCategoriesFormState> {
  render() {
    const vendorCategories = map(this.props?.vendorCategories, 'id');
    const selected = (id: any) => vendorCategories.includes(id);

    return (
      <div className="VendorServiceCategoriesForm">
        <div className="categories">
          {
            this.props?.categories == undefined ? <Skeleton active /> : 
            this.props?.categories.map(category => {
              return (
                <Button key={category.id} type="default" disabled={selected(category.id)} className={selected(category.id) ? 'selected': ''}> {category.name} </Button>
              )
            })
          }
        </div>
      </div>
    );
  }
}

export default VendorServiceCategoriesForm;